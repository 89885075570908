import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import grey from '@material-ui/core/colors/grey';
import FormHelperText from '@material-ui/core/FormHelperText';
import { VALIDATION_ERROR_REQUIRED } from 'utility/constants';
import { Field } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grouped: {
      backgroundColor: 'white',
      color: grey[500],
      width: '100%',
      border: 'none',
      borderRadius: 0,
      '&:not(:first-child)': {
        borderLeft: `1px solid ${grey[300]}`,
      },
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    root: {
      boxShadow: theme.shadows[1],
      borderRadius: 0,
      width: '100%',
    },
  })
);

export interface Props extends Field {
  onChange: (value: fhir.CodeableConcept | undefined, name: string) => void;
  options: fhir.CodeableConcept[];
  value: string | undefined;
}

const validate = (value: string | undefined, required: boolean | undefined) => {
  if (required && !value) {
    return VALIDATION_ERROR_REQUIRED;
  }
};

const ButtonGroup: React.FC<Props> = ({
  disabled,
  label,
  name,
  onChange,
  onValidate,
  options,
  required,
  value,
}) => {
  const classes = useStyles();

  const [showErrors, setShowErrors] = useState<boolean>(false);

  const handleChange = (event: React.MouseEvent<HTMLElement>, code: string) => {
    const concept =
      options.find((concept: fhir.CodeableConcept) => {
        return concept?.coding?.[0]?.code === code;
      }) || undefined;

    onChange(concept, name);
  };

  const error = validate(value, required) || '';
  const isValid = !error;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const getError = () => {
    return showErrors && !!error ? error || '' : '';
  };
  const singleChoiceFieldError = getError();
  return (
    <Box width={1}>
      {label ? (
        <Box pb={0.5}>
          <FormLabel required={required}>{label}</FormLabel>
        </Box>
      ) : null}
      <ToggleButtonGroup
        size="medium"
        value={value}
        exclusive
        onChange={handleChange}
        onFocus={() => setShowErrors(false)}
        onBlur={() => setShowErrors(true)}
        classes={{
          root: classes.root,
          grouped: classes.grouped,
        }}
      >
        {options.map(item => (
          <ToggleButton
            key={item?.coding?.[0]?.code}
            disableRipple
            disableFocusRipple
            value={item?.coding?.[0]?.code}
            classes={{
              selected: classes.selected,
            }}
            disabled={disabled}
          >
            {item?.coding?.[0]?.display || item?.coding?.[0]?.code}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {singleChoiceFieldError && (
        <FormHelperText error={!!singleChoiceFieldError}>
          {singleChoiceFieldError}
        </FormHelperText>
      )}
    </Box>
  );
};

export default ButtonGroup;
