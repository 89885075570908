import { TelemedicineEncounterStatus } from 'telemedicine/encounters/constants';

export const ENTRY_LINK = {
  path: '/my-encounters',
  text: 'My Encounters',
  module: 'my-encounters',
  component: 'Dashboard',
};
export interface IEncounterWithReview extends fhir.Encounter {
  reviews: IEncounterReview[];
}

export type IEncounterReview = {
  status: TelemedicineEncounterStatus;
  text: string;
};
