import { Palette } from '@woundtech/ui-colors';
import { INTERVAL_HISTORY } from 'interval-history/constants';

export const STATUS_STARTED = 'Started';
export const STATUS_COMPLETED = 'Completed';
export const STATUS_SIGNED = 'Signed';
export const STATUS_ESIGNED = 'eSigned';
export const VISIT_FORMS = 'Forms';
export const ADDITIONAL_ACTIONS = 'Additional actions';
export const FORMS_VISIT = 'Forms Visited';
export const COMPOSITION_FINAL = 'final';
export const GUARDIAN = 'Guardian';
export const SIGNED_ON_PAPER = 'Signed on Paper';

export const ENCOUNTER_LIST_TITLE = 'Encounters';
export const ENCOUNTER_VIEW_TITLE = 'Encounter';
export const ENCOUNTER_STATUS_PLANNED = 'planned';
export const ENCOUNTER_STATUS_IN_PROGRESS = 'in-progress';
export const ENCOUNTER_STATUS_FINISHED = 'finished';
export const ENCOUNTER_STATUS_AMENDED = 'amendment';
export const ENCOUNTER_STATUS_ESIGNED = 'eSigned';
export const ENCOUNTER_STATUS_CANCELED = 'cancelled';
export const ADMISSION_CONSENT = 'admission-consent';
export const MEDICAL_HISTORY = 'medical-history';
export const MEDICAL_HISTORY_TITLE = 'Medical History';
export const WOUND_ASSESSMENT = 'wound-assessment';
export const BARRIERS = 'Barriers';
export const HPI = 'HPI';
export const PATIENT_CHECK_IN_VIEW = 'patient-checkin-view';
export const PATIENT_CHECK_OUT = 'patient-checkout';
export const WOUND_CODE = 'wounds';
export const ENCOUNTER_STATUS_AMENDMENT_IN_PROGRESS = 'amendmentInprogress';
export const ENCOUNTER_STATUS_INPROGRESS = 'inProgress';
export const TELEMEDICINE_CHECKIN = 'telemedicine-checkin';
export const TELEMEDICINE = 'TELEMEDICINE';
export const CHECKED_IN_AT = 'CHECKED_IN_AT';
export const ENCOUNTER_LOCATION_ID = 'checkOutLocation';
export const PASSWORD_VERIFY_ERR_MSG =
  'Wrong password! Please click change password in the user profile or contact your admin.';
export const CHECKOUT_TIME_ERROR =
  'Check out time cannot be before check in time';
export const IMPORTED_ENCOUNTER_SYSTEM =
  'http://woundexpert.com/fhir/encounter/id';
export const ENCOUNTER_NOT_CANCELED_STATUSES = [
  ENCOUNTER_STATUS_PLANNED,
  ENCOUNTER_STATUS_IN_PROGRESS,
  ENCOUNTER_STATUS_FINISHED,
];
export const TOTAL_SCORE = 'total-score';
export const ONSET_SINCE = 'onset-since';
export const ONSET_SINCE_REQUIRED = 'onset-since-required';
export const ONSET_UNKNOWN = 'onset-unknown';
export const AUTHORIZATION_TITLE = 'Authorization';
export const SELECT_AUTHORIZATION_HEADING = 'Select Authorization';
export const AUTHORIZATION_ALERT_TEXT =
  'An active authorization has not been attached to this encounter.';
export const SOCIAL_HISTORY_FORM = 'Questionnaire/med-history-social';
export const ADMISSION_CONSENT_FORM = 'Questionnaire/admission-consent';
export const socForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-social',
  'Questionnaire/care-plan',
];
export const allergyForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-allergy',
  'Questionnaire/care-plan',
];
export const socialForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-social',
  'Questionnaire/care-plan',
];
export const medicationForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-medication',
  'Questionnaire/care-plan',
];
export const surgicalForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-surgical',
  'Questionnaire/care-plan',
];
export const familyForms = [
  'Questionnaire/wound-assessment',
  'Questionnaire/review-of-systems',
  'Questionnaire/current-medical-condition',
  'Questionnaire/med-history-family',
  'Questionnaire/care-plan',
];

export const questionnaireResponseMapping = {
  'care-plan': {
    'care-plan-encounter-duration-required':
      'care-plan-encounter-duration-required',
    'care-plan-patient-acuity-group-patient-acuity':
      'care-plan-patient-acuity-group-patient-acuity',
    'care-plan-treatment-pathway-group-treatment-pathway':
      'care-plan-treatment-pathway-group-treatment-pathway',
    'care-plan-suspend-encounter-group-reason':
      'care-plan-suspend-encounter-group-reason',
    'care-plan-overall-assessment-group-overall-assessment':
      'care-plan-overall-assessment-group-overall-assessment',
    'care-plan-treatment-plan-group-treatment-plan-reason':
      'care-plan-treatment-plan-group-treatment-plan-reason',
    'care-plan-progress-info-reason': 'care-plan-progress-info-reason',
    'care-plan-treatment-plan-group-treatment-plan':
      'care-plan-treatment-plan-group-treatment-plan',
  },
  'skin-assessment': {
    'skin-assessment-skin-check-group-skin-check':
      'skin-assessment-skin-check-group-skin-check',
    'skin-assessment-skin-check-performed-on-group-skin-check-performed-on':
      'skin-assessment-skin-check-performed-on-group-skin-check-performed-on',
    'skin-assessment-result-group-results':
      'skin-assessment-result-group-results',
  },
  barriers: [
    'local-factors-additional-barriers-to-healing',
    'local-factors-anatomical-contractures-acquired-or-congenital-deformities-group-anatomical-contractures-acquired-or-congenital-deformities-status',
    'local-factors-bioburden-group-bioburden-status',
    'local-factors-non-viable-tissue-necrotic-group-non-viable-tissue-necrotic-status',
    'local-factors-offloading-group-offloading-status',
    'local-factors-poor-perfusion-group-poor-perfusion-status',
    'local-factors-edema-group-edema-status',
    'local-factors-incontinence-group-incontinence-status',
    'social-factors-additional-barriers-to-healing',
    'social-factors-lack-of-adequate-assistance-group-lack-of-adequate-assistance-status',
    'social-factors-lack-of-ancillary-services-pt-sw-ot-group-lack-of-ancillary-services-pt-sw-ot-status',
    'social-factors-lack-of-appropriate-food-group-lack-of-appropriate-food-status',
    'social-factors-lack-of-communication-phone-internet-or-language-barriers-group-lack-of-communication-phone-internet-or-language-barriers-status',
    'social-factors-lack-of-financial-resources-for-dme-or-medications-group-lack-of-financial-resources-for-dme-or-medications-status',
    'social-factors-lack-of-safety-or-sanitary-condition-group-lack-of-safety-or-sanitary-condition-status',
    'social-factors-lack-of-secure-housing-group-lack-of-secure-housing-status',
    'social-factors-lack-of-transportation-group-lack-of-transportation-status',
    'systemic-factors-additional-barriers-to-healing',
    'systemic-factors-chronic-diseases-group-chronic-diseases-status',
    'systemic-factors-immunosuppressive-disease-group-immunosuppressive-disease-status',
    'systemic-factors-malnutrition-group-malnutrition-status',
    'systemic-factors-medications-chemicals-group-medications-chemicals-status',
    'systemic-factors-psychophysiological-stress-group-psychophysiological-stress-status',
    'systemic-factors-substance-abuse-alcohol-or-street-drugs-including-thc-group-substance-abuse-alcohol-or-street-drugs-including-thc-status',
  ],
  'current-medical-condition': {
    'chief-complaint': 'chief-complaint',
    'hpi-narrative': 'hpi-narrative',
    'current-medical-condition-do-you-have-pain':
      'current-medical-condition-do-you-have-pain',
    'cur-med-condition-pain-quality': 'cur-med-condition-pain-quality',
    'cur-med-condition-severity': 'cur-med-condition-severity',
    'information-obtained-from-required': 'information-obtained-from-required',
    'onset-since-required': 'onset-since-required',
    'cur-med-condition-severity-required':
      'cur-med-condition-severity-required',
    'temporal-factor': 'temporal-factor',
    'context-origin': 'context-origin',
    'alleviating-modifying-factors': 'alleviating-modifying-factors',
    'associated-signs-symptoms-required': 'associated-signs-symptoms-required',
    'additional-hpi-narrative': 'additional-hpi-narrative',
    ONSET_SINCE: ONSET_SINCE,
    ONSET_UNKNOWN: ONSET_UNKNOWN,
  },
  'pressure-ulcer-risk-assessment': {
    activity: 'activity',
    'friction-shear': 'friction-shear',
    mobility: 'mobility',
    moisture: 'moisture',
    nutrition: 'nutrition',
    'sensory-perception': 'sensory-perception',
    'total-score': 'total-score',
  },
  'wound-assessment': {
    'wound-name': 'wound-name',
    'wound-type': 'wound-type',
    'onset-date': 'onset-date',
    'wound-origin': 'wound-origin',
    'acquired-in-facility-or-hospital': 'acquired-in-facility-or-hospital',
    'wound-condition': 'wound-condition',
    'wound-status': 'wound-status',
    'wound-resolved-by': 'wound-resolved-by',
    'date-resolved': 'date-resolved',
    'wound-location-body-site': 'wound-location-body-site',
    'diagnosis-wound-icd-code': 'diagnosis-wound-icd-code',
    'wound-measurement-length': 'wound-measurement-length',
    'wound-measurement-width': 'wound-measurement-width',
    'wound-measurement-depth': 'wound-measurement-depth',
    'wound-appearance-stage': 'wound-appearance-stage',
    'wound-appearance-thickness': 'wound-appearance-thickness',
    'wound-appearance-exudate-amount': 'wound-appearance-exudate-amount',
    'wound-appearance-exudate-type': 'wound-appearance-exudate-type',
    'wound-appearance-wound-margin': 'wound-appearance-wound-margin',
    'wound-appearance-odor': 'wound-appearance-odor',
    'wound-appearance-was-the-pain-measured':
      'wound-appearance-was-the-pain-measured',
    'wound-appearance-pain-severity': 'wound-appearance-pain-severity',
    'wound-appearance-reasons-for-not-measuring-pain':
      'wound-appearance-reasons-for-not-measuring-pain',
    'characteristics-of-tissue-adherent-slough':
      'characteristics-of-tissue-adherent-slough',
    'characteristics-of-tissue-moist-slough':
      'characteristics-of-tissue-moist-slough',
    'characteristics-of-tissue-dry-eschar':
      'characteristics-of-tissue-dry-eschar',
    'characteristics-of-tissue-moist-eschar':
      'characteristics-of-tissue-moist-eschar',
    'characteristics-of-tissue-epithelialization':
      'characteristics-of-tissue-epithelialization',
    'characteristics-of-tissue-granulation':
      'characteristics-of-tissue-granulation',
    'characteristics-of-tissue-granulation-texture':
      'characteristics-of-tissue-granulation-texture',
    'characteristics-of-tissue-granulation-color':
      'characteristics-of-tissue-granulation-color',
    'characteristics-of-tissue-unable-to-assess-group-unable-to-assess':
      'characteristics-of-tissue-unable-to-assess-group-unable-to-assess',
    'characteristics-of-tissue-unable-to-assess-group-reason':
      'characteristics-of-tissue-unable-to-assess-group-reason',
    'periwound-skin-appearance-texture': 'periwound-skin-appearance-texture',
    'periwound-skin-appearance-edema-grade':
      'periwound-skin-appearance-edema-grade',
    'periwound-skin-appearance-moisture': 'periwound-skin-appearance-moisture',
    'periwound-skin-appearance-color': 'periwound-skin-appearance-color',
    'periwound-skin-appearance-temperature':
      'periwound-skin-appearance-temperature',
    'periwound-skin-appearance-local-pulse':
      'periwound-skin-appearance-local-pulse',
  },
  'nutritional-assessment': {
    'food-intake-decline-id': 'food-intake-decline-id',
    'nutritional-assessment-nutritional-screening-weight-loss-during-last-3-months':
      'nutritional-assessment-nutritional-screening-weight-loss-during-last-3-months',
    'nutritional-assessment-nutritional-screening-patients-current-mobility-status':
      'nutritional-assessment-nutritional-screening-patients-current-mobility-status',
    'has-suffered-stress-id': 'has-suffered-stress-id',
    'nutritional-assessment-nutritional-screening-neuro-psychological-problems':
      'nutritional-assessment-nutritional-screening-neuro-psychological-problems',
    'nutritional-assessment-nutritional-screening-body-mass-index-bmi-height':
      'nutritional-assessment-nutritional-screening-body-mass-index-bmi-height',
    'nutritional-assessment-nutritional-screening-body-mass-index-bmi-weight':
      'nutritional-assessment-nutritional-screening-body-mass-index-bmi-weight',
    'nutritional-assessment-nutritional-screening-body-mass-index-bmi-bmi':
      'nutritional-assessment-nutritional-screening-body-mass-index-bmi-bmi',
    'nutritional-assessment-assessment-lives-independently-not-in-a-nursing-home-or-hospital':
      'nutritional-assessment-assessment-lives-independently-not-in-a-nursing-home-or-hospital',
    'nutritional-assessment-assessment-takes-more-than-3-prescription-drugs-per-day':
      'nutritional-assessment-assessment-takes-more-than-3-prescription-drugs-per-day',
    'nutritional-assessment-assessment-pressure-sores-or-skin-ulcers':
      'nutritional-assessment-assessment-pressure-sores-or-skin-ulcers',
    'nutritional-assessment-assessment-how-many-full-meals-does-the-patient-eat-daily':
      'nutritional-assessment-assessment-how-many-full-meals-does-the-patient-eat-daily',
    'nutritional-assessment-assessment-selected-consumption-markers-for-protein-intake':
      'nutritional-assessment-assessment-selected-consumption-markers-for-protein-intake',
    'nutritional-assessment-assessment-consumes-two-or-more-servings-of-fruits-or-vegetables-per-day':
      'nutritional-assessment-assessment-consumes-two-or-more-servings-of-fruits-or-vegetables-per-day',
    'nutritional-assessment-assessment-fluid-water-juice-coffee-tea-milk-is-consumed':
      'nutritional-assessment-assessment-fluid-water-juice-coffee-tea-milk-is-consumed',
    'nutritional-assessment-assessment-mode-of-feeding':
      'nutritional-assessment-assessment-mode-of-feeding',
    'nutritional-assessment-assessment-self-view-of-nutritional-status':
      'nutritional-assessment-assessment-self-view-of-nutritional-status',
    'nutritional-assessment-assessment-comparison-with-other-people':
      'nutritional-assessment-assessment-comparison-with-other-people',
    'nutritional-assessment-assessment-mid-arm-circumference':
      'nutritional-assessment-assessment-mid-arm-circumference',
    'nutritional-assessment-assessment-calf-circumference':
      'nutritional-assessment-assessment-calf-circumference',
  },
  'lower-extremity': {
    'lower-extremity-assessment-pulses-popliteal-left':
      'lower-extremity-assessment-pulses-popliteal-left',
    'lower-extremity-assessment-pulses-popliteal-right':
      'lower-extremity-assessment-pulses-popliteal-right',
    'lower-extremity-assessment-pulses-posterior-tibial-left':
      'lower-extremity-assessment-pulses-posterior-tibial-left',
    'lower-extremity-assessment-pulses-posterior-tibial-right':
      'lower-extremity-assessment-pulses-posterior-tibial-right',
    'lower-extremity-assessment-pulses-dorsalis-pedis-left':
      'lower-extremity-assessment-pulses-dorsalis-pedis-left',
    'lower-extremity-assessment-pulses-dorsalis-pedis-right':
      'lower-extremity-assessment-pulses-dorsalis-pedis-right',
    'lower-extremity-assessment-blood-pressures-brachial-left':
      'lower-extremity-assessment-blood-pressures-brachial-left',
    'lower-extremity-assessment-blood-pressures-brachial-right':
      'lower-extremity-assessment-blood-pressures-brachial-right',
    'lower-extremity-assessment-blood-pressures-ankle-left':
      'lower-extremity-assessment-blood-pressures-ankle-left',
    'lower-extremity-assessment-blood-pressures-ankle-right':
      'lower-extremity-assessment-blood-pressures-ankle-right',
    'lower-extremity-assessment-blood-pressures-ankle-brachial-index-left':
      'lower-extremity-assessment-blood-pressures-ankle-brachial-index-left',
    'lower-extremity-assessment-blood-pressures-ankle-brachial-index-right':
      'lower-extremity-assessment-blood-pressures-ankle-brachial-index-right',
  },
  'med-history-family': [
    'family-history-father-living-group-father-living',
    'family-history-father-living-group-fathers-age',
    'family-history-mother-living-group-mother-living',
    'family-history-mother-living-group-mothers-age',
    'family-history-family-diagnosis-any-family-members-diagnosed-with-the-following',
    'family-history-notes',
  ],
  'med-history-social': [
    'social-history-do-you-have-a-caregiver-that-you-would-like-involved-in-your-wound-treatment',
    'social-history-do-you-have-a-caregiver-that-you-would-like-involved-in-your-wound-treatment-caregiver-name',
    'social-history-alcohol-consumption',
    'social-history-alcohol-consumption-amount-of-drinks',
    'social-history-alcohol-consumption-frequency',
    'social-history-tobacco-consumption',
    'social-history-tobacco-consumption-tobacco-type',
    'social-history-tobacco-consumption-quit',
    'social-history-tobacco-consumption-quit-date',
    'social-history-do-you-require-assistance-walking-moving-around',
    'social-history-do-you-require-assistance-walking-moving-around-select-type',
  ],
  'educational-delivery': {
    'educational-delivery-training-provided-to':
      'educational-delivery-training-provided-to',
    'educational-delivery-training-provided-on':
      'educational-delivery-training-provided-on',
  },
};

export const medicalHistoryForms = [
  'med-history-condition',
  'med-history-medication',
  'med-history-surgical',
  'med-history-allergy',
  'med-history-social',
  'med-history-family',
];

export const STATUS = 'status';
export const MULTI_NOTES = 'multi-notes';
export const STATUS_POST = 'status-post';
export const ACTIVE_STATUS = 'active';

export const followUpForm = [
  'Questionnaire/wound-assessment',
  'Questionnaire/care-plan',
  `Questionnaire/${INTERVAL_HISTORY}`,
];

export const PARENT_ENCOUNTER_TYPE_CODE = 'parent';

export const VALUE_SET_DELIVERY_TYPE =
  'http://woundtech.net/fhir/ValueSet/delivery-type';

export const VALUE_SET_ENCOUTER_REASON_CANCELLED_URL =
  'http://woundtech.net/fhir/ValueSet/encounter-reason-cancelled';

export const ESIGN_VERIFY_CAPTION =
  'As the clinician, I acknowledge that I completed documentation for this encounter and I am e-signing the below documentation.';

export enum EncounterStatus {
  canceled = 'cancelled',
  finished = 'finished',
  inProgress = 'in-progress',
  planned = 'planned',
}

export enum EncounterState {
  inProgress = 'inProgress',
  checkedIn = 'Checked-in',
  checkedOut = 'checkedOut',
  eSigned = 'eSigned',
  amendmentInprogress = 'amendmentInprogress',
}

export const getCompositionStatusColor = compositionStatus => {
  switch (compositionStatus) {
    case 'Amended': {
      return Palette.Purple.Main;
    }
    case 'Amendment in progress': {
      return Palette.Orange.Main;
    }
    case 'Signed': {
      return Palette.Teal.Dark;
    }
    default: {
      return Palette.Purple.Main;
    }
  }
};

export const AMENDMENT_IN_PROGRESS_TEXT = 'Amendment in progress';

export enum EncounterDeliveryType {
  inPerson = 'in-person',
  telemedicine = 'telehealth',
}

export const CHECKED_IN = 'checked-in';
export const CHECKED_OUT = 'checked-out';

export const TELEMEDICINE_CHECK_IN: fhir.CodeableConcept = {
  coding: [
    {
      system:
        'http://woundtech.net/fhir/CodeSystem/woundtech-clinical-telemedicine-check-in',
      code: 'telemedicine-check-in',
      display: 'Check in',
    },
  ],
};

export const TELEMEDICINE_CHECK_OUT: fhir.CodeableConcept = {
  coding: [
    {
      system:
        'http://woundtech.net/fhir/CodeSystem/woundtech-clinical-telemedicine-check-in',
      code: 'telemedicine-check-out',
      display: 'Check out',
    },
  ],
};

export const TM_ENCOUNTER_ACTION_CHK_IN = 'checkIn';
export const TM_ENCOUNTER_ACTION_CHK_OUT = 'checkout';
export const TM_ENCOUNTER_ACTION_ADD_TO_WAITING_ROOM = 'addToWaitingRoom';
export const ENCOUNTER_STATUS_REQUESTED = 'requested';
export const PATIENT_CHECKED_OUT_URL =
  'http://woundtech.net/fhir/extensions/encounter/patient-checked-out-at';
export const SUSPEND_ENCOUNTER_URL =
  'http://woundtech.net/fhir/extensions/encounter/suspend-encounter';

export const DEFAULT_DELIVERY_TYPE = {
  system: 'http://woundtech.net/fhir/CodeSystem/delivery-type',
  code: 'in-person',
  display: 'In person',
};

export const ENCOUNTER_CANCELLATION_URL =
  'http://woundtech.net/fhir/CodeSystem/encounter-reason-cancelled';

export const ENCOUNTER_CANCELLATION_REASON_ERROR = {
  system: ENCOUNTER_CANCELLATION_URL,
  code: 'Error',
  display: 'The Encounter was Entered in error',
};
export const DISCHARGE_ENCOUTERLINKS_LIST_LABEL = 'Discharge';
