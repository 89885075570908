import { PlaceholderKind } from '@woundtech/ui-constants';
import { ucFirst } from '@woundtech/ui-utils';
import { textMaskFormat, INPUT_MASK_PHONE_NUMBER } from 'styleguide/form/Text';
import { getPhoneNumberExtension } from './helpers';

export const faxNumber = (data: fhir.ContactPoint | undefined) => {
  if (!data) {
    return PlaceholderKind.NotAvailable;
  }

  const number = data?.value
    ? textMaskFormat(data?.value, INPUT_MASK_PHONE_NUMBER)
    : '';

  return `${number} (Fax)`;
};

export const phoneNumber = (data: fhir.ContactPoint | undefined) => {
  if (!data) {
    return PlaceholderKind.NotAvailable;
  }

  const number = data?.value
    ? textMaskFormat(data?.value, INPUT_MASK_PHONE_NUMBER)
    : '';
  const type = ucFirst(data?.use || '');
  const extension = getPhoneNumberExtension(data) || '';

  if (type === 'Work' && number && extension) {
    return `(${type}) ${number} ext.${extension}`;
  } else if (type === 'Work' && number) {
    return `(${type}) ${number}`;
  } else if (type === 'Temp' && number) {
    return `(Other) ${number}`;
  } else if (type !== '') {
    return `(${type}) ${number}`;
  } else {
    return `${number}`;
  }
};

export const phoneNumbers = (
  telecome: fhir.ContactPoint[] | undefined,
  showMultiple: boolean = false
) => {
  if (!telecome || telecome.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  const items = showMultiple
    ? telecome.filter(item => item.system === 'phone')
    : telecome
        .filter(item => item.system === 'phone')
        .filter((_, index) => index < 1);

  return (
    items.map(item => phoneNumber(item)).join(', ') ||
    PlaceholderKind.NotAvailable
  );
};

export const email = (telecome: fhir.ContactPoint[] | undefined) => {
  if (!telecome || telecome.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  const items = telecome
    .filter(item => item.system === 'email')
    .filter((_, index) => index < 1);

  return items?.[0]?.value || PlaceholderKind.NotAvailable;
};

export const fax = (telecom: fhir.ContactPoint[] | undefined) => {
  if (!telecom || telecom.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  const items = telecom
    .filter(item => item.system === 'fax')
    .filter((_, index) => index < 1);

  return (
    textMaskFormat(items?.[0]?.value, INPUT_MASK_PHONE_NUMBER) ||
    PlaceholderKind.NotAvailable
  );
};

export const portalURL = (telecom: fhir.ContactPoint[] | undefined) => {
  if (!telecom || telecom.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  const items = telecom
    .filter(item => item.system === 'url')
    .filter((_, index) => index < 1);

  return items?.[0]?.value || PlaceholderKind.NotAvailable;
};

export const phoneAndFax = (telecom: fhir.ContactPoint[] | undefined) => {
  if (!Array.isArray(telecom) || telecom.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  const items = telecom?.filter(
    item => (item.system === 'fax' || item.system === 'phone') && item.value
  );

  if (items.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  return items
    .map((item: fhir.ContactPoint) => {
      if (item.system === 'fax') {
        return faxNumber(item);
      }

      return phoneNumber(item);
    })
    .join(', ');
};
