import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';

export type Props = {
  definitions: any[];
  onDelete: (id: string) => void;
  values: any;
  disabled?: boolean;
};

const DataListChips: React.FC<Props> = ({
  definitions,
  disabled,
  onDelete,
  values,
}) => {
  const chips = definitions
    .filter(definition => values[definition.id])
    .map(definition => {
      const value = values[definition.id];
      const label = definition.title ? `${definition.title}: ${value}` : value;

      return {
        label,
        key: definition.id,
      };
    })
    .filter(chip => !!chip);

  return (
    <Box display="flex" flexWrap="wrap" width={1}>
      {chips.map(chip => (
        <Box mr={1} mb={1} key={chip?.key} maxWidth={1}>
          <Chip
            label={chip?.label}
            size="small"
            onDelete={() => onDelete(chip?.key)}
            disabled={disabled}
          />
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(DataListChips);
