import { deepCopyObject } from 'utility/helpers';

export type Action = { type: 'setUserPreferences'; value?: any };

export type State = {
  userPreferences?: any;
};

export const defaultState: State = {};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setUserPreferences': {
      const newState = {
        ...state,
        userPreferences: deepCopyObject(action.value),
      };
      return newState;
    }
  }
}
