export const CONFIG_MULTI_OPTIONS_VISIBILITY_STATUS = {
  '/clinical-config/orders': 'EnableOrders',
  '/clinical-config/educational-training': 'EnableEducationalDelivery',
  '/clinical-config/review-of-systems': 'EnableReviewOfSystems',
  '/clinical-config/barriers': 'EnableBarriers',
  '/clinical-config/treatment-steps': 'EnableTreatments',
  '/clinical-config/products': 'EnableTreatments',
  '/clinical-config/wound-type': 'EnableTreatments',
  '/clinical-config/procedures': 'EnableTreatments',
  '/notifications': 'EnableInAppNotificationConfig',
};

export const OLDER_VERSION_TEXT = 'Version not updated to';
export const CONTACT_SUPPORT = 'please contact EMR support';
export const PASSWORD_EXPIRY_ALERT_KEY = 'password:expiry:alert';

export const ACCORDION_STATUS_LINK_TARGETS = {
  'Order has been e-signed': 'orders',
  'Draft order created': 'orders',
  'Order has been confirmed': 'orders',
  'New Results added in orders': 'orders',
  'Patient has been added': 'my-schedule',
  'Patient has been rescheduled': 'my-schedule',
  'Patient has been removed': 'my-schedule',
  'Patient has been put on hold': 'admission',
  'Patient added to scheduling': 'my-schedule',
  'CCD Document':'ccd'
};
