import {
  CARE_FACILITY_ROLE_URL,
  CONTACT_PERSON_PRIMARY,
  CONTACT_PERSON_STATUS,
  INSTRUCTION_TYPE,
} from 'master-list/constants';

export const getSystemCode = (data, system) =>
  data?.find(item => item?.coding?.[0]?.system === system);

export const getTelecomValue = (contact, type) =>
  contact?.find(item => item?.system === type)?.value;
export const checkTelecomIsEmpty = (telecom, type) => {
  let check = false;
  if (typeof telecom === 'undefined' || Object.keys(telecom).length === 0) {
    return true;
  }
  if (
    type === 'fax' &&
    (telecom?.use === '' || typeof telecom?.use === 'undefined') &&
    telecom?.value === ''
  ) {
    check = true;
  }
  if (
    type === 'phone' &&
    (telecom?.use === '' || typeof telecom?.use === 'undefined') &&
    telecom?.value === ''
  ) {
    check = true;
  }
  if (type === 'email' && telecom?.value === '') check = true;
  return check;
};

export const checkIfEmpty = listOfObjects => {
  return listOfObjects?.map(
    object =>
      checkTelecomIsEmpty(
        object?.telecom?.filter(telecom => telecom.system === 'phone')?.[0],
        'phone'
      ) &&
      checkTelecomIsEmpty(
        object?.telecom?.filter(telecom => telecom.system === 'email')?.[0],
        'email'
      ) &&
      checkTelecomIsEmpty(
        object?.telecom?.filter(telecom => telecom.system === 'fax')?.[0],
        'fax'
      )
  );
};

export const formatTelecomData = telecom => {
  let telecomData: any[] = [];
  telecom?.forEach((contactInfo, index) => {
    const rankedTelecomData =
      contactInfo?.telecom?.map(item => ({ ...item, rank: index + 1 })) || [];
    telecomData = [...telecomData, ...rankedTelecomData];
  });
  return telecomData;
};

export const groupBy = (arr, prop) => {
  const groupedTelecom = (arr || [])?.reduce(
    (groups, item) => ({
      ...groups,
      [item[prop]]: [...(groups[item[prop]] || []), item],
    }),
    {}
  );

  return Object.keys(groupedTelecom)?.map(key => ({
    telecom: groupedTelecom[key],
  }));
};

export const getRoleFromPractitioner = practitioner => {
  return practitioner?.qualification?.find(
    item => item?.code?.coding?.[0]?.system === CARE_FACILITY_ROLE_URL
  )?.code?.coding?.[0]?.display;
};

export const getRoleCodeableConcept = roleText => {
  if (roleText?.length === 0) {
    return undefined;
  }
  const roleCode = roleText
    ?.trim()
    ?.toLowerCase()
    ?.replace(' ', '-');
  return {
    coding: [
      {
        system: CARE_FACILITY_ROLE_URL,
        code: roleCode,
        display: roleText,
      },
    ],
    text: roleText,
  };
};

export const getExtensionValueBoolean = (contact, url) => {
  return contact?.extension?.find(item => item?.url === url)?.valueBoolean;
};

export const getExtensionValueString = (extension, url) => {
  return extension?.find(item => item?.url === url)?.valueString;
};

export const isExtensionExists = (extension, url, value) => {
  return (
    extension?.filter(item => item?.url === url && item?.valueString === value)
      .length > 0
  );
};

export const isContactPrimary = contact => {
  return getExtensionValueBoolean(contact, CONTACT_PERSON_PRIMARY) === true;
};

export const isContactActive = contact => {
  return getExtensionValueBoolean(contact, CONTACT_PERSON_STATUS) === true;
};

export const setPayloadtoUpdateHealthCareContact = (
  careDetails,
  newContactData
) => {
  const practitioners = careDetails?.practitioners?.map(item => ({
    ...item,
    resourceType: 'Practitioner',
  }));

  return {
    organization: careDetails.organization,
    practitioners: [...practitioners, newContactData],
  };
};

export const setTelecomAndPractitionerCareFacility = careFacility => {
  const telecomData = formatTelecomData(careFacility?.organization?.telecom);
  const practitioners = careFacility?.practitioners?.map(item => ({
    ...item,
    resourceType: 'Practitioner',
  }));
  return {
    ...careFacility,
    organization: { ...careFacility?.organization, telecom: telecomData },
    practitioners,
  };
};

export const isPrimary = extension =>
  extension.filter(
    item => item.valueBoolean && item.url === CONTACT_PERSON_PRIMARY
  )?.length;
const updateExtension = extension =>
  extension.map(item => {
    if (item.url === CONTACT_PERSON_PRIMARY) {
      return { ...item, valueBoolean: false };
    }
    return item;
  });

export const updatePayloadAndSetPrimary = (participant, formData) => {
  if (!participant?.length) {
    return [];
  }
  if (isPrimary(formData?.extension)) {
    return participant.map(item => ({
      ...item.member.resource,
      extension: updateExtension(item?.member?.resource?.extension),
    }));
  } else return participant.map(item => item.member.resource);
};

export const getInstruction = extension =>
  extension?.find(item => item?.url === INSTRUCTION_TYPE);
