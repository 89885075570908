import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export type ModalBarProps = {
  title?: string;
  disabled?: boolean;
  onCancel: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'sticky',
      backgroundColor: '#2196F3',
    },
    title: {
      fontSize: '20px',
    },
    continueButton: {
      marginLeft: 'auto',
      marginRight: '8px',
      fontSize: '18px',
      textTransform: 'none',
    },
  })
);

export const ModalBar: React.FC<ModalBarProps> = ({ title, onCancel }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onCancel}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Box display="inline-block" px={1}>
          <Typography color="inherit" className={classes.title}>
            {title}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
