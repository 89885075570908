import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Palette } from '@woundtech/ui-colors';
import { attributeTextStyles } from './AttributeItemTextStyles';

const AttributeListItemLabel: React.FC<{
  text: string;
  variant?: 'small' | 'large' | 'verysmall';
}> = ({ text, variant = 'small' }) => {
  const classes = attributeTextStyles();
  return (
    <Box pr={1} color={Palette.Gray.MediumDarker}>
      <Typography className={classes[variant]} noWrap color="inherit">
        {text}
      </Typography>
    </Box>
  );
};

export default React.memo(AttributeListItemLabel);
