import React from 'react';
import RadioTouchButton from './RadioTouchButton';
import Grid from '@material-ui/core/Grid';
import { OPTION_STATUS } from '../constants';
import Box from '@material-ui/core/Box';

export type Props = {
  value: string | undefined;
  options: any[];
  onChange: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  getOptionId: (option: any) => string | undefined;
  getTag?: (option: any) => any;
  getOptionDisabled?: (option: fhir.CodeableConcept) => boolean;
  getOptionLabel: (option: any) => string | undefined;
  getOptionWidth?: (
    option: any,
    id: string,
    label: string,
    isMobile?: boolean
  ) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  orientation?: string;
  lastSelectedValue?: any;
  inline?: boolean;
  noWrap?: boolean;
  type?: 'button';
};

const RadioTouchGroup: React.FC<Props> = ({
  value,
  options,
  onChange,
  getOptionId,
  getOptionLabel,
  getOptionWidth,
  lastSelectedValue,
  inline,
  noWrap,
  getTag,
  getOptionDisabled,
  type,
}) => {
  const getOptionVariant = (option: fhir.CodeableConcept) => {
    const id = getOptionId(option);
    if (id === value && id === lastSelectedValue) {
      return OPTION_STATUS.selectedAgain;
    } else if (id === value) {
      return OPTION_STATUS.selected;
    } else if (id === lastSelectedValue) {
      return OPTION_STATUS.lastSelected;
    } else {
      return OPTION_STATUS.unSelected;
    }
  };

  const getColumnXS = (
    option,
    id,
    label
  ): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    if (getOptionWidth) {
      return getOptionWidth(option, id, label, true);
    }

    return 12;
  };

  const getColumnSM = (
    option,
    id,
    label
  ): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    if (getOptionWidth) {
      return getOptionWidth(option, id, label);
    }

    if (label.length > 40) {
      return 12;
    } else if (label.length > 18) {
      return 8;
    } else {
      return 4;
    }
  };

  const getColumnMD = (
    option,
    id,
    label
  ): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    if (getOptionWidth) {
      return getOptionWidth(option, id, label);
    }

    if (label.length > 40) {
      return 12;
    } else if (label.length > 18) {
      return 8;
    } else {
      return 4;
    }
  };

  const getColumnXL = (
    option,
    id,
    label
  ): 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 => {
    if (getOptionWidth) {
      return getOptionWidth(option, id, label);
    }

    if (label.length > 40) {
      return 9;
    } else if (label.length > 18) {
      return 6;
    } else {
      return 3;
    }
  };

  return (
    <>
      {type ? (
        <Box display={'flex'} justifyContent={'flex-end'}>
          {options.map(option => {
            const optionLabel = getOptionLabel(option);
            const optionId = getOptionId(option);
            return (
              <Box key={optionId} ml={1}>
                <RadioTouchButton
                  onClick={(event, string) => {
                    if (optionId) {
                      onChange(event, optionId);
                    }
                  }}
                  disabled={getOptionDisabled && getOptionDisabled(option)}
                  value={optionLabel}
                  variant={
                    getOptionDisabled && getOptionDisabled(option)
                      ? OPTION_STATUS.disabled
                      : getOptionVariant(option)
                  }
                  noWrap={noWrap}
                  type={type}
                />
                {getTag && getTag(option)}
              </Box>
            );
          })}
        </Box>
      ) : (
        <Grid container spacing={2} direction={inline ? 'column' : 'row'}>
          {options.map(option => {
            const optionLabel = getOptionLabel(option);
            const optionId = getOptionId(option);
            return (
              <Grid
                item
                key={optionId}
                xs={inline ? 12 : getColumnXS(option, optionId, optionLabel)}
                sm={inline ? 12 : getColumnSM(option, optionId, optionLabel)}
                md={inline ? 12 : getColumnMD(option, optionId, optionLabel)}
                xl={inline ? 9 : getColumnXL(option, optionId, optionLabel)}
              >
                <RadioTouchButton
                  onClick={(event, string) => {
                    if (optionId) {
                      onChange(event, optionId);
                    }
                  }}
                  disabled={getOptionDisabled && getOptionDisabled(option)}
                  value={optionLabel}
                  variant={
                    getOptionDisabled && getOptionDisabled(option)
                      ? OPTION_STATUS.disabled
                      : getOptionVariant(option)
                  }
                  noWrap={noWrap}
                />
                {getTag && getTag(option)}
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default React.memo(RadioTouchGroup);
