import { gql } from '@apollo/client';

export const AddressFragment = gql`
  fragment Address on Address {
    extension {
      url
      valueReference {
        display
        reference
      }
      valueString
    }
    city
    country
    district
    line
    postalCode
    state
    text
    type
    use
  }
`;
