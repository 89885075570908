export const CURRENT_MEDICAL_CONDITION = 'current-medical-condition';
export const CMC_INFORMATION_OBTAINED_FROM = 'information-obtained-from';
export const CMC_CHIEF_COMPLAINT = 'chief-complaint';
export const CMC_HISTORY_OF_PRESENT_ILLNESS = 'history-of-present-illness';
export const CMC_HPI_NARRATIVE = 'hpi-narrative';
export const CMC_DO_YOU_HAVE_PAIN =
  'current-medical-condition-do-you-have-pain';
export const CMC_DO_YOU_HAVE_PAIN_YES = '373076001';
export const CMC_DO_YOU_HAVE_PAIN_NO = '373077005';
export const CMC_QUALITY = 'cur-med-condition-pain-quality';
export const CMC_CONDITION_SEVERITY = 'cur-med-condition-severity';
export const CMC_ONSET_SINCE = 'onset-since';
export const CMC_ONSET_UNKNOWN = 'onset-unknown';
export const CMC_TIMING = 'cur-med-condition-pain-timing';
export const CMC_CONTEXT = 'context';
export const CMC_MODIFYING_FACTORS = 'modifying-factors';
export const CMC_ASSOCIATED_SIGNS_SYMPTOMS = 'associated-signs-symptoms';
export const CMC_LOCATION = 'cur-med-condition-body-location';
export const CMC_NOTES = 'notes';

export const CMC_INFORMATION_OBTAINED_FROM_REQUIRED =
  'information-obtained-from-required';
export const CMC_ONSET_SINCE_REQUIRED = 'onset-since-required';
export const CMC_LOCATION_NOTES = 'cur-med-condition-body-location-notes';
export const CMC_CONDITION_SEVERITY_REQUIRED =
  'cur-med-condition-severity-required';
export const CMC_CONDITION_SEVERITY_NOTES =
  'cur-med-condition-severity-required-notes';
export const CMC_TEMPORAL_FACTORS = 'temporal-factor';
export const CMC_TEMPORAL_FACTORS_NOTES = 'temporal-factors-notes';
export const CMC_CONTEXT_ORIGIN = 'context-origin';
export const CMC_MODIFYING_FACTORS_ALLEVIATING =
  'alleviating-modifying-factors';
export const CMC_ASSOCIATED_SIGNS_SYMPTOMS_REQUIRED =
  'associated-signs-symptoms-required';

export const CURRENT_MEDICAL_CONDITION_TITLE = 'Current Medical Condition';

export const CURRENT_MEDICAL_CONDITION_TEXT = 'Current medical condition';

export const CMC_HISTORY_OF_PRESENT_ILLNESS_TITLE =
  'History of present illness';

export const CMC_NOTES_TITLE = 'Notes';
export const CMC_REASONS_TITLE = 'Reasons';

export const CMC_NO_PAIN_QUALITY = '81765008';
export const CMC_TIMING_TITLE = 'Timing';
