export const ENTRY_PERMISSION = ['settings', 'financial'];
export const PATH_CLINICIAN_LIST = '/clinicians';
export const PATH_CLINICIAN_VIEW = `/clinicians/:clinicianId`;

export function getClinicianViewUrl(clinicianId: string): string {
  return PATH_CLINICIAN_VIEW.replace(':clinicianId', clinicianId);
}

export const ENTRY_LINK = {
  path: '/clinicians',
  text: 'Clinicans',
  module: 'clinician-config',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
