export const MASTER_LIST_PATH = '/master-list';
export const BASE_MARKET_TEAM_URL = `/master-list/market-team-config`;
export const PCC_AVAILABILITY_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/practitioner/pcc/availability';
export const BASE_CARE_FACILITY_URL = `/master-list/care-facility`;
export const BASE_HOME_HEALTH_URL = `/master-list/home-health`;
export const BASE_PCP_CONFIG_URL = `/master-list/pcp-config`;
export const ORGNAIZATION_TYPE =
  'http://woundtech.net/fhir/CodeSystem/organization-type';
export const ORGNAIZATION_SUB_TYPE =
  'http://woundtech.net/fhir/CodeSystem/organization-sub-type';
export const CARE_FACILITY_SUB_TYPE =
  'http://woundtech.net/fhir/ValueSet/care-facility-organization-sub-type';
export const CONTACT_PERSON_STATUS =
  'http://woundtech.net/extensions/fhir/care-team-facility/contact/isActive';
export const CONTACT_PERSON_PRIMARY =
  'http://woundtech.net/extensions/fhir/care-team-facility/contact/isPrimary';
export const INSTRUCTION_TYPE =
  'http://woundtech.net/extensions/fhir/care-team-facility/instruction';
export const CARE_FACILITY_ROLE_URL =
  'http://woundtech.net/fhir/CodeSystem/care-facility-role';

export const CONTACT_PERSON_EMPTY = 'No contact person associated.';
export const CONTACT_INFO_EMPTY = 'No contact info provided.';
export const FAX_INFO_EMPTY = 'No fax info provided.';
export const EMAIL_INFO_EMPTY = 'No Email info provided.';

export const CARE_FACILITY_CODE = 'care-facility';
export const ASSISTED_LIVING_FACILITY_CODE = 'assisted-living-facility';
export const SKILLED_NURSING_FACILITY_CODE = 'skilled-nursing-facility';

export const HOME_HEALTH_CODE = 'home-health';
export const HOME_HEALTH_AGENCY_CODE = 'hha';

export const DEFAULT_TYPE_CARE_FACILITY = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/organization-type',
      code: CARE_FACILITY_CODE,
      display: 'Care Facility',
    },
  ],
  text: 'Care Facility',
};
export const DEFAULT_TYPE_HOME_HEALTH = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/organization-type',
      code: HOME_HEALTH_CODE,
      display: 'Home Health',
    },
  ],
  text: 'Home Health',
};

export const DEFAULT_MOBILE = [
  {
    system: 'phone',
    use: 'mobile',
    value: '',
  },
];

export const DEFAULT_WORK = [
  {
    system: 'phone',
    use: 'work',
    value: '',
  },
];

export const MOBILE_CODE = 'mobile';
export const WORK_CODE = 'work';

export const CREATE_FACILITY_TEXT = 'Facility';
export const CARE_FACILITY_ATTRIBUTES = {
  viewHeaderTitle: 'Facility',
  nameLabel: 'Facility Name',
  typeLabel: 'Facility Type',
  showAddressInstruction: true,
  addressLabel: 'Facility Address',
  contactInfoLabel: 'Facility Contact Info',
  contactPersonLabel: 'Contact Persons',
  showMarket: true,
};

export const CREATE_HOME_HEALTH_TEXT = 'Home Health';
export const HOME_HEALTH_ORG_TYPE = 'Home Health Agency';
export const HOME_HEALTH_ATTRIBUTES = {
  nameLabel: 'Name',
  showAddressInstruction: false,
  addressLabel: 'Address',
  contactInfoLabel: 'Contact Info',
  contactPersonLabel: 'Contact Persons',
  viewHeaderTitle: 'Home Health',
  showMarket: false,
};

export const ASSISTED_LIVING_FACILITY = {
  system: 'http://woundtech.net/fhir/CodeSystem/organization-sub-type',
  code: ASSISTED_LIVING_FACILITY_CODE,
  display: 'Assisted Living Facility',
};

export const SKILLED_NURSING_FACILITY = {
  system: 'http://woundtech.net/fhir/CodeSystem/organization-sub-type',
  code: SKILLED_NURSING_FACILITY_CODE,
  display: 'Skilled Nursing Facility',
};

export const CARE_FACILITY_TYPE = [
  {
    coding: [
      {
        system: 'http://woundtech.net/fhir/CodeSystem/organization-sub-type',
        code: ASSISTED_LIVING_FACILITY_CODE,
        display: 'Assisted Living Facility',
      },
    ],
    text: 'Assisted Living Facility',
  },
  {
    coding: [
      {
        system: 'http://woundtech.net/fhir/CodeSystem/organization-sub-type',
        code: SKILLED_NURSING_FACILITY_CODE,
        display: 'Skilled Nursing Facility',
      },
    ],
    text: 'Skilled Nursing Facility',
  },
];

export const NO_CONTACT_PERSON_SELECTED = 'No contact person selected';

export const CREATE_PCP_TEXT = 'PCP';
export const GENERAL_TEXT = 'GENERAL';
export const SPECIFIC_TEXT = 'SPECIFIC';
