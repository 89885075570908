export const DOCUMENT_TYPE_VALUE_SET_URL =
  'http://woundtech.net/fhir/ValueSet/document-type';

export const DELETE_DOCUMENT_CONFIRMATION_MESSAGE =
  'Are you sure you want to Delete this Document?';
export const DISCARD_DOCUMENT_CONFIRMATION_MESSAGE =
  'Are you sure you want to Discard this Document?';

export const DELETE_BUTTON_TEXT = 'Delete';
export const DISCARD_BUTTON_TEXT = 'Discard';
export const RECALCULATE_SEQUENCE =
  'Your changes to the route have not been saved, do you want to save and recalculate';

export const DEFAULT_OPEN_FOLDER = 'insurance';
export const CCD_OPEN_FOLDER = 'ccd';
export const supportedFileForFax = '.pdf';
export const CONSENT = 'Consent';
export const FOLDER_TYPE_TITLE = {
  insurance: 'Insurances',
  referral: 'Referrals',
  eligibility: 'Eligibilities',
  authorization: 'Authorizations',
  admission: 'Medical Records / Admissions',
  letters: 'Letters',
  'order-result': 'Orders',
  task: 'Tasks',
};
