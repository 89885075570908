import React from 'react';
import FaxField from './Fax';
import EmailField from './Email';
import PhoneField from './Phone';
import URLField from './URL';

import { Field } from '../types';

export interface Props extends Field {
  onChange: (value: fhir.ContactPoint[]) => void;
  onValidate: (isValid: boolean) => void;
  rank?: number;
  system: 'fax' | 'phone' | 'email' | 'url';
  value: fhir.ContactPoint[] | undefined;
  typeRequired?: boolean;
  typeDisabled?: boolean;
}

const defaultRank = 1;

const Telecom: React.FC<Props> = ({
  value,
  disabled,
  onChange,
  onValidate,
  required,
  rank = defaultRank,
  system,
  name,
  typeRequired,
  typeDisabled,
}) => {
  const item = (value || []).find((contactPoint: fhir.ContactPoint) => {
    return contactPoint.system === system;
  }) || {
    system,
    rank,
    value: '',
  };

  const handleItemChange = (newItemValue: fhir.ContactPoint | undefined) => {
    const hasItemValue = newItemValue?.value || newItemValue?.use;
    const currentValue = value || [];
    const index = currentValue.findIndex((contactPoint: fhir.ContactPoint) => {
      return contactPoint.system === system;
    });

    let newValue;
    if (index === -1) {
      newValue = hasItemValue ? [...currentValue, newItemValue] : currentValue;
    } else {
      newValue = currentValue
        .map((item, itemIndex) => {
          if (itemIndex === index && hasItemValue) {
            return newItemValue;
          }

          if (itemIndex === index && !hasItemValue) {
            return undefined;
          }

          return item;
        })
        .filter(item => !!item);
    }

    onChange(newValue);
  };

  const props = {
    disabled,
    name,
    onChange: handleItemChange,
    onValidate,
    required,
    value: item,
    typeRequired,
    typeDisabled,
  };

  switch (system) {
    case 'fax': {
      return <FaxField {...props} />;
    }
    case 'email': {
      return <EmailField {...props} />;
    }
    case 'phone': {
      return <PhoneField {...props} />;
    }
    case 'url': {
      return <URLField {...props} />;
    }
    default: {
      return null;
    }
  }
};

export default React.memo(Telecom);
