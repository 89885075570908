import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Hidden, useMediaQuery } from '@material-ui/core';
import Alert from 'styleguide/layout/Alert/Alert';
import clsx from 'clsx';
import Drawer from 'styleguide/Drawer';
import Navigation from './Navigation';
import LockedScreen from 'styleguide/LockedScreen';

import { Context as AuthorizationContext } from 'authorization/Context';
import { Context as AuthenticationContext } from 'authentication/Context';
import { Context as AppConfigContext } from 'application/Configuration';

import { Bar } from './index';
import { ENTRY_LINK as ACCOUNT_ENTRY_LINK } from 'account';
import { NAVIGATION_DRAWER_WIDTH } from 'utility/constants';
import { debounce, detectDevice } from 'utility/helpers';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';
import { CONFIG_MULTI_OPTIONS_VISIBILITY_STATUS } from './constants';
import { checkRoutePermission } from 'common/utils';
import { RECALCULATE_SEQUENCE } from 'document-management/constants';

// The trick to viewport units on mobile
function getDynamicViewportHeight() {
  const calculatedViewportHeight = 'calc(var(--vh, 1vh) * 100)';
  const isJsCssVarSupported = !!document?.documentElement?.style?.setProperty;
  const isCssVarSupported =
    CSS && CSS.supports && CSS.supports('height', calculatedViewportHeight);

  if (isCssVarSupported && isJsCssVarSupported) {
    return calculatedViewportHeight;
  }

  return '100vh';
}

function setViewportHeight() {
  if (document?.documentElement?.style?.setProperty) {
    setTimeout(() => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    }, 200);
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerOpen: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${NAVIGATION_DRAWER_WIDTH})`,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '100%',
    },
    viewport: {
      height: getDynamicViewportHeight(),
      [theme.breakpoints.up('lg')]: {
        height: `100vh`,
      },
    },
  })
);

export type Props = {
  navigationItems: any[];
};

const PrivateLayout: React.FC<Props> = ({ children, navigationItems }) => {
  const { path } = useRouteMatch();
  const classes = useStyles();
  const { user, isAuthorized } = useContext(AuthorizationContext);
  const {
    signOut,
    lockedScreen,
    screenLock,
    screenUnlock,
    sequencedDestinationsPrivate,
  } = useContext(AuthenticationContext);
  const { IdleLogoutDelay, DeploymentEnvironment } = useContext(
    AppConfigContext
  );

  const IdleScreenLockDelay = window?._env_?.IDLE_LOCK_SCREEN_DELAY || 600000; // need to implement in config api in order to get this from config context
  const featureToggles = useContext(FeatureToggleContext);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const isMobileOS = detectDevice() === 'tablet';
  const [open, setOpen] = React.useState(true);
  const lastActivityRef = useRef(Date.now());

  const [showResequenceAlert, setShowResequenceAlert] = React.useState(false);
  const toggleOpen = useCallback(() => {
    if (
      sequencedDestinationsPrivate &&
      sequencedDestinationsPrivate?.length > 0
    ) {
      setShowResequenceAlert(true);
    } else {
      setOpen(!open);
    }
  }, [sequencedDestinationsPrivate, setShowResequenceAlert, setOpen, open]);

  useEffect(() => {
    isMobile ? setOpen(false) : setOpen(true);
  }, [isMobile, path, setOpen]);

  const handleDragAndDrop = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const {
    function: activateIdleLogout,
    cancel: deactivateIdleLogout,
  } = useMemo(
    () =>
      debounce(() => {
        signOut(true);
      }, IdleLogoutDelay || 5400000),
    [IdleLogoutDelay, signOut]
  );

  const {
    function: activateIdleScreenLock,
    cancel: deactivateIdleScreenLock,
  } = useMemo(
    () =>
      debounce(() => {
        screenLock();
      }, IdleScreenLockDelay || 600000),
    [IdleScreenLockDelay, screenLock]
  );

  const checkTimestamps = useCallback(() => {
    const dateNow = Date.now();
    const timeDiference = dateNow - lastActivityRef.current;
    if (IdleLogoutDelay && timeDiference >= IdleLogoutDelay) {
      signOut(true);
    }
    if (IdleScreenLockDelay && timeDiference >= IdleScreenLockDelay) {
      screenLock();
    }
    lastActivityRef.current = dateNow;
  }, [IdleLogoutDelay, IdleScreenLockDelay, screenLock, signOut]);
  const onActivity = useCallback(
    _ => {
      checkTimestamps();
      activateIdleLogout();
      activateIdleScreenLock();
    },
    [checkTimestamps, activateIdleLogout, activateIdleScreenLock]
  );

  useEffect(() => {
    activateIdleLogout();
    activateIdleScreenLock();
    return () => {
      deactivateIdleLogout();
      deactivateIdleScreenLock();
    };
  }, [
    activateIdleScreenLock,
    deactivateIdleScreenLock,
    deactivateIdleLogout,
    activateIdleLogout,
    isMobileOS,
  ]);

  const onVisibilityChange = useCallback(
    _ => {
      checkTimestamps();
    },
    [checkTimestamps]
  );

  useEffect(() => {
    if (!isMobileOS) {
      return;
    }
    window.addEventListener('visibilitychange', onVisibilityChange, false);
    window.addEventListener('touchstart', onActivity, false);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChange, false);
      window.removeEventListener('touchstart', onActivity, false);
    };
  }, [onVisibilityChange, isMobileOS, onActivity]);

  // The trick to viewport units on mobile
  useEffect(() => {
    setViewportHeight();
    window.addEventListener('orientationchange', setViewportHeight);
    window.addEventListener('resize', setViewportHeight);

    return () => {
      window.removeEventListener('orientationchange', setViewportHeight);
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);

  //close menu on locked in mobile
  useEffect(() => {
    if (isMobile && lockedScreen) {
      setOpen(false);
    }
  }, [isMobile, lockedScreen]);

  const multiOptionsVisibilityStatus = useCallback(
    value => {
      const valueStatus =
        CONFIG_MULTI_OPTIONS_VISIBILITY_STATUS?.[value] || null;
      return valueStatus ? featureToggles?.[valueStatus] : null;
    },
    [featureToggles]
  );

  const filterCheck = option => {
    const checkAllowedConfigList = multiOptionsVisibilityStatus(option?.path);
    let menuItemEnabled = true;
    if (checkAllowedConfigList !== null) {
      menuItemEnabled = Boolean(checkAllowedConfigList === 'true');
    }
    if (option?.permission) {
      const authValue = option?.displayLink
        ? checkRoutePermission(option?.permission, isAuthorized) &&
          option?.displayLink(user, DeploymentEnvironment, featureToggles)
        : checkRoutePermission(option?.permission, isAuthorized);
      return authValue && menuItemEnabled;
    }
    return true;
  };

  const filterPermittedNavigationItems = (navigationItems): any[] => {
    const filteredPermittedNavigationItems: any[] = [];
    navigationItems.forEach(navigationItem => {
      const option = { ...navigationItem };
      if (filterCheck(option)) {
        if (!!option.children)
          option.children = filterPermittedNavigationItems(option.children);
        filteredPermittedNavigationItems.push(option);
      }
    });
    return filteredPermittedNavigationItems;
  };

  const permittedNavigationItems = filterPermittedNavigationItems(
    navigationItems
  );

  const flatenItems = (items: any, flatenedItems: any[]): any[] => {
    for (let item of items) {
      if (!!item.children) flatenItems(item.children, flatenedItems);
      else flatenedItems.push(item);
    }
    return flatenedItems;
  };

  const flattenedPermittedNavigationItems = flatenItems(
    permittedNavigationItems,
    []
  );

  const removeAccountEntryLink = items => {
    const filteredItems: any[] = [];
    for (let currentItem of items) {
      const item = { ...currentItem };
      if (!!item.children)
        item.children = removeAccountEntryLink(item.children);
      if (item.path !== ACCOUNT_ENTRY_LINK.path) filteredItems.push(item);
    }
    return filteredItems;
  };

  const items = removeAccountEntryLink(permittedNavigationItems);

  const isCurrentPathAllowed = flattenedPermittedNavigationItems.find(
    item => item.path === path
  );

  if (!isCurrentPathAllowed && items.length) {
    let pathname;
    const defaultRoute = flattenedPermittedNavigationItems.find(
      route =>
        route?.isDefaultLink &&
        route?.isDefaultLink(user, DeploymentEnvironment)
    );

    if (defaultRoute) {
      pathname = defaultRoute?.path;
    } else {
      pathname = flattenedPermittedNavigationItems?.[0]?.path;
    }

    return (
      <Redirect
        to={{
          pathname,
        }}
      />
    );
  }

  return (
    <Box
      className={classes.viewport}
      display="flex"
      flexDirection="column"
      onDrop={handleDragAndDrop}
      onDragOver={handleDragAndDrop}
      onMouseMove={onActivity}
      onClick={onActivity}
      onKeyPress={onActivity}
    >
      <Box id="locking-wrapper">
        <LockedScreen locked={lockedScreen} onUnlock={() => screenUnlock()} />
      </Box>
      <Box>
        <Bar onToggleMenu={toggleOpen} />
      </Box>
      <Hidden mdDown>
        <Box display="flex" flexGrow={1} overflow="hidden auto">
          <Drawer variant="permanent" open={open}>
            <Navigation items={items} />
          </Drawer>
          <Box
            p={1}
            height="auto"
            className={clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
          >
            {children}
          </Box>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box display="flex" flexGrow={1} overflow="hidden auto">
          <Drawer variant="temporary" open={open} toggleOpen={toggleOpen}>
            <Navigation items={items} toggleOpen={toggleOpen} />
          </Drawer>
          <Box height="auto" width={1}>
            {children}
          </Box>
        </Box>
      </Hidden>
      {showResequenceAlert && (
        <Alert
          open={showResequenceAlert}
          title=""
          submitButtonText="Okay"
          hideCancelButton
          onCancel={() => setShowResequenceAlert(false)}
          onSubmit={() => setShowResequenceAlert(false)}
        >
          {RECALCULATE_SEQUENCE}
        </Alert>
      )}
    </Box>
  );
};

export default PrivateLayout;
