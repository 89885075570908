import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { phoneNumber } from 'styleguide/form/Telecom/formatters';
import { MOBILE_CODE, WORK_CODE } from '../../constants';
import { textMaskFormat, INPUT_MASK_PHONE_NUMBER } from 'styleguide/form/Text';
import CardLayout from 'styleguide/layout/Card';
import FormCardLayout from 'styleguide/layout/FormCard';
import CareFacilityCard from '.';
import { getTelecomValue } from '../utils';
import AttributeList from 'styleguide/display/AttributeList';
import { getContactPersonStatus } from '../Builder/builder';
import Typography from '@material-ui/core/Typography';
import { NO_CONTACT_PERSON_SELECTED } from '../../constants';

export type Props = {
  data: any;
  emptyText?: string;
  type?: boolean;
};

const CareFacilityContactCard: React.FC<Props> = ({
  data,
  emptyText = 'No data found to display',
  type,
}) => {
  const conatctAttributes = useMemo(() => {
    const telecom = data?.contact?.telecom;
    const phoneDetails = telecom?.filter(item => item.system === 'phone');
    const mobilePhone = phoneNumber(
      phoneDetails?.find(item => item?.use === MOBILE_CODE)
    );
    const workPhone = phoneNumber(
      phoneDetails?.find(item => item?.use === WORK_CODE)
    );

    return [
      {
        icon: 'phone',
        value: workPhone || PlaceholderKind.NotAvailable,
      },
      {
        icon: 'phone',
        value: mobilePhone || PlaceholderKind.NotAvailable,
      },
      {
        name: 'Fax',
        value:
          textMaskFormat(
            getTelecomValue(telecom, 'fax'),
            INPUT_MASK_PHONE_NUMBER
          ) || PlaceholderKind.NotAvailable,
      },
      {
        name: 'Email',
        value:
          getTelecomValue(telecom, 'email') || PlaceholderKind.NotAvailable,
      },
    ];
  }, [data]);

  if (!data?.facility) {
    return <Typography variant="body1">{emptyText}</Typography>;
  }

  return (
    <>
      {data?.contact && (
        <>
          <FormCardLayout>
            <Box>
              <CardLayout disabled>
                <CareFacilityCard
                  onClick={() => {}}
                  data={data?.facility}
                  type={type}
                />
              </CardLayout>
            </Box>
          </FormCardLayout>

          {data?.contact?.id !== 'none' ? (
            <FormCardLayout>
              <Box mt={1} />
              <CardLayout disabled>
                <CareFacilityCard
                  onClick={() => {}}
                  data={data?.contact}
                  customAttributes={conatctAttributes}
                  cardStatus={getContactPersonStatus(data.contact)}
                />
              </CardLayout>
            </FormCardLayout>
          ) : (
            <Box m={1}>
              <AttributeList
                attributes={[
                  {
                    name: 'Contact',
                    value: NO_CONTACT_PERSON_SELECTED,
                    columns: 2,
                  },
                ]}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default CareFacilityContactCard;
