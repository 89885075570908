import React from 'react';
import ContactInfo from './ContactInfo';
import { checkIfEmpty } from '../utils';
import Collection from 'fhir/general/Collection';

export type Props = {
  data: any;
  onChange: (data: any) => void;
  onValidate: (isValid: boolean) => void;
  title: string;
};

const MultipleContactInfo: React.FC<Props> = ({
  data,
  onChange,
  onValidate,
  title,
}) => {
  const isEmpty = contactInfo => {
    if (checkIfEmpty(contactInfo).length === 0) return false;
    else return checkIfEmpty(contactInfo).some(entry => entry === true);
  };

  return (
    <Collection
      data={data}
      isItemEmpty={isEmpty}
      onChange={onChange}
      onValidate={onValidate}
      page={ContactInfo}
      showRemoveForFirstEntry={true}
      title={title}
    />
  );
};

export default React.memo(MultipleContactInfo);
