import React, { useEffect } from 'react';
import DropDownField from './DropDown';
import ButtonGroupField from './ButtonGroup';
import TypeaheadField from './Typeahead';

import { Field } from '../types';
import SingleChoiceGroup from './SingleChoiceGroup';

function defaultGetOptionLabel(option: any) {
  return option?.coding?.[0]?.display || option?.coding?.[0]?.code || '';
}
function defaultGetOptionId(option: any) {
  return option?.coding?.[0]?.code;
}
export interface Props extends Field {
  onChange: (value: any | undefined, name: string) => void;
  options: any[];
  getOptionDisabled?: (option: fhir.CodeableConcept) => boolean;
  value: string | undefined;
  lastSelectedValue?: any;
  variant: 'drop-down' | 'button-group' | 'typeahead' | 'radio-button-group';
  getOptionLabel?: (option: any) => string;
  getOptionId?: (option: any) => string;
  getTag?: (option: any) => any;
  getOptionWidth?: (
    option: any,
    id: string,
    label: string,
    isMobile?: boolean
  ) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  description?: string;
  hasError?: boolean;
  inline?: boolean;
  labelSize?: 'large' | 'medium' | 'small';
  noWrap?: boolean;
  enableSearchBar?: boolean;
  search?: string;
  setSearch?: (value) => void;
  filteredOptions?: string[];
  type?: 'button';
}

const SingleChoiceField: React.FC<Props> = ({
  required = false,
  value,
  lastSelectedValue,
  label,
  name,
  onChange,
  onValidate = () => {},
  options,
  variant,
  disabled = false,
  getOptionDisabled,
  getOptionId = defaultGetOptionId,
  getOptionLabel = defaultGetOptionLabel,
  getOptionWidth,
  description,
  hasError = false,
  inline,
  labelSize,
  noWrap,
  getTag,
  enableSearchBar = false,
  search = '',
  setSearch = () => {},
  filteredOptions = [],
  type,
}) => {
  const props = {
    name,
    label,
    value,
    lastSelectedValue,
    onChange,
    onValidate,
    options,
    required,
    disabled,
    getOptionId,
    getOptionLabel,
    getOptionDisabled,
    getOptionWidth,
    description,
    hasError,
    inline,
    labelSize,
    noWrap,
    getTag,
    enableSearchBar,
    search,
    setSearch,
    filteredOptions,
    type,
  };

  // Reset the value when a related option doesn't exist anymore
  useEffect(() => {
    if (value && name && options && options.length > 0) {
      const hasRelatedOption = options.some(
        option => getOptionId(option) === value
      );

      if (!hasRelatedOption) {
        onChange(undefined, name);
      }
    }
  }, [value, options, name, getOptionId, onChange]);

  return variant === 'typeahead' ? (
    <TypeaheadField {...props} />
  ) : variant === 'button-group' ? (
    <ButtonGroupField {...props} />
  ) : variant === 'drop-down' ? (
    <DropDownField {...props} />
  ) : variant === 'radio-button-group' ? (
    <SingleChoiceGroup {...props} />
  ) : null;
};

export default React.memo(SingleChoiceField);
