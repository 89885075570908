import { Clinician } from '.';
import { PlaceholderKind } from '@woundtech/ui-constants';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DISPLAY_DATE_FORMAT } from 'styleguide/form/Date/constants';
import { PractitionerRole } from '.';
import { CODE_SYSTEM_JOB_FUNCTION, User, UserJobFunction } from 'user';
import {
  getTimezoneNameByState,
  getCodeableConceptCode,
} from 'utility/helpers';
import { deepCopy } from '../utility/utils';
import {
  ADP_ENABLED_JOB_FUNCTIONS,
  ADP_EMPLOYEE_ID_IDENTIFIER_SYSTEM,
  ADP_START_DATE_URL,
  ADP_SYNC_ENABLED_URL,
} from 'clinician-config/adp-integration/constants';
import { getIdentifierBySystem } from 'fhir/general/Identifier';
import {
  ADDITIONAL_MARKETS_EXTENSION_URL,
  ADDITIONAL_MARKET_EXTENSION_URL,
  EXTENSION_URL_CLINICIAN_ACUITY_LEVEL_TOGGLE,
  EXTENSION_URL_CLINICIAN_ROUTE_TOGGLE,
  MARKETS_EXTENSION_URL,
  MARKET_EXTENSION_URL,
  STATES_EXTENSION_URL,
  STATE_EXTENSION_URL,
} from 'clinician-config/constants';
import { marketType } from 'fhir/general/constants';
import { getOtherExtensions } from 'team/utility/helpers';
import { email } from 'styleguide/form/Telecom/formatters';

export function getClinicianPractitionerRole(
  clinician?: Clinician
): PractitionerRole | undefined {
  return deepCopy({ ...clinician?.participant?.[0]?.member?.resource });
}

export function getClinicianId(clinician?: Clinician) {
  return getClinicianPractitionerRole(
    clinician
  )?.practitioner?.reference?.split('/')[1];
}

export function getClinicianAddress(clinician: Clinician | undefined) {
  const address = getClinicianPractitionerRole(clinician)?.practitioner
    ?.resource?.address;
  if (address && address[0]) {
    return [
      ...(address[0].line ?? []),
      address[0].city,
      address[0].district,
      address[0].state,
      address[0].postalCode,
    ]
      .filter(part => part)
      .join(', ');
  }
  return '';
}

export function getClinicianEmail(clinician: Clinician | undefined) {
  const telecom = getClinicianPractitionerRole(clinician)?.practitioner
    ?.resource?.telecom;
  if (telecom) {
    return email(telecom);
  }
  return '';
}

export function getClinicianPractitioner(clinician?: Clinician) {
  return getClinicianPractitionerRole(clinician)?.practitioner?.resource;
}

export function getClinicianPractitionerReference(clinician?: Clinician) {
  return getClinicianPractitionerRole(clinician)?.practitioner?.reference;
}
export function getClinicianAddressState(
  clinician?: Clinician
): string | undefined {
  return getClinicianPractitioner(clinician)?.address?.find(
    item => item.use === 'work'
  )?.state;
}

export function getLicenseExpiryDate(clinician?: Clinician) {
  const endDate = getClinicianPractitioner(clinician)?.qualification?.[0]
    ?.period?.end;

  return endDate
    ? format(parseISO(endDate), DISPLAY_DATE_FORMAT)
    : PlaceholderKind.NotAvailable;
}

export function getLicense(clinician?: Clinician) {
  return (
    getClinicianPractitioner(clinician)?.qualification?.[0]?.code?.coding?.[0]
      ?.display || PlaceholderKind.NotAvailable
  );
}

export function getClinicainSpecialities(
  clinician?: Clinician
): fhir.CodeableConcept[] | undefined {
  return getClinicianPractitionerRole(clinician)?.specialty;
}

export function getClinicianSupervisingPhysician(
  clinician?: Clinician
): fhir.Practitioner | undefined {
  return clinician?.participant?.[1]?.member?.resource?.practitioner?.resource;
}

export function getClinicainJobFunctions(
  clinician?: Clinician
): fhir.CodeableConcept[] | undefined {
  const clinicianCodes = getClinicianPractitionerRole(clinician)?.code;
  return clinicianCodes?.filter(
    jobFunction => jobFunction?.coding?.[0]?.system === CODE_SYSTEM_JOB_FUNCTION
  );
}

export function getIsClinicainOnsiteProvider(clinician?: Clinician): boolean {
  const jobFunctions = getClinicainJobFunctions(clinician);
  return (
    jobFunctions?.some(
      item => getCodeableConceptCode(item) === UserJobFunction.onsiteProvider
    ) || false
  );
}

export function getClinicianTimeZone(clinician?: Clinician): string {
  const state = getClinicianAddressState(clinician);
  if (state) {
    return getTimezoneNameByState(state);
  }
  return 'America/New_York';
}

export function getIsPractitionerADPEnabled(clinician?: Clinician): boolean {
  const jobFunctions = getClinicainJobFunctions(clinician);
  return (
    jobFunctions?.some(item =>
      ADP_ENABLED_JOB_FUNCTIONS?.includes(
        getCodeableConceptCode(item) as UserJobFunction
      )
    ) || false
  );
}

export const getAdpEmployeeId = (
  practitioner: fhir.Practitioner | User | null | undefined
): string | undefined =>
  getIdentifierBySystem(
    practitioner?.identifier,
    ADP_EMPLOYEE_ID_IDENTIFIER_SYSTEM
  )?.value;

export const getAdpSyncEnabled = (
  practitioner: fhir.Practitioner | User | null | undefined
): boolean =>
  practitioner?.extension?.find(item => item?.url === ADP_SYNC_ENABLED_URL)
    ?.valueBoolean || false;

export const getAdpStartDate = (
  practitioner: fhir.Practitioner | User | null | undefined
): string | undefined =>
  practitioner?.extension?.find(item => item?.url === ADP_START_DATE_URL)
    ?.valueDateTime;

export const enabledClinicianRoute = (
  practitioner: fhir.Practitioner | User | null | undefined
): boolean =>
  practitioner?.extension?.find(
    item => item?.url === EXTENSION_URL_CLINICIAN_ROUTE_TOGGLE
  )?.valueBoolean || false;

export const enabledClinicianAcuityLevel = (
  practitioner: fhir.Practitioner | User | null | undefined
): boolean =>
  practitioner?.extension?.find(
    item => item?.url === EXTENSION_URL_CLINICIAN_ACUITY_LEVEL_TOGGLE
  )?.valueBoolean || false;

export const prepareMarketData = (
  extension: fhir.Extension[] | undefined,
  values: fhir.Location | fhir.Location[] | undefined,
  type?: string | undefined
): fhir.Extension[] | [] => {
  if (!extension) return [];

  const marketValueIndex = extension.findIndex(
    d => d.url === getMarketTypeUrl(type)
  );

  if (values && extension) {
    const references = {
      url: getMarketTypeUrl(type),
      extension: Array.isArray(values)
        ? values.map(market => ({
            url: getMarketUrl(type),
            valueReference: {
              reference: `${market.resourceType}/${market.id}`,
              display: market.name,
            },
          }))
        : getValueReference(values, type),
    };

    prepareExtension(marketValueIndex, extension, references);
  } else if (!values && extension) {
    return extension.filter(d => d.url !== getMarketTypeUrl(type));
  }

  return extension;
};

export function getMarketTypeUrl(mType: string | undefined): string {
  switch (mType) {
    case marketType.market:
      return MARKETS_EXTENSION_URL;
    case marketType.add_market:
      return ADDITIONAL_MARKETS_EXTENSION_URL;
    default:
      return MARKETS_EXTENSION_URL;
  }
}
export function getMarketUrl(mType: string | undefined): string {
  switch (mType) {
    case marketType.market:
      return MARKET_EXTENSION_URL;
    case marketType.add_market:
      return ADDITIONAL_MARKET_EXTENSION_URL;
    default:
      return MARKET_EXTENSION_URL;
  }
}

export const getValueReference = (
  market: fhir.Location | undefined,
  type: string | undefined
) => {
  return [
    {
      url: getMarketUrl(type),
      valueReference: {
        display: market?.name,
        reference: `${market?.resourceType}/${market?.id}`,
      },
    },
  ];
};

export const getClinicianMarkets = (
  data: fhir.Practitioner | undefined,
  type?: string | undefined
): fhir.Location[] | undefined => {
  return data?.extension
    ?.find(extension => extension.url === getMarketTypeUrl(type))
    ?.extension?.map(extension => ({
      id: extension?.valueReference?.reference?.split('/')[1],
      resourceType: 'Location',
      name: extension?.valueReference?.display,
    }));
};

export const prepareStateData = (
  extension: fhir.Extension[] | undefined,
  values: fhir.Location[] | undefined
): fhir.Extension[] => {
  const stateValueIndex: number | undefined = extension?.findIndex(
    d => d.url === STATES_EXTENSION_URL
  );
  const preparedExtension = extension ? extension : [];
  if (values?.length) {
    const references = {
      url: STATES_EXTENSION_URL,
      extension:
        values?.map(state => {
          return {
            url: STATE_EXTENSION_URL,
            ...state,
          };
        }) || [],
    };
    prepareExtension(stateValueIndex, preparedExtension, references);
  } else return [];

  return preparedExtension || [];
};

export const setStateData = (
  data: fhir.Practitioner,
  references: fhir.Extension[]
) => {
  const extensionWithoutSubscription = getOtherExtensions(
    data,
    STATES_EXTENSION_URL
  );

  const updatedData = {
    ...data,
    extension: extensionWithoutSubscription
      ? [...references, ...extensionWithoutSubscription]
      : references,
  };
  return updatedData;
};

export const getStateValue = (
  extension: fhir.Extension[] | undefined
): fhir.Extension[] | undefined => {
  return extension?.find(item => item.url === STATES_EXTENSION_URL)?.extension;
};
export const getStateCodes = (
  extension: fhir.Extension[] | undefined
): string => {
  return (
    extension
      ?.find(item => item.url === STATES_EXTENSION_URL)
      ?.extension?.map(subItems => subItems.valueString)
      .join(',') ?? ''
  );
};

export const getMarketNames = (
  extension: fhir.Extension[] | undefined,
  type?: string | undefined
): string => {
  return (
    extension
      ?.find(extension => extension.url === getMarketTypeUrl(type))
      ?.extension?.map(subItems => subItems?.valueReference?.display)
      .join(',') ?? ''
  );
};

export const prepareExtension = (
  index: number | undefined,
  extension: fhir.Extension[],
  references
) =>
  index !== undefined && index > -1
    ? (extension[index] = references)
    : extension.push(references);
