import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, useMediaQuery, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Numpad from 'styleguide/form/NumPad';
import PublicLayout from 'styleguide/layout/Public';
import { Palette } from '@woundtech/ui-colors';
import { detectDevice } from 'utility/helpers';
import { Context as PinContext } from 'authentication/Context';
import { Context as AuthorizationContext } from 'authorization/Context';
import Typography from '@material-ui/core/Typography';
import Alert from 'styleguide/layout/Alert';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';

const useStyles = makeStyles(theme => ({
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: Palette.Base.White,
    top: 0,
    left: 0,
    zIndex: 1600,
  },
}));

type Props = {
  locked: boolean;
  onUnlock: Function;
};

const LockedScreen: React.FC<Props> = ({
  locked = false,
  onUnlock = () => {},
}) => {
  const [openedNumpad, setOpenedNumpad] = useState<boolean>(locked);
  const [openLogoutConfirmDialog, setOpenLogoutConfirmDialog] = useState(false);
  const [numPadError, setNumpadError] = useState<boolean>(false);
  const [numpadHelperText, setNumpadHelperText] = useState<string>('');
  const [numpadInitialValue, setNumpadInitialValue] = useState<string>('');
  const { verifyPin, signOut } = useContext(PinContext);
  const featureToggles = useContext(FeatureToggleContext);

  const { user } = useContext(AuthorizationContext);
  let loggedinUser = user?.name?.[0]?.text;
  loggedinUser = user?.name?.[0]?.suffix
    ? `${loggedinUser} ${user?.name?.[0]?.suffix}`
    : loggedinUser;

  const classes = useStyles();

  const isMobile =
    useMediaQuery((theme: any) => theme.breakpoints.down('md')) ||
    detectDevice() === 'tablet';

  const handleNumpadClose = () => {
    setOpenedNumpad(false);
  };

  const handleOnChange = useCallback((data: string) => {
    setNumpadError(false);
    setNumpadHelperText('');
  }, []);

  const handleNumpadResponse = (data: string) => {
    if (verifyPin(data)) {
      setNumpadError(false);
      setNumpadHelperText('');
      setNumpadInitialValue('');
      setOpenedNumpad(false);
      onUnlock();
    } else {
      setNumpadError(true);
      setNumpadHelperText('Incorrect PIN, logout to continue');
    }
  };

  const handleLogout = () => {
    setOpenLogoutConfirmDialog(true);
  };

  const handleConfirmLogout = () => {
    signOut();
  };

  const handleCancelLogout = () => {
    setOpenLogoutConfirmDialog(false);
  };

  useEffect(() => {
    setOpenedNumpad(locked);
  }, [locked]);

  if (locked && featureToggles?.EnableUserPin === 'true') {
    return (
      <Box
        id="locked-background"
        className={classes.background}
        onClick={() => setOpenedNumpad(true)}
      >
        <PublicLayout>
          <Numpad
            open={openedNumpad}
            isMobile={isMobile}
            title="Unlock EMR"
            titleIcon="LockOpen"
            userMessage={`${loggedinUser}, please verify yourself`}
            inputTitle="Enter your PIN to unlock"
            enterTitle="Unlock"
            hiddenValue={true}
            allowShowOnHidden={true}
            onEnter={(data: string) => {
              handleNumpadResponse(data);
            }}
            closeButton={false}
            onClose={() => handleNumpadClose()}
            onChange={data => handleOnChange(data)}
            max={10}
            min={6}
            textAlign="left"
            initValue={numpadInitialValue}
            helperText={numpadHelperText}
            error={numPadError}
            bottomLink="Logout"
            handleBottomLinkAction={() => handleLogout()}
            noModalBackground={true}
            withLogo={true}
          />
          {openLogoutConfirmDialog && (
            <Modal open={openLogoutConfirmDialog} style={{ zIndex: 1700 }}>
              <Alert
                open={openLogoutConfirmDialog}
                onSubmit={handleConfirmLogout}
                onCancel={handleCancelLogout}
                title={'Logout confirmation'}
                cancelButtonText="NO"
                submitButtonText="YES"
                closeIcon={true}
              >
                <Typography>Are you sure you want to Logout?</Typography>
              </Alert>
            </Modal>
          )}
        </PublicLayout>
      </Box>
    );
  } else {
    return <div />;
  }
};

export default LockedScreen;
