import { parse, parseISO, isValid, differenceInMinutes } from 'date-fns';
import { utcToZonedTime, format, zonedTimeToUtc } from 'date-fns-tz';
import {
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_INCORRECT,
} from 'utility/constants';
import { DISPLAY_TIME_FORMAT } from './constants';

export const getTimeFromDate = (
  date: fhir.date,
  timeZone?: string | undefined
) => {
  try {
    const dateObj = timeZone ? utcToZonedTime(date, timeZone) : parseISO(date);

    return format(dateObj, DISPLAY_TIME_FORMAT);
  } catch {}
};

export const getTimeFromZonedDateTime = (date: fhir.date) => {
  return date ? format(parseISO(date.slice(0, -1)), DISPLAY_TIME_FORMAT) : '';
};

export const setTimeToDefaultISODate = (time: fhir.time) => {
  const date = parse(time, DISPLAY_TIME_FORMAT, new Date(1970, 0, 1));
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const setTimeToDate = (
  time: fhir.time,
  date: fhir.date,
  timeZone?: string | undefined
) => {
  try {
    if (!timeZone) {
      return parse(time, DISPLAY_TIME_FORMAT, parseISO(date)).toISOString();
    }

    const dateObj = utcToZonedTime(date, timeZone);
    const dateTimeObj = parse(time, DISPLAY_TIME_FORMAT, dateObj);

    return zonedTimeToUtc(dateTimeObj, timeZone).toISOString();
  } catch {}
};

export const transformInput = (
  value: string | undefined,
  timeZone?: string
) => {
  if (!value) {
    return;
  }

  try {
    return parse(value, DISPLAY_TIME_FORMAT, new Date());
  } catch {}
};

export const getTimeDifference = (startDate, endDate) => {
  const totalTime = differenceInMinutes(endDate, startDate);
  const timeDifference = Math.abs(totalTime);
  if (timeDifference > 60) {
    const hours = timeDifference / 60;
    const roundedHours = Math.floor(hours);
    const minutes = (hours - roundedHours) * 60;
    const roundedMinutes = Math.round(minutes);
    return {
      timeDifference: totalTime,
      formattedTime: `${roundedHours} hours ${roundedMinutes} minutes`,
    };
  }
  return {
    timeDifference: totalTime,
    formattedTime: `${timeDifference} minutes`,
  };
};

export const transformOutput = (
  time: Date | undefined,
  value: string,
  timeZone?: string
) => {
  if (!time || !isValid(time)) {
    return value;
  }

  try {
    return format(time, DISPLAY_TIME_FORMAT);
  } catch {}

  return '';
};

export const validate = (data?: fhir.time, required?: boolean) => {
  const errors: any = {};

  if (!required && !data) {
    return errors;
  }

  if (required && !data) {
    return { time: VALIDATION_ERROR_REQUIRED };
  }

  const dateObj = parse(data || '', DISPLAY_TIME_FORMAT, new Date());
  if (!isValid(dateObj)) {
    return { time: VALIDATION_ERROR_INCORRECT };
  }

  return errors;
};
