import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import TextField from 'styleguide/form/Text';
import SingleChoiceField from 'styleguide/form/SingleChoice';

import {
  PREFIX_OPTIONS,
  VALIDATION_PATTERN_SPECIAL_CHARACTERS,
} from './constants';

export type Props = {
  value?: fhir.HumanName;
  onChange: (data: fhir.HumanName) => void;
  onValidate: (isValid: boolean) => void;
  displayPrefix: boolean;
  displayMiddleName: boolean;
  required?: boolean;
  disabled?: boolean;
  prefixVariant?: 'drop-down' | 'text';
  firstNameLabel?: string;
  lastNameLabel?: string;
  firstNameRequired?: boolean;
  lastNameRequired?: boolean;
};

const mapFormValue = (name: string, value: string, data?: fhir.HumanName) => {
  const newData = {
    use: 'official',
    ...(data || {}),
  };

  const given = data?.given || ['', ''];
  const firstName = given?.[0] || '';
  const middleName = given?.[1] || '';

  switch (name) {
    case 'firstName': {
      newData['given'] = [value, middleName];
      break;
    }
    case 'middleName':
      newData['given'] = [firstName, value];
      break;
    case 'lastName':
      newData['family'] = value || '';
      break;
    case 'prefix':
      newData['prefix'] = [value];
      break;
    default:
      break;
  }

  newData['text'] = [
    newData['prefix'],
    ...(newData['given'] || []),
    newData['family'],
  ]
    .filter(item => !!item)
    .join(' ');
  return newData;
};

const HumanName: React.FC<Props> = ({
  value: data,
  onChange,
  onValidate,
  displayMiddleName,
  displayPrefix,
  prefixVariant = 'drop-down',
  required = false,
  disabled = false,
  firstNameLabel = 'First Name',
  lastNameLabel = 'Last Name',
  firstNameRequired = false,
  lastNameRequired = false,
}) => {
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isMiddleNameValid, setIsMiddleNameValid] = useState(true);
  const [isPrefixValid, setIsPrefixValid] = useState(true);

  const handleChange = useCallback(
    (value: string, name: string) => {
      onChange(mapFormValue(name, value, data));
    },
    [data, onChange]
  );

  const handlePrefixChange = (value?: fhir.CodeableConcept | string) => {
    onChange(
      mapFormValue(
        'prefix',
        typeof value === 'string' ? value : value?.coding?.[0]?.code || '',
        data
      )
    );
  };

  const isValid =
    isFirstNameValid && isLastNameValid && isMiddleNameValid && isPrefixValid;
  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const threeColumns = displayMiddleName || displayPrefix;

  return (
    <Grid container spacing={2}>
      {displayPrefix && (
        <Grid item xs={12} sm={4}>
          {prefixVariant === 'drop-down' ? (
            <SingleChoiceField
              disabled={disabled}
              label="Prefix"
              name="prefix"
              value={data?.prefix?.[0]}
              onChange={handlePrefixChange}
              onValidate={setIsPrefixValid}
              options={PREFIX_OPTIONS}
              variant="drop-down"
            />
          ) : (
            <TextField
              disabled={disabled}
              label="Credentials"
              name="credentials"
              onChange={handlePrefixChange}
              onValidate={setIsPrefixValid}
              value={data?.prefix?.[0] || ''}
              maxLength={36}
              placeholder={'MD'}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={threeColumns ? 4 : 6}>
        <TextField
          disabled={disabled}
          label={firstNameLabel}
          name="firstName"
          onChange={handleChange}
          onValidate={setIsFirstNameValid}
          validationPattern={VALIDATION_PATTERN_SPECIAL_CHARACTERS}
          required={required || firstNameRequired}
          value={data?.given?.[0] || ''}
          maxLength={36}
        />
      </Grid>
      {displayMiddleName && (
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={disabled}
            label="Middle Name"
            name="middleName"
            onChange={handleChange}
            onValidate={setIsMiddleNameValid}
            validationPattern={VALIDATION_PATTERN_SPECIAL_CHARACTERS}
            value={data?.given?.[1] || ''}
            maxLength={36}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={threeColumns ? 4 : 6}>
        <TextField
          disabled={disabled}
          label={lastNameLabel}
          name="lastName"
          onChange={handleChange}
          onValidate={setIsLastNameValid}
          validationPattern={VALIDATION_PATTERN_SPECIAL_CHARACTERS}
          required={required || lastNameRequired}
          value={data?.family || ''}
          maxLength={36}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(HumanName);
