import { gql } from '@apollo/client';
import { AddressFragment } from 'fhir/general/Address';
import { ContactPointFragment } from 'styleguide/form/Telecom';
import { IdentifierFragment } from 'fhir/general/Identifier';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';
import { HumanNameFragment } from 'fhir/general/HumanName';

export const ClinicianFragment = gql`
  fragment Clinician on CareTeam {
    id
    participant {
      member {
        resource {
          ... on PractitionerRole {
            id
            active
            specialty {
              ...CodeableConcept
            }
            code {
              ...CodeableConcept
            }
            practitioner {
              reference
              resource {
                id
                active
                resourceType
                name {
                  ...HumanName
                }
                identifier {
                  ...Identifier
                }
                extension {
                  url
                  valueDateTime
                  valueBoolean
                  valueString
                  valueReference {
                    reference
                  }
                  extension {
                    url
                    valueDateTime
                    valueBoolean
                    valueString
                    valueReference {
                      reference
                      display
                    }
                  }
                }
                address {
                  ...Address
                }
                telecom {
                  ...ContactPoint
                }
                qualification {
                  identifier {
                    ...Identifier
                  }
                  period {
                    start
                    end
                  }
                  code {
                    ...CodeableConcept
                  }
                }
              }
            }
          }
        }
      }
      role {
        ...CodeableConcept
      }
    }
    managingOrganization {
      reference
      display
    }
  }
  ${AddressFragment}
  ${ContactPointFragment}
  ${IdentifierFragment}
  ${CodeableConceptFragment}
  ${HumanNameFragment}
`;

export const CareTeamPhysiciansQuery = gql`
  query CareTeamPhysicians(
    $search: CareteamPhysicianSearchInput
    $first: Int
    $after: String
  ) {
    CareTeamPhysicians(search: $search, first: $first, after: $after) {
      edges {
        cursor
        node {
          ...Clinician
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${ClinicianFragment}
`;

export const GetPayPeriodEncounterHistoryQuery = gql`
  query GetPayPeriodEncounterHistory(
    $practitionerId: String!
    $pageParams: PageParamsInput
  ) {
    GetPayPeriodEncounterHistory(
      practitionerId: $practitionerId
      pageParams: $pageParams
    ) {
      currentPage
      totalPages
      totalRecords
      response {
        payPeriod {
          id
          start
          end
        }
        current
        encounterStats {
          soc
          followUp
          signed
        }
      }
    }
  }
`;

export const GetPayPeriodEncounters = gql`
  query GetPayPeriodEncounters(
    $practitionerId: String!
    $payPeriodId: String!
  ) {
    GetPayPeriodEncounters(
      practitionerId: $practitionerId
      payPeriodId: $payPeriodId
    ) {
      id
      period {
        start
        end
      }
      type {
        ...CodeableConcept
      }
      subject {
        reference
        resource {
          name {
            ...HumanName
          }
          birthDate
          gender
        }
      }
    }
  }
  ${HumanNameFragment}
  ${CodeableConceptFragment}
`;
