export const PATH_TH_CLINICIAN_LIST = '/th-clinician';
export const PATH_TH_CLINICIAN_VIEW = '/th-clinician/:clinicianId';
export const PATH_TH_CLINICIAN_EDIT = '/th-clinician/:clinicianId/edit';

export function getTHClinicianListUrl(): string {
  return PATH_TH_CLINICIAN_LIST;
}

export function getTHClinicianViewUrl(clinicianId: string): string {
  return PATH_TH_CLINICIAN_VIEW.replace(':clinicianId', clinicianId);
}

export function getTHClinicianEditUrl(clinicianId: string): string {
  return PATH_TH_CLINICIAN_EDIT.replace(':clinicianId', clinicianId);
}

export const ENTRY_PERMISSION = 'settings';
export const ENTRY_LINK = {
  path: PATH_TH_CLINICIAN_LIST,
  text: 'TH Clinicians',
  module: 'th-clinician-management',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export function getAssociatedIds(
  careTeam: any,
  roleCode: string[]
): string[] | undefined {
  return careTeam?.participant
    ?.filter(participant => {
      return participant?.role?.some((role: fhir.CodeableConcept) =>
        roleCode?.includes(role?.coding?.[0]?.code || 'undefined')
      );
    })
    .map(participant => participant?.member?.reference?.split('/')[1])
    .filter(id => !!id);
}
