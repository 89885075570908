import { isArrayEmpty } from 'utility/helpers';
import uuid from 'react-uuid';
import { SlotStatus } from 'clinical-config/config/types';
import { ResourceType } from 'utility/constants';
import { ISlotWithOperationType } from 'patient/types';
import {
  DayIndexes,
  isDayAvailable,
  SLOT_SEQUENCE_EXT,
  weekDay,
} from 'clinician-config/constants';
import {
  convertTimeToDate,
  removeTempSlotIDs,
  sortFreeSlotsByStartTime,
  TEMP_TEXT,
} from 'clinician-config/PreferenceBuilder';
import {
  getTimeFromZonedDateTime,
  transformInput,
} from 'styleguide/form/Time/utils';
import {
  EXTENSION_URL_PATIENT_EXCEPTION_STARTTIME,
  EXTENSION_URL_PATIENT_EXCEPTION_ENDTIME,
  UNABLE_TO_OBTAIN_EMAIL_EXTENSION_URL,
} from 'patient/constants';
import { PatientAvailability } from 'patient/types';
import isValid from 'date-fns/isValid';
import { dateStringToObject } from 'styleguide/form/Date/utils';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

export const defaultStartTime = '';
export const defaultEndTime = '';

export function getWeekDay(slot: fhir.Slot) {
  return slot?.extension?.find(item => item.url === weekDay)?.valuePositiveInt;
}
export function getSlotSequence(slot: fhir.Slot) {
  return slot?.extension?.find(ext => ext.url === SLOT_SEQUENCE_EXT)
    ?.valueInteger;
}
export const isGeneralWeekday = (week, slot) => {
  if (
    getWeekDay(week) === 1 &&
    getWeekDay(slot) !== 0 &&
    getWeekDay(slot) !== 6
  ) {
    return true;
  }
  return false;
};
export const isGeneralWeekend = (week, slot) => {
  if (
    getWeekDay(week) === 0 &&
    (getWeekDay(slot) === 0 || getWeekDay(slot) === 6)
  ) {
    return true;
  }
  return false;
};

export const checkEmptyName = name => {
  let check = true;

  if (!name || typeof name === 'undefined' || Object.keys(name).length === 0) {
    return true;
  }
  for (let item in name) {
    if (typeof name[item] === 'undefined') {
      check = check && true;
    }
    if (item !== 'use' && !name[item] && typeof name[item] === 'string') {
      check = check && name[item] === '';
    }
    if (name[item] instanceof Array) {
      const isItemEmpty = isArrayEmpty(name[item]);
      check = check && isItemEmpty;
    }
  }
  return check;
};
export const checkTelecomIsEmpty = (telecom, type) => {
  let check = false;
  if (typeof telecom === 'undefined' || Object.keys(telecom).length === 0) {
    return true;
  }
  if (
    type === 'phone' &&
    (telecom?.use === '' || typeof telecom?.use === 'undefined') &&
    telecom?.value === ''
  ) {
    check = true;
  }
  if (type === 'email' && telecom?.value === '') check = true;
  return check;
};
export const checkIfRelationshipIsEmpty = relation => {
  if (typeof relation === 'undefined' || Object.keys(relation).length === 0) {
    return true;
  }
};
export const checkIfEmpty = listOfObjects => {
  return listOfObjects.map(
    object =>
      checkEmptyName(object?.name) &&
      checkIfRelationshipIsEmpty(object?.relationship?.[0]) &&
      checkTelecomIsEmpty(
        object?.telecom?.filter(telecom => telecom.system === 'phone')?.[0],
        'phone'
      ) &&
      checkTelecomIsEmpty(
        object?.telecom?.filter(telecom => telecom.system === 'email')?.[0],
        'email'
      )
  );
};

export const removeEmptyObjects = listOfObjects => {
  if (typeof listOfObjects === 'undefined') return;

  const emptyObjects = checkIfEmpty(listOfObjects);
  const values = [...listOfObjects];
  emptyObjects.forEach((value, index) => {
    if (value) {
      values.splice(index, 1);
    }
  });
  return values;
};

export const removeResourceFromPractitioner = practitionerList => {
  if (!Array.isArray(practitionerList)) return;
  const newPractitionerList = practitionerList.map(
    ({ resource, ...item }) => item
  );
  return newPractitionerList;
};

export const getRaceOrEthnicity = (data, type) => {
  return data?.extension?.find(item => item.url === type)?.valueCodeableConcept;
};

export const getUnableToObtainEmail = data => {
  const unableToObtainEmail = data?.extension?.find(
    item => item?.url === UNABLE_TO_OBTAIN_EMAIL_EXTENSION_URL
  )?.valueBoolean;
  return unableToObtainEmail ? 'Unable to obtain email' : null;
};

export const removeResourceFromExtention = extensionList => {
  if (!Array.isArray(extensionList)) return;
  const newextensionList = extensionList.map(({ valueReference, ...item }) => {
    if (valueReference && Object.keys(valueReference).includes('resource')) {
      const { resource, ...otherItems } = valueReference;
      return { ...item, valueReference: otherItems };
    }
    return { ...item, valueReference };
  });
  return newextensionList;
};

export const getPatientAvailability = (
  patientSlots: ISlotWithOperationType[] = [],
  isAlwaysAvailable: boolean
): PatientAvailability => {
  const hasPatientAvailabilityWith2Slots =
    patientSlots && patientSlots.length === 2;

  let slots;
  if (hasPatientAvailabilityWith2Slots) {
    slots = [];
    isAlwaysAvailable = true;
  } else {
    slots = patientSlots;
  }

  let availabileSlots =
    slots && slots?.filter(slot => slot.status === SlotStatus.Free);

  const unavailabileSlot =
    slots && slots?.find(slot => slot.status === SlotStatus.Busy);

  const exception = getExceptionSlot(unavailabileSlot, isAlwaysAvailable);

  let availability = {
    isAlwaysAvailable,
    availableSlots: [] as ISlotWithOperationType[],
    exception,
  };
  availability = {
    ...availability,
    availableSlots: availabileSlots.length
      ? convertFreeSlotDateToTime(availabileSlots)
      : DayIndexes.map(dayIndex => createDefaultSlot(dayIndex)),
  };

  return availability;
};

function getExceptionSlot(unavailabile, isAlwaysAvailable) {
  return {
    slotId: unavailabile?.id || '',
    startDate: unavailabile?.start || '',
    endDate: unavailabile?.end || '',
    startTime:
      (unavailabile?.extension?.find(
        ext => ext.url === EXTENSION_URL_PATIENT_EXCEPTION_STARTTIME
      )?.valueString as string) || '',
    endTime:
      (unavailabile?.extension?.find(
        ext => ext.url === EXTENSION_URL_PATIENT_EXCEPTION_ENDTIME
      )?.valueString as string) || '',
    isRequired: !isAlwaysAvailable,
  };
}

export const createDefaultSlot = dayIndex => {
  const slot: ISlotWithOperationType = {
    id: `${TEMP_TEXT}${uuid()}`,
    resourceType: 'Slot',
    status: 'free',
    start: '',
    end: '',
    schedule: {},
    extension: [
      {
        url: weekDay,
        valuePositiveInt: dayIndex,
      },
      {
        url: SLOT_SEQUENCE_EXT,
        valueInteger: 1,
      },
      {
        url: isDayAvailable,
        valueBoolean: false,
      },
    ],
  };

  return slot;
};

export const getExceptionAvailabilitySlots = exception => {
  return [
    {
      id: exception?.slotId ? exception.slotId : undefined,
      resourceType: ResourceType.Slot,
      status: SlotStatus.Free,
      start: exception?.startDate,
      end: exception?.endDate,
      extension: [
        {
          url: EXTENSION_URL_PATIENT_EXCEPTION_STARTTIME,
          valueString: exception?.startTime,
        },
        {
          url: EXTENSION_URL_PATIENT_EXCEPTION_ENDTIME,
          valueString: exception?.endTime,
        },
      ],
    },
  ];
};

export const getAvailabilitySlots = ({
  isAlwaysAvailable,
  availableSlots,
  exception,
}) => {
  const freeSlots = isAlwaysAvailable
    ? availableSlots
        .map(slot =>
          slot.id?.includes(TEMP_TEXT)
            ? null
            : { ...slot, operationType: 'DELETE' }
        )
        .filter(slot => slot)
    : convertTimeToDate(
        sortFreeSlotsByStartTime(removeTempSlotIDs(availableSlots))
      );
  return [
    ...freeSlots,
    {
      id: exception?.slotId ? exception.slotId : undefined,
      resourceType: ResourceType.Slot,
      status: SlotStatus.Busy,
      start: exception?.startDate,
      end: exception?.endDate,
      extension: [
        {
          url: EXTENSION_URL_PATIENT_EXCEPTION_STARTTIME,
          valueString: exception?.startTime,
        },
        {
          url: EXTENSION_URL_PATIENT_EXCEPTION_ENDTIME,
          valueString: exception?.endTime,
        },
      ],
    },
  ];
};
export function convertFreeSlotDateToTime(slots: fhir.Slot[] = []) {
  const convertedSlots = slots?.map(slot => {
    if (slot.status === SlotStatus.Free) {
      return {
        ...slot,
        start: getTimeFromZonedDateTime(slot.start) || slot.start,
        end: getTimeFromZonedDateTime(slot.end) || slot.end,
      };
    }
    return slot;
  });

  return convertedSlots;
}

export const isTimeRangeValid = (
  givenStartTime: boolean,
  value: string,
  startTime: string,
  endTime: string
): boolean => {
  const startDate = transformInput(givenStartTime ? value : startTime);
  const endDate = transformInput(givenStartTime ? endTime : value);

  return (
    isValid(startDate) &&
    isValid(endDate) &&
    isBefore(startDate as Date, endDate as Date)
  );
};

export const isDateRangeValid = (
  givenStartDate: boolean,
  value: string,
  startDate: string,
  endDate: string
): boolean => {
  const sDate = dateStringToObject(
    givenStartDate ? value : startDate,
    undefined
  );
  const eDate = dateStringToObject(givenStartDate ? endDate : value, undefined);

  return isValid(sDate) && isValid(eDate) && !isAfter(sDate, eDate);
};

export function setTimeForSlotsForWeekDays(
  slots: ISlotWithOperationType[],
  time,
  type,
  slotId
) {
  const modifiedSlots: ISlotWithOperationType[] = slots?.map(item => {
    if (item?.id === slotId) {
      return {
        ...item,
        [type]: time,
      };
    }
    return item;
  });
  return modifiedSlots;
}

export function setTimeForSlotsForAllDays(
  slots: ISlotWithOperationType[],
  time,
  type,
  slotId
) {
  const modifiedSlots: ISlotWithOperationType[] = slots?.map(item => {
    const slot = slots.find(slot => slot.id === slotId) as fhir.Slot;
    if (
      getSlotSequence(item) === getSlotSequence(slot) &&
      (isGeneralWeekday(slot, item) || isGeneralWeekend(slot, item))
    ) {
      return {
        ...item,
        [type]: time,
      };
    }
    return item;
  });
  return modifiedSlots;
}
