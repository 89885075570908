import { ENTRY_LINK as TEAM_ENTRY_LINK } from 'team';
import { ENTRY_LINK as USER_ENTRY_LINK } from 'user';
import { ENTRY_LINK as PCC_MANAGEMENT_ENTRY_LINK } from 'pcc-management';
import { ENTRY_LINK as LEAD_CLINICIAN_MANAGEMENT_ENTRY_LINK } from 'lead-clinician-management';
import { ENTRY_LINK as TH_CLINICIAN_MANAGEMENT_ENTRY_LINK } from './th-clinician-management';
import { ENTRY_LINK as CLINICIAN_ENTRY_LINK } from 'clinician-config';
import { MARKET_CONFIG } from 'master-list/market-team-config/index';
import { ENTRY_LINK as NOTIFICATION_CONFIG } from 'notification-config/index';

export const ENTRY_PERMISSION = ['settings', 'financial', 'security'];

export const ENTRY_LINK = {
  path: '/settings',
  text: 'User Config',
  icon: 'account_box',
  permission: ENTRY_PERMISSION,
  module: 'settings',
  component: 'Dashboard',
  children: [
    USER_ENTRY_LINK,
    TEAM_ENTRY_LINK,
    MARKET_CONFIG,
    PCC_MANAGEMENT_ENTRY_LINK,
    CLINICIAN_ENTRY_LINK,
    LEAD_CLINICIAN_MANAGEMENT_ENTRY_LINK,
    TH_CLINICIAN_MANAGEMENT_ENTRY_LINK,
    NOTIFICATION_CONFIG,
  ],
};
