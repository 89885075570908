import { PlaceholderKind } from '@woundtech/ui-constants';
import { formatDistanceToNow, parseISO, isValid } from 'date-fns';

export const PATH_WAITINGROOM_LIST = '/waiting-room';
export const PATH_WAITINGROOM_CHECKIN = `/waiting-room/:patientId/:encounterId/check-in`;

export const ENTRY_LINK = {
  path: '/waiting-room',
  text: 'Waiting room',
  module: 'waiting-room',
  component: 'Dashboard',
};
export function getWaitingRequestDuration(start: string | undefined) {
  if (!start) {
    return PlaceholderKind.NotAvailable;
  }
  const startDateObj = parseISO(start);
  if (!isValid(startDateObj)) {
    return PlaceholderKind.NotAvailable;
  }
  return formatDistanceToNow(startDateObj);
}
