import { getCanViewClinicianScheduling } from './permissions';

export const ENTRY_PERMISSION = 'patient';
export const PATH_APPOINTMENT_VIEW = `/my-schedule/:appointmentId`;

export const ROUTE_ARRIVAL_TIME = 'Arrival time';
export const ROUTE_ACTUAL_TIME = 'Actual Time';
export const ROUTE_ESTIMATED_DURATION = 'Estimated duration';
export const ROUTE_ACTUAL_DURATION = 'Duration';
export const ROUTE_ESTIMATION_TIME = 'Estimation Time';
export const TIME = 'Time';

export const ROUTE_EST = 'Est. Time';
export const EST = 'EST';
export const ROUTE_DURATION = 'Duration';
export const ROUTE_ESTIMATED_DRIVE = 'Est. Drive';
export const ROUTE_ACTUAL_DRIVE = 'Actual Drive';
export const ROUTE_DRIVE = 'Drive';
export const ROUTE_DEPART = 'Depart';
export const ROUTE_ARRIVE = 'Arrive';
export const ROUTE_START_ROUTE = 'Start Route';
export const ROUTE_CHECK_IN = 'Check-in';
export const ROUTE_DEPARTED = 'Departed';
export const ROUTE_SKIPPED = 'Skipped';
export const ROUTE_ARRIVED = 'Arrived';
export const ROUTE_RESUME = 'Resume';
export const ROUTE_RESUME_ROUTE = 'Resume Route';
export const ROUTE_PAUSE_ROUTE = 'Pause Route';
export const ROUTE_UNKNOWN = 'unknown';

export function getAppointmentViewURL(
  isMySchedule: boolean,
  appointmentId: string
): string {
  return `/${isMySchedule ? 'my-schedule' : 'schedule'}/${appointmentId}`;
}

export const displayLink = (user, env) => {
  return getCanViewClinicianScheduling(user);
};

export const isDefaultLink = (user, env) => {
  return displayLink(user, env);
};

export const clinicianEmptyMessage =
  'There seems to be an issue with clinician card display. Please contact admin';

export const ENTRY_LINK = {
  path: '/my-schedule',
  text: 'My Schedule',
  icon: 'date_range',
  module: 'clinician-scheduling',
  component: 'Dashboard',
  displayLink: displayLink,
  isDefaultLink: isDefaultLink,
  permission: ENTRY_PERMISSION,
};

export function getViewRouteURL(
  isMySchedule: boolean,
  routeId: string
): string {
  return `/${isMySchedule ? 'my-schedule' : 'schedule'}/view/${routeId}`;
}

export function getViewRouteMapURL(
  isMySchedule: boolean,
  routeId: string
): string {
  return `/${isMySchedule ? 'my-schedule' : 'schedule'}/view/${routeId}/map`;
}
