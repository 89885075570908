import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import SingleChoiceField from 'styleguide/form/SingleChoice';
import TextField, {
  INPUT_MASK_PHONE_NUMBER,
  INPUT_PATTERN_NUMBER,
  VALIDATION_PATTERN_PHONE_NUMBER,
} from 'styleguide/form/Text';

import { useCases } from './constants';

import { Field } from '../types';
import { getPhoneNumberExtension } from './helpers';

export interface Props extends Field {
  disabled?: boolean;
  onChange: (value: fhir.ContactPoint) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  typeRequired?: boolean;
  value: fhir.ContactPoint | undefined;
  typeDisabled?: boolean;
  isPatient?: boolean;
}

const TelecomPhone: React.FC<Props> = ({
  value: data,
  disabled,
  onChange,
  onValidate,
  required,
  typeDisabled,
  typeRequired,
  isPatient,
}) => {
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isExtensionValid, setIsExtensionValid] = useState(true);
  const [isUseValid, setIsUseValid] = useState(false);

  const handleNumberChange = (value: string) => {
    onChange({
      ...data,
      value,
    });
  };

  const handleUseChange = (use: fhir.CodeableConcept | undefined) => {
    onChange({
      ...data,
      use: use?.coding?.[0]?.code,
    });
  };

  const handleExtensionNumberChange = (value: string) => {
    const extension = [
      {
        url: 'PHONE_NUMBER_EXTENSION',
        valueString: value,
      },
    ];
    onChange({
      ...data,
      extension,
    });
  };

  const value = data?.value?.replace(/[^0-9]+/gi, '') || '';
  const use = data?.use;
  const extension = getPhoneNumberExtension(data);
  const isPhoneReqired =
    required ||
    (!!use && !typeDisabled) ||
    !!value ||
    (extension !== undefined && extension !== '');
  const fieldWidth = use === 'work' || use === 'temp' ? 4 : 6;
  const isValid = isNumberValid && isExtensionValid && isUseValid;
  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);
  const [isOnChangeTriggered, setIsOnChangeTriggered] = useState<boolean>(
    false
  );
  useEffect(() => {
    if (isPhoneReqired && use === 'home' && !isOnChangeTriggered) {
      setIsOnChangeTriggered(true);
      onChange({
        ...data,
        use: use,
      });
    }
  }, [use, data, isPhoneReqired, onChange, isOnChangeTriggered]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={fieldWidth}>
        <SingleChoiceField
          disabled={disabled || typeDisabled}
          label="Phone Type"
          name="phoneType"
          onChange={handleUseChange}
          onValidate={setIsUseValid}
          options={useCases}
          value={use}
          variant="drop-down"
          required={typeRequired || (isPatient && isPhoneReqired)}
        />
      </Grid>
      <Grid item xs={12} sm={fieldWidth}>
        <TextField
          disabled={disabled}
          label="Phone Number"
          mask={INPUT_MASK_PHONE_NUMBER}
          maxLength={INPUT_MASK_PHONE_NUMBER.length}
          name="phoneNumber"
          onChange={handleNumberChange}
          onValidate={setIsNumberValid}
          placeholder="(999) 999-9999"
          required={isPhoneReqired}
          validationPattern={VALIDATION_PATTERN_PHONE_NUMBER}
          value={value}
        />
      </Grid>
      {data?.use === 'work' && (
        <Grid item xs={12} sm={4}>
          <TextField
            disabled={disabled}
            label="Extension"
            name="extension"
            onChange={handleExtensionNumberChange}
            onValidate={setIsExtensionValid}
            inputPattern={INPUT_PATTERN_NUMBER}
            value={extension}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(TelecomPhone);
