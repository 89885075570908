export const useCases = [
  {
    coding: [
      {
        code: 'mobile',
        display: 'Mobile',
      },
    ],
  },
  {
    coding: [
      {
        code: 'home',
        display: 'Home',
      },
    ],
  },
  {
    coding: [
      {
        code: 'work',
        display: 'Work',
      },
    ],
  },
  {
    coding: [
      {
        code: 'temp',
        display: 'Other',
      },
    ],
  },
];
