import React, { useState, useEffect } from 'react';
import Loading from 'styleguide/Loading';

export interface IFeatureToggle {
  EnableElasticProductSearch?: string;
  EnableEncounterFilter?: string;
  EnableWoundtechADIntegration?: string;
  EnableOrders?: string;
  EnableEducationalDelivery?: string;
  EnableReviewOfSystems?: string;
  EnableBarriers?: string;
  EnableTreatments?: string;
  EnableTreatmentToOtherWounds?: string;
  EnableUserPin?: string;
  EnableExternalUserVerification?: string;
  EnableInAppNotificationConfig?: string;
  EnableRoundingFeature?: string;
  EnableAddressFacilities?: string;
  EnableADPIntegration?: string;
  EnableADPSyncButton?: string;
  EnablePatientElasticSearch?: string;
  EnableRoutingFeature?: string;
  EnableTaskElasticSearch?: string;
  EnableContinuousScrollFeature?: boolean;
  EnablePCPElasticSearch?: string;
  EnableSupplements?: string;
  EnableImageCompression?: string;
  EnableCBRReport?: string;
}

const defaultValue: IFeatureToggle = {};

export const Context = React.createContext(defaultValue);

const FeatureToggles: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [featureToggles, setFeatureToggles] = useState<IFeatureToggle>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(window?._env_?.FEATURE_TOGGLE_URI || '/api/featureFlags')
      .then(async response => {
        const data = await response.json();
        setFeatureToggles(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading, setFeatureToggles]);

  if (loading) {
    return <Loading />;
  }

  return <Context.Provider value={featureToggles}>{children}</Context.Provider>;
};

export default FeatureToggles;
