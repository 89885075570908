import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  createStyles,
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import { theme } from 'styleguide/Theme/config';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    iconPostion: {
      marginRight: '16px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    trimTopSpace: {
      paddingTop: '0px !important',
      marginTop: '0px',
    },
  })
);

const dialogTheme = createMuiTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        padding: '16px',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '0',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0',
        marginTop: '16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0',
        marginTop: '16px',
      },
    },
  },
});

const cancelButtonTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
});

export type Props = {
  cancelButtonText?: string;
  children: React.ReactNode;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  icon?: string;
  onCancel?: () => void;
  onSubmit?: () => void;
  open: boolean;
  submitButtonText?: string;
  title?: string;
  disableBackdropClick?: boolean;
  closeIcon?: boolean;
  submitButtonDisabled?: boolean;
  comment?: string;
  maxwidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const Alert: React.FC<Props> = ({
  children,
  icon,
  open,
  title = '',
  onCancel,
  onSubmit,
  submitButtonText = 'Continue',
  cancelButtonText = 'Cancel',
  hideCancelButton,
  hideSubmitButton,
  disableBackdropClick = true,
  closeIcon = false,
  submitButtonDisabled,
  comment,
  maxwidth = 'sm',
}) => {
  const classes = useStyles();
  const shouldHideTitle = title === '';
  return (
    <ThemeProvider theme={dialogTheme}>
      <Dialog
        fullWidth
        open={open}
        disablePortal
        disableBackdropClick={disableBackdropClick}
        scroll="body"
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxwidth}
      >
        {shouldHideTitle || (
          <DialogTitle id="alert-dialog-title" disableTypography>
            <Box display="flex" alignItems="center">
              {icon && <Icon className={classes.iconPostion}>{icon}</Icon>}
              {title}
              {closeIcon && (
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={onCancel}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </DialogTitle>
        )}
        <DialogContent
          classes={shouldHideTitle ? { root: classes.trimTopSpace } : {}}
        >
          {children}
        </DialogContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Typography variant="body2" color="textSecondary">
              {comment}
            </Typography>
          </Box>
          <Box>
            {(!hideCancelButton || !hideSubmitButton) && (
              <DialogActions>
                {!hideCancelButton && (
                  <ThemeProvider theme={cancelButtonTheme}>
                    <Button
                      disableElevation
                      color="primary"
                      onClick={onCancel}
                      variant="outlined"
                    >
                      {cancelButtonText}
                    </Button>
                  </ThemeProvider>
                )}
                {!hideSubmitButton && (
                  <Button
                    disableElevation
                    onClick={onSubmit}
                    variant="contained"
                    color="primary"
                    disabled={submitButtonDisabled}
                  >
                    {submitButtonText}
                  </Button>
                )}
              </DialogActions>
            )}
          </Box>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
};
export default React.memo(Alert);
