import { gql } from '@apollo/client';

export const IdentifierFragment = gql`
  fragment Identifier on Identifier {
    use
    type {
      coding {
        code
        display
        system
      }
    }
    system
    value
  }
`;
