export { default } from './MultipleChoice';

export function getDynamicOptionWidth(option, id, label, isMobile): string {
  if (isMobile) {
    return '100%';
  }

  if (label.length > 40) {
    return '100%';
  } else if (label.length > 15) {
    return 'calc(100% / 3 * 2)';
  } else {
    return 'calc(100% / 3)';
  }
}
