import React from 'react';
import TextField, {
  VALIDATION_PATTERN_URL,
  INPUT_PATTERN_URL,
} from 'styleguide/form/Text';

import { Field } from '../types';

export interface Props extends Field {
  disabled?: boolean;
  onChange: (value: fhir.ContactPoint | undefined) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  value: fhir.ContactPoint | undefined;
}

const URL: React.FC<Props> = ({
  value: data,
  disabled,
  onChange,
  onValidate,
  required,
  name = 'url',
}) => {
  const handleChange = (value: string) => {
    onChange({
      ...data,
      value,
    });
  };

  return (
    <TextField
      disabled={disabled}
      inputPattern={INPUT_PATTERN_URL}
      label="Portal URL"
      name={name}
      onChange={handleChange}
      onValidate={onValidate}
      required={required}
      validationPattern={VALIDATION_PATTERN_URL}
      value={data?.value}
    />
  );
};

export default React.memo(URL);
