export { default } from './Identifier';
export { IdentifierFragment } from './fragments';

export const IDENTIFIER_SYSTEM_NPI = 'http://hl7.org/fhir/sid/us-npi';

export function getIdentifierBySystem(
  identifier: fhir.Identifier[] | undefined,
  system: string
) {
  return (identifier || []).find(item => item?.system === system);
}

export function addIdentifierBySystem(
  identifier: fhir.Identifier[] | undefined,
  value: fhir.Identifier
) {
  if (!Array.isArray(identifier) && value) {
    return [value];
  }

  const index = (identifier || []).findIndex(
    item => item?.system === value?.system
  );

  if (index === -1) {
    return [...(identifier || []), value];
  }

  return Object.assign([], identifier, { [index]: value });
}
