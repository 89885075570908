import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import WebVersionAlert from '../../authentication/SignIn/WebVersionAlert';
import Loading from 'styleguide/Loading/Loading';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';
import { setSessionStorageRecord, getSessionStorageRecord } from 'application';
import { setReloadReason } from 'application/wt-debugger';

export type Props = {};

const OldVersionAlert: React.FC<Props> = () => {
  const { EnableWoundtechADIntegration } = useContext(FeatureToggleContext);
  const [newWebVersion, setNewWebVersion] = useState();
  const [webVersionLoading, setWebVersionLoading] = useState<boolean>(false);
  const [showWebVersionAlert, setShowWebVersionAlert] = useState<boolean>(
    false
  );

  const currentWebVersion = useMemo(
    () => window?._env_?.BUILD_NUMBER || PlaceholderKind.NotAvailable,
    []
  );

  const isOlderVersion = useCallback(
    latestVersion =>
      currentWebVersion !== '1' &&
      !!latestVersion &&
      currentWebVersion !== latestVersion,
    [currentWebVersion]
  );

  useEffect(() => {
    const fetchWebVersion = async () => {
      try {
        const screenRefreshed = getSessionStorageRecord('old-version-alert');
        if (screenRefreshed !== 'true') {
          setWebVersionLoading(true);
          await fetch(window?._env_?.WEB_VERSION_URI || '/api/status')
            .then(response => response.json())
            .then(async data => {
              const latestVersion = data?.info?.web?.version;
              setNewWebVersion(latestVersion);
              if (isOlderVersion(latestVersion)) {
                setShowWebVersionAlert(true);
                setSessionStorageRecord('old-version-alert', 'true');
              }
            });
          setWebVersionLoading(false);
        }
      } catch (e) {
        setWebVersionLoading(false);
      }
    };
    fetchWebVersion();
  }, [isOlderVersion]);

  const handleWebVersionAlertSubmit = () => {
    setReloadReason(window.location.href, 'New web version cause reload');
    window.location.reload();
  };

  if (webVersionLoading) {
    return <Loading />;
  }

  return showWebVersionAlert && EnableWoundtechADIntegration === 'true' ? (
    <WebVersionAlert
      currentWebVersion={currentWebVersion}
      newWebVersion={newWebVersion}
      onSubmit={handleWebVersionAlertSubmit}
    />
  ) : null;
};

export default OldVersionAlert;
