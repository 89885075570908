import { gql } from '@apollo/client';

export const ContactPointFragment = gql`
  fragment ContactPoint on ContactPoint {
    extension {
      url
      valueString
    }
    rank
    system
    use
    value
  }
`;
