import { gql } from '@apollo/client';
import { UserFragment } from 'user/Schema';

export const VarificationCodeResponseFragment = gql`
  fragment VerificationCodeResponse on VerificationCodeResponse {
    email
    createdOn
    attemptsRemaining
    resendRequestRemaining
    isValidCode
    isError
    errorMessage
  }
`;

export const UpsertUserPinMutation = gql`
  mutation upsertUserPin($userPin: String!) {
    upsertUserPin(userPin: $userPin) {
      ...User
      userPin
      userPinModifiedOn
    }
  }
  ${UserFragment}
`;

export const validatePINVerificationCodeMutation = gql`
  mutation validatePINVerificationCode($verificationCode: String!) {
    validatePINVerificationCode(verificationCode: $verificationCode) {
      ...VerificationCodeResponse
    }
  }
  ${VarificationCodeResponseFragment}
`;

export const sendPINVerificationCodeMutation = gql`
  mutation sendPINVerificationCode {
    sendPINVerificationCode {
      ...VerificationCodeResponse
    }
  }
  ${VarificationCodeResponseFragment}
`;

export const ConfirmPasswordChangeMutation = gql`
  mutation confirmPasswordChange($email: String!) {
    confirmPasswordChange(email: $email) {
      ...User
    }
  }
  ${UserFragment}
`;
