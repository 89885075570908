import { AppointmentStatus } from 'appointment/constants';
import { Clinician } from 'clinician';
import { getClinicianId } from 'clinician/helpers';
import {
  AddressSequence,
  ResequenceMultipleRouteOptimize,
  ScheduleState,
} from 'routing/types';
import { Slot } from 'routing/types';

export type ScheduleContextAction =
  | { type: 'setClinician'; clinician?: Clinician; userId?: string }
  | { type: 'setCurrentDate'; currentDate: fhir.dateTime; userId?: string }
  | { type: 'setSelectedAppointment'; appointment: fhir.Appointment }
  | { type: 'setSelectedSlot'; slot: Slot }
  | { type: 'setRouteId'; routeId: string }
  | { type: 'setAvailableSOCCount'; availableSOCCount: number }
  | { type: 'setShowCanceledView'; showCanceled?: boolean }
  | { type: 'setWeekendView'; weekendView?: boolean }
  | { type: 'setReCalculation'; sequencedDestinations: AddressSequence[] }
  | { type: 'setOutboundAlert'; showOutboundAlert?: boolean }
  | { type: 'setShowCanceledView'; showCanceled?: boolean };

export type State = {
  clinician?: Clinician;
  currentDate: fhir.dateTime;
  selectedDates: string[];
  resequenceMultipleRouteoptimize: ResequenceMultipleRouteOptimize[];
  selectedAppointment?: fhir.Appointment;
  selectedSlot?: Slot;
  weekendView?: boolean;
  filters: {
    status: string[];
  };
  routeId: string;
};

export const defaultState: ScheduleState = {
  currentDate: new Date().toISOString(),
  filters: {
    status: [
      AppointmentStatus.checkedIn,
      AppointmentStatus.confirmed,
      AppointmentStatus.error,
      AppointmentStatus.finished,
      AppointmentStatus.missed,
      AppointmentStatus.pending,
    ],
  },
  showCanceled: false,
  dispatch: (action?: ScheduleContextAction) => {},
  showOutboundAlert: false,
  /* This is known whether it is for Schedule or MySchedule from Provider input */
  isMySchedule: false,
  refetch: false,
  sequencedDestinations: [],
  routeId: '',
  availableSOCCount: 0,
};

export function reducer(
  state: ScheduleState,
  action: ScheduleContextAction
): ScheduleState {
  switch (action.type) {
    case 'setClinician': {
      if (!state.isMySchedule) {
        const clinicianId = getClinicianId(action?.clinician);
        if (clinicianId) {
          localStorage.setItem(
            `PCC:${action?.userId}:SchedulingClinician`,
            clinicianId
          );
        }
      }

      return { ...state, clinician: action?.clinician };
    }
    case 'setReCalculation': {
      return {
        ...state,
        sequencedDestinations: action?.sequencedDestinations,
      };
    }
    case 'setCurrentDate': {
      if (state.isMySchedule) {
        sessionStorage.setItem(
          `Clinician:${action?.userId}:SchedulingDate`,
          action?.currentDate
        );
      } else {
        localStorage.setItem(
          `PCC:${action?.userId}:SchedulingDate`,
          action?.currentDate
        );
      }
      return { ...state, currentDate: action?.currentDate };
    }

    case 'setSelectedSlot': {
      return {
        ...state,
        selectedSlot: action?.slot,
        selectedAppointment: undefined,
      };
    }

    case 'setRouteId': {
      return {
        ...state,
        routeId: action?.routeId,
      };
    }
    case 'setAvailableSOCCount': {
      return {
        ...state,
        availableSOCCount: action?.availableSOCCount,
      };
    }
    case 'setSelectedAppointment': {
      return {
        ...state,
        selectedAppointment: action?.appointment,
        selectedSlot: undefined,
      };
    }

    case 'setShowCanceledView': {
      return {
        ...state,
        showCanceled: action.showCanceled,
        filters: {
          status: action.showCanceled
            ? [
                AppointmentStatus.canceled,
                AppointmentStatus.checkedIn,
                AppointmentStatus.confirmed,
                AppointmentStatus.error,
                AppointmentStatus.finished,
                AppointmentStatus.missed,
                AppointmentStatus.pending,
              ]
            : [
                AppointmentStatus.checkedIn,
                AppointmentStatus.confirmed,
                AppointmentStatus.error,
                AppointmentStatus.finished,
                AppointmentStatus.missed,
                AppointmentStatus.pending,
              ],
        },
      };
    }

    case 'setWeekendView': {
      return { ...state, weekendView: action?.weekendView };
    }
    case 'setOutboundAlert': {
      return { ...state, showOutboundAlert: action?.showOutboundAlert };
    }

    default:
      return state;
  }
}
