import React, { useCallback } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Palette } from '@woundtech/ui-colors';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    medium: {
      backgroundColor: Palette.Gray.Light,
      height: theme.spacing(9),
      width: theme.spacing(9),
    },
    container: {
      backgroundColor: Palette.Gray.Light,
    },
    placeholder: {
      color: theme.palette.common.black,
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    avatarLink: {
      cursor: 'pointer',
    },
    editIcon: {
      color: theme.palette.common.white,
      fontSize: 12,
      position: 'absolute',
      left: 4,
      top: 4,
    },
    photo: {
      height: theme.spacing(9),
      width: theme.spacing(9),
      objectFit: 'cover',
    },
    popOverPhoto: {
      height: 'auto',
      width: '350px',
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
    },
  })
);

const getInitials = (name: string) => {
  const initials = name
    .replace(/[^a-zA-Z- ]/g, '')
    .replace(/and/g, '')
    .match(/\b\w/g);

  return (initials || []).slice(0, 2).join('');
};

export type Props = {
  name: string;
  alt?: string;
  height?: string;
  src?: string;
  text?: string;
  textColor?: string;
  icon?: string;
  width?: string;
  color?: string;
  pictureLoad?: (action: string) => void;
};

const Component: React.FC<Props> = ({
  src,
  alt = '',
  name,
  icon,
  text,
  textColor,
  color = Palette.Gray.Background,
  pictureLoad,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const showPopover = Boolean(anchorEl);
  const handleUploadPhoto = useCallback(() => {
    pictureLoad?.('upload');
  }, [pictureLoad]);

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box position="relative">
      {src ? (
        <Box
          height={72}
          width={72}
          border={1}
          bgcolor={Palette.Gray.Moderate}
          borderColor={Palette.Gray.MediumDarker}
          overflow="hidden"
          onClick={pictureLoad ? handleAvatarClick : () => {}}
          className={classes.avatarLink}
        >
          <img src={src} alt={alt} className={classes.photo} />
        </Box>
      ) : (
        <Avatar src={src} alt={alt} variant="square" className={classes.medium}>
          {!icon && (
            <Box p={1.5} width={1} height={1} className={classes.container}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                style={{
                  color: textColor,
                  backgroundColor: color,
                }}
                className={classes.placeholder}
                width={1}
                height={1}
                overflow="hidden"
              >
                {text ? text : getInitials(name)}
              </Box>
            </Box>
          )}
          {icon && (
            <Icon
              style={{
                color,
                fontSize: 48,
              }}
            >
              {icon}
            </Icon>
          )}
        </Avatar>
      )}
      {pictureLoad && (
        <Box
          position="absolute"
          right={-7}
          bottom={-7}
          bgcolor={Palette.Gray.MediumDarker}
          borderRadius={'50%'}
          width={20}
          height={20}
          padding={0}
          className={classes.avatarLink}
          onClick={handleUploadPhoto}
        >
          <Icon className={classes.editIcon}>{'edit'}</Icon>
        </Box>
      )}
      <Popover
        open={showPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box
          onClick={handlePopoverClose}
          width={1}
          height={1}
          bgcolor={Palette.Gray.MediumDarker}
        >
          <img src={src} alt={alt} className={classes.popOverPhoto} />
        </Box>
      </Popover>
    </Box>
  );
};

export default React.memo(Component);
