import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import getDay from 'date-fns/getDay';
import { isToday, startOfWeek, endOfWeek } from 'date-fns';
import isWithinInterval from 'date-fns/isWithinInterval';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { setTimeToDate } from 'styleguide/form/Time/utils';

import {
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_INCORRECT,
} from 'utility/constants';

import {
  ONE_HOUR_IN_MILISECONDS,
  ONE_MINUTE_IN_MILISECONDS,
} from './constants';

export const transformInput = (
  value: string | undefined,
  timeZone?: string
) => {
  if (!value) {
    return;
  }

  try {
    return timeZone ? utcToZonedTime(value, timeZone) : parseISO(value);
  } catch {}
};

export const transformOutput = (
  date: Date | undefined,
  value: string,
  timeZone?: string,
  representation?: 'complete' | 'date'
) => {
  if (!date || !isValid(date)) {
    return value;
  }

  if (timeZone) {
    return zonedTimeToUtc(date, timeZone)?.toISOString();
  }

  try {
    if (representation === 'date') {
      return formatISO(date, {
        representation,
      });
    }

    return date.toISOString();
  } catch {}

  return '';
};

export const validate = (
  data?: fhir.date,
  required?: boolean,
  minDate?: string,
  maxDate?: string
) => {
  const errors: any = {};

  if (!required && !data) {
    return errors;
  }

  if (required && !data) {
    return { date: VALIDATION_ERROR_REQUIRED };
  }

  const dateObj = parseISO(data || '');
  if (!isValid(dateObj)) {
    return { date: VALIDATION_ERROR_INCORRECT };
  }

  if (minDate && isBefore(dateObj, parseISO(minDate))) {
    return { date: VALIDATION_ERROR_INCORRECT };
  }

  if (maxDate && isAfter(dateObj, parseISO(maxDate))) {
    return { date: VALIDATION_ERROR_INCORRECT };
  }

  return errors;
};

export function getLocalTime(date: string, offset: number): Date {
  const setDate = new Date(date);
  const localOffset = setDate.getTimezoneOffset() * ONE_MINUTE_IN_MILISECONDS;
  const utc = setDate.getTime() + localOffset;
  return new Date(utc + ONE_HOUR_IN_MILISECONDS * offset);
}

export function getStartOfDay(date: string | undefined, timeZone?: string) {
  if (!date || (date && !isValid(parseISO(date)))) {
    return date;
  }

  if (!timeZone) {
    return startOfDay(parseISO(date)).toISOString();
  }

  return zonedTimeToUtc(
    startOfDay(utcToZonedTime(date, timeZone)),
    timeZone
  ).toISOString();
}

export function getEndOfDay(date: string | undefined, timeZone?: string) {
  if (!date || (date && !isValid(parseISO(date)))) {
    return date;
  }

  if (!timeZone) {
    return endOfDay(parseISO(date)).toISOString();
  }

  return zonedTimeToUtc(
    endOfDay(utcToZonedTime(date, timeZone)),
    timeZone
  ).toISOString();
}

export function getDayOfWeekNumber(date: string, timeZone?: string): number {
  if (!date) {
    return 0;
  }

  const dateObj =
    isValid(parseISO(date)) && timeZone
      ? utcToZonedTime(date, timeZone)
      : parseISO(date);
  return getDay(dateObj);
}

export function getIsToday(date: string, timeZone?: string): boolean {
  if (!date) {
    return false;
  }

  if (!timeZone) {
    return isToday(parseISO(date));
  }

  const dateObj = utcToZonedTime(date, timeZone);

  const todayInTimeZone = utcToZonedTime(new Date().toISOString(), timeZone);

  return isWithinInterval(dateObj, {
    start: startOfDay(todayInTimeZone),
    end: endOfDay(todayInTimeZone),
  });
}

export function getIsThisWeek(date: string, timeZone?: string): boolean {
  if (!date) {
    return false;
  }

  if (!timeZone) {
    return isToday(parseISO(date));
  }

  const dateObj = utcToZonedTime(date, timeZone);

  const todayInTimeZone = utcToZonedTime(new Date().toISOString(), timeZone);

  return isWithinInterval(dateObj, {
    start: startOfWeek(todayInTimeZone),
    end: endOfWeek(todayInTimeZone),
  });
}

export const modifyTimeInDate = (time, date) => {
  try {
    const modifiedDate = setTimeToDate(time, date);
    if (isValid(dateStringToObject(modifiedDate || '', undefined))) {
      return modifiedDate;
    }
  } catch {}
};

export function dateStringToObject(date: string, timeZone: string | undefined) {
  return isValid(parseISO(date)) && timeZone
    ? utcToZonedTime(date, timeZone)
    : parseISO(date);
}

export function dateObjectToString(date: Date, timeZone: string | undefined) {
  return timeZone
    ? zonedTimeToUtc(date, timeZone).toISOString()
    : date.toISOString();
}
