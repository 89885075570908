import React, { useContext, useMemo } from 'react';
import { useSubscription } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { Context as AuthorizationContext } from 'authorization/Context';
import { PrivateLayoutContext } from 'styleguide/layout/Private/Context';
import { isTelemedicineClinician, getUserStates } from 'user';
import { onEncounterUpdatedSubscription } from 'encounter/Schema';
import { shortTimezoneDateTime } from 'styleguide/form/Datetime/formatters';
import { PATH_WAITINGROOM_LIST } from '..';

export type Props = {};

const TelemedicineWaitingRoomNotification: React.FC<Props> = () => {
  const { user } = useContext(AuthorizationContext);
  const { dispatch } = useContext(PrivateLayoutContext);

  const userStates = useMemo(() => getUserStates(user), [user]);

  const isWaitingRoomPath = !!useRouteMatch({
    path: PATH_WAITINGROOM_LIST,
    exact: true,
  });

  useSubscription(onEncounterUpdatedSubscription, {
    variables: {
      patientStates: userStates,
      telemedicineStatuses: ['requested'],
    },
    skip: !isTelemedicineClinician(user) || isWaitingRoomPath,
    fetchPolicy: 'no-cache',
    onSubscriptionData: _ => {
      dispatch({
        type: 'setNotification',
        value: {
          title: 'New waiting room request',
          content: shortTimezoneDateTime(new Date().toISOString()),
        },
      });
    },
  });

  return null;
};

export default React.memo(TelemedicineWaitingRoomNotification);
