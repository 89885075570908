import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, CircularProgress, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      height: '100%',
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    iconWithoutText: {
      marginLeft: '40%',
    },
  })
);

export type ButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  variant: 'text' | 'outlined' | 'contained' | undefined;
  loading?: boolean | undefined;
  size?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
};

const CircularProgressButton: React.FC<ButtonProps> = ({
  color,
  disabled = false,
  label,
  onClick,
  variant,
  loading,
  size,
  icon,
}) => {
  const classes = useStyles();
  return (
    <Box mr={1} className={classes.wrapper}>
      <Button
        disableElevation
        className={classes.actionButton}
        color={color}
        disabled={disabled}
        onClick={onClick}
        variant={variant}
        size={size}
        startIcon={icon}
      >
        {label}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          thickness={4}
          className={classes.buttonProgress}
        />
      )}
    </Box>
  );
};

export default React.memo(CircularProgressButton);
