import { gql } from '@apollo/client';

export const CodeableConceptFragment = gql`
  fragment CodeableConcept on CodeableConcept {
    coding {
      code
      display
      system
    }
    text
  }
`;
