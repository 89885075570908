import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormLabel } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';
import clsx from 'clsx';
import { Size } from '../constants';

type LabelProps = {
  children: React.ReactNode;
  size?: 'large' | 'medium' | 'small' | 'x-medium';
  required?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelColor: {
      color: Palette.Gray.Darker,
      lineHeight: 1.5,
    },
    formLabelLarge: {
      fontSize: 24,
    },
    formLabelMedium: {
      fontSize: 20,
    },
    formLabelXMedium: {
      fontSize: 18,
    },
    formLabelSmall: {
      fontSize: 16,
    },
  })
);

const Label: React.FC<LabelProps> = ({
  children,
  size = Size.MEDIUM,
  ...props
}) => {
  const classes = useStyles();
  const labelStyles = clsx({
    [classes.formLabelLarge]: size === Size.LARGE,
    [classes.formLabelMedium]: size === Size.MEDIUM,
    [classes.formLabelSmall]: size === Size.SMALL,
    [classes.formLabelXMedium]: size === Size.XMEDIUM,
  });
  return (
    <FormLabel className={clsx(classes.labelColor, labelStyles)} {...props}>
      {children}
    </FormLabel>
  );
};

export default Label;
