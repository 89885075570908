import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Palette } from '@woundtech/ui-colors';
import { PINStrengthLevel } from '@woundtech/ui-constants';

export type Props = {
  strengthLevel: PINStrengthLevel;
  rules: {
    length: boolean;
  };
};

function getStrengthLabel(value: number): string {
  if (value > 80) {
    return 'Strong';
  } else if (value >= 60) {
    return 'Moderate';
  }

  return 'Weak';
}

function getStrengthColor(value: number): string {
  if (value > 80) {
    return Palette.Green.Main;
  } else if (value >= 60) {
    return Palette.Orange.Main;
  }

  return Palette.Red.Main;
}

const StrengthMeter: React.FC<Props> = ({ strengthLevel, rules }) => {
  const items = useMemo(() => {
    return [
      {
        checked: rules.length,
        text: 'Numeric 6-10 digits',
      },
    ];
  }, [rules.length]);

  const strength = ((strengthLevel + 1) / 3) * 100;

  const Progress = withStyles({
    root: {
      backgroundColor: Palette.Gray.Light,
    },
    bar: {
      backgroundColor: getStrengthColor(strength),
    },
  })(LinearProgress);

  return (
    <Box>
      <Progress variant="determinate" value={strength} />
      <Box bgcolor="white" p={1}>
        <Box mb={1}>
          <Typography>{getStrengthLabel(strength)}</Typography>
        </Box>
        {items.map((item, index) => (
          <Box display="flex" key={item.text} mt={index > 0 ? 1 : 0}>
            <Box
              display="flex"
              pr={1}
              color={item.checked ? Palette.Green.Main : Palette.Red.Main}
            >
              <Icon>{item.checked ? 'check' : 'error_outline'}</Icon>
            </Box>
            <Box>
              <Typography>{item.text}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(StrengthMeter);
