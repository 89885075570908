import React, { useReducer, useMemo, useContext, useEffect } from 'react';
import Context from './Context';
import { reducer, defaultState } from 'routing/Schedule/reducer';
import Error from 'styleguide/Error';
import { getClinicianId, getClinicianPractitioner } from 'clinician/helpers';
import { ERROR_ACCOUNT_NOT_LINKED } from 'clinician-config/constants';
import { Context as AuthorizationContext } from 'authorization/Context';
import { Loading } from 'aws-amplify-react';
import { useClinicianQuery } from 'routing/Context/useClinicianQuery';
import { Clinician } from 'clinician';

type Props = {
  children?: React.ReactNode;
  isMySchedule?: boolean;
};

const Provider: React.FC<Props> = ({ children, isMySchedule = false }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    isMySchedule,
    currentDate: new Date().toISOString(),
  });
  const { user } = useContext(AuthorizationContext);
  const scheduleClinicianId = getClinicianId(
    JSON.parse(
      localStorage.getItem(`PCC:${user?.id}:SchedulingClinician`) as string
    )
  );

  const queryCliniciansIds =
    scheduleClinicianId && scheduleClinicianId !== user?.id
      ? `${user?.id},${scheduleClinicianId}`
      : user?.id;

  const { loading, error, clinicians } = useClinicianQuery({
    clinicianId: queryCliniciansIds,
  });

  const clinician = clinicians.find(
    clin => getClinicianPractitioner(clin)?.id === user?.id
  ) as Clinician;

  const scheduleClinician = clinicians.find(
    clin => getClinicianPractitioner(clin)?.id === scheduleClinicianId
  ) as Clinician;

  useEffect(() => {
    if (isMySchedule) {
      dispatch({ type: 'setClinician', clinician });
    } else {
      dispatch({ type: 'setClinician', clinician: scheduleClinician });
    }
  }, [clinician, scheduleClinician, isMySchedule, dispatch]);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
    }),

    [state, dispatch]
  );

  if (error) {
    return <Error text={error?.message} />;
  }

  if (loading) {
    return <Loading />;
  }

  if (isMySchedule && !getClinicianId(state?.clinician)) {
    return <Error text={ERROR_ACCOUNT_NOT_LINKED} />;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
