import { gql } from '@apollo/client';

export const HumanNameFragment = gql`
  fragment HumanName on HumanName {
    use
    family
    given
    prefix
    suffix
    text
  }
`;
