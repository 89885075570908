import React, { useMemo, useEffect } from 'react';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import DefaultFormControl from 'styleguide/DefaultFormControl';
import {
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_INCORRECT,
  AUTOCOMPLETE_ATTRIBUTE,
} from 'utility/constants';

import { Field } from '../types';
import {
  MIN_LENGTH_DEFAULT,
  textMaskFormat,
  TEXT_AREA_MAX_ROWS,
  TEXT_AREA_MAX_LENGTH,
  TEXT_AREA_MIN_ROWS,
} from './index';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginRight: 4,
      '& > * + *': {
        marginRight: theme.spacing(6),
      },
    },
    maskInput: {
      WebkitTextSecurity: 'disc',
    },
  })
);

export interface Props extends Field {
  inputPattern?: RegExp;
  mask?: (string | RegExp)[];
  validationPattern?: RegExp;
  value: string | undefined;
  maxLength?: number | null;
  minLength?: number;
  onKeyPress?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  multiline?: boolean;
  endIcon?: string;
  errorMessage?: string;
  onEndIconClick?: (e) => void;
  isEndIconDisabled?: boolean;
  loading?: boolean;
  type?: string;
  autoFocus?: boolean;
  rows?: string | number;
  maxRows?: string | number;
  maskInput?: boolean;
  onClick?: () => void;
}

const validate = (
  rawValue: string,
  required?: boolean,
  pattern?: RegExp,
  minLength?: number,
  errorMessage?: string
) => {
  const value = (rawValue || '').trimLeft();

  if (!value && required) {
    return VALIDATION_ERROR_REQUIRED;
  }

  if (value && pattern && !value.match(pattern)) {
    return VALIDATION_ERROR_INCORRECT;
  }

  if (value && minLength && value.length < minLength) {
    return VALIDATION_ERROR_INCORRECT;
  }

  if (errorMessage) {
    return errorMessage;
  }
};

const TextField: React.FC<Props> = ({
  disabled = false,
  endIcon,
  errorMessage,
  inputPattern,
  isEndIconDisabled,
  label,
  loading,
  mask,
  maxLength = TEXT_AREA_MAX_LENGTH,
  minLength = MIN_LENGTH_DEFAULT,
  multiline,
  name,
  onChange,
  onEndIconClick,
  onKeyDown,
  onKeyPress,
  onValidate,
  placeholder = '',
  required = false,
  type = 'text',
  validationPattern,
  value = '',
  autoFocus = false,
  rows = TEXT_AREA_MIN_ROWS,
  maxRows = TEXT_AREA_MAX_ROWS,
  maskInput = false,
  onClick = () => {},
}) => {
  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ name: string; value: any }>) => {
    if (value && inputPattern && !value.match(inputPattern)) {
      return;
    }

    onChange(mask ? value.replace(/[-\s()X]+/gi, '') : value, name);
  };

  const helperText =
    validate(value, required, validationPattern, minLength, errorMessage) || '';
  const inputProps = useMemo(() => ({ maxLength }), [maxLength]);
  const isValid = !helperText;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const displayValue = textMaskFormat(value, mask);
  const classes = useStyles();

  const endAdornment = loading ? (
    <InputAdornment position="end">
      <Box display="flex" p={1}>
        <div className={classes.root}>
          <CircularProgress size={20} />
        </div>
      </Box>
    </InputAdornment>
  ) : (
    endIcon && (
      <InputAdornment position="end">
        <Box display="flex">
          <IconButton onClick={onEndIconClick} disabled={isEndIconDisabled}>
            <Icon color="action">{endIcon}</Icon>
          </IconButton>
        </Box>
      </InputAdornment>
    )
  );

  return (
    <DefaultFormControl
      disabled={disabled}
      helperText={helperText}
      label={label}
      required={required}
    >
      <FilledInput
        data-field-name={name}
        autoComplete={AUTOCOMPLETE_ATTRIBUTE}
        autoFocus={autoFocus}
        fullWidth
        inputProps={inputProps}
        multiline={multiline}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type={type}
        value={displayValue || ''}
        endAdornment={endAdornment}
        rows={rows}
        rowsMax={maxRows}
        classes={maskInput ? { input: classes.maskInput } : undefined}
        onBlur={() => {
          const newValue = (value || '')
            .replace(multiline ? /(?!\n)\s+/g : /\s+/g, ' ')
            .trim();
          if (newValue !== value) {
            onChange(newValue, name);
          }
        }}
        onClick={onClick}
      />
    </DefaultFormControl>
  );
};

export default React.memo(TextField);
