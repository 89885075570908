export const WEB_VERSION_ALERT_TITLE = 'Update Your App Version';
export const OLDER_WEB_VERSION_WARNING =
  'Please refresh the browser to use the latest version of the app.';
export const CURRENT_VERSION = 'Current Version:';
export const NEW_VERSION = 'New Version:';
export const SUBMIT_BUTTON_TEXT = 'Refresh';

export const PASSWORD_EXPIRY_ERROR = 'Your password has been expired';
export const ACCOUNT_EXPIRY_ERROR = 'Your account has been expired';
export const RESET_PASSWORD_TITLE = 'Reset Password';
export const RESET_PASSWORD_MESSAGE =
  'Your password has expired. Kindly reset your password to login.';

export const EXTERNAL_USER_ACCOUNT_EXPIRED = 'external-user-account-expired';
export const ACCOUNT_VERIFICATION = 'Account Verification';
export const ACCOUNT_VERIFICATION_MESSAGE =
  'You need to verify your account to continue using EMR. A verification code has been sent to your email';
export const ACCOUNT_VERIFICATION_CODE = 'Enter verification code';
export const SUBMIT_TEXT = 'Submit Code';
export const RESEND_CODE = 'Resend Code';
export const INVALID_CODE = 'Invalid Code';
export const INVALID_CODE_MESSAGE =
  'Invalid verification code provided, please try again.';

export const ERROR_ENTER_EMAIL = 'Please enter email id to continue';
export const ERROR_INTERNAL_USE_OAUTH = 'Please use "Continue with your Woundtech account" option above. Contact support@woundtech.net if you still need help signing in after using this option.';
export const EXTERNAL_USER_LABEL = 'Continue with email (for non-Woundtech users)'