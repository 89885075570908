import { MASTER_LIST_PATH, HOME_HEALTH_CODE } from '../constants';

export const ENTRY_PERMISSION = 'settings';

export const HOME_HEALTH = {
  path: `${MASTER_LIST_PATH}/home-health`,
  text: 'Home Health',
  module: HOME_HEALTH_CODE,
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export type ICareFacility = {
  organization: fhir.Organization;
  practitioners?: fhir.Practitioner[];
};
