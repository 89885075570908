import { ucFirst } from '@woundtech/ui-utils';
import { ineligibilityErrorSystem } from './constants';
import {
  getIdentifierBySystem,
  IDENTIFIER_SYSTEM_NPI,
} from 'fhir/general/Identifier';

export const getIsInsuranceEligible = eligibilityResponse => {
  return (eligibilityResponse?.insurance || []).every(
    (item: any) => item.inforce
  );
};

export const getIneligibleReason = eligibilityResponse => {
  const reasons = eligibilityResponse?.error
    ?.filter(
      item => item?.code?.coding?.[0]?.system === ineligibilityErrorSystem
    )
    ?.map(item => item?.code?.coding?.[0]?.display?.replace('Error:', ''));
  return reasons?.map(item => ucFirst(item?.trim()))?.join(', ');
};

export const getProviderNpi = (item: fhir.Organization) => {
  return getIdentifierBySystem(item?.identifier, IDENTIFIER_SYSTEM_NPI)?.value;
};

export const getFieldValueFromExtension = (
  data: fhir.Extension[] | undefined,
  url: string
): string => {
  const value = data?.find(data => data?.url === url)?.valueString || '';
  return value;
};

export const deepClone = data => JSON.parse(JSON.stringify(data));
