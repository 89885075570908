import { ADMINISTRATIVE_CONFIG_PATH } from '../../administrative-config/constants';

export const COMMLOG_TOPICS = {
  path: `${ADMINISTRATIVE_CONFIG_PATH}/code-system/communication-topics`,
  text: 'Comm Log Topics',
  module: 'administrative-config-code-system',
  component: 'Dashboard',
};

export const ADMISSION_CANCEL_REASON = {
  path: `${ADMINISTRATIVE_CONFIG_PATH}/code-system/admission-cancellation-reason`,
  text: 'Adm Canc Reasons',
  module: 'administrative-config-code-system',
  component: 'Dashboard',
};

export const DISCHARGE_REASON = {
  path: `${ADMINISTRATIVE_CONFIG_PATH}/code-system/discharge-reason`,
  text: 'Discharge Reasons',
  module: 'administrative-config-code-system',
  component: 'Dashboard',
};
