import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

export type Props = {
  icon?: string;
  onAction?: () => void;
  text: string;
  iconAction?: string;
  textAction?: string;
  disableAddButton?: boolean | undefined;
};

const Empty: React.FC<Props> = ({
  onAction,
  icon,
  text,
  textAction,
  iconAction = 'add',
  disableAddButton = false,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height={1}
      width={1}
    >
      {icon && (
        <Box p={1}>
          <Icon fontSize="large" color="action">
            {icon}
          </Icon>
        </Box>
      )}
      <Typography variant="body1" align="center">
        {text}
      </Typography>
      {textAction && onAction && (
        <Box p={2}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            startIcon={<Icon>{iconAction}</Icon>}
            onClick={onAction}
            disabled={disableAddButton}
          >
            {textAction}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(Empty);
