export const getProgress = (fileSize, progress) => {
  if (fileSize === 0) return '0 Bytes';
  const k = 1024,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'],
    i = Math.floor(Math.log(fileSize) / Math.log(k));

  return (
    parseFloat((progress / Math.pow(k, i)).toFixed(2)) +
    ' ' +
    sizes[i] +
    ' to ' +
    parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) +
    ' ' +
    sizes[i]
  );
};
