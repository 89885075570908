function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default async function componentLoader(
  lazyComponent,
  attemptsLeft = 3,
  interval = 1000
) {
  try {
    return await lazyComponent();
  } catch (err) {
    if (attemptsLeft > 0) {
      await wait(interval);
      return componentLoader(lazyComponent, attemptsLeft - 1, interval);
    } else {
      throw err;
    }
  }
}
