import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import AsynchronousLayout from 'styleguide/layout/Asynchronous';
import FormLayout from 'styleguide/layout/Form';
import HealthCareFacilityForm from 'master-list/common/Form';
import {
  CARE_FACILITY_ATTRIBUTES,
  DEFAULT_TYPE_CARE_FACILITY,
} from 'master-list/constants';
import { setTelecomAndPractitionerCareFacility } from 'master-list/common/utils';
import { CreateCareFacilityMutation } from 'master-list/common/Schema';
import { ICareFacility } from 'master-list/care-facility';
import { useLegalEntitiesQuery } from 'organization/hooks';
import Box from '@material-ui/core/Box';
type Props = {
  isOpen: boolean;
  onClose: () => void;
};
const CreateFacility: React.FC<Props> = ({ isOpen, onClose }) => {
  const [formValid, setFormValid] = useState<boolean>(false);

  const [formData, setFormData] = useState<ICareFacility>({
    organization: {
      resourceType: 'Organization',
      active: true,
      type: [DEFAULT_TYPE_CARE_FACILITY],
    },
  });

  const {
    entities,
    loading: entityLoading,
    error: entityError,
  } = useLegalEntitiesQuery();

  const [submit, { loading, error }] = useMutation(CreateCareFacilityMutation, {
    refetchQueries: ['getHealthCareFacilityList'],
    awaitRefetchQueries: true,
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    try {
      await submit({
        variables: {
          ...setTelecomAndPractitionerCareFacility(formData),
        },
      });
      onClose();
    } catch {}
  }, [submit, formData, onClose]);

  return (
    <Box display="flex" flexDirection="column" height={1} width={1}>
      <AsynchronousLayout
        data={formData}
        loading={entityLoading}
        error={entityError}
      >
        <FormLayout
          isValid={
            formValid &&
            formData?.organization?.address &&
            formData?.organization?.address?.[0]?.state
              ? true
              : false
          }
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          error={error}
          loading={loading}
        >
          <Box pt={1} flexGrow="1" display="flex">
            <Box width={1} height="auto">
              <HealthCareFacilityForm
                data={formData}
                onChange={setFormData}
                onValidate={setFormValid}
                formFields={CARE_FACILITY_ATTRIBUTES}
                entities={entities}
              />
            </Box>
          </Box>
        </FormLayout>
      </AsynchronousLayout>
    </Box>
  );
};

export default CreateFacility;
