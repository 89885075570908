import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { Context as AuthorizationContext } from 'authorization/Context';
import AuthorizationError from 'authorization/Error';
import { checkRoutePermission } from 'common/utils';

interface AuthorizationRoute extends RouteProps {
  permission: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthorizationRoute: React.FC<AuthorizationRoute> = ({
  component: Component,
  children,
  ...rest
}) => {
  const { isAuthorized } = useContext(AuthorizationContext);

  return (
    <Route
      {...rest}
      render={props => {
        if (
          rest.permission &&
          !checkRoutePermission(rest.permission, isAuthorized)
        ) {
          return <AuthorizationError />;
        }

        return Component ? <Component {...props} /> : children;
      }}
    />
  );
};

export default AuthorizationRoute;
