export const ENTRY_PERMISSION = 'tasking';
export const ENTRY_LINK = {
  path: '/tasks',
  text: 'Tasks',
  icon: 'assignment_turned_in',
  module: 'task',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

const taskOutputMap = {
  'identify-patient': 'patientId',
  'identify-insurance': 'coverageId',
  'add-referral-authorization': 'referralId',
  'add-admission': 'admissionId',
  'identify-fax-document-type': 'faxDocumentAckId',
};

export const getTaskSteps = (task, currentTask) => {
  return task?.planDefinition?.action?.[0]?.action?.map(action => {
    const id = action?.code?.[0]?.coding?.[0]?.code;
    const subStep = task?.composedOf?.find(step => {
      return step?.resource?.code?.coding?.[0]?.code === id;
    });

    const value = subStep?.resource?.output?.[0]?.valueReference;
    return {
      id,
      label: action?.title || '',
      status: subStep?.resource?.status,
      link: value
        ? {
            label: value?.display,
            url: getSubTaskUrl(task, subStep?.resource),
          }
        : undefined,
      active: currentTask?.id === subStep?.resource?.id,
    };
  });
};

export const getDocTypeFromTaskType = (
  taskType: fhir.CodeableConcept,
  docTypes: any
) => {
  switch (taskType?.coding?.[0]?.code) {
    case 'referral':
      return docTypes?.expandValueSet?.expansion?.contains?.filter(
        item => item.code === 'referral'
      );
    case 'authorization-response':
      return docTypes?.expandValueSet?.expansion?.contains?.filter(
        item => item.code === 'authorization'
      );
    case 'medical-record':
      return docTypes?.expandValueSet?.expansion?.contains?.filter(
        item => item.code === 'admission'
      );
    case 'admission':
      return docTypes?.expandValueSet?.expansion?.contains?.filter(
        item => item.code === 'admission'
      );
    default:
      return;
  }
};

export const getTaskValues = task => {
  return task?.composedOf
    ?.filter(item => item?.resource?.status === 'completed')
    .reduce((accumulator, item) => {
      const taskType = item?.resource?.instantiatesCanonical.split('/')[1];
      const variableName = taskOutputMap[taskType];

      if (variableName) {
        accumulator[
          variableName
        ] = item?.resource?.output?.[0]?.valueReference?.reference?.split(
          '/'
        )[1];
      }

      return accumulator;
    }, {});
};

export const getSubTaskUrl = (task: any, subTask: any) => {
  const {
    patientId,
    coverageId,
    referralId,
    admissionId,
    faxDocumentAckId,
    slug,
    orderId,
  } = getTaskValues(task);

  const taskId = task?.id;
  const subTaskId = subTask?.id;
  const subTaskSlug = subTask?.code?.coding?.[0]?.code;

  switch (subTaskSlug) {
    case 'identify-patient': {
      if (patientId) {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/demographics`;
      } else {
        return `/tasks/${taskId}/${subTaskId}`;
      }
    }
    case 'identify-insurance': {
      if (coverageId) {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/coverages/${coverageId}`;
      } else {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/coverages`;
      }
    }
    case 'add-referral-authorization': {
      if (referralId) {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/referrals/${referralId}`;
      } else {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/referrals/new`;
      }
    }
    case 'add-admission': {
      if (admissionId) {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/admissions/${admissionId}`;
      } else {
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/admissions/new`;
      }
    }
    case 'identify-fax-document-type': {
      if (faxDocumentAckId) {
        return `/tasks/${taskId}/${subTaskId}/identify-document/preview-attachments`;
      }
      return `/tasks/${taskId}/${subTaskId}/identify-document/preview-attachments`;
    }
    case 'identify-order': {
      if (orderId)
        return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/past-orders`;
      return `/tasks/${taskId}/${subTaskId}/patients/${patientId}/past-orders/${slug}/${orderId}`;
    }
  }
};

export const getPrevSubTaskUrl = (task: any, currentSubTask: any) => {
  const currentSubTaskIndex = task?.composedOf?.findIndex(
    item => item?.resource?.id === currentSubTask?.id
  );

  const prevSubTask = task?.composedOf?.[currentSubTaskIndex - 1]?.resource;
  if (prevSubTask) {
    return getSubTaskUrl(task, prevSubTask);
  }
};

export const getNextSubTaskUrl = (task: any, currentSubTask: any) => {
  const currentSubTaskIndex = task?.composedOf?.findIndex(
    item => item?.resource?.id === currentSubTask?.id
  );

  const nextSubTask = task?.composedOf?.[currentSubTaskIndex + 1]?.resource;
  if (nextSubTask) {
    return getSubTaskUrl(task, nextSubTask);
  } else {
    return `/tasks/${task?.id}/complete`;
  }
};

export const getTaskContinueUrl = (task: any) => {
  const isCompleted = task?.composedOf?.every(item => {
    return item?.resource?.status === 'completed';
  });

  if (isCompleted) {
    return `/tasks/${task?.id}/complete`;
  }

  const subTask = task?.composedOf?.find(item => {
    return item?.resource?.status === 'in-progress';
  });

  if (!subTask) {
    return;
  }

  const { patientId, coverageId, referralId, admissionId } = getTaskValues(
    task
  );

  if (!patientId && !coverageId && !referralId && !admissionId) {
    return `/tasks/${task?.id}/${subTask?.resource?.id}`;
  }

  if (patientId && !coverageId && !referralId && !admissionId) {
    return `/tasks/${task?.id}/${subTask?.resource?.id}/patients/${patientId}/coverages`;
  }

  if (patientId && coverageId && !referralId && !admissionId) {
    return `/tasks/${task?.id}/${subTask?.resource?.id}/patients/${patientId}/referrals/new`;
  }

  if (patientId && referralId && !admissionId) {
    return `/tasks/${task?.id}/${subTask?.resource?.id}/patients/${patientId}/admissions/new`;
  }

  if (admissionId) {
    return `/tasks/${task?.id}/complete`;
  }
};
