import { CARE_FACILITY } from './care-facility/index';
import { HOME_HEALTH } from './home-health/index';
import { PCP_CONFIG } from './pcp-config/index';
import {
  COMMLOG_TOPICS,
  ADMISSION_CANCEL_REASON,
  DISCHARGE_REASON,
} from 'master-list/code-system/index';
import { ENTRY_LINK as PAYER_ENTRY_LINK } from 'payer';

import { MASTER_LIST_PATH } from './constants';

export const ENTRY_PERMISSION = 'settings';

export const ENTRY_LINK = {
  path: MASTER_LIST_PATH,
  text: 'Master Lists',
  icon: 'list',
  permission: ENTRY_PERMISSION,
  children: [
    CARE_FACILITY,
    HOME_HEALTH,
    COMMLOG_TOPICS,
    ADMISSION_CANCEL_REASON,
    DISCHARGE_REASON,
    PAYER_ENTRY_LINK,
    PCP_CONFIG,
  ],
};
