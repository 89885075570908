import React from 'react';
import Box from '@material-ui/core/Box';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
      [theme.breakpoints.up('lg')]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
    },
  })
);

export type Props = {
  children?: React.ReactNode;
};

const Component: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  if (!children) {
    return null;
  }

  return (
    <Box className={classes.root} height={1}>
      {children}
    </Box>
  );
};

export default React.memo(Component);
