import { gql } from '@apollo/client';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';

export const DocumentReferenceFragment = gql`
  fragment DocumentReference on DocumentReference {
    id
    resourceType
    subject {
      reference
    }
    type {
      ...CodeableConcept
    }
    category {
      ...CodeableConcept
    }
    content {
      attachment {
        id
        title
        contentType
        creation
        url
      }
    }
    docStatus
    date
    author {
      reference
      display
    }
    context {
      related {
        reference
      }
    }
  }
  ${CodeableConceptFragment}
`;
