import React, { useMemo } from 'react';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { fullAddressFormat } from 'fhir/general/Address';
import HomeHealthCard from 'master-list/common/Card';
import { phoneNumber } from 'styleguide/form/Telecom/formatters';

type Props = {
  data: any;
  onClick: () => void;
};

const Component: React.FC<Props> = ({ data, onClick }) => {
  const attributes = useMemo(() => {
    const telecom = data?.telecom?.find(item => item?.system === 'phone');
    const telecomValue = telecom
      ? phoneNumber(telecom)
      : PlaceholderKind.NotAvailable;
    return [
      {
        icon: 'phone',
        value: telecomValue,
        columns: 2,
      },
      {
        icon: 'location_on',
        value: fullAddressFormat(data?.address?.[0]),
        columns: 2,
      },
    ];
  }, [data]);

  return (
    <HomeHealthCard
      title={data?.name?.[0]?.text}
      onClick={onClick}
      data={data}
      customAttributes={attributes}
      cardStatus={data?.active}
    />
  );
};

export default React.memo(Component);
