import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import TelecomField from 'styleguide/form/Telecom';
import HumanNameField from 'fhir/general/HumanName';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from 'styleguide/form/Text';
import Box from '@material-ui/core/Box';
import {
  CARE_FACILITY_ROLE_URL,
  CONTACT_PERSON_PRIMARY,
  CONTACT_PERSON_STATUS,
  DEFAULT_MOBILE,
  DEFAULT_WORK,
  MOBILE_CODE,
  WORK_CODE,
} from 'master-list/constants';
import {
  getExtensionValueBoolean,
  getRoleCodeableConcept,
  getRoleFromPractitioner,
} from '../utils';

export type Props = {
  data?: any;
  onChange: (data: any) => void;
  onValidate: (isValid: boolean) => void;
};

const ContactPerson: React.FC<Props> = ({ data, onChange, onValidate }) => {
  const [validity, setValidity] = useState<any>({});
  const isActive = useMemo(() => {
    const active = getExtensionValueBoolean(data, CONTACT_PERSON_STATUS);
    return active === undefined ? true : active;
  }, [data]);

  const isPrimary = useMemo(() => {
    const primary = getExtensionValueBoolean(data, CONTACT_PERSON_PRIMARY);
    return primary === undefined ? false : primary;
  }, [data]);

  const workPhone: fhir.ContactPoint[] = useMemo(() => {
    const phone = data?.telecom?.find(item => item?.use === WORK_CODE);
    return phone ? [phone] : DEFAULT_WORK;
  }, [data]);
  const mobilePhone: fhir.ContactPoint[] = useMemo(() => {
    const phone = data?.telecom?.find(item => item?.use === MOBILE_CODE);
    return phone ? [phone] : DEFAULT_MOBILE;
  }, [data]);

  const handleContactNameChange = useCallback(
    (name: fhir.HumanName | undefined) => {
      onChange({
        ...(data || {}),
        name: [name],
      });
    },
    [data, onChange]
  );

  const handleStatusChange = useCallback(
    (value: boolean) => {
      const updatedStatus = {
        url: CONTACT_PERSON_STATUS,
        valueBoolean: !isActive,
      };
      const updatedExtension =
        data?.extension?.filter(item => item?.url !== CONTACT_PERSON_STATUS) ||
        [];
      onChange({
        ...(data || {}),
        extension: [updatedStatus, ...updatedExtension],
      });
    },
    [data, onChange, isActive]
  );

  const handlePrimaryChange = useCallback(
    (value: boolean) => {
      const updatedPrimary = {
        url: CONTACT_PERSON_PRIMARY,
        valueBoolean: !isPrimary,
      };
      const updatedExtension =
        data?.extension?.filter(item => item?.url !== CONTACT_PERSON_PRIMARY) ||
        [];
      onChange({
        ...(data || {}),
        extension: [updatedPrimary, ...updatedExtension],
      });
    },
    [data, onChange, isPrimary]
  );

  const handleRoleChange = useCallback(
    (role: string | undefined) => {
      const filteredRole = data?.qualifications?.filter(
        item => item?.code.coding?.[0]?.system !== CARE_FACILITY_ROLE_URL
      );
      onChange({
        ...(data || {}),
        qualification: [
          ...(filteredRole || []),
          { code: getRoleCodeableConcept(role) },
        ],
      });
    },
    [data, onChange]
  );

  const handleWorkPhoneChange = useCallback(
    (value: fhir.ContactPoint[]) => {
      const updatedWorkPhone = {
        ...value?.[0],
        use: WORK_CODE,
      };
      const updatedTelecom =
        data?.telecom?.filter(item => item?.use !== WORK_CODE) || [];
      onChange({
        ...(data || {}),
        telecom: updatedWorkPhone?.value?.length
          ? [...updatedTelecom, updatedWorkPhone]
          : updatedTelecom,
      });
    },
    [data, onChange]
  );

  const handleMobilePhoneChange = useCallback(
    (value: fhir.ContactPoint[]) => {
      const updatedMobilePhone = {
        ...value?.[0],
        use: MOBILE_CODE,
      };
      const updatedTelecom =
        data?.telecom?.filter(item => item?.use !== MOBILE_CODE) || [];
      onChange({
        ...(data || {}),
        telecom: updatedMobilePhone?.value?.length
          ? [...updatedTelecom, updatedMobilePhone]
          : updatedTelecom,
      });
    },
    [data, onChange]
  );

  const handleTelecomChange = useCallback(
    (telecom: fhir.ContactPoint[] | undefined) => {
      const updatedTelecom = telecom?.filter(item => item?.value?.length);
      onChange({
        ...(data || {}),
        telecom: updatedTelecom,
      });
    },
    [data, onChange]
  );

  const handleValidityChange = (key: string) => (
    isValid: boolean | undefined
  ) => {
    if (validity[key] !== isValid) {
      setValidity({
        ...validity,
        [key]: isValid,
      });
    }
  };

  useEffect(() => {
    const isValid = Object.keys(validity).every((key: string) => validity[key]);
    onValidate(isValid);
  }, [data, validity, onValidate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          label={'Role'}
          maxLength={60}
          name="role"
          onChange={handleRoleChange}
          onValidate={handleValidityChange('ContactPersonRole')}
          value={getRoleFromPractitioner(data)}
        />
      </Grid>
      <Grid item xs={4}>
        <Box m={1} textAlign={'right'}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isPrimary}
                onChange={event => handlePrimaryChange(event?.target?.checked)}
                color="primary"
                value={isPrimary}
              />
            }
            label="Primary"
            labelPlacement="end"
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box m={1} textAlign={'right'}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={isActive}
                onChange={event => handleStatusChange(event?.target?.checked)}
                color="primary"
                value={isActive}
              />
            }
            label="Active"
            labelPlacement="end"
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <HumanNameField
          displayMiddleName={false}
          displayPrefix={false}
          onChange={handleContactNameChange}
          onValidate={handleValidityChange('ContactPersonsName')}
          value={data?.name?.[0]}
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TelecomField
          name="fax"
          onChange={handleTelecomChange}
          onValidate={handleValidityChange('ContactPersonPhone')}
          system="fax"
          value={data?.telecom}
        />
      </Grid>
      <Grid item xs={6}>
        <TelecomField
          name="email"
          onChange={handleTelecomChange}
          onValidate={handleValidityChange('ContactPersonEmail')}
          system="email"
          value={data?.telecom}
        />
      </Grid>
      <Grid item xs={12}>
        <TelecomField
          name="workPhone"
          onChange={handleWorkPhoneChange}
          onValidate={handleValidityChange('ContactPersonWorkPhone')}
          system="phone"
          value={workPhone}
          typeDisabled
        />
      </Grid>
      <Grid item xs={12}>
        <TelecomField
          name="mobilePhone"
          onChange={handleMobilePhoneChange}
          onValidate={handleValidityChange('ContactPersonMobilePhone')}
          system="phone"
          value={mobilePhone}
          typeDisabled
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactPerson);
