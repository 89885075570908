import { UserJobFunction } from 'user';

export const ADP_ENABLED_JOB_FUNCTIONS = [
  UserJobFunction.clinician,
  UserJobFunction.advanceClinician,
];
export const MAX_LENGTH_ADP_EMPLOYEE_ID = 15;
export const ADP_EMPLOYEE_ID_IDENTIFIER_SYSTEM =
  'http://woundtech.net/fhir/identifiers/adp/employee-id';
export const ADP_SYNC_ENABLED_URL =
  'http://woundtech.net/fhir/extensions/adp-sync-enabled';
export const ADP_START_DATE_URL =
  'http://woundtech.net/fhir/extensions/clinician-start-date';
