import { BASE_PCP_CONFIG_URL } from '../constants';

export const ENTRY_PERMISSION = 'settings';

export const PCP_CONFIG = {
  path: `${BASE_PCP_CONFIG_URL}`,
  text: 'PCPs',
  module: 'pcp-config',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
