import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import TelecomField from 'styleguide/form/Telecom';
import { checkIfEmpty } from '../utils';

export type Props = {
  data?: any;
  onChange: (data: any) => void;
  onValidate: (isValid: boolean) => void;
};

const ContactInfo: React.FC<Props> = ({ data, onChange, onValidate }) => {
  const [validity, setValidity] = useState<any>({});

  const handleTelecomChange = useCallback(
    (telecom: fhir.ContactPoint[] | undefined) => {
      onChange({
        ...(data || {}),
        telecom,
      });
    },
    [data, onChange]
  );

  const handleValidityChange = (key: string) => (
    isValid: boolean | undefined
  ) => {
    if (validity[key] !== isValid) {
      setValidity({
        ...validity,
        [key]: isValid,
      });
    }
  };

  useEffect(() => {
    const isValid = Object.keys(validity).every((key: string) => validity[key]);
    const isEmpty = checkIfEmpty([data])?.some(entry => entry === true);
    onValidate(isValid && !isEmpty);
  }, [data, validity, onValidate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TelecomField
          name="fax"
          onChange={handleTelecomChange}
          onValidate={handleValidityChange('facilityFax')}
          system="fax"
          value={data?.telecom}
        />
      </Grid>
      <Grid item xs={6}>
        <TelecomField
          name="email"
          onChange={handleTelecomChange}
          onValidate={handleValidityChange('facilityEmail')}
          system="email"
          value={data?.telecom}
        />
      </Grid>
      <Grid item xs={12}>
        <TelecomField
          name="phone"
          onChange={handleTelecomChange}
          onValidate={handleValidityChange('facilityPhone')}
          system="phone"
          value={data?.telecom}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactInfo);
