import React, { useEffect, useState } from 'react';
import TextField, {
  MIN_LENGTH_DEFAULT,
  MAX_LENGTH_DEFAULT,
} from 'styleguide/form/Text';

export type Props = {
  data?: fhir.Identifier[];
  onChange: (data: any) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onValidate: (isValid: boolean) => void;
  inputPattern?: RegExp;
  validationPattern?: RegExp;
  placehoder?: string;
  required?: boolean;
  template: fhir.Identifier;
  label: string;
  maxLength?: number;
  minLength?: number;
  mask?: (string | RegExp)[];
  name: string;
  bySystem?: boolean;
};

const getIdentifierByTemplate = (
  data: fhir.Identifier[],
  template: fhir.Identifier,
  bySystem?: boolean
) => {
  if (!Array.isArray(data)) {
    return;
  }

  return data.find(
    item =>
      item?.type?.coding?.[0]?.code === template.type?.coding?.[0]?.code ||
      (bySystem && item?.system === template.system)
  );
};

const Identifier: React.FC<Props> = ({
  data = [],
  onChange,
  onKeyPress = () => {},
  onValidate,
  inputPattern,
  validationPattern,
  placehoder = '',
  required,
  template,
  label,
  maxLength = MAX_LENGTH_DEFAULT,
  minLength = MIN_LENGTH_DEFAULT,
  mask,
  name,
  bySystem,
}) => {
  const [isValid, setIsValid] = useState(false);

  const identifier = getIdentifierByTemplate(data, template, bySystem);

  const handleChange = value => {
    const itemValue = {
      ...template,
      value,
    };

    if (identifier) {
      const newData = data.map(item => {
        if (
          item?.type?.coding?.[0]?.code ===
            itemValue?.type?.coding?.[0]?.code ||
          (bySystem && item?.system === itemValue?.system)
        ) {
          return itemValue;
        }

        return item;
      });

      onChange(newData);
    } else {
      onChange([...(data || []), itemValue]);
    }
  };

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <TextField
      inputPattern={inputPattern}
      label={label}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      onValidate={setIsValid}
      placeholder={placehoder}
      required={required}
      validationPattern={validationPattern}
      value={identifier?.value || ''}
      mask={mask}
    />
  );
};

export default React.memo(Identifier);
