import React, { useReducer, useMemo, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Loading from 'styleguide/Loading';
import Error from 'styleguide/Error';
import { AuthorizationQuery } from '../Schema';
import { Context as AuthenticationContext } from 'authentication/Context';
import Context from './Context';
import { reducer, defaultState } from './reducer';
import { PUBLIC_PATHNAME } from 'utility/constants';
import { emailFormat } from 'styleguide/form/Telecom';
import { fullNameFormat } from 'fhir/general/HumanName';
import { identifierBySystem } from 'fhir/general/Identifier/formatters';
import { hasUserPermission } from 'user';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';

type Props = {
  children?: React.ReactNode;
};

declare global {
  interface Window {
    DD_RUM: any;
  }
}

const Provider: React.FC<Props> = ({ children }) => {
  const {
    isAuthenticated,
    completed: isAuthenticationCompleted,
    storePin,
    signOut,
  } = useContext(AuthenticationContext);
  const featureToggles = useContext(FeatureToggleContext);

  const [state, dispatch] = useReducer(reducer, defaultState);

  const setUserForRUM = async (data: any) => {
    if (data) {
      const user = data?.GetUserWithCognitoAttributes;
      const rumUser = {
        email: emailFormat(user?.telecom),
        name: fullNameFormat(user?.name),
        id: identifierBySystem(
          user?.identifier,
          'http://woundtech.net/fhir/identifiers/user'
        ),
      };
      window?.DD_RUM?.setUser(rumUser);
    }
  };

  const { loading, error } = useQuery(AuthorizationQuery, {
    fetchPolicy: 'network-only',
    skip: !isAuthenticationCompleted || !isAuthenticated,
    onCompleted: data => {
      if (
        featureToggles?.EnableExternalUserVerification?.toLowerCase() ===
          'true' &&
        data?.GetUserWithCognitoAttributes?.isExtUserAccountExpired
      ) {
        signOut();
      } else {
        setUserForRUM(data);
        const pin = data?.GetUserWithCognitoAttributes?.userPin;
        if (pin) {
          storePin(pin);
        }
        dispatch({
          type: 'authorizationCompleted',
          user: data?.GetUserWithCognitoAttributes,
          organization: data?.DefaultOrganization,
        });
      }
    },
    onError: error => {
      dispatch({ type: 'authorizationFailed', error: error?.message });
    },
  });

  const value = useMemo(
    () => ({
      ...state,
      isAuthorized: (permissionId: string) => {
        return hasUserPermission(state?.user, permissionId);
      },
    }),
    [state]
  );

  if (isAuthenticationCompleted && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: PUBLIC_PATHNAME,
        }}
      />
    );
  }

  if (error) {
    return <Error text={error?.message} />;
  }

  if (loading || !state.completed || !isAuthenticationCompleted) {
    return <Loading />;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
