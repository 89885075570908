import React, { useState, useMemo, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import FormLayout from 'styleguide/layout/Form';
import PeriodField from 'styleguide/form/Period';
import { formatISO } from 'date-fns';
import FieldSet from 'styleguide/layout/FieldSet';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AsynchronousLayout from 'styleguide/layout/Asynchronous';
import { useQuery } from '@apollo/client';
import { GetEvents } from 'notification-config/Schema';
import MultiChoiceGroup from 'styleguide/form/MultipleChoice/MultiChoiceGroup';
import { ResponseEvent, Event } from 'styleguide/layout/Private/AppBar';

export type Props = {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  onClose: () => void;
  filters: SelectedFilterParams;
};

export type SelectedFilterParams = {
  filters: {
    startDate?: string;
    endDate?: string;
    events: ResponseEvent[];
  };
};

export type FilterParams = {
  filters: {
    startDate?: string;
    endDate?: string;
    events: ResponseEvent[];
  };
};

const Filters: React.FC<Props> = ({ onCancel, onSubmit, onClose, filters }) => {
  const [selectedFilters, setSelectedFilters] = useState<FilterParams>({
    ...filters,
  });
  const [selectEvent, setSelectEvents] = useState<ResponseEvent[]>([]);
  const [updateFilters, setUpdateFilters] = useState<boolean>(true);

  const { data: eventsData, loading, error } = useQuery(GetEvents, {
    fetchPolicy: 'no-cache',
  });

  const eventsList = useMemo(() => {
    const list = eventsData?.GetEvents?.map((event: Event) => {
      return {
        ...event,
        display: event?.description,
        code: event?._id,
      };
    });

    return list;
  }, [eventsData]);

  const resultChange = useCallback(
    (isChecked, option) => {
      if (isChecked) {
        setSelectEvents([...selectEvent, option]);
        setSelectedFilters({
          ...selectedFilters,
          filters: {
            ...selectedFilters.filters,
            events: [...(selectedFilters?.filters?.events || []), option],
          },
        });
      } else {
        const filtered = selectEvent.filter(v => v.code !== option.code);
        setSelectEvents([...filtered]);
        setSelectedFilters({
          ...selectedFilters,
          filters: {
            ...selectedFilters.filters,
            events: [...filtered],
          },
        });
      }
    },
    [setSelectedFilters, selectedFilters, selectEvent]
  );

  useEffect(() => {
    if (!updateFilters) return;
    setSelectEvents(prev => [...prev, ...selectedFilters.filters.events]);
    setUpdateFilters(false);
  }, [updateFilters, selectedFilters, setSelectEvents]);

  const handlePeriodChange = useCallback(
    (filterPeriod: fhir.Period) => {
      setSelectedFilters({
        ...selectedFilters,
        filters: {
          ...selectedFilters.filters,
          startDate: filterPeriod?.start
            ? formatISO(new Date(filterPeriod?.start))
            : undefined,
          endDate: filterPeriod?.end
            ? formatISO(new Date(filterPeriod?.end))
            : undefined,
        },
      });
    },
    [selectedFilters]
  );

  const handleSubmit = useCallback(() => {
    onSubmit(selectedFilters);
    onClose();
  }, [onSubmit, selectedFilters, onClose]);

  const resetFilters = useCallback(() => {
    setSelectedFilters({
      filters: {
        startDate: undefined,
        endDate: undefined,
        events: [...selectEvent],
      },
    });
  }, [selectEvent, setSelectedFilters]);

  return (
    <AsynchronousLayout data={true} loading={loading} error={error}>
      <FormLayout
        onCancel={onCancel}
        onSubmit={handleSubmit}
        submitButtonText="Apply"
        isValid={true}
      >
        <Box pt={2}>
          <FieldSet>
            <Box mb={2}>
              <Grid container>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">Date</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      onClick={resetFilters}
                      disabled={false}
                    >
                      Clear
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <PeriodField
                startLabel={'Start'}
                endLabel={'End'}
                label="Session Date Filter"
                name="FilterBy"
                onChange={handlePeriodChange}
                onValidate={() => {}}
                value={{
                  start: selectedFilters?.filters?.startDate,
                  end: selectedFilters?.filters?.endDate,
                }}
              />
            </Box>
          </FieldSet>
        </Box>
        <FieldSet>
          <Box mb={2}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="subtitle1">
                  Event Type ({selectEvent?.length})
                </Typography>
              </Grid>
              <Box px={2} mt={2}>
                <Grid item xs={12}>
                  <MultiChoiceGroup
                    name="test"
                    onChange={(option, isChecked) =>
                      resultChange(option, isChecked)
                    }
                    onValidate={() => {}}
                    options={eventsList}
                    isSelected={option => {
                      return [...selectEvent]?.some(
                        item => item?.code === option?.code
                      );
                    }}
                    value={selectEvent}
                    getOptionLabel={(option: any) => option?.display}
                    labelSize="small"
                  />
                </Grid>
              </Box>
            </Grid>
          </Box>
        </FieldSet>
      </FormLayout>
    </AsynchronousLayout>
  );
};
export default React.memo(Filters);
