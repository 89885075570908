import React, { useContext, useMemo } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import UserNavigation from 'user/Navigation';
import { Context as AppConfigContext } from 'application/Configuration';
import NavigationList from './NavigationList';
import { Context as WebVersionContext } from 'application/WebVersion';
import Icon from '@material-ui/core/Icon';
import { Palette } from '@woundtech/ui-colors';
import { OLDER_VERSION_TEXT, CONTACT_SUPPORT } from './constants';

export type PrimaryNavigationItemProps = {
  disabled?: boolean;
  icon: string;
  path: string;
  text: string;
  children?: any;
};

export type PrimaryNavigationProps = {
  items: Array<PrimaryNavigationItemProps>;
  toggleOpen?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
      fontSize: '1rem',
    },
    active: {
      backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
    },
    warningIcon: {
      fontSize: '1rem',
      marginRight: '4px',
    },
  })
);

const PrimaryNavigation: React.FC<PrimaryNavigationProps> = ({
  items,
  toggleOpen,
}) => {
  const classes = useStyles();
  const { DeploymentEnvironment } = useContext(AppConfigContext);
  const { info } = useContext(WebVersionContext);

  const currentWebVersion = useMemo(() => window?._env_?.BUILD_NUMBER, []);
  const newWebVersion = useMemo(() => info?.web?.version, [info]);
  const isOlderVersion = useMemo(
    () =>
      currentWebVersion !== '1' &&
      !!newWebVersion &&
      currentWebVersion !== newWebVersion,
    [currentWebVersion, newWebVersion]
  );

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      height="auto"
      overflow="auto"
      justifyContent="space-between"
      fontWeight="fontWeightBold"
      width={1}
    >
      <Box>
        <NavigationList items={items} toggleOpen={toggleOpen} />
      </Box>
      <Box>
        <UserNavigation />
        <Divider />
        <Box p={0.5}>
          {isOlderVersion && (
            <Box color={Palette.Orange.Light}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Icon className={classes.warningIcon}>warning</Icon>
                <Typography align="center" variant="caption">
                  {`${OLDER_VERSION_TEXT} ${newWebVersion},`}
                </Typography>
              </Box>
              <Typography align="center" variant="caption" display="block">
                {CONTACT_SUPPORT}
              </Typography>
            </Box>
          )}
          <Typography
            align="center"
            variant="caption"
            display="block"
          >{`Build Version: ${currentWebVersion} / ${DeploymentEnvironment}`}</Typography>
          <Typography align="center" variant="caption" display="block">
            Copyright {new Date().getFullYear()} Woundtech
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PrimaryNavigation);
