export interface DeviceName {
  name: string;
  type:
    | 'udi-label-name'
    | 'user-friendly-name'
    | 'patient-reported-name'
    | 'manufacturer-name'
    | 'model-name'
    | 'other';
}

export enum DeviceStatus {
  active = 'active',
  inactive = 'inactive',
  error = 'entered-in-error',
  unknown = 'unknown',
}

export enum ActivityDefinitionStatus {
  draft = 'draft',
  active = 'active',
  retired = 'retired',
  unknown = 'unknown',
}

export enum PlanDefinitionStatus {
  draft = 'draft',
  active = 'active',
  retired = 'retired',
  unknown = 'unknown',
}

export enum ScheduleResourceType {
  Patient = 'Patient',
  Practitioner = 'Practitioner',
}

export enum SlotStatus {
  Busy = 'busy',
  Free = 'free',
  BusyUnavailable = 'busy-unavailable',
  BusyTentative = 'busy-tentative',
  EnteredInError = 'entered-in-error',
}

export interface Product {
  id: string;
  deviceName: Array<DeviceName>;
  distinctIdentifier?: string;
  note?: fhir.Annotation[];
  identifier?: fhir.Identifier[];
  status?: DeviceStatus;
}
export interface Filters {
  status?: DeviceStatus | ActivityDefinitionStatus;
}

export interface ActivityDefinition {
  resourceType: string;
  id?: string;
  name: string;
  usage?: string;
  description?: string;
  code?: fhir.CodeableConcept;
  extension?: ActivityDefinitionExtension[];
  status?: ActivityDefinitionStatus;
}

export interface ActivityDefinitionExtension {
  url?: string;
  valueBoolean?: boolean;
  valueString?: string;
}

export type errorMessage = { message: string };
