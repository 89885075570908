import { PlaceholderKind } from '@woundtech/ui-constants';

export const fullName = (
  name: fhir.HumanName[] | undefined,
  showPrefix: boolean = true
) => {
  if (!name || !Array.isArray(name) || name.length < 1 || !name?.[0]) {
    return PlaceholderKind.NotAvailable;
  }

  let displayName = name.find(item => item.use === 'official') || name?.[0];
  if (!displayName) {
    return PlaceholderKind.NotAvailable;
  }

  const parts = [
    ...(showPrefix ? displayName?.prefix || [] : []),
    ...(displayName?.given || []),
    displayName?.family,
  ].filter(part => !!part);

  return parts.length > 0 ? parts.join(' ') : PlaceholderKind.NotAvailable;
};

export const firstName = (name: fhir.HumanName[] | undefined) => {
  const item = name?.find(item => item.use === 'official') || name?.[0];
  return item?.given?.[0] || '';
};

export const lastName = (name: fhir.HumanName[] | undefined) => {
  const item = name?.find(item => item.use === 'official') || name?.[0];
  return item?.family || '';
};
