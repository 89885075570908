import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      display: 'flex',
      justifyContent: 'center',
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      padding: 0,
    },
    alertIcon: {
      svg: {
        color: theme.palette.primary.main,
      },
    },
  })
);

export type Props = {};

const DataListEnd: React.FC<Props> = () => {
  const classes = useStyles();

  return (
    <Alert
      className={classes.alert}
      severity="info"
      variant="outlined"
      iconMapping={{
        info: (
          <Icon color="primary" fontSize="inherit">
            info
          </Icon>
        ),
      }}
    >
      The end of results
    </Alert>
  );
};

export default React.memo(DataListEnd);
