export const PATH_PCC_LIST = '/pcc-config';
export const PATH_PCC_VIEW = '/pcc-config/:userId';
export const PATH_PCC_EDIT = '/pcc-config/:userId/edit';

export function getPccListUrl(): string {
  return PATH_PCC_LIST;
}

export function getPccViewUrl(userId: string): string {
  return PATH_PCC_VIEW.replace(':userId', userId);
}

export function getPccEditUrl(userId: string): string {
  return PATH_PCC_EDIT.replace(':userId', userId);
}

export const ENTRY_PERMISSION = 'settings';
export const ENTRY_LINK = {
  path: PATH_PCC_LIST,
  text: 'PCCs',
  module: 'pcc-management',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export function getAssociatedIds(
  careTeam: any,
  roleCode: string[]
): string[] | undefined {
  return careTeam?.participant
    ?.filter(participant => {
      return participant?.role?.some((role: fhir.CodeableConcept) =>
        roleCode.includes(role?.coding?.[0]?.code || 'undefined')
      );
    })
    .map(participant => participant?.member?.reference?.split('/')[1])
    .filter(id => !!id);
}
