import React from 'react';
import { Box, Button, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Label from 'styleguide/form/Label';
import { Palette } from '@woundtech/ui-colors';
import Typography from '@material-ui/core/Typography';
import InlineError from 'styleguide/core/InlineError';
import { VALIDATION_ERROR_REQUIRED } from 'utility/constants';

export type ActionProps = {
  icon?: string;
  onClick: () => void;
  text: string;
  disabled?: boolean;
  hasError?: boolean;
  variant?: 'outlined' | 'contained' | 'text';
};
export type Props = {
  action?: ActionProps | ActionProps[];
  bgcolor?: string;
  children?: React.ReactNode;
  headerContent?: React.ReactElement;
  contentPlacement?: 'end' | 'center';
  label?: string;
  description?: string;
  icon?: string;
  required?: boolean;
  empty?: boolean;
  hasError?: boolean;
  labelSize?: 'large' | 'medium' | 'small';
  labelFlexGrow?: number;
  px?: Number;
  py?: Number;
};

const useStyles = makeStyles({
  error: {
    border: `1px solid ${Palette.Red.Main}`,
    borderRadius: 8,
  },
  break: {
    wordBreak: 'break-word',
  },
});

const FieldSet: React.FC<Props> = ({
  action,
  bgcolor = Palette.Gray.Background,
  children,
  headerContent,
  label,
  description,
  icon,
  required,
  hasError = false,
  labelSize,
  labelFlexGrow = 1,
  contentPlacement = 'center',
  px = 1.5,
  py = 1.5,
}) => {
  const classes = useStyles();

  const actions = action ? (Array.isArray(action) ? action : [action]) : null;
  const isEmpty = React.Children.toArray(children).length < 1;
  let contentMarginTop = 0;
  if (!!action && !isEmpty) {
    contentMarginTop = 3;
  } else if (label && !isEmpty) {
    contentMarginTop = 2;
  }

  return (
    <Box
      px={px}
      py={py}
      data-group={label}
      bgcolor={bgcolor}
      borderRadius={4}
      className={hasError ? classes.error : ''}
      width={1}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box flexGrow={labelFlexGrow} display="flex" alignItems="center">
          {icon && (
            <Box mr={2}>
              <Icon>{icon}</Icon>
            </Box>
          )}
          {label && (
            <Label size={labelSize} required={required} data-group-title>
              {label}
            </Label>
          )}
        </Box>
        {headerContent && (
          <Box
            flexGrow={1}
            display="flex"
            justifyContent={
              actions && !(contentPlacement === 'end') ? 'center' : 'flex-end'
            }
          >
            {headerContent}
          </Box>
        )}
        {actions && (
          <Box
            flexGrow={contentPlacement === 'end' ? 'unset' : 1}
            textAlign="right"
          >
            <Box display="flex" justifyContent="flex-end">
              {actions?.map((item, index) => (
                <Box key={item?.text} ml={index > 0 ? 1 : 0}>
                  <Button
                    disableElevation
                    color="primary"
                    variant={item?.variant || 'outlined'}
                    startIcon={item?.icon ? <Icon>{item?.icon}</Icon> : null}
                    onClick={item?.onClick}
                    disabled={item?.disabled}
                  >
                    {item?.text}
                  </Button>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {hasError && (
          <Box width={actions && actions.length > 0 ? 1 : undefined}>
            <InlineError text={VALIDATION_ERROR_REQUIRED} />
          </Box>
        )}
      </Box>
      {description && (
        <Box mb={1} display="flex">
          <Typography color="textSecondary">{description}</Typography>
        </Box>
      )}
      <Box mt={contentMarginTop} data-group-content className={classes.break}>
        {children}
      </Box>
    </Box>
  );
};
export default React.memo(FieldSet);
