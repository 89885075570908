import React from 'react';
import Typography from '@material-ui/core/Typography';

export type Props = {
  text: string;
  noWrap?: boolean;
};

const SectionTitle: React.FC<Props> = ({ text, noWrap = false }) => {
  return (
    <Typography
      color="textPrimary"
      noWrap={noWrap}
      variant="h5"
      data-section-title
    >
      {text}
    </Typography>
  );
};

export default React.memo(SectionTitle);
