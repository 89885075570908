import React from 'react';
import Icon from '@material-ui/core/Icon';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgressButton from 'styleguide/core/Button/CircularProgressButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      height: '100%',
    },
    iconWithoutText: {
      marginLeft: '40%',
    },
  })
);
export type Props = {
  icon: string;
  variant?: string;
  text?: string;
  color?: any;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

const SectionAction: React.FC<Props> = ({
  icon,
  variant,
  onClick,
  text = '',
  loading,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <CircularProgressButton
      color="primary"
      label={text}
      variant={variant ? 'contained' : 'outlined'}
      icon={
        icon ? (
          <Icon className={text?.length > 1 ? '' : classes.iconWithoutText}>
            {icon}
          </Icon>
        ) : null
      }
      loading={loading}
      disabled={disabled || loading}
      onClick={onClick}
    />
  );
};

export default React.memo(SectionAction);
