import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  VALIDATION_ERROR_REQUIRED,
  AUTOCOMPLETE_ATTRIBUTE,
} from 'utility/constants';

import { Field } from '../types';

import CheckBoxTouchButton from './CheckBoxTouchButton';
import { OPTION_STATUS } from '../constants';

function defaultGetOptionId(option: any) {
  return option?.coding?.[0]?.code || '';
}

function defaultGetOptionLabel(option: any) {
  return option?.coding?.[0]?.display || option?.coding?.[0]?.code || '';
}

export interface Props extends Field {
  getOptionLabel?: (option: any) => string;
  getOptionId?: (option: any) => string;
  getOptionWidth?: (
    option: any,
    id: string,
    label: string,
    isMobile?: boolean
  ) => string;
  getOptionDisabled?: (option: any) => boolean;
  getOptionGroup?: (option: any) => string;
  onChange: (value: any[] | undefined, name: string) => void;
  options: any[];
  value: any[] | undefined;
  variant?: 'drop-down' | 'checkboxes';
  upperCaseLabel?: boolean;
}

const MultipleChoiceField: React.FC<Props> = ({
  required = false,
  value,
  label,
  placeholder,
  name,
  onChange,
  onValidate = () => {},
  options,
  disabled = false,
  requiredText = VALIDATION_ERROR_REQUIRED,
  getOptionLabel = defaultGetOptionLabel,
  getOptionId = defaultGetOptionId,
  getOptionWidth,
  getOptionDisabled,
  getOptionGroup,
  variant = 'drop-down',
  upperCaseLabel = false,
}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const [showErrors, setShowErrors] = useState(false);

  const handleChange = (event: object, value: any) => {
    onChange(value, name);
  };

  const selectedValues = options?.filter(option => {
    const id = getOptionId(option);
    const selectedOption = value?.find(
      valueItem => getOptionId(valueItem) === id
    );
    return !!selectedOption;
  });

  const isValid = required ? Array.isArray(value) && value.length > 0 : true;
  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <FormControl
      fullWidth
      size="small"
      variant="filled"
      required={required}
      error={showErrors && !isValid}
      onBlur={() => setShowErrors(true)}
      onFocus={() => setShowErrors(false)}
    >
      {variant === 'drop-down' && (
        <Autocomplete
          autoComplete={false}
          filterSelectedOptions
          multiple
          options={options}
          getOptionLabel={getOptionLabel}
          onChange={handleChange}
          disabled={disabled}
          value={selectedValues}
          size="small"
          renderInput={params => {
            return (
              <TextField
                data-field-name={name}
                {...params}
                autoComplete={AUTOCOMPLETE_ATTRIBUTE}
                label={label}
                placeholder={placeholder}
                size="small"
                required={required}
                fullWidth
                variant="filled"
                onFocus={() => setShowErrors(false)}
                onBlur={() => setShowErrors(true)}
              />
            );
          }}
        />
      )}
      {variant === 'checkboxes' && (
        <Box display="flex" flexWrap="wrap" margin={-1}>
          {options?.map((option, optionIndex) => {
            const optionId = getOptionId(option);
            const optionLabel = getOptionLabel(option);
            const isSelected =
              selectedValues?.some(
                currentItem => getOptionId(currentItem) === optionId
              ) || false;

            const isOptionDisabled =
              getOptionDisabled && getOptionDisabled(option);

            const optionWidth = getOptionWidth
              ? getOptionWidth(option, optionId, optionLabel, isMobile)
              : 1;

            let groupTitle = '';
            if (getOptionGroup) {
              const currentGroup = getOptionGroup(option);
              const previousGroup = getOptionGroup(options[optionIndex - 1]);

              if (currentGroup && currentGroup !== previousGroup) {
                groupTitle = currentGroup;
              }
            }

            return (
              <Box p={1} width={optionWidth} key={optionId}>
                {groupTitle && (
                  <Box mb={1}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {groupTitle}
                    </Typography>
                  </Box>
                )}
                <CheckBoxTouchButton
                  label={
                    upperCaseLabel ? optionLabel?.toUpperCase() : optionLabel
                  }
                  onClick={isChecked => {
                    const newValue = isChecked
                      ? [...selectedValues, option]
                      : selectedValues.filter(currentItem => {
                          return optionId !== getOptionId(currentItem);
                        });
                    onChange(newValue, name);
                  }}
                  variant={
                    isSelected
                      ? OPTION_STATUS.selected
                      : OPTION_STATUS.unSelected
                  }
                  disabled={isOptionDisabled || disabled}
                />
              </Box>
            );
          })}
        </Box>
      )}
      {showErrors && !isValid && (
        <FormHelperText>{requiredText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default React.memo(MultipleChoiceField);
