import { gql } from '@apollo/client';
import { IdentifierFragment } from 'fhir/general/Identifier';
import { ContactPointFragment } from 'styleguide/form/Telecom';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';
import { HumanNameFragment } from 'fhir/general/HumanName';
const AddressFragment = gql`
  fragment Address on Address {
    extension {
      url
      valueReference {
        display
        reference
      }
      valueString
    }
    city
    country
    district
    line
    postalCode
    state
    text
    type
    use
  }
`;
const ExtensionFragment = gql`
  fragment Extension on Extension {
    url
    valuePositiveInt
    valueBoolean
    valueReference {
      reference
    }
    valueString
    extension {
      url
      valuePositiveInt
      valueString
      valueDateTime
      valueReference {
        type
        reference
        display
      }
      __typename
    }
    __typename
  }
`;

export const OrganizationFragment = gql`
  fragment Organization on Organization {
    id
    resourceType
    name
    active
    identifier {
      ...Identifier
    }
    telecom {
      ...ContactPoint
    }
    address {
      ...Address
    }
    contact {
      name {
        ...HumanName
      }
      purpose {
        ...CodeableConcept
      }
      telecom {
        ...ContactPoint
      }
      address {
        ...Address
      }
      extension {
        ...Extension
      }
    }
    type {
      ...CodeableConcept
    }
    endpoint {
      reference
      display
      type
    }
  }
  ${AddressFragment}
  ${CodeableConceptFragment}
  ${HumanNameFragment}
  ${IdentifierFragment}
  ${ContactPointFragment}
  ${ExtensionFragment}
`;

export const OrganizationListQuery = gql`
  query OrganizationList(
    $first: Int
    $after: String
    $type: String
    $partOfId: String
    $state: String
    $term: String
    $last: Int
    $before: String
    $payorId: String
    $name: String
    $active: String
  ) {
    OrganizationList(
      first: $first
      after: $after
      type: $type
      partOfId: $partOfId
      state: $state
      term: $term
      last: $last
      before: $before
      payorId: $payorId
      name: $name
      active: $active
    ) {
      edges {
        cursor
        node {
          id
          resourceType
          name
          active
          identifier {
            ...Identifier
          }
          telecom {
            ...ContactPoint
          }
          address {
            ...Address
          }
          contact {
            name {
              ...HumanName
            }
          }
          type {
            ...CodeableConcept
          }
          partOf {
            reference
            resource {
              ...Organization
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${AddressFragment}
  ${CodeableConceptFragment}
  ${HumanNameFragment}
  ${IdentifierFragment}
  ${ContactPointFragment}
  ${OrganizationFragment}
`;

export const OrganizationQuery = gql`
  query Organization($id: String!) {
    Organization(id: $id) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;

export const CreateOrganizationMutation = gql`
  mutation createOrganization($organization: OrganizationInput!) {
    createOrganization(organization: $organization) {
      id
    }
  }
`;

export const UpdateOrganizationMutation = gql`
  mutation updateOrganization($id: String!, $organization: OrganizationInput!) {
    updateOrganization(id: $id, organization: $organization) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;
