import React from 'react';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import { Palette } from '@woundtech/ui-colors';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MuiDrawer from '@material-ui/core/Drawer';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APPLICATION_NAME } from '@woundtech/ui-constants';
import { NAVIGATION_DRAWER_WIDTH } from 'utility/constants';

export type Props = {
  anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  open?: boolean;
  toggleOpen?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: NAVIGATION_DRAWER_WIDTH,
      whiteSpace: 'nowrap',
    },
    root: {
      background: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  })
);

const TemporaryDrawer: React.FC<Props> = ({
  anchor = 'left',
  children,
  open,
  toggleOpen,
}) => {
  const classes = useStyles();
  return (
    <MuiDrawer
      className={classes.drawer}
      anchor={anchor}
      open={open}
      onClose={toggleOpen}
    >
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        height={1}
        width={1}
        className={classes.root}
      >
        <Toolbar>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            mr={1}
            color={Palette.Base.White}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {APPLICATION_NAME}
            </Typography>
          </Box>
        </Toolbar>
        <Divider />
        <Box display="flex" flexGrow={1} height={1}>
          {children}
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default TemporaryDrawer;
