import { OrganizationReference } from 'organization';
export function getDischargeRequestCreateUrl(
  patientId,
  admissionId,
  baseURL = '/patients'
) {
  return `${baseURL}/${patientId}/admissions/${admissionId}/discharge-request`;
}

export function getDischargeRequestViewUrl(
  patientId,
  admissionId,
  dischargeRequestId,
  baseURL = '/patients'
) {
  return `${baseURL}/${patientId}/admissions/${admissionId}/discharge-request/${dischargeRequestId}`;
}

export function getDischargeRequestUpdateUrl(
  patientId,
  admissionId,
  dischargeRequestId,
  baseURL = '/patients'
) {
  return `${baseURL}/${patientId}/admissions/${admissionId}/discharge-request/${dischargeRequestId}/edit`;
}

export interface DischargeRequest {
  episodeOfCareId?: fhir.id;
  created: fhir.dateTime;
  note?: fhir.Narrative;
  reasons?: fhir.CodeableConcept[];
  requestedBy?: fhir.Reference;
  dischargedBy?: fhir.Reference;
  dischargedOn?: fhir.dateTime;
  status: DischargeRequestStatus;
  conditions?: fhir.Condition[];
  dischargeDisposition?: fhir.CodeableConcept;
  dispositionPlaceOfService?: fhir.CodeableConcept;
  dispositionPhysician?: fhir.Reference;
  dispositionOtherProvider?: {
    name: fhir.HumanName;
    speciality: fhir.CodeableConcept;
  };
  dispositionOtherFacility?: fhir.Organization;
  dispositionHomeHealth?: OrganizationReference;
}

export enum DischargeRequestStatus {
  requested = 'requested',
  confirmed = 'confirmed',
  cancelled = 'cancelled',
  pending = 'Pending',
}

export enum DischargeRequestDisposition {
  pos = 'pos',
  pcp = 'pcp',
  otherProvider = 'other-provider',
  otherFacility = 'other-hcf',
  homeHealth = 'home-health',
  hospital = 'hospital',
  snf = 'snf',
}

export const DISCHARGE_DISPOSITION_VALUESET =
  'http://woundtech.net/fhir/ValueSet/discharge-disposition';

export const DICHARGE_HOME_HEALTH_CODE = 'hha';
