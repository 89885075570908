import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import FieldSet from 'styleguide/layout/FieldSet';
import { Switch } from '@material-ui/core';

const cancelButtonTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: 'nowrap',
        minWidth: 'auto',
      },
      outlined: {
        background: '#FFF',
      },
    },
  },
});

export type Props = {
  applyText?: string;
  children: React.ReactNode;
  onApply: () => void;
  onReset: () => void;
  resetText?: string;
  label?: string;
  disableSearchButton?: boolean;
  showToggle?: boolean;
  toggleActive?: boolean;
  onToggleChange?: (val) => void;
};

const LookupFilter: React.FC<Props> = ({
  applyText = 'Search',
  children,
  onApply,
  onReset,
  resetText = 'Clear',
  label,
  disableSearchButton = false,
  showToggle = false,
  toggleActive = false,
  onToggleChange = val => {},
}) => {
  const [isActive, setIsActive] = useState(toggleActive);

  const onKeyPress = useCallback(
    event => {
      if (event.charCode === 13 || event.keyCode === 13) {
        onApply();
      }
    },
    [onApply]
  );

  const handleChange = () => {
    setIsActive(!isActive);
    onToggleChange(!isActive);
  };

  const onResetClick = () => {
    if (showToggle) {
      setIsActive(toggleActive);
    }
    onReset();
  };

  return (
    <FieldSet label={label}>
      <Box data-lookup-filter onKeyPress={onKeyPress}>
        {children}
        <Box
          display="flex"
          alignItems="center"
          justifyContent={showToggle ? 'space-between' : 'flex-end'}
          mt={2}
        >
          <Box ml={0} display={showToggle ? '' : 'none'}>
            All
            <Switch
              checked={isActive}
              onChange={handleChange}
              name="Active"
              color="primary"
            />
            Active
          </Box>
          <Box display="flex">
            <Box>
              <ThemeProvider theme={cancelButtonTheme}>
                <Button
                  onClick={onResetClick}
                  disableElevation
                  color="primary"
                  variant="outlined"
                >
                  {resetText}
                </Button>
              </ThemeProvider>
            </Box>
            <Box ml={2}>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                onClick={onApply}
                disabled={showToggle ? false : disableSearchButton}
              >
                {applyText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </FieldSet>
  );
};

export default React.memo(LookupFilter);
