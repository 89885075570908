import { UserJobFunction } from 'user';
import {
  getAdmissionStatusColor,
  getAdmissionStatusLabel,
  isAdmissionOnHold,
} from 'admission/helpers';
import { getPatientSubStatus } from './Builder';
import {
  EncounterLink,
  PropColorLink,
} from 'encounter/EncounterLandingPage/EncounterLinksList';
import {
  AdmissionStatus,
  ADMISSION_ONHOLD_REASON_URL,
  AdmissionOnHoldReason,
} from 'admission/constants';
import {
  ON_HOLD_PLANNED_REMOVED,
  ON_HOLD_ACTIVE_REMOVED,
  ON_HOLD_NOT_REMOVED,
  CARE_FACILITY_SERVICE_EXTENSION,
} from 'patient/constants';
import {
  SERVICE_ADDRESS_URL,
  VERIFY_ADDRESS_URL,
} from 'fhir/general/constants';
import { findIdFromReference } from 'utility/utils';
import { validStartTimeForCurrentDay } from 'clinician-config/PreferenceBuilder';

export const getLoggedInUserRoleObject = user => {
  const role = user?.jobFunctions?.[0]?.coding?.[0]?.code;
  const userDetails = [
    {
      reference: `Practitioner/${user?.id}`,
      resource: {
        id: user?.id,
        participant: user,
      },
    },
  ];
  switch (role) {
    case UserJobFunction.pccSupport: {
      return {
        poff: userDetails,
      };
    }
    case UserJobFunction.pcc: {
      return {
        pcc: userDetails,
      };
    }
    case UserJobFunction.clinician: {
      return {
        clinician: [
          {
            reference: `Practitioner/${userDetails?.[0]?.resource?.participant?.id}`,
            resource: {
              participant: {
                participant: [
                  {
                    member: {
                      resource: {
                        practitioner: {
                          resource: userDetails?.[0]?.resource?.participant,
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      };
    }
    case UserJobFunction.advanceClinician: {
      return {
        clinician: [
          {
            reference: `Practitioner/${userDetails?.[0]?.resource?.participant?.id}`,
            resource: {
              participant: {
                participant: [
                  {
                    member: {
                      resource: {
                        practitioner: {
                          resource: userDetails?.[0]?.resource?.participant,
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        ],
      };
    }
    default:
      return {};
  }
};

export function getPatietSubStatusLabel(patient): string {
  const admission = patient?.admission || patient?.activeAdmission;
  const status = getPatientSubStatus(patient);
  const mapReasons = true;

  const onholdReasons: string[] = isAdmissionOnHold(admission)
    ? getPatientOnHoldReasonsFromAdmission(admission, mapReasons)
    : [];

  const description = onholdReasons ? onholdReasons?.[0] : '';

  return getAdmissionStatusLabel(status, description);
}

export function getPatientOnHoldReasonsFromAdmission(
  admission,
  mapReasons?: boolean
): string[] {
  if (!admission) {
    return [];
  }

  const reasons = admission?.extension
    ?.filter(extension => extension?.url === ADMISSION_ONHOLD_REASON_URL)
    ?.map(extension => {
      const reasonCoding = extension?.valueCodeableConcept?.coding?.[0];
      return !!mapReasons
        ? AdmissionOnHoldReason[reasonCoding?.code]
        : reasonCoding?.display;
    });

  return reasons || [];
}

export function getPatietSubStatusColor(patient): string {
  const status = getPatientSubStatus(patient);
  return getAdmissionStatusColor(status);
}

export function formatSearch(query) {
  return query
    ?.replace(/,/g, ' ')
    .replace(/\s+/g, ' ')
    .trim();
}

export function htmlDecode(str) {
  var txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
}

export function htmlEncode(str) {
  var div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
}

export function getMultiLabelStatus(link: EncounterLink) {
  const labels = link?.chip || '',
    colors = link?.chipColor || '';

  if (labels === '' && colors === '') return [];
  const labelValue = labels?.split(',');
  const colorValue = colors?.split(',');
  let newList: PropColorLink[] = [];
  if (labelValue?.length > 1) {
    labelValue.forEach((subItem, i) => {
      newList.push({ label: subItem, color: colorValue?.[i] });
    });
  } else {
    newList.push({
      label: labelValue?.[0],
      color: colorValue?.[0],
    });
  }
  return newList;
}

export const getRemovedStatus = (currentStatusValues, newStatusValues) => {
  if (
    currentStatusValues?.[AdmissionStatus.onholdPlanned] &&
    !newStatusValues?.[AdmissionStatus.onholdPlanned]
  ) {
    return ON_HOLD_PLANNED_REMOVED;
  } else if (
    currentStatusValues?.[AdmissionStatus.onholdActive] &&
    !newStatusValues?.[AdmissionStatus.onholdActive]
  ) {
    return ON_HOLD_ACTIVE_REMOVED;
  } else {
    return ON_HOLD_NOT_REMOVED;
  }
};
export const getCareFacilityExtension = organizationId => ({
  url: CARE_FACILITY_SERVICE_EXTENSION,
  valueReference: {
    reference: `Organization/${organizationId}`,
  },
});

export const withCareFacilityExtension = extension =>
  extension?.filter(item => item?.url === CARE_FACILITY_SERVICE_EXTENSION);
export const withoutCareFacilityExtension = extension =>
  extension?.filter(item => item?.url !== CARE_FACILITY_SERVICE_EXTENSION);

const getParticipentsCareFacility = (careTeamId, contactId) => {
  return contactId === 'none'
    ? [
        {
          member: {
            reference: `CareTeam/${careTeamId}`,
          },
        },
      ]
    : [
        {
          member: {
            reference: `Practitioner/${contactId}`,
          },
        },
        {
          member: {
            reference: `CareTeam/${careTeamId}`,
          },
        },
      ];
};
export const getCareFacilityPayload = (
  healthCareFacilityAddress,
  type = 'create'
) => {
  const findResourceIdFromCareTeamParticipants = (
    participants,
    resourceType
  ) => {
    const { member } =
      participants?.find(participant => {
        const {
          member: { reference: participantReference },
        } = participant || {};
        return participantReference?.includes(resourceType);
      }) || {};

    return findIdFromReference(member);
  };

  if (healthCareFacilityAddress.length === 0) {
    return [];
  }

  return healthCareFacilityAddress?.map(address => {
    const healthCareData = address?.healthCareFacility || address;
    const contactId = healthCareData?.contact?.id;
    const facilityInfo = healthCareData?.facilityInfo;

    const existingParentCareTeamId = findResourceIdFromCareTeamParticipants(
      facilityInfo?.careTeam?.participant,
      'CareTeam'
    );
    const careTeamId =
      !!existingParentCareTeamId && existingParentCareTeamId !== 'undefined'
        ? existingParentCareTeamId
        : facilityInfo?.careTeam?.id;
    const previousChildCareId = address?.childCareId; // update the child care id, patient specific

    return {
      organization: {
        resourceType: 'Organization',
        id: facilityInfo?.organization?.id,
        active: true,
      },
      careTeam:
        type !== 'create' || !previousChildCareId
          ? {
              resourceType: 'CareTeam',
              status: 'active',
              id: previousChildCareId,
              participant: getParticipentsCareFacility(careTeamId, contactId),
              note: { text: address?.instruction || '' },
            }
          : {
              resourceType: 'CareTeam',
              status: 'active',
              participant: getParticipentsCareFacility(careTeamId, contactId),
              note: { text: address?.instruction || '' },
            },
    };
  });
};

export const getFacilityInfo = (facility, healthCareFacility) => {
  if (healthCareFacility.length === 0) {
    return;
  }

  const selectedPractionerContact = facility?.careTeam?.participant
    ?.filter(
      participent =>
        participent?.member?.reference?.indexOf('Practitioner') !== -1
    )
    .map(item => item.member.reference);
  const validhelathCareData = validOrganization(healthCareFacility);
  const contactToGet = validhelathCareData?.filter(
    item => item.careTeam.id === facility?.careTeam?.id
  );
  const contact = contactToGet
    ?.map(item =>
      item?.careTeam?.participant?.filter(item =>
        selectedPractionerContact?.includes(item?.member?.reference)
      )
    )
    ?.flat(1)[0];

  const careData = {
    contact: contact?.member?.resource || { id: 'none' },
    facility: facility?.organization,
    facilityInfo: facility,
  };
  return careData;
};

export const addAddressType = address =>
  address.map(item => {
    const hasCareFacilityExtension = withCareFacilityExtension(item.extension)
      ?.length;
    if (!hasCareFacilityExtension && item.state) {
      return { ...item, addressType: 'home' };
    } else {
      return { ...item, addressType: 'facility' };
    }
  });

export const validOrganization = careFacilityData =>
  careFacilityData?.filter(item => item?.organization);

export const getHealthCareFacilityAddress = (formData, type = 'create') => {
  const healthCareFacilityAddress = formData?.address?.filter(
    address => address?.addressType === 'facility'
  );
  return getCareFacilityPayload(healthCareFacilityAddress, type);
};

export const getUpdatedAddress = address =>
  address?.map(address => {
    if (address?.addressType === 'facility') {
      return { extension: address.extension, postalCode: address?.postalCode };
    }

    const extensionWithoutCareFacility = withoutCareFacilityExtension(
      address?.extension
    );
    delete address?.addressType;
    delete address?.healthCareFacility;
    delete address?.instruction;
    delete address?.childCareId;
    return { ...address, extension: extensionWithoutCareFacility };
  });

export const getInstructionOfPatinet = careTeam =>
  careTeam?.note?.[0].text || '';

export const prepareCareFacilityData = (patientData, facilityData) => {
  const careFacilityDetails = validOrganization(facilityData);
  return patientData?.address?.map(item => {
    if (item?.addressType === 'facility') {
      const preSelectedOrgId = item?.extension
        ?.filter(item => item?.valueReference?.reference)[0]
        ?.valueReference?.reference?.split('/')?.[1];
      const selectedFacility = patientData?.healthCareFacility?.filter(
        item => item?.organization?.id === preSelectedOrgId
      )?.[0];
      const childCareId = selectedFacility?.careTeam?.id;

      const updatedAddress = {
        ...item,
        childCareId: childCareId,
        instruction: getInstructionOfPatinet(selectedFacility?.careTeam),
        healthCareFacility: getFacilityInfo(
          selectedFacility,
          careFacilityDetails
        ),
      };
      return updatedAddress;
    }
    return item;
  });
};

export const getServiceFacilityAddress = (
  serviceFacilityReference,
  facilities
) => {
  const serviceFacilityId = findIdFromReference(serviceFacilityReference);
  return facilities?.find(
    facility => facility?.organization?.id === serviceFacilityId
  )?.organization?.address;
};

export const getPatientServiceAddress = addresses =>
  addresses?.find(address =>
    address?.extension?.find(
      item => item.url === 'SERVICE_ADDRESS' && item?.valueBoolean
    )
  );

export const getPatientStateAndZipCode = (
  addresses: any,
  healthCareFacilities?: any
) => {
  const serviceAddress = addresses?.find(address =>
    address?.extension?.find(
      item => item.url === 'SERVICE_ADDRESS' && item?.valueBoolean
    )
  );
  const facilityReference = serviceAddress?.extension?.find(
    item => item?.url === CARE_FACILITY_SERVICE_EXTENSION
  )?.valueReference;

  if (!!facilityReference) {
    const facilityAddress =
      serviceAddress?.healthCareFacility?.facility?.address ||
      getServiceFacilityAddress(facilityReference, healthCareFacilities);
    return {
      isFacility: true,
      zipCode: facilityAddress?.[0]?.postalCode,
      state: facilityAddress?.[0]?.state,
    };
  } else {
    return {
      isFacility: false,
      zipCode: serviceAddress?.postalCode,
      state: serviceAddress?.state,
    };
  }
};

export const getServiceAddressInfo = (facility, address) => {
  const extensionDetails =
    address
      ?.filter(item => {
        const ids = item?.extension?.map(
          item => item?.valueReference?.reference?.split('/')?.[1]
        );
        return ids?.includes(facility?.organization?.id) && item?.extension;
      })?.[0]
      ?.extension?.map(item => item?.url) || '';

  const isService = extensionDetails?.includes(SERVICE_ADDRESS_URL);
  const isVerified = extensionDetails?.includes(VERIFY_ADDRESS_URL);

  if (isService) {
    if (isVerified) {
      return `(Service Address - Verified)`;
    }
    return `(Service Address)`;
  } else if (isVerified) {
    return `(Verified)`;
  } else return '';
};

export const isPatientAvailabilityValid = formData =>
  formData?.isAlwaysAvailable
    ? true
    : formData?.availableSlots?.find(slot => slot?.start && slot?.end);

export const isExceptionAvailabilityValid = (slot, appointmentDay) =>
  slot?.startTime &&
  slot?.endTime &&
  validStartTimeForCurrentDay(slot?.startTime, appointmentDay);
