import { hasUserRoles, UserRole } from 'user';

export const ENTRY_PERMISSION = 'patient';
export const PATH_CLINICIAN_ENCOUNTERS_LIST = '/clinician-encounters';
export const PATH_CLINICIAN_ENCOUNTER_VIEW = `/clinician-encounters/:appointmentId`;

export function getClinicianViewUrl(appointmentId: string): string {
  return PATH_CLINICIAN_ENCOUNTER_VIEW.replace(':appointmentId', appointmentId);
}

export const displayLink = (user, env, filterToggles) => {
  return (
    !hasUserRoles(user, [UserRole.external, UserRole.reviewerReadOnly]) &&
    filterToggles?.EnableEncounterFilter === 'true'
  );
};

export const ENTRY_LINK = {
  path: '/clinician-encounters',
  text: 'Encounters',
  module: 'clinician-encounters',
  component: 'Dashboard',
  icon: 'contacts-icon',
  displayLink: displayLink,
  permission: ENTRY_PERMISSION,
};
