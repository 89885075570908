import React, {
  useContext,
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import BackwardNavigation from 'styleguide/navigation/Backward';
import { APPLICATION_NAME } from '@woundtech/ui-constants';
import { PrivateLayoutContext } from './Context';
import { Context as authorizationContext } from 'authorization/Context';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';
import EnvironmentChip from 'styleguide/EnvironmentIcon';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ModalDrawer from 'styleguide/ModalDrawer';
import {
  getNotificationByTitle,
  getNotificationCount,
  getNotificationList,
  onNotificationCreatedSubscription,
  updateNotificationList,
} from 'notification-config/Schema';
import PrimaryList from 'common/PrimaryList';
import AccordionCard from 'common/PrimaryList/AccordionCard';
import FilterDrawer from 'styleguide/ModalDrawer';
import Filters from './Filters';
import { useMutation, useQuery } from '@apollo/client';
import { Badge } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';
import { ACCORDION_STATUS_LINK_TARGETS } from './constants';
import Button from 'styleguide/core/Button';
import { setSessionStorageRecord, getSessionStorageRecord } from 'application';
import { PASSWORD_EXPIRY_ALERT_KEY } from './constants';
import { isCaseManager } from 'user';
import { isEmpty, uniq } from 'lodash';

export type PrimaryBarProps = {
  onToggleMenu: any;
};

export enum EventStatus {
  _active = 'active',
  _inactive = 'inactive',
  _retired = 'retired',
}

export interface Event {
  _id: string;
  title: string;
  status: EventStatus;
  description: string;
  createdAt: string;
  updatedAt: string;
}
export interface ResponseEvent {
  _id: string;
  title: string;
  status: EventStatus;
  description: string;
  createdAt: string;
  updatedAt: string;
  code: string;
  display: string;
}

export type FilterParams = {
  filters: {
    search: string;
    viewed: boolean;
    startDate?: string;
    endDate?: string;
    events: ResponseEvent[];
    notify?: any[];
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    badge: {
      backgroundColor: Palette.Red.Light,
      color: Palette.Base.White,
    },
    alignCloseIcon: {
      alignItems: 'flex-start',
    },
  })
);

const PrimaryBar: React.FC<PrimaryBarProps> = ({ onToggleMenu }) => {
  const isTabletDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const classes = useStyles();
  const { user } = useContext(authorizationContext);
  const featureToggles = useContext(FeatureToggleContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [alertsCount, setAlertsCount] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<FilterParams>({
    filters: {
      search: '',
      viewed: false,
      startDate: undefined,
      endDate: undefined,
      events: [],
      notify: [],
    },
  });

  const [searchQuery, setSearchQuery] = useState<string>('');

  const {
    info,
    breadcrumbs,
    currentBreadcrumbId,
    notification,
    passwordExpiryNotification,
    dispatch,
    showSuggestion,
    onClickSuggestion,
  } = useContext(PrivateLayoutContext);

  const isNotificationsActive = useMemo(
    () => Boolean(featureToggles?.EnableInAppNotificationConfig === 'true'),
    [featureToggles]
  );

  const jobfunctions = useMemo(() => {
    return user?.jobFunctions?.map(job =>
      !job?.text?.includes('PCC') ? job?.text?.toLowerCase() : job?.text
    );
  }, [user]);

  const qualifiedJobFunctions = useMemo(() => {
    return jobfunctions?.filter(
      job => job === 'clinician' || job === 'case manager' || job === 'PCC'
    );
  }, [jobfunctions]);

  const { data: jobfunctionsData } = useQuery(getNotificationByTitle, {
    fetchPolicy: 'no-cache',
    skip:
      !isNotificationsActive ||
      !qualifiedJobFunctions ||
      isEmpty(qualifiedJobFunctions),
    variables: {
      title: qualifiedJobFunctions,
    },
  });

  const events = useMemo(() => {
    return jobfunctionsData?.GetJobFunctionByTitle?.map(job => [
      ...job?.events,
    ]).flat();
  }, [jobfunctionsData]);

  const { subscribeToMore, refetch } = useQuery(getNotificationCount, {
    fetchPolicy: 'no-cache',
    skip: !isNotificationsActive || !user?.id,
    notifyOnNetworkStatusChange: true,
    variables: {
      practitionerId: user?.id,
      notify: jobfunctions,
    },
    onCompleted: data => {
      const totalCount = data?.GetNotificationsCount;
      setAlertsCount(totalCount);
    },
  });

  const [updateNotifications] = useMutation(updateNotificationList, {
    refetchQueries: [
      {
        query: getNotificationCount,
        variables: {
          notify: jobfunctions,
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const filteredEvents = events?.map(event => event?._id);
    const uniqEvents = uniq(filteredEvents);
    if (!user || !uniqEvents) return;
    const unsubscribe = subscribeToMore({
      document: onNotificationCreatedSubscription,
      variables: { id: user?.id, notify: uniqEvents },
      updateQuery: (prev, { subscriptionData }) => {
        refetch();
        return prev;
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore, refetch, user, events]);

  const visibleBreadcrumbs = useMemo(() => {
    if (isTabletDown || !localStorage.getItem('x-feature-breadcrumb-label')) {
      return breadcrumbs.filter(
        breadcrumb => breadcrumb.id === currentBreadcrumbId
      );
    }

    const currentBreadcrumb = breadcrumbs.find(
      breadcrumb => breadcrumb.id === currentBreadcrumbId
    );

    if (!currentBreadcrumb) {
      return [];
    }

    const currentBreadcrumbIndex = breadcrumbs.findIndex(
      breadcrumb => breadcrumb.id === currentBreadcrumbId
    );

    const previousBreadcrumb = breadcrumbs[currentBreadcrumbIndex - 1];
    if (!previousBreadcrumb) {
      return [];
    }

    return [
      {
        ...currentBreadcrumb,
        name: previousBreadcrumb.name,
      },
    ];
  }, [breadcrumbs, currentBreadcrumbId, isTabletDown]);

  useEffect(() => {
    const unsubscribe = history.listen(function(event, action) {
      switch (action) {
        case 'PUSH': {
          dispatch({
            type: 'addBreadcrumb',
            value: {
              id: event.key,
              name: document.title,
              url: history.location.pathname,
            },
          });
          break;
        }
        case 'POP': {
          dispatch({
            type: 'setCurrentBreadcrumbId',
            id: event.key,
          });
          break;
        }
        case 'REPLACE': {
          dispatch({
            type: 'replaceBreadcrumb',
            value: {
              id: event.key,
              name: document.title,
              url: history.location.pathname,
            },
          });
          break;
        }
      }
    });

    return () => unsubscribe();
  }, [history, dispatch]);

  const onCloseNotification = useCallback(
    (type: string) => {
      dispatch({
        type,
      });
    },
    [dispatch]
  );

  const isPasswordAlertClosed = useMemo(
    () => getSessionStorageRecord(PASSWORD_EXPIRY_ALERT_KEY),
    []
  );

  const handlePasswordExpiryAlertSubmit = useCallback(() => {
    onCloseNotification('setPasswordExpiryNotification');
    setSessionStorageRecord(PASSWORD_EXPIRY_ALERT_KEY, true);
    passwordExpiryNotification?.onSubmit &&
      passwordExpiryNotification.onSubmit();
  }, [passwordExpiryNotification, onCloseNotification]);

  const onShowNotification = useCallback(() => {
    try {
      const likeAudio = new Audio('/notification.wav');

      const promise = likeAudio.play();

      if (promise !== undefined) {
        promise.then(() => {}).catch(() => {});
      }
    } catch {}
  }, []);

  // workaround for Safari
  useEffect(() => {
    function unlockAudio() {
      const sound = new Audio('/notification.wav');

      const promise = sound.play();

      if (promise !== undefined) {
        promise.then(() => {}).catch(() => {});
      }

      document.body.removeEventListener('click', unlockAudio);
      document.body.removeEventListener('touchstart', unlockAudio);
    }

    document.body.addEventListener('click', unlockAudio);
    document.body.addEventListener('touchstart', unlockAudio);
  }, []);

  const handleSubmit = useCallback(
    data => {
      const {
        filters: { startDate, endDate, events },
      } = data;

      setCount(() => {
        const eventFilter = events.length > 0 ? 1 : 0;
        const dateFilter = startDate || endDate ? 1 : 0;
        return eventFilter + dateFilter;
      });

      setSelectedFilters({
        ...selectedFilters,
        ...data,
        viewed: notificationStatus,
        notify: jobfunctions,
      });
    },
    [selectedFilters, notificationStatus, jobfunctions, setCount]
  );

  const variables = useMemo(
    () => ({
      filters: {
        ...selectedFilters.filters,
        search: searchQuery,
        viewed: notificationStatus,
        notify: jobfunctions || [],
        events: [...selectedFilters.filters.events.map(s => s.title)],
        practitionerId: user?.id,
        practitionerName: user?.name?.[0]?.text,
      },
      first: 25,
    }),
    [jobfunctions, notificationStatus, selectedFilters, searchQuery, user]
  );

  const handleSwitchChange = useCallback(() => {
    setNotificationStatus(!notificationStatus);
  }, [notificationStatus, setNotificationStatus]);

  const selectedAccordionPanel = useCallback(
    async data => {
      if (!data.viewed)
        try {
          const response = await updateNotifications({
            variables: {
              id: data._id,
            },
          });
          if (response) {
            setAlertsCount(prev => {
              return prev === 0 ? 0 : prev - 1;
            });
          }
        } catch {}
    },
    [updateNotifications]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchQuery(value);
    },
    [setSearchQuery]
  );

  const handleToggleTune = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleFilterToggle = useCallback(() => {}, []);

  const dataListProps = useMemo(() => {
    return {
      component: AccordionCard,
      onSearch: handleSearch,
      showAccordion: true,
      selectedAccordionPanel: selectedAccordionPanel,
      searchQuery: searchQuery,
      onToggleTune: handleToggleTune,
      onFilterToggle: handleFilterToggle,
      toggleSwitch: {
        label: 'Unread',
        status: notificationStatus,
        onToggleClick: handleSwitchChange,
      },
      canCreate: false,
      showItemCard: false,
      filterCount: count,
    };
  }, [
    notificationStatus,
    handleSearch,
    selectedAccordionPanel,
    handleToggleTune,
    handleFilterToggle,
    handleSwitchChange,
    searchQuery,
    count,
  ]);

  const handleNotificationClick = useCallback(() => {
    setOpen(true);
    setNotificationStatus(true);
  }, [setOpen]);

  const handleAccordionClick = useCallback(
    accordionItem => {
      const title = accordionItem?.event?.title;
      const patientId = accordionItem?.meta?.patientId;
      const orderId =
        accordionItem?.resourceDetails?.resourceType === 'ServiceRequest' &&
        accordionItem?.resourceDetails?.id;
      const slug =
        accordionItem?.resourceDetails?.resourceType === 'ServiceRequest' &&
        accordionItem?.resourceDetails?.instantiatesCanonical?.[0]?.split(
          '/'
        )[1];
      const linkType = ACCORDION_STATUS_LINK_TARGETS?.[title];
      let redirect = '';
      if (linkType === 'admission') {
        redirect = `patients/${patientId}/admissions`;
      } else if (linkType === 'my-schedule') {
        if (isCaseManager(user)) {
          redirect = `patients`;
        } else {
          redirect = 'my-schedule';
        }
      } else if (linkType === 'orders') {
        redirect =
          slug && orderId
            ? `patients/${patientId}/past-orders/${slug}/${orderId}`
            : `patients/${patientId}/orders`;
      } else if (linkType === 'ccd') {
        const admissionId = accordionItem?.resource?.admission;
        const documentId = accordionItem?.resourceDetails?.id;
        redirect = documentId
          ? `patients/${patientId}/admissions/${admissionId}/ccd/documents/CCD/${documentId}`
          : '';
      }

      setOpen(false);
      if (redirect !== '') {
        history.push(`/${redirect}`);
      }
    },
    [history, user]
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box mr={1}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onToggleMenu}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            flexGrow={1}
            flexDirection={'row'}
          >
            <Box mr={1}>
              {visibleBreadcrumbs && visibleBreadcrumbs?.length > 0 ? (
                <BackwardNavigation
                  breadcrumbs={visibleBreadcrumbs}
                  inverse
                  onNavigate={() => history.goBack()}
                />
              ) : (
                <Typography variant="h6" noWrap>
                  {APPLICATION_NAME}
                </Typography>
              )}
            </Box>
            <Box
              ml="auto"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {isNotificationsActive && (
                <IconButton color="inherit" onClick={handleNotificationClick}>
                  <Badge
                    badgeContent={alertsCount}
                    classes={{ badge: classes.badge }}
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              )}
              <ModalDrawer
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                title={'Notifications'}
              >
                <Box pt={1.1}>
                  <PrimaryList
                    type="GetNotificationList"
                    query={getNotificationList}
                    variables={variables}
                    dataListProps={dataListProps}
                    dataListItemProps={{
                      accordionClick: handleAccordionClick,
                      onClose: () => setOpen(false),
                    }}
                  />
                </Box>
                <FilterDrawer
                  title="Filters"
                  open={openDrawer}
                  onClose={() => setOpenDrawer(false)}
                >
                  <Filters
                    onCancel={() => setOpenDrawer(false)}
                    onSubmit={handleSubmit}
                    onClose={() => setOpenDrawer(false)}
                    filters={selectedFilters}
                  />
                </FilterDrawer>
              </ModalDrawer>

              {showSuggestion && (
                <IconButton
                  color="inherit"
                  aria-label="open suggestions"
                  onClick={onClickSuggestion}
                  edge="start"
                >
                  <EmojiObjectsIcon />
                </IconButton>
              )}
              {info ? (
                <Typography variant="body2" noWrap>
                  {info}
                </Typography>
              ) : (
                <Box height={0.5}>
                  <EnvironmentChip />
                </Box>
              )}
            </Box>
            <Snackbar
              open={!!notification}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onEnter={onShowNotification}
            >
              <Alert
                onClose={() => onCloseNotification('setNotification')}
                severity="info"
              >
                <AlertTitle>{notification?.title}</AlertTitle>
                {notification?.content}
              </Alert>
            </Snackbar>
            <Snackbar
              open={!!passwordExpiryNotification && !isPasswordAlertClosed}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              onEnter={onShowNotification}
            >
              <Alert
                onClose={() => {
                  onCloseNotification('setPasswordExpiryNotification');
                  setSessionStorageRecord(PASSWORD_EXPIRY_ALERT_KEY, true);
                }}
                severity={
                  !!passwordExpiryNotification?.alertSeverity
                    ? passwordExpiryNotification?.alertSeverity
                    : 'info'
                }
                classes={{ action: classes.alignCloseIcon }}
              >
                <AlertTitle>{passwordExpiryNotification?.title}</AlertTitle>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {passwordExpiryNotification?.content}
                  {passwordExpiryNotification?.submitLabel && (
                    <Box ml={2}>
                      <Button
                        color={Palette.Blue.Main}
                        label={
                          passwordExpiryNotification?.submitLabel || 'Submit'
                        }
                        onClick={handlePasswordExpiryAlertSubmit}
                        variant="text"
                      />
                    </Box>
                  )}
                </Box>
              </Alert>
            </Snackbar>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default React.memo(PrimaryBar);
