export const INTERVAL_HISTORY = 'interval-history';
export const INTERVAL_HISTORY_NOTES = 'interval-history-additional-notes';
export const INTERVAL_PATIENT_NEW_OR_ONG_SYMPT_LINKID =
  'interval-history-patient-group-new-or-ongoing-symptoms';
export const INTERVAL_PATIENT_NEW_OR_ONG_SYMPT_CODE = '385315009';
export const INTERVAL_ADD_MED_VT_IN_P_TWO_W_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-specialist';
export const INTERVAL_ADD_MED_VT_IN_P_TWO_W_CODE = '394658006';
export const INTERVAL_DRILLDOWN_CODE_LINKIDS = [
  INTERVAL_PATIENT_NEW_OR_ONG_SYMPT_CODE,
  INTERVAL_ADD_MED_VT_IN_P_TWO_W_CODE,
];
export const INTERVAL_HIST_PTNT_GROUP = 'interval-history-patient-group';
export const INTERVAL_HIST_PTNT_GROUP_PTNT_LINK_ID =
  'interval-history-patient-group-patient';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_GROUP =
  'interval-history-patient-caregiver-feels-wound-s-is-are-group';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_LINKID =
  'interval-history-patient-caregiver-feels-wound-s-is-are-group-patient-caregiver-feels-wound-s-is-are';
export const INTERVAL_HIST_PAIN_STATUS_GROUP =
  'interval-history-pain-status-group';
export const INTERVAL_HIST_PAIN_STATUS_LINKID =
  'interval-history-pain-status-group-pain-status';
export const INTERVAL_HIST_PAIN_STATUS_NOTES_LINKID =
  'interval-history-pain-status-group-notes';
export const INTERVAL_HIST_APPTITE_GROUP = 'interval-history-appetite-group';
export const INTERVAL_HIST_APPTITE_GROUP_NOTES_LINID =
  'interval-history-appetite-group-notes';
export const INTERVAL_HIST_APPTITE_GROUP_APPTITE_LINKID =
  'interval-history-appetite-group-appetite';
export const INTERVAL_HIST_NTRTN_STS_S_TW_WKS_GROUP =
  'interval-history-nutrition-status-since-last-few-visits-group';
export const INTERVAL_HIST_NTRTN_STS_S_TW_WKS_GROUP_NOTES_LINID =
  'interval-history-nutrition-status-since-last-few-visits-group-notes';
export const INTERVAL_HIST_NTRTN_STS_S_TW_WKS_LINKID =
  'interval-history-nutrition-status-since-last-few-visits-group-nutrition-status-since-last-few-visits';
export const INTERVAL_ADD_MED_VT_IN_PAST_TWO_WKS_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group';
export const INTERVAL_AD_WOUND_TRTMTS_NT_INC_DUR_WT_GROUP =
  'interval-history-additional-wound-treatments-not-included-during-woundtech-visits-group';
export const INTERVAL_AD_WOUND_TRTMTS_NT_INC_DUR_WT_VISITS =
  'interval-history-additional-wound-treatments-not-included-during-woundtech-visits-group-notes';
export const INTERVAL_AD_WOUND_TRTMTS_NT_INC_DUR_WT_LINKID =
  'interval-history-additional-wound-treatments-not-included-during-woundtech-visits-group-additional-wound-treatments-not-included-during-woundtech-visits';
export const INTERVAL_NON_ADH_TO_TRMT_PLNS_GROUP =
  'interval-history-non-adherence-to-treatment-plans-group';
export const INTERVAL_NON_ADH_TO_TRMT_PLNS_NOTES_LINK_ID =
  'interval-history-non-adherence-to-treatment-plans-group-notes';
export const INTERVAL_NON_ADH_TO_TRMT_PLNS =
  'interval-history-non-adherence-to-treatment-plans-group-non-compliance-to-treatment-plans';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-additional-medical-visits-treatments-in-the-past-2-weeks';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_NOTES =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-notes';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_HSP_DUE_TO_CODE =
  '434081000124108';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_RLVNT_CHNGS_TO_TRTMNT_CODE =
  '445528004';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_URGNT_CARE_ER_DUE_TO_CODE =
  '702927004-3457005';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_HSP_DUE_TO_NOTES_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-hospitalization-reason';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_NOTES_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-notes';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_RLVNT_CHNGS_TO_TRTMNT_NOTES_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-notes-for-relevant-changes-to-treatment-plans';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_URGNT_CARE_ER_DUE_TO_NOTES_LINKID =
  'interval-history-additional-medical-visits-treatments-in-the-past-2-weeks-group-urgent-care-er-reason';
export const INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_NOTES_OPTIOS_CODE = [
  INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_RLVNT_CHNGS_TO_TRTMNT_CODE,
  INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_HSP_DUE_TO_CODE,
  INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_URGNT_CARE_ER_DUE_TO_CODE,
];

export const INTERVAL_HIST_PTNT_GROUP_WORSE_OR_DOING_POORLY_CODE = '271299001';
export const INTERVAL_HIST_PTNT_GROUP_WORSE_OR_DOING_POORLY_NOTES_LINKID =
  'interval-history-patient-group-notes';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_WORSENING_CODE = '230993007';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_WORSENING_NOTES_LINKIS =
  'interval-history-patient-caregiver-feels-wound-s-is-are-group-notes';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_CONCERNING_CODE = '103307008';
export const INTERVAL_HIST_CR_GVR_WND_IS_R_CONCERNING_NOTES_LINKIS =
  'interval-history-patient-caregiver-feels-wound-s-is-are-group-notes-for-concerning';
export const getLinkId = code => {
  switch (code) {
    case INTERVAL_PATIENT_NEW_OR_ONG_SYMPT_CODE: {
      return INTERVAL_PATIENT_NEW_OR_ONG_SYMPT_LINKID;
    }
    case INTERVAL_ADD_MED_VT_IN_P_TWO_W_CODE: {
      return INTERVAL_ADD_MED_VT_IN_P_TWO_W_LINKID;
    }
    case INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_HSP_DUE_TO_CODE: {
      return INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_HSP_DUE_TO_NOTES_LINKID;
    }
    case INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_RLVNT_CHNGS_TO_TRTMNT_CODE: {
      return INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_RLVNT_CHNGS_TO_TRTMNT_NOTES_LINKID;
    }
    case INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_URGNT_CARE_ER_DUE_TO_CODE: {
      return INTERVAL_ADD_MED_VISIT_TREATMENT_IN_IN_PAST_TWO_WKS_URGNT_CARE_ER_DUE_TO_NOTES_LINKID;
    }
    case INTERVAL_HIST_CR_GVR_WND_IS_R_CONCERNING_CODE: {
      return INTERVAL_HIST_CR_GVR_WND_IS_R_CONCERNING_NOTES_LINKIS;
    }
    default: {
      return undefined;
    }
  }
};
