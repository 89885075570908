import React, { useMemo, useEffect, useReducer, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import uuid from 'react-uuid';
import formatISO from 'date-fns/formatISO';
import { Field } from '../types';
import FileInput from './FileInput';
import FileList from './FileList';
import { DEFAULT_DOCUMENT_TYPE } from '../constants';

export type Action =
  | { type: 'setAttachments'; data: any }
  | { type: 'fileUploadingStart'; item: any }
  | { type: 'fileUploadingFailed'; item: any; error: string }
  | { type: 'fileUploadingCompleted'; item: any }
  | { type: 'fileUploadingProgress'; item: any; progress: number }
  | { type: 'setFiles'; files: (File | null)[] }
  | { type: 'setCompleted'; completed: boolean }
  | { type: 'deleteFile'; item: any };

const defaultReducerValue = {
  items: [],
  completed: false,
  valid: true,
};

function reducer(state: any, action: Action) {
  switch (action.type) {
    case 'fileUploadingStart': {
      const items = state.items.map((item: any) => {
        if (item.attachment.id === action.item.attachment.id) {
          return {
            ...item,
            error: null,
            progress: 0,
            upload: false,
          };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case 'fileUploadingProgress': {
      if (
        !state.items.find(
          item => item.attachment.id === action.item.attachment.id
        )
      ) {
        return state;
      }

      const items = state.items.map((item: any) => {
        if (item.attachment.id === action.item.attachment.id) {
          return {
            ...item,
            progress: action.progress,
          };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case 'fileUploadingCompleted': {
      if (
        !state.items.find(
          item => item.attachment.id === action.item.attachment.id
        )
      ) {
        return state;
      }

      const items = state.items.map((item: any) => {
        if (item.attachment.id === action.item.attachment.id) {
          return {
            ...item,
            file: null,
            progress: 100,
            completed: true,
          };
        }

        return item;
      });

      const completed = items.every((item: any) => item.completed);

      return {
        ...state,
        items,
        completed,
        valid: completed,
      };
    }
    case 'fileUploadingFailed': {
      const items = state.items.map((item: any) => {
        if (item.attachment.id === action.item.attachment.id) {
          return {
            ...item,
            error: action.error,
            progress: 100,
          };
        }

        return item;
      });

      return {
        ...state,
        items,
      };
    }
    case 'setAttachments': {
      const newItems = (action?.data || []).map((attachment: any) => ({
        attachment,
        file: null,
        progress: 100,
        error: null,
        completed: true,
        upload: false,
      }));

      return {
        ...state,
        items: newItems,
        completed: false,
        valid: true,
      };
    }
    case 'deleteFile': {
      const items = state.items.filter(item => {
        return item.attachment.id !== action.item.attachment.id;
      });

      const completed = items.every((item: any) => item.completed);
      return {
        ...state,
        items,
        completed,
        valid: completed,
      };
    }
    case 'setFiles': {
      const newItems = action.files
        .map((file: File | null) => {
          if (file) {
            return {
              attachment: {
                contentType: file.type,
                creation: formatISO(new Date(), { representation: 'date' }),
                id: uuid(),
                title: file.name,
              },
              file,
              progress: 0,
              error: null,
              upload: true,
            };
          }

          return null;
        })
        .filter(item => !!item);

      return {
        ...state,
        items: [...state.items, ...newItems],
        completed: false,
        valid: false,
      };
    }
    case 'setCompleted': {
      return {
        ...state,
        completed: action.completed,
      };
    }
    default: {
      return state;
    }
  }
}

export interface Props extends Field {
  contentType?: string;
  type?: fhir.CodeableConcept;
  subjectReference?: string;
  data?: fhir.DocumentReference;
  files?: (File | null)[];
  selectOptions?: any;
  selectOptionLabel?: string;
  onSelectChange?: any;
  onItemAdded?: any;
  onItemRemoved?: any;
  limit?: number;
}

const DocumentField: React.FC<Props> = ({
  contentType = '*',
  type = DEFAULT_DOCUMENT_TYPE,
  subjectReference,
  value,
  disabled,
  onChange,
  onValidate,
  name,
  files,
  selectOptions = [],
  onSelectChange = null,
  selectOptionLabel = '',
  onItemAdded = null,
  onItemRemoved = null,
  limit,
}) => {
  const [state, dispatch] = useReducer(reducer, defaultReducerValue);
  const content = value?.content;

  const defaultDocumentReference = useMemo(
    () => ({
      resourceType: 'DocumentReference',
      type,
      subject: {
        reference: subjectReference,
      },
      content: [],
    }),
    [type, subjectReference]
  );

  useEffect(() => {
    const data = (content || []).map(item => item.attachment);
    dispatch({ type: 'setAttachments', data });
  }, [content, dispatch]);

  const handleSelect = useCallback(
    (files: (File | null)[]) => {
      dispatch({ type: 'setFiles', files });
    },
    [dispatch]
  );

  useEffect(() => {
    if (state.completed) {
      dispatch({
        type: 'setCompleted',
        completed: false,
      });

      const documentReference = {
        ...(value || defaultDocumentReference),
        content: state.items.map(item => ({
          attachment: item.attachment,
        })),
      };

      const hasContent = (documentReference?.content || []).length > 0;

      onChange(hasContent ? documentReference : undefined, name);
    }
  }, [
    value,
    defaultDocumentReference,
    state.completed,
    state.items,
    name,
    onChange,
  ]);

  useEffect(() => {
    onValidate(state.valid);
  }, [state.valid, onValidate]);

  useEffect(() => {
    if (Array.isArray(files) && files.length > 0) {
      handleSelect(files);
    }
  }, [files, handleSelect]);

  useEffect(() => {
    if (!value) {
      return;
    }

    let newValue = value;
    if (type && !value?.type) {
      newValue = {
        ...newValue,
        type,
      };
    }

    if (subjectReference && !value?.subject?.reference) {
      newValue = {
        ...newValue,
        subject: {
          reference: subjectReference,
        },
      };
    }

    if (value !== newValue && newValue?.content?.length > 0) {
      onChange(newValue, name);
    }
  }, [value, type, subjectReference, name, onChange]);

  return (
    <Box>
      <FileInput
        contentType={contentType}
        onSelect={handleSelect}
        disabled={disabled || (!!limit && limit <= state?.items?.length)}
        limit={limit}
      />
      <FileList
        dispatch={dispatch}
        items={state?.items}
        selectOptions={selectOptions}
        selectOptionLabel={selectOptionLabel}
        onSelectChange={onSelectChange}
        onItemAdded={onItemAdded}
        onItemRemoved={onItemRemoved}
      />
    </Box>
  );
};

export default React.memo(DocumentField);
