import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAccountPasswordUrl } from 'account';
import { Context as AuthorizationContext } from 'authorization/Context';
import { PrivateLayoutContext } from 'styleguide/layout/Private/Context';
import {
  CHANGE_PASSWORD,
  PASSWORD_EXPIRY_NOTIFICATION_TEXT,
  SUBMIT_LABEL,
  DAY,
  DAYS,
} from './constants';
import { isExternalUser } from 'user';

export type Props = {};

const PasswordExpiryNotification: React.FC<Props> = () => {
  const { user } = useContext(AuthorizationContext);
  const { dispatch } = useContext(PrivateLayoutContext);
  const history = useHistory();

  useEffect(() => {
    const passwordExpiresConfigValue = user?.passwordExpiresConfigValue;
    const passwordChangedSinceInDays = user?.passwordChangedSinceInDays;
    const passwordValidDays =
      passwordExpiresConfigValue &&
      passwordChangedSinceInDays &&
      passwordExpiresConfigValue - passwordChangedSinceInDays;

    if (
      isExternalUser(user) &&
      passwordValidDays &&
      passwordValidDays > 0 &&
      passwordValidDays <= 10
    ) {
      dispatch({
        type: 'setPasswordExpiryNotification',
        value: {
          title: CHANGE_PASSWORD,
          content: `${PASSWORD_EXPIRY_NOTIFICATION_TEXT} ${passwordValidDays} ${
            passwordValidDays === 1 ? DAY : DAYS
          }.`,
          onSubmit: () => history.push(getAccountPasswordUrl()),
          submitLabel: SUBMIT_LABEL,
          alertSeverity: 'warning',
        },
      });
    } else if (passwordValidDays === 0) {
      history.push(getAccountPasswordUrl());
    }
  }, [dispatch, history, user]);

  return null;
};

export default React.memo(PasswordExpiryNotification);
