import { PlaceholderKind } from '@woundtech/ui-constants';
import { hasUserJobFunctions, UserJobFunction } from 'user';

export const ENTRY_PERMISSION = 'patient';
export const displayLink = (user, env) => {
  return hasUserJobFunctions(user, [
    UserJobFunction.billing,
    UserJobFunction.caseManager,
    UserJobFunction.crossFunctional,
    UserJobFunction.executive,
    UserJobFunction.leadClinician,
    UserJobFunction.advanceClinician,
    UserJobFunction.pcc,
    UserJobFunction.pccSupport,
    UserJobFunction.supervisingPhysician,
    UserJobFunction.processAssociate,
  ]);
};

export const ENTRY_LINK = {
  path: '/schedule',
  text: 'Schedule',
  icon: 'date_range',
  module: 'scheduling',
  component: 'Dashboard',
  displayLink: displayLink,
  permission: ENTRY_PERMISSION,
};

export function getViewSchedulingUrl() {
  return `/schedule`;
}

export function getViewAppointmentUrl(appointmentId: string) {
  return `/schedule/${appointmentId}`;
}

export const getAppointmentTypeAcronym = type => {
  switch (type) {
    case 'soc-patient':
      return 'SOC';
    case 'follow-up':
      return 'FU';
    case 'soc-evaluation':
      return 'EVL';
    case 're-admit':
      return 'RE';
    default:
      return PlaceholderKind.NotAvailable;
  }
};
