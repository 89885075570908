import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AdditionalFormField from './AdditionalFormField';

export type Action = {
  icon: string;
  iconColor: 'error' | 'primary';
  onClick: () => void;
  disabled?: boolean;
};

export type Props = {
  children?: React.ReactNode;
  disableSave?: boolean;
  disableEdit?: boolean;
  onClear?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
  onToggle?: (abatementDateTime) => void;
  availibilityChecked?: boolean;
  onAvailibilityChange?: () => void;
  onSave?: () => void;
  hideActionButton?: boolean;
  onAdd?: () => void;
  disableAdd?: boolean;
  alignActionItems?: string;
  additionalData?: any;
};

const FormCardLayout: React.FC<Props> = ({
  children,
  disableSave = false,
  onClear,
  onEdit,
  onRemove,
  onToggle,
  onAvailibilityChange,
  availibilityChecked,
  onSave,
  disableEdit = false,
  hideActionButton = false,
  onAdd,
  disableAdd = false,
  alignActionItems = 'flex-end',
  additionalData,
}) => {
  const actions: Action[] = useMemo(() => {
    const actions: Action[] = [];
    if (!!onEdit) {
      actions.push({
        icon: 'edit',
        iconColor: 'primary',
        onClick: onEdit,
        disabled: disableEdit,
      });
    }

    if (!!onRemove) {
      actions.push({
        icon: 'delete',
        iconColor: 'error',
        onClick: onRemove,
      });
    }

    if (!!onSave) {
      actions.push({
        icon: 'check',
        iconColor: 'primary',
        onClick: onSave,
        disabled: disableSave,
      });
    }

    if (!!onClear) {
      actions.push({
        icon: 'clear',
        iconColor: 'error',
        onClick: onClear,
      });
    }

    if (!!onAdd) {
      actions.push({
        icon: 'add',
        iconColor: 'primary',
        onClick: onAdd,
        disabled: disableAdd,
      });
    }

    return actions;
  }, [
    disableSave,
    disableEdit,
    disableAdd,
    onAdd,
    onClear,
    onEdit,
    onRemove,
    onSave,
  ]);

  return (
    <>
      {!!onAvailibilityChange && (
        <Box ml={1} my={1}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={availibilityChecked}
                onChange={event => {
                  event.stopPropagation();
                  onAvailibilityChange();
                }}
                color="primary"
                value={true}
              />
            }
            label="Available"
            labelPlacement="end"
          />
        </Box>
      )}
      <Box
        display="flex"
        alignItems={actions?.length === 1 ? alignActionItems : undefined}
        width={1}
      >
        <Box flexGrow={1} overflow="hidden">
          {children}
        </Box>
        {actions.length > 0 && !hideActionButton && (
          <Box
            ml={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {actions?.map(action => (
              <Box key={action.icon}>
                <IconButton
                  size="small"
                  onClick={event => {
                    event.stopPropagation();
                    action.onClick();
                  }}
                  disabled={action?.disabled}
                >
                  <Icon
                    color={action?.disabled ? 'disabled' : action.iconColor}
                  >
                    {action.icon}
                  </Icon>
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
        {additionalData && (
          <AdditionalFormField
            onToggle={onToggle}
            additionalData={additionalData}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(FormCardLayout);
