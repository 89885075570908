export const PATH_ACCOUNT_VIEW = '/account';
export const PATH_ACCOUNT_PASSWORD = '/account/password';
export const PATH_ACCOUNT_PIN = '/account/pin';
export const PATH_PAY_PERIOD_HISTORY = '/account/pay-period-history';
export const PATH_PAY_PERIOD_ENCOUNTER_HISTORY =
  '/account/:payPeriodId/list/:practitionerId';

export function getAccountViewUrl(): string {
  return PATH_ACCOUNT_VIEW;
}

export function getAccountPayPeriodHistoryUrl(): string {
  return PATH_PAY_PERIOD_HISTORY;
}

export function getAccountPasswordUrl(): string {
  return PATH_ACCOUNT_PASSWORD;
}

export function getAccountPinUrl(): string {
  return PATH_ACCOUNT_PIN;
}

export const ENTRY_LINK = {
  component: 'Dashboard',
  icon: 'account_box',
  module: 'account',
  path: '/account',
  text: 'User Profile',
};
