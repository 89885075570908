import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import { Icon, Box } from '@material-ui/core';

export type Props = {
  variation?: 'error' | 'success';
  onCompleted?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.modal,
      opacity: 1,
    },
    content: {
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    success: {
      color: green[500],
      fontSize: 64,
    },
    error: {
      color: red[500],
      fontSize: 64,
    },
  })
);

const FEEDBACK_DURATION = 500;

const ICON_MAP = {
  success: 'check_circle',
  error: 'error',
};

const Feedback: React.FC<Props> = ({ onCompleted, variation = 'success' }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      onCompleted && onCompleted();
    }, FEEDBACK_DURATION);
  }, [onCompleted]);

  return (
    <Box className={classes.root} height={1} position="absolute" width={1}>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width={1}
        height={1}
        p={1}
        className={classes.content}
      >
        <Icon fontSize="large" className={classes[variation]}>
          {ICON_MAP[variation]}
        </Icon>
      </Box>
    </Box>
  );
};

export default React.memo(Feedback);
