import { gql } from '@apollo/client';
import { UserFragment } from 'user/Schema';

export const AuthorizationQuery = gql`
  query Authorization {
    DefaultOrganization {
      id
      name
    }
    GetUserWithCognitoAttributes {
      ...User
      userPin
      userPinModifiedOn
      passwordChangedOn
      passwordExpiresConfigValue
      passwordChangedSinceInDays
      isExtUserAccountExpired
    }
  }
  ${UserFragment}
`;
