import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActionArea } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Palette } from '@woundtech/ui-colors';

const useStyles = makeStyles(() =>
  createStyles({
    textSelection: {
      userSelect: 'initial',
      pointerEvents: 'initial !important' as 'initial',
    },
    active: {
      backgroundColor: grey[100],
    },
    borderActive: {
      border: `1px solid ${Palette.Blue.Main}`,
      boxSizing: 'border-box',
      boxShadow:
        '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    },
  })
);

export type Props = {
  children?: React.ReactNode;
  active?: boolean;
  borderActive?: boolean;
  disabled?: boolean;
  bgColor?: string;
  onClick?: () => void;
  disableTextSelection?: boolean;
};

const Component: React.FC<Props> = ({
  children,
  active,
  borderActive,
  disabled = false,
  bgColor,
  onClick,
  disableTextSelection,
}) => {
  const classes = useStyles();
  return (
    <Card
      variant="outlined"
      className={clsx({
        [classes.active]: active,
        [classes.borderActive]: borderActive,
      })}
      style={{ backgroundColor: bgColor }}
    >
      {disabled ? (
        <CardContent>{children}</CardContent>
      ) : (
        <CardActionArea
          onClick={onClick}
          component="div"
          className={clsx({
            [classes.textSelection]: !disableTextSelection,
          })}
        >
          <CardContent>{children}</CardContent>
        </CardActionArea>
      )}
    </Card>
  );
};

export default React.memo(Component);
