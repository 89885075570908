import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { OPTION_STATUS } from '../constants';
import {
  checkboxStylesDefault,
  containerStylesDefault,
  containerStylesRed,
  checkboxStylesRed,
} from './constants';

export interface Props {
  disabled?: boolean;
  onClick: (isChecked: boolean) => void;
  label?: string | undefined;
  variant?: OPTION_STATUS;
  noWrap?: boolean;
  fromMultiChoiceTable?: boolean;
  tag?: any;
  customStyle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formLabelRoot: {
      marginLeft: 0,
      alignItems: 'flex-start',
    },
  })
);

const CheckBoxTouchButton: React.FC<Props> = ({
  onClick,
  label,
  variant = OPTION_STATUS.selected,
  disabled,
  noWrap,
  fromMultiChoiceTable = false,
  tag,
  customStyle = false,
}) => {
  const classes = useStyles();
  const containerClasses = customStyle
    ? containerStylesRed()
    : containerStylesDefault();
  const checkboxClasses = customStyle
    ? checkboxStylesRed()
    : checkboxStylesDefault();
  let isChecked =
    variant === OPTION_STATUS.selected ||
    variant === OPTION_STATUS.selectedAgain;
  const handleClick = event => {
    event.preventDefault();
    isChecked = !isChecked;
    onClick(isChecked);
  };
  const isLastSelected = variant === OPTION_STATUS.lastSelected;

  return (
    <>
      {fromMultiChoiceTable ? (
        <Box
          onClick={disabled ? undefined : handleClick}
          className={containerClasses.checkBoxContainer}
        >
          <Checkbox
            classes={{
              root: checkboxClasses.checkBoxRoot,
              checked: checkboxClasses.checked,
            }}
            className={clsx(checkboxClasses[variant])}
            checked={isChecked}
            disabled={disabled}
          />
        </Box>
      ) : (
        <>
          <Box
            onClick={disabled ? undefined : handleClick}
            className={clsx(
              containerClasses.root,
              containerClasses[variant],
              containerClasses.thinBorder,
              {
                disabled: disabled,
              }
            )}
          >
            <FormControlLabel
              classes={{ root: classes.formLabelRoot }}
              control={
                <Checkbox
                  classes={{
                    root: checkboxClasses.root,
                    checked: checkboxClasses.checked,
                  }}
                  className={clsx(checkboxClasses[variant])}
                  checked={isChecked}
                  disabled={disabled}
                />
              }
              label={
                <Box
                  py={1.5}
                  color={
                    disabled && variant === OPTION_STATUS.selected
                      ? 'white'
                      : undefined
                  }
                >
                  <Typography
                    noWrap={noWrap}
                    color={'inherit'}
                    className={
                      isLastSelected ? containerClasses.lastSelectedLabel : ''
                    }
                  >
                    {label}
                  </Typography>
                </Box>
              }
            />
          </Box>
          {tag && <Box mt={1}>{tag}</Box>}
        </>
      )}
    </>
  );
};

export default React.memo(CheckBoxTouchButton);
