import { AddressSequence } from 'routing/types';

export type Action =
  | { type: 'init' }
  | { type: 'initCompleted'; isAuthenticated: boolean; isInternal: boolean }
  | { type: 'initFailed'; error: string }
  | { type: 'signIn' }
  | { type: 'signInCompleted'; user: any }
  | {
      type: 'setSequenceDestinationForPrivate';
      sequencedDestinationsPrivate: AddressSequence[];
    }
  | { type: 'signInFailed'; error: string }
  | { type: 'signOut' }
  | { type: 'signOutCompleted'; isExpired?: boolean }
  | { type: 'signOutFailed'; error: string }
  | { type: 'ssoSignInCompleted' }
  | { type: 'ssoSignInFailed'; error: string };

export type State = {
  isAuthenticated: boolean;
  isInternal: boolean;
  isLoading: boolean;
  completed: boolean;
  error?: string | null;
  isExpired?: boolean;
  sequencedDestinationsPrivate?: AddressSequence[];
  dispatch: (action?: any) => void;
};

export const defaultState: State = {
  isLoading: false,
  isAuthenticated: false,
  isInternal: false,
  completed: false,
  sequencedDestinationsPrivate: [],
  dispatch: (action?: Action) => {},
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'init':
      return { ...state, isLoading: true, error: null };
    case 'initCompleted':
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        isInternal: action.isInternal,
        isLoading: false,
        completed: true,
      };
    case 'initFailed':
      return { ...state, isLoading: false, error: action.error };
    case 'signIn':
      return { ...state, isLoading: true, error: null, isExpired: false };
    case 'signInCompleted':
      return {
        ...state,
        isAuthenticated: true,
        isInternal: false,
        isLoading: false,
      };
    case 'setSequenceDestinationForPrivate': {
      return {
        ...state,
        sequencedDestinationsPrivate: action?.sequencedDestinationsPrivate,
      };
    }
    case 'ssoSignInCompleted':
      return {
        ...state,
        isAuthenticated: true,
        isInternal: true,
        isLoading: false,
      };
    case 'signInFailed':
      return { ...state, isLoading: false, error: action.error };
    case 'ssoSignInFailed':
      return { ...state, isLoading: false, error: action.error };
    case 'signOut':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'signOutCompleted':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isInternal: false,
        isExpired: action?.isExpired,
      };
    case 'signOutFailed':
      return { ...state, isLoading: false, error: action.error };
  }
}
