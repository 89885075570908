import { makeStyles } from '@material-ui/core/styles';

export const attributeTextStyles = makeStyles({
  verysmall: {
    fontSize: 13,
  },
  small: {
    fontSize: 14,
  },
  large: {
    fontSize: 18,
  },
});
