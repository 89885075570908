export const ENTRY_PERMISSION = 'settings';
export const PATH_NOTIFICATION_LIST = '/notifications';
export const SUBSCRIBED_EVENTS = 'Subscribed Events for ';
export const ENTRY_LINK = {
  path: '/notifications',
  text: 'Notifications',
  module: 'notification-config',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
