import React from 'react';
import { defaultState } from './reducer';

const Context = React.createContext({
  ...defaultState,
  ssoSignIn: () => {},
  ssoSignInFailed: error => {},
  signIn: () => {},
  signOut: (isExpired?: boolean) => {},
  lockedScreen: false,
  screenLock: () => {},
  screenUnlock: () => {},
  storePin: (pin: string) => {},
  verifyPin: (pin: string): boolean => false,
  setSequenceDestinationForPrivate: (action: any) => {},
});

export default Context;
