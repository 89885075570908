import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, Icon } from '@material-ui/core';
import { AUTOCOMPLETE_ATTRIBUTE } from 'utility/constants';

export type Props = {
  onSearch?: (query: string) => void;
  searchQuery?: string;
  disabled?: boolean;
};

const DataListSearch: React.FC<Props> = ({
  onSearch,
  searchQuery,
  disabled,
}) => {
  const [query, setQuery] = useState(searchQuery || '');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value);

  const handleSearchSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13 || event.keyCode === 13) {
      onSearch && onSearch((query || '').trim());
    }
  };

  useEffect(() => {
    setQuery(searchQuery || '');
  }, [setQuery, searchQuery]);

  return (
    <TextField
      autoComplete={AUTOCOMPLETE_ATTRIBUTE}
      fullWidth
      variant="outlined"
      size="small"
      onChange={handleSearchChange}
      onKeyPress={handleSearchSubmit}
      value={query || ''}
      placeholder="Search..."
      disabled={disabled}
      inputProps={{ maxLength: 200 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label=""
              disabled={disabled}
              onClick={() => onSearch && onSearch((query || '').trim())}
            >
              <Icon color={disabled ? 'disabled' : 'action'}>search</Icon>
            </IconButton>
          </InputAdornment>
        ),
      }}
      onBlur={() => {
        const newValue = query.trim();
        if (newValue !== query) {
          setQuery(newValue);
        }
      }}
    />
  );
};

export default React.memo(DataListSearch);
