export const BULK_OPERATIONS = 'bulk-operations';
export const MISSED_APPOINTMENTS = '/missed-appointments';
export const MADE_ENCOUNTERS = '/made-encounters';

export const VALUE_SET_LETTER_STATUS_URL =
  'http://woundtech.net/fhir/ValueSet/letter-status';
export const VALUE_SET_MISSING_INFORMATION_URL =
  'http://woundtech.net/fhir/ValueSet/missing-information';
export const COMMUNICATION_STATUS_APPOINTMENT =
  'http://woundtech.net/fhir/extensions/appointment/communication';
export const COMMUNICATION_STATUS_ENCOUNTER =
  'http://woundtech.net/fhir/extensions/encounter/communication';

export enum AppointmentStatus {
  failed = 'Failed',
  noFax = 'No Fax',
  noPcp = 'No PCP',
}

export enum AppointmentType {
  'followUp' = 'FU',
  'socPatient' = 'SOC',
  'socEvaluation' = 'EO',
  'reAdmit' = 'RA',
}

export enum LETTER_STATUS_CODE_MAPPING {
  none = 'none',
  sent = 'completed',
  queued = 'in-progress',
  failed = 'entered-in-error',
}

export const SEND_LETTER_MESSAGE =
  'missed appointment letters are ready to be sent. Do you want to proceed?';

export const MADE_ENCOUNTERS_SEND_LETTERS =
  'made encounter letters are ready to be sent. Do you want to proceed?';

export const defaultLeterStatus = [
  {
    coding: [
      {
        __typename: 'Code',
        system: 'http://woundtech.net/fhir/CodeSystem/letter-status',
        code: 'none',
        display: 'None',
      },
    ],
    text: 'None',
  },
];
export enum LIST_TYPE {
  missedAppointments = 'missed-appointments',
  madeEncounters = 'made-encounters',
}
export const COMMUNICATION_URL =
  'http://woundtech.net/fhir/extensions/encounter/communication';
export const MADE_ENCOUNTERS_FILTERNAME = 'MadeEncounter';
export const MADE_ENCOUNTERS_PRIMARY_LIST_TYPE = 'GetMadeEncounters';
