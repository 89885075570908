import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Modal,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  Close,
  Backspace,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import * as icons from '@material-ui/icons';
import { validKeys, regexRule, regexDecimalRule } from './constants';
import { useNumpadStyles, useDesktopStyles } from './styles';
import { Palette } from '@woundtech/ui-colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import Form from 'styleguide/layout/Form';
import FieldSet from 'styleguide/layout/FieldSet';

import EnvironmentChip from 'styleguide/EnvironmentIcon';

export type Props = {
  open: boolean;
  isMobile: boolean;
  title?: string;
  titleIcon?: string;
  userMessage?: string;
  inputTitle?: string;
  inputName?: string;
  enterTitle?: string;
  enterIcon?: string;
  decimal?: boolean;
  hiddenValue?: boolean;
  allowShowOnHidden?: boolean;
  max?: number;
  min?: number;
  initValue?: string;
  onEnter?: Function;
  onClose?: Function;
  onChange?: Function | undefined;
  helperText?: string;
  error?: boolean;
  textAlign?:
    | 'left'
    | 'right'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'center'
    | 'end'
    | 'start'
    | 'justify'
    | 'match-parent'
    | undefined;
  checkValidKeys?: boolean;
  allowEmpty?: boolean;
  closeButton?: boolean;
  bottomLink?: string;
  handleBottomLinkAction?: Function;
  withLogo?: boolean;
  noModalBackground?: boolean;
};

const NumPad: React.FC<Props> = ({
  open,
  isMobile,
  title,
  titleIcon = '',
  userMessage = '',
  inputTitle,
  inputName = 'PIN',
  enterTitle = 'Enter',
  enterIcon = '',
  decimal = false,
  hiddenValue = false,
  allowShowOnHidden = false,
  max = 0,
  min = 0,
  initValue = '',
  onEnter = () => {},
  onClose = () => {},
  onChange,
  helperText = '',
  error = false,
  textAlign = 'left',
  checkValidKeys = true,
  allowEmpty = false,
  closeButton = true,
  bottomLink = '',
  handleBottomLinkAction = () => {},
  withLogo = false,
  noModalBackground = false,
}) => {
  const [inputType, setInputType] = useState<string>(
    hiddenValue ? 'password' : 'text'
  );
  const [inputValue, setStateInputValue] = useState<string>('');
  const [helperTextFieldValue, setHelperTextFieldValue] = useState<string>(
    helperText
  );
  const [errorFieldValue, setErrorFieldValue] = useState<boolean>(error);

  const useIcon = (iconName: string) => {
    const iconsNames = Object.keys(icons);
    if (iconsNames.includes(iconName)) {
      return icons[iconName];
    }
    return false;
  };
  const TheEnterIcon = useIcon(enterIcon);
  const TheTitleIcon = useIcon(titleIcon);

  const inputValueRef = useRef<string>(inputValue);
  const setInputValue = useCallback(
    (data: string) => {
      if (max !== 0) {
        if (data.length <= max) {
          inputValueRef.current = data;
          setStateInputValue(data);
          if (typeof onChange === 'function' && data !== '') {
            onChange(data);
          }
        }
      } else {
        inputValueRef.current = data;
        setStateInputValue(data);
        if (typeof onChange === 'function' && data !== '') {
          onChange(data);
        }
      }
    },
    [max, onChange]
  );
  const numpadClasses = useNumpadStyles();
  const desktopClasses = useDesktopStyles();

  const handleNumberButtonPress = (value: string) => {
    if (value === '.') {
      if (decimal && !inputValue.includes('.')) {
        setInputValue(inputValue + value);
      }
    } else {
      setInputValue(inputValue + value);
    }
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleEnter = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    if (checkValid(inputValueRef.current)) {
      onEnter(inputValueRef.current);
    }
  };

  const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
    if (event) {
      event.stopPropagation();
    }
    onClose();
  };

  const handleDelete = () => {
    const newString = inputValue.substring(0, inputValue.length - 1);
    setInputValue(newString);
  };

  const handleVisibility = () => {
    if (inputType === 'text') {
      setInputType('password');
    } else {
      setInputType('text');
    }
  };

  const checkValid = useCallback(
    (data: string) => {
      const checkRegex = (value: string) => {
        if (!checkValidKeys) {
          return true;
        }

        let regEx = regexRule;
        if (decimal) {
          regEx = regexDecimalRule;
        }
        let response = regEx.test(value);
        return response;
      };

      let notMetMinimun = min > 0 && data.length > 0 && data.length < min;
      let notMetMaximun = max > 0 && data.length > max;
      let notMetEmpty = !allowEmpty && data.length === 0;
      let notMetRegex = !checkRegex(data);

      let response = !(
        notMetMinimun ||
        notMetMaximun ||
        notMetEmpty ||
        notMetRegex
      );
      return response;
    },
    [allowEmpty, checkValidKeys, decimal, max, min]
  );

  const keyDownHandler = event => {
    const { key } = event;
    event.preventDefault();
    if (key === 'Backspace') {
      const newString = inputValueRef.current.substring(
        0,
        inputValueRef.current.length - 1
      );
      setInputValue(newString);
    } else if (key === 'Enter') {
      handleEnter();
    } else if (key === 'Escape' && closeButton) {
      handleClose();
    } else if (key === '.') {
      if (
        decimal &&
        !inputValueRef.current.includes('.') &&
        inputValueRef.current.length > 0
      ) {
        setInputValue(inputValueRef.current + key);
      }
    } else if (checkValidKeys) {
      if (validKeys.includes(key)) {
        setInputValue(inputValueRef.current + key);
      }
    } else {
      setInputValue(inputValueRef.current + key);
    }
  };

  useEffect(() => {
    if (error) {
      setInputValue('');
    }

    setErrorFieldValue(error);
    setHelperTextFieldValue(helperText);
  }, [helperText, error, setInputValue]);

  const handleHelperText = useCallback(() => {
    let newHelperText = '';
    if (max !== 0 && inputValueRef.current.length === max) {
      newHelperText =
        'Max digits length reached (' + inputValueRef.current.length + ')';
    }
    setHelperTextFieldValue(newHelperText);
    setErrorFieldValue(false);
  }, [max]);

  useEffect(() => {
    let value = initValue;

    if (initValue || allowEmpty) {
      if (!checkValid(initValue)) {
        value = '';
        setErrorFieldValue(true);
        setHelperTextFieldValue('Bad initial value');
      }
    }
    setInputValue(value);
  }, [checkValid, setInputValue, initValue, open, allowEmpty]);

  useEffect(() => {
    if (!errorFieldValue) {
      handleHelperText();
    }
  }, [inputValue, handleHelperText, errorFieldValue]);

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return function cleanup() {
      document.removeEventListener('keydown', keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let inputProps: any = {
    autoComplete: 'new-password',
    type: inputType,
  };

  if (isMobile) {
    inputProps = {
      autoComplete: 'new-password',
      type: inputType,
      style: { fontSize: '30px', textAlign: textAlign },
    };
  }

  if (allowShowOnHidden) {
    inputProps = {
      autoComplete: 'new-password',
      type: inputType,
      endAdornment: (
        <InputAdornment
          position="end"
          className={desktopClasses.inputAdornment}
        >
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleVisibility}
            onMouseDown={handleVisibility}
            edge="end"
          >
            {inputType === 'password' ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  if (isMobile) {
    return (
      <Modal
        open={open}
        className={
          noModalBackground ? numpadClasses.noModalBackground : undefined
        }
        style={{ zIndex: 1650 }}
      >
        <Box className={numpadClasses.container}>
          <Box className={numpadClasses.innerContainer}>
            {withLogo && (
              <Box className={numpadClasses.logo}>
                <img
                  className={numpadClasses.image}
                  src="https://d7esvgkdelteg.cloudfront.net/logo.png"
                  alt="Woundtech"
                />
                <Box className={numpadClasses.chip}>
                  <EnvironmentChip />
                </Box>
              </Box>
            )}
            <Box id="numpad" className={numpadClasses.numpad}>
              {title && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={numpadClasses.title}>
                    {titleIcon && (
                      <TheTitleIcon className={numpadClasses.titleIcon} />
                    )}
                    {title}
                  </Typography>
                </Grid>
              )}
              {closeButton && (
                <IconButton
                  aria-label="close"
                  className={numpadClasses.closeButton}
                  onClick={handleClose}
                >
                  <Close />
                </IconButton>
              )}
              <Grid item xs={12}>
                <TextField
                  className={numpadClasses.input}
                  autoFocus={false}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  value={inputValue}
                  label={inputTitle}
                  helperText={helperTextFieldValue}
                  error={errorFieldValue}
                  InputProps={inputProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  {['1', '2', '3'].map(value => (
                    <Grid key={value} item className={numpadClasses.gridItem}>
                      <Button
                        onClick={() => handleNumberButtonPress(value)}
                        className={numpadClasses.button}
                      >
                        {value}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  {['4', '5', '6'].map(value => (
                    <Grid key={value} item className={numpadClasses.gridItem}>
                      <Button
                        onClick={() => handleNumberButtonPress(value)}
                        className={numpadClasses.button}
                      >
                        {value}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  {['7', '8', '9'].map(value => (
                    <Grid key={value} item className={numpadClasses.gridItem}>
                      <Button
                        onClick={() => handleNumberButtonPress(value)}
                        className={numpadClasses.button}
                      >
                        {value}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid key="dot" item className={numpadClasses.gridItem}>
                    {decimal && (
                      <Button
                        disabled={!decimal || !inputValue.length}
                        onClick={() => handleNumberButtonPress('.')}
                        className={numpadClasses.buttonDecimal}
                      >
                        <span className={numpadClasses.spanDecimal}>.</span>
                      </Button>
                    )}
                  </Grid>
                  <Grid key={0} item className={numpadClasses.gridItem}>
                    <Button
                      onClick={() => handleNumberButtonPress('0')}
                      className={numpadClasses.button}
                    >
                      0
                    </Button>
                  </Grid>
                  <Grid key="del" item className={numpadClasses.gridItem}>
                    <Button
                      disabled={!inputValue.length}
                      onClick={() => handleDelete()}
                      className={numpadClasses.buttonDel}
                    >
                      <Backspace />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className={bottomLink ? numpadClasses.buttonsBox : undefined}
              >
                {bottomLink && (
                  <Button
                    disabled={false}
                    onClick={() => handleBottomLinkAction()}
                    className={numpadClasses.bottomLink}
                    disableElevation
                    color="secondary"
                    variant="outlined"
                  >
                    {bottomLink}
                  </Button>
                )}
                <Button
                  disabled={!checkValid(inputValue)}
                  onClick={event => handleEnter(event)}
                  className={
                    bottomLink
                      ? numpadClasses.buttonEnter
                      : numpadClasses.buttonEnterFullWith
                  }
                  disableElevation
                  color="primary"
                  variant="contained"
                >
                  {enterIcon && (
                    <TheEnterIcon className={numpadClasses.enterIcon} />
                  )}{' '}
                  {enterTitle}
                </Button>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  } else {
    return (
      <Modal
        open={open}
        className={
          noModalBackground ? desktopClasses.noModalBackground : undefined
        }
        style={{ zIndex: 1650 }}
      >
        <Box className={desktopClasses.container}>
          <Box className={desktopClasses.innerContainer}>
            {withLogo && (
              <Box className={desktopClasses.logo}>
                <img
                  className={desktopClasses.image}
                  src="https://d7esvgkdelteg.cloudfront.net/logo.png"
                  alt="Woundtech"
                />
                <Box className={desktopClasses.chip}>
                  <EnvironmentChip />
                </Box>
              </Box>
            )}
            <Box id="npdesktop" className={desktopClasses.npdesktop}>
              {title && (
                <Grid item xs={12}>
                  <Typography variant="h6" className={desktopClasses.title}>
                    {titleIcon && (
                      <TheTitleIcon className={desktopClasses.titleIcon} />
                    )}
                    {title}
                  </Typography>
                </Grid>
              )}
              {closeButton && (
                <IconButton
                  aria-label="close"
                  className={desktopClasses.closeButton}
                  onClick={handleClose}
                >
                  <Close />
                </IconButton>
              )}
              {userMessage && (
                <Grid item xs={12} className={desktopClasses.userMessage}>
                  <Typography variant="caption">{userMessage}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Form
                  isValid={checkValid(inputValue)}
                  onSubmit={() => handleEnter()}
                  onCancel={closeButton ? handleClose : undefined}
                  onAsideAction={() => handleBottomLinkAction()}
                  enableAsideButton={!!bottomLink}
                  asideButtonColor={Palette.Red.Main}
                  asideButtonVariant="outlined"
                  submitButtonText={enterTitle}
                  cancelButtonText="Cancel"
                  asideButtonText={bottomLink}
                  canDisableSubmit={!checkValid(inputValue)}
                  canCancel={closeButton}
                >
                  <Box className={desktopClasses.fieldSet}>
                    <FieldSet label={inputTitle} labelSize="small">
                      <TextField
                        className={desktopClasses.input}
                        autoFocus={false}
                        onChange={e => {
                          handleInputChange(e);
                        }}
                        value={inputValue}
                        label={inputName}
                        helperText={helperTextFieldValue}
                        error={errorFieldValue}
                        variant="filled"
                        InputProps={inputProps}
                      />
                    </FieldSet>
                  </Box>
                </Form>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }
};

export default NumPad;
