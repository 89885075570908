import {
  getTimeFromDate,
  getTimeFromZonedDateTime,
  setTimeToDefaultISODate,
} from 'styleguide/form/Time/utils';
import { setTimeToDate } from 'styleguide/form/Time/utils';
import uuid from 'react-uuid';
import {
  weekDay,
  numberOfVisits,
  isDayAvailable,
  startOfCare,
  APPOINTMENT_TYPE_SOC_KIND_CODES,
  SLOT_SEQUENCE_EXT,
  DayIndexes,
  HIDE_SCHEDULE_DEFAULT_INFO_EXT,
  OPERATION_TYPE_DELETE,
} from '../constants';
import {
  AppointmentStatus,
  ALWAYS_AVAILABLE,
  GENERAL_AVAILABLE,
  SPECIFIC_AVAILABLE,
} from 'appointment/constants';
import { format, isBefore, isMatch, isSameDay, parseISO } from 'date-fns';
import { DISPLAY_TIME_FORMAT } from 'styleguide/form/Time/constants';
import { ISlotWithOperationType } from 'patient/types';
import { defaultEndTime, defaultStartTime } from 'patient/Form/utils';
import { deepClone } from 'support/utils';
import { areIsoDatesSame } from 'utility/utils';
import { APPOINTMENT_STATUS_FREE } from 'common/constants';

function getNumberOfVisits(slot: fhir.Slot | undefined) {
  return slot?.extension?.find(item => item.url === numberOfVisits)
    ?.valuePositiveInt;
}
export function containsWeekDaySlot(slot: fhir.Slot) {
  return slot?.extension?.find(item => item?.url === weekDay);
}

export const DAYS_OF_WEEK = 5;
export const DAYS_OF_WEEKEND = 2;
export const SUNDAY_INDEX = 0;
export const SATURDAY_INDEX = 6;

export function isSlotNonDeleteOperationType(slot: ISlotWithOperationType) {
  return slot?.operationType !== OPERATION_TYPE_DELETE;
}

export function isSlotExtSequence1(ext: any) {
  return ext.url === SLOT_SEQUENCE_EXT && ext?.valueInteger === 1;
}

export function isSlotExtSequenceOtherThan1(ext: any) {
  return ext.url === SLOT_SEQUENCE_EXT && ext?.valueInteger !== 1;
}

export function getSlotsForWeekdays(slots: fhir.Slot[]) {
  return slots.filter(
    slot =>
      slot.extension?.some(ext => {
        return (
          ext.url === weekDay &&
          (ext?.valuePositiveInt ?? 0) > SUNDAY_INDEX &&
          (ext?.valuePositiveInt ?? 0) < SATURDAY_INDEX
        );
      }) ?? false
  );
}

export function areAllSlotsAvailable(weekDaySlots: fhir.Slot[]) {
  return weekDaySlots.every(
    slot =>
      slot.extension?.some(
        ext => ext.url === isDayAvailable && ext.valueBoolean
      ) ?? false
  );
}

export function updateSlotsOnAvailabilityTypeChange(
  slots,
  resetSlotTime = false,
  resetIsDayAvailable = false,
  markDeleteNonFirstSlot = true
) {
  return slots.map(slot => {
    const clonedSlot = deepClone(slot);
    if (resetSlotTime) {
      clonedSlot.start = '';
      clonedSlot.end = '';
    }
    let isNonFirstSeq = false;
    clonedSlot.extension?.forEach(ext => {
      if (ext?.url === isDayAvailable && resetIsDayAvailable) {
        ext.valueBoolean = false;
      }
      if (isSlotExtSequenceOtherThan1(ext)) {
        isNonFirstSeq = true;
      }
    });
    if (isNonFirstSeq && markDeleteNonFirstSlot) {
      clonedSlot.operationType = OPERATION_TYPE_DELETE;
    }
    return clonedSlot;
  });
}

export function createArrayRange(
  start: number = 1,
  stop: number = 1,
  step: number = 1
) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );
}

export function areAllSlotSeqSame(
  weekDaySlots: fhir.Slot[],
  slotNumber: number = 1,
  allowEmptyStartAndEndTime: boolean = false
) {
  const filterSlotsForGivenSeq = weekDaySlots.filter(
    slot =>
      slot.extension?.some(
        ext => ext.url === SLOT_SEQUENCE_EXT && ext?.valueInteger === slotNumber
      ) ?? false
  );
  const firstSlotStartTime = filterSlotsForGivenSeq?.[0]?.start;
  const firstSlotEndTime = filterSlotsForGivenSeq?.[0]?.end;
  return (
    (!allowEmptyStartAndEndTime
      ? firstSlotStartTime && firstSlotEndTime
      : true) &&
    filterSlotsForGivenSeq.every(
      slot =>
        slot?.start === firstSlotStartTime && slot?.end === firstSlotEndTime
    )
  );
}

export function getWeekDay(slot: fhir.Slot) {
  return slot?.extension?.find(item => item.url === weekDay)?.valuePositiveInt;
}

export function getSlotSequence(slot: fhir.Slot) {
  return slot?.extension?.find(ext => ext.url === SLOT_SEQUENCE_EXT)
    ?.valueInteger;
}

export function getWeekDayAvailability(slot: fhir.Slot) {
  return slot?.extension?.find(item => item.url === isDayAvailable)
    ?.valueBoolean;
}

export function isWeekDaySelected(slots: fhir.Slot[], weekDay) {
  return slots
    ?.find(slot => getWeekDay(slot) === weekDay)
    ?.extension?.find(item => item.url === isDayAvailable)?.valueBoolean;
}

export function isAtleastOneDayAvailable(slots: fhir.Slot[]) {
  return slots.filter(slot => getWeekDayAvailability(slot) === true)?.length;
}

function getAppointmentType(slot: fhir.Slot | undefined) {
  return slot?.appointmentType?.coding?.[0]?.code;
}

export function getTotalAppointmentSlot(
  slots: fhir.Slot[] | undefined
): fhir.Slot | undefined {
  return slots?.find(
    item => !item.appointmentType && !containsWeekDaySlot(item)
  );
}

export function getTotalAppointmentSlotDetails(slots: fhir.Slot[] | undefined) {
  const defaultSlot = getTotalAppointmentSlot(slots);
  const hideDefaultSlot = !!defaultSlot?.extension?.find(
    ext => ext.url === HIDE_SCHEDULE_DEFAULT_INFO_EXT
  );
  return {
    start: defaultSlot?.start,
    end: defaultSlot?.end,
    hideDefaultSlot,
    id: defaultSlot?.id,
    slot: defaultSlot,
  };
}

export function getTotalAppointments(slots: fhir.Slot[] | undefined) {
  const totalAppointmentSlot = slots?.find(
    item => !item.appointmentType && !containsWeekDaySlot(item)
  );
  return getNumberOfVisits(totalAppointmentSlot);
}

export function getTotalAppointmentsForWeekDays(
  slots: fhir.Slot[] | undefined,
  weekDay
) {
  const totalAppointmentSlot = slots?.find(
    item => !item.appointmentType && getWeekDay(item) === weekDay
  );
  return getNumberOfVisits(totalAppointmentSlot);
}
export function getSOCAppointments(slots: fhir.Slot[] | undefined) {
  const socAppointmentSlot = slots?.find(
    item =>
      getAppointmentType(item) === startOfCare && !containsWeekDaySlot(item)
  );
  return getNumberOfVisits(socAppointmentSlot);
}
export function getSOCForWeekDays(slots: fhir.Slot[] | undefined, weekDay) {
  const socAppointmentSlot = slots?.find(
    item =>
      getAppointmentType(item) === startOfCare && getWeekDay(item) === weekDay
  );
  return getNumberOfVisits(socAppointmentSlot);
}
export function getStartTime(slots: fhir.Slot[] | undefined) {
  const totalAppointmentSlot = slots?.find(item => !item.appointmentType);
  return (
    totalAppointmentSlot?.start && getTimeFromDate(totalAppointmentSlot?.start)
  );
}

export const TEMP_TEXT = 'temp_';

export const createNewSlot = (currentSlot, nextInSequence) => {
  const exts =
    currentSlot.extension.filter(ext => ext.url !== SLOT_SEQUENCE_EXT) || [];
  const slot: ISlotWithOperationType = {
    id: `${TEMP_TEXT}${uuid()}`,
    resourceType: 'Slot',
    status: 'free',
    start: currentSlot.start,
    end: currentSlot.end,
    schedule: {
      ...currentSlot.schedule,
    },
    extension: [
      ...exts,
      {
        url: SLOT_SEQUENCE_EXT,
        valueInteger: nextInSequence,
      },
    ],
  };

  return slot;
};

export const getNextInSequence = (
  slots: ISlotWithOperationType[] = [],
  dayIndex: number | undefined
) => {
  return (
    (slots
      ?.filter(
        item =>
          !item.appointmentType &&
          item?.status === 'free' &&
          item?.operationType !== OPERATION_TYPE_DELETE &&
          getWeekDay(item) === dayIndex
      )
      .reduce((val, item) => {
        let seqValue =
          item?.extension?.find(ext => ext.url === SLOT_SEQUENCE_EXT)
            ?.valueInteger || 0;
        return seqValue > val ? seqValue : val;
      }, 0) || 0) + 1
  );
};

export function getTimeSlotForWeekDays(
  slots: ISlotWithOperationType[] | undefined,
  weekDay
) {
  const daySlots = slots?.filter(
    item =>
      !item?.appointmentType &&
      item?.status === 'free' &&
      item?.operationType !== OPERATION_TYPE_DELETE &&
      getWeekDay(item) === weekDay
  );

  return daySlots
    ? daySlots.sort((aSlot, bSlot) => {
        const aSlotSeq =
          aSlot?.extension?.find(item => item.url === SLOT_SEQUENCE_EXT)
            ?.valueInteger || 0;
        const bSlotSeq =
          bSlot?.extension?.find(item => item.url === SLOT_SEQUENCE_EXT)
            ?.valueInteger || 0;

        return aSlotSeq > bSlotSeq ? 1 : -1;
      })
    : [];
}

export function removeTempSlotIDs(slots: fhir.Slot[] = []) {
  const { start, end } = getTotalAppointmentSlotDetails(slots);
  const filteredSlots = slots
    .map(slot => {
      const isAvailable = slot?.extension?.find(
        item => item.url === isDayAvailable
      )?.valueBoolean;
      const slotSeq = slot?.extension?.find(
        item => item.url === SLOT_SEQUENCE_EXT
      )?.valueInteger;
      if (
        DayIndexes.includes(getWeekDay(slot) ?? -1) &&
        !slot.appointmentType &&
        !isAvailable
      ) {
        if (slotSeq === 1) {
          return {
            ...slot,
            start: start || defaultStartTime,
            end: end || defaultEndTime,
          };
        } else {
          return slot.id?.includes(TEMP_TEXT)
            ? null
            : {
                ...slot,
                operationType: OPERATION_TYPE_DELETE,
              };
        }
      }

      return slot;
    })
    .filter(slot => slot);

  return (filteredSlots as ISlotWithOperationType[]).map(slot => {
    if (slot.id && slot.id.includes(TEMP_TEXT)) {
      return {
        ...slot,
        id: undefined,
      };
    }
    return slot;
  });
}

export function convertTimeToDate(slots: fhir.Slot[] = []) {
  const res = slots.map(slot => {
    const dayIndex = getWeekDay(slot);
    const convertIt =
      (dayIndex !== undefined && DayIndexes.includes(dayIndex)) ||
      !containsWeekDaySlot(slot);
    if (convertIt && slot?.start && slot?.end) {
      return {
        ...slot,
        start: slot.start ? setTimeToDefaultISODate(slot.start) : slot.start,
        end: slot.end ? setTimeToDefaultISODate(slot.end) : slot.end,
      };
    }
    return slot;
  });
  return res;
}

export function sortFreeSlotsByStartTime(slots: fhir.Slot[] = []) {
  const slotsOnDay = getDaySlots(slots);
  let resSlots: fhir.Slot[] = [];
  Object.entries(slotsOnDay).forEach(([dayIndex, daySlots]) => {
    if (DayIndexes.includes(+dayIndex)) {
      const daySortedSlots = daySlots.sort((slotA, slotB) => {
        return validStartAndEndTimes(slotA.start, slotB.start) ? -1 : 1;
      });
      let slotSeq = 0;
      resSlots = resSlots.concat(
        daySortedSlots.map(slot => {
          if (slot.operationType === OPERATION_TYPE_DELETE) {
            return slot;
          }
          const exts = slot.extension || [];
          slotSeq++;
          return {
            ...slot,
            extension: [
              ...exts.filter(ext => ext.url !== SLOT_SEQUENCE_EXT),
              { url: SLOT_SEQUENCE_EXT, valueInteger: slotSeq },
            ],
          };
        })
      );
    } else {
      resSlots = resSlots.concat(daySlots);
    }
  });
  return resSlots?.map(slot => {
    const isAvailable = slot?.extension?.find(
      item => item.url === isDayAvailable
    )?.valueBoolean;
    return {
      ...slot,
      start: isAvailable ? slot?.start : '',
      end: isAvailable ? slot?.end : '',
    };
  });
}

const NO_DAY_INDEX = 'no_day_index';

export const getFreeSlotsWithoutSequence = slots => {
  return (
    slots?.filter(
      slot =>
        slot?.status === 'free' &&
        !slot?.appointmentType &&
        slot?.operationType !== OPERATION_TYPE_DELETE &&
        slot?.extension?.find(item => item.url === weekDay) &&
        !slot?.extension?.find(item => item.url === SLOT_SEQUENCE_EXT)
    ) || []
  );
};

export function appendIdAndSlotSequence(slots: fhir.Slot[] | undefined) {
  const freeSlotsWithoutSequence = getFreeSlotsWithoutSequence(slots);
  if (freeSlotsWithoutSequence.length) {
    freeSlotsWithoutSequence?.forEach(slot => {
      if (!slot.id) {
        slot.id = `${TEMP_TEXT}${uuid()}`;
      }
    });

    let slotsOnDay: { [key: string]: fhir.Slot[] } = getDaySlots(
      freeSlotsWithoutSequence
    );

    Object.entries(slotsOnDay).forEach(([dayIndex, daySlots]) => {
      let nextSlotSeq = 1;
      daySlots.forEach(slot => {
        const exts = slot?.extension || [];
        exts.push({
          url: SLOT_SEQUENCE_EXT,
          valueInteger: nextSlotSeq,
        });
        nextSlotSeq++;
        slot.extension = exts;
      });
    });
  }

  slots?.forEach(slot => {
    if (slot?.start?.includes('Z')) {
      slot.start = getTimeFromZonedDateTime(slot.start) || slot.start;
    }
    if (slot?.end?.includes('Z')) {
      slot.end = getTimeFromZonedDateTime(slot.end) || slot.end;
    }
  });

  const { id, hideDefaultSlot, slot } = getTotalAppointmentSlotDetails(slots);
  if (!id && !hideDefaultSlot) {
    slot?.extension?.push({
      url: HIDE_SCHEDULE_DEFAULT_INFO_EXT,
      valueBoolean: true,
    });
  }

  return slots;
}

export const getDaySlots = (slots: ISlotWithOperationType[]) => {
  let slotsOnDay: { [key: string]: ISlotWithOperationType[] } = {};
  slots?.reduce((acc, slot) => {
    const dayIndex = slot?.extension?.find(item => item.url === weekDay)
      ?.valuePositiveInt;
    const key =
      dayIndex !== undefined && !slot.appointmentType
        ? dayIndex.toString()
        : NO_DAY_INDEX;
    acc[key] = acc[key] ? acc[key].concat(slot) : [slot];
    return acc;
  }, slotsOnDay);
  return slotsOnDay;
};

export function getStartTimeForWeekDays(
  slots: fhir.Slot[] | undefined,
  weekDay
) {
  const totalAppointmentSlot = slots?.find(
    item => !item.appointmentType && getWeekDay(item) === weekDay
  );
  return (
    totalAppointmentSlot?.start && getTimeFromDate(totalAppointmentSlot?.start)
  );
}
export function getEndTimeForWeekDays(slots: fhir.Slot[] | undefined, weekDay) {
  const totalAppointmentSlot = slots?.find(
    item => !item.appointmentType && getWeekDay(item) === weekDay
  );
  return (
    totalAppointmentSlot?.end && getTimeFromDate(totalAppointmentSlot?.end)
  );
}
export function setTotalAppointments(
  slots: fhir.Slot[] | undefined,
  numberOfAppointments: number
): fhir.Slot[] | undefined {
  const isslotsInfoValid =
    numberOfAppointments > (getSOCAppointments(slots) || 0);
  const modifiedSlots: fhir.Slot[] | undefined = slots?.map(item => {
    const validInfo = isslotsInfoValid
      ? !item.appointmentType
      : !item.appointmentType && !containsWeekDaySlot(item);
    if (validInfo) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== numberOfVisits) ||
            []),
          {
            url: numberOfVisits,
            valuePositiveInt: +numberOfAppointments,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots;
}

export function setTotalAppointmentsForWeekDays(
  slots: fhir.Slot[] | undefined,
  numberOfAppointments: number,
  weekDay?: number
): fhir.Slot[] | undefined {
  const modifiedSlots: fhir.Slot[] | undefined = slots?.map(item => {
    if (!item?.appointmentType && getWeekDay(item) === weekDay) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== numberOfVisits) ||
            []),
          {
            url: numberOfVisits,
            valuePositiveInt: +numberOfAppointments,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots;
}
export function setSOCAppointments(
  slots: fhir.Slot[] | undefined,
  numberOfAppointments
): fhir.Slot[] | undefined {
  const isslotsInfoValid =
    (getTotalAppointments(slots) || 0) > numberOfAppointments;
  const modifiedSlots: fhir.Slot[] | undefined = slots?.map(item => {
    const validInfo = isslotsInfoValid
      ? getAppointmentType(item) === startOfCare
      : getAppointmentType(item) === startOfCare && !containsWeekDaySlot(item);
    if (validInfo) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== numberOfVisits) ||
            []),
          {
            url: numberOfVisits,
            valuePositiveInt: +numberOfAppointments,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots;
}
export function setSOCForWeekDays(
  slots: fhir.Slot[] | undefined,
  numberOfAppointments: number,
  weekDay?: number
): fhir.Slot[] | undefined {
  const modifiedSlots: fhir.Slot[] | undefined = slots?.map(item => {
    if (
      getAppointmentType(item) === startOfCare &&
      getWeekDay(item) === weekDay
    ) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== numberOfVisits) ||
            []),
          {
            url: numberOfVisits,
            valuePositiveInt: +numberOfAppointments,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots;
}
export function setTimeForSlots(slots: fhir.Slot[], time, type) {
  const { hideDefaultSlot, slot } = getTotalAppointmentSlotDetails(slots);

  const modifiedSlots: fhir.Slot[] | undefined = slots?.map(item => {
    const slotSeq = item?.extension?.find(ext => ext.url === SLOT_SEQUENCE_EXT)
      ?.valueInteger;
    if (hideDefaultSlot || slotSeq === 1 || slot?.id === item?.id) {
      return {
        ...item,
        [type]: time,
      };
    }
    return item;
  });
  return modifiedSlots;
}

export const showSlotsOverlappingError = (
  slots: ISlotWithOperationType[] = []
) => {
  const timeMapper = slots.map(slot => {
    return { start: slot.start || '', end: slot.end || '' };
  });
  let showError = false;
  slots.forEach((_, index) => {
    const { start, end } = timeMapper[index];
    if (start && end && !showError) {
      if (timeMapper.length > index) {
        for (let i = index + 1; i < timeMapper.length; i++) {
          const currStartTime = timeMapper[i].start;
          const currEndTime = timeMapper[i].end;
          if (currStartTime && currEndTime) {
            showError =
              (validStartAndEndTimes(start, currStartTime) &&
                validStartAndEndTimes(currStartTime, end)) ||
              (validStartAndEndTimes(currStartTime, start) &&
                validStartAndEndTimes(start, currEndTime)) ||
              (validStartAndEndTimes(currStartTime, end) &&
                validStartAndEndTimes(end, currEndTime)) ||
              (validStartAndEndTimes(start, currEndTime) &&
                validStartAndEndTimes(currEndTime, end));

            if (showError) {
              break;
            }
          }
        }
      }
    }
  });

  return showError;
};

export const setTimeToCurrentDate = time =>
  setTimeToDate(time, new Date().toISOString());

export const validStartAndEndTimes = (start, end) => {
  if (start && end) {
    return (
      isMatch(start, DISPLAY_TIME_FORMAT) &&
      isMatch(end, DISPLAY_TIME_FORMAT) &&
      isBefore(
        new Date(setTimeToCurrentDate(start) || ''),
        new Date(setTimeToCurrentDate(end) || '')
      )
    );
  }

  return true;
};

export const validStartTimeForCurrentDay = (start, appointmentDate) => {
  if (start && appointmentDate) {
    const givenDate = parseISO(appointmentDate);
    const isSame = isSameDay(givenDate, new Date());
    if (isSame) {
      const currentTimeInHHMMFormat = format(new Date(), 'hh:mm a');
      return (
        new Date(setTimeToCurrentDate(start) || '').getTime() >=
        new Date(setTimeToCurrentDate(currentTimeInHHMMFormat) || '').getTime()
      );
    }
  }
  return true;
};

export function setTimeForSlotsForWeekDays(
  slots: ISlotWithOperationType[],
  time,
  type,
  weekDay,
  slotId
) {
  const modifiedSlots: ISlotWithOperationType[] = slots?.map(item => {
    if (getWeekDay(item) === weekDay && item?.id === slotId) {
      return {
        ...item,
        [type]: time,
      };
    }
    return item;
  });
  return modifiedSlots;
}

export function setWeekDayAvailability(
  dayIndex: number,
  slots: ISlotWithOperationType[],
  checked: boolean
): fhir.Slot[] {
  const modifiedSlots: (ISlotWithOperationType | null)[] = slots?.map(item => {
    if (getWeekDay(item) === dayIndex) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== isDayAvailable) ||
            []),
          {
            url: isDayAvailable,
            valueBoolean: checked,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots.filter(s => s) as ISlotWithOperationType[];
}

export function setGeneralAvailability(
  slots: ISlotWithOperationType[],
  checked: boolean,
  weekDay: number
): fhir.Slot[] {
  const modifiedSlots: (ISlotWithOperationType | null)[] = slots?.map(item => {
    const isWeekDay =
      weekDay === 1 && getWeekDay(item) !== 0 && getWeekDay(item) !== 6;
    const isWeekend =
      weekDay === 0 && (getWeekDay(item) === 0 || getWeekDay(item) === 6);
    if (isWeekDay || isWeekend) {
      return {
        ...item,
        extension: [
          ...(item?.extension?.filter(slot => slot.url !== isDayAvailable) ||
            []),
          {
            url: isDayAvailable,
            valueBoolean: checked,
          },
        ],
      };
    }
    return item;
  });
  return modifiedSlots.filter(slot => slot) as ISlotWithOperationType[];
}

const appointmentInSlotsSOC = appointments => {
  return (
    appointments?.filter(item => {
      const appointmentTypeCode = item?.appointmentType?.coding?.[0]?.code;

      const isKindOfSOC =
        APPOINTMENT_TYPE_SOC_KIND_CODES.indexOf(appointmentTypeCode || '') !==
        -1;
      const status = item?.status?.toLowerCase();
      return (
        isKindOfSOC &&
        item?.id &&
        status !== AppointmentStatus.missed &&
        status !== AppointmentStatus.canceled
      );
    })?.length || 0
  );
};
const totalappointmentInSlots = appointments =>
  appointments?.filter(
    item =>
      item?.status !== AppointmentStatus.missed &&
      item?.status !== AppointmentStatus.canceled
  )?.length;

export const getTotalAppointmentsCountWithException = (
  exceptionalSlots,
  appointments
) => {
  const totalSlots = exceptionalSlots?.[0]?.extension?.filter(
    item => item?.url === 'NUMBER_OF_VISITS'
  )?.[0]?.valuePositiveInt;
  const updatedAvailableSlots =
    totalSlots - totalappointmentInSlots(appointments);
  return updatedAvailableSlots > 0 ? updatedAvailableSlots : 0;
};

export const getTotalSOCAppointmentsCountWithException = (
  exceptionalSlots,
  appointments
) => {
  const totalSlots = exceptionalSlots?.[0]?.extension?.filter(
    item => item?.url === 'NUMBER_OF_VISITS'
  )?.[0]?.valuePositiveInt;
  const updatedAvailableSlots =
    totalSlots - appointmentInSlotsSOC(appointments);
  return updatedAvailableSlots > 0 ? updatedAvailableSlots : 0;
};
export const getScheduleTotalAppointmentsCount = (
  scheduleData,
  dayOfWeek,
  appointments,
  date,
  isAvailable
) => {
  if (isAvailable) {
    return 0;
  }
  const exceptionalSlots = scheduleData?.filter(
    item =>
      item?.status === 'free' &&
      !item?.appointmentType &&
      areIsoDatesSame(date, item?.start)
  );
  if (exceptionalSlots?.length) {
    return getTotalAppointmentsCountWithException(
      exceptionalSlots,
      appointments
    );
  }
  return getTotalAppointmentsCount(scheduleData, dayOfWeek, appointments);
};

export function isSchedulingPreferenceSlotException(
  schedulingPreferenceSlot,
  isoDate: string,
  forSOC: boolean = true
) {
  const isStatusFree =
    schedulingPreferenceSlot?.status?.toLowerCase() ===
    APPOINTMENT_STATUS_FREE.toLowerCase();
  const hasAppointmentType = schedulingPreferenceSlot?.appointmentType;
  const hasNoAppointmentType = !schedulingPreferenceSlot?.appointmentType;
  return (
    isStatusFree &&
    (forSOC ? hasAppointmentType : hasNoAppointmentType) &&
    areIsoDatesSame(isoDate, schedulingPreferenceSlot?.start)
  );
}

export function filterPredicateForExceptionSlots(
  isoDate: string,
  forSOC: boolean = true
) {
  return slot => isSchedulingPreferenceSlotException(slot, isoDate, forSOC);
}

export const getSchedulingPreferenceTotalCount = (
  schedulingPreferenceSlots: fhir.Slot[],
  dayOfWeek: number,
  date: string,
  isAvailable: boolean
): number => {
  if (isAvailable) {
    return 0;
  }
  const exceptionSlots = schedulingPreferenceSlots?.filter(
    filterPredicateForExceptionSlots(date, false)
  );
  if (exceptionSlots?.length) {
    return getNumberOfVisits(exceptionSlots[0]) ?? 0;
  }
  return getDefaultSchedulingPreferenceTotalCount(
    schedulingPreferenceSlots,
    dayOfWeek
  );
};

export const getTotalAppointmentsCount = (
  scheduleSettings: fhir.Slot[],
  dayOfWeek: number,
  appointments
): number => {
  const totalAppointmentsAvailable = getTotalAppointmentsForWeekDays(
    scheduleSettings,
    dayOfWeek
  );
  const appointmentsInSlot = totalappointmentInSlots(appointments) || 0;
  let totalAppointments;
  if (totalAppointmentsAvailable) {
    totalAppointments = totalAppointmentsAvailable - appointmentsInSlot;
  }
  return totalAppointments > 0 ? totalAppointments : 0;
};

export const getDefaultSchedulingPreferenceTotalCount = (
  schedulingPreferenceSlots: fhir.Slot[],
  dayOfWeek: number
): number => {
  const totalAppointmentsAvailable = getTotalAppointmentsForWeekDays(
    schedulingPreferenceSlots,
    dayOfWeek
  );
  return totalAppointmentsAvailable ?? 0;
};

export const getTotalAppointmentsScheduled = appointments => {
  const appointmentsInSlot = appointments?.filter(
    item =>
      item?.id &&
      item?.status?.toLowerCase() !== AppointmentStatus.missed &&
      item?.status?.toLowerCase() !== AppointmentStatus.canceled
  )?.length;
  return appointmentsInSlot > 0 ? appointmentsInSlot : 0;
};

export const getSOCAppointmentsCount = (
  scheduleSettings: fhir.Slot[],
  dayOfWeek: number,
  appointments
) => {
  const socAppointmentsAvailable = getSOCForWeekDays(
    scheduleSettings,
    dayOfWeek
  );

  const socAppointmentsInSlot = appointmentInSlotsSOC(appointments);

  let socAppointments;
  if (socAppointmentsAvailable) {
    socAppointments = socAppointmentsAvailable - socAppointmentsInSlot;
  }

  return socAppointments > 0 ? socAppointments : 0;
};
const numberOfVistsOfSlots = extension => {
  return extension?.filter(item => item?.url === 'NUMBER_OF_VISITS')?.[0]
    ?.valuePositiveInt;
};
const isDateEqual = (src, des) => src?.slice(0, 10) === des?.slice(0, 10);
export const exceptionFreeSchedulingSOC = (scheduleSettings: fhir.Slot[]) => {
  return scheduleSettings?.filter(
    item => item?.status === 'free' && item?.appointmentType
  );
};
export const exceptionFreeSchedulingSlots = (scheduleSettings: fhir.Slot[]) => {
  return scheduleSettings?.filter(
    item => item?.status === 'free' && !item?.appointmentType
  );
};

export const exceptionSOC = (
  scheduleSettings: fhir.Slot[],
  date,
  appointments
) => {
  const scheduleData = exceptionFreeSchedulingSOC(scheduleSettings);
  const dataForSelectedDate = scheduleData?.filter(item =>
    isDateEqual(item?.start, date)
  )?.[0];
  const totalSLots = numberOfVistsOfSlots(dataForSelectedDate?.extension);
  const socAppointmentsInSlot = appointmentInSlotsSOC(appointments);

  let socAppointments;
  if (dataForSelectedDate) {
    socAppointments = totalSLots - socAppointmentsInSlot;
  }

  return socAppointments > 0 ? socAppointments : 0;
};

export const getAvailabilityType = (availableType: string): string => {
  if (availableType === GENERAL_AVAILABLE) {
    return 'General Availability';
  } else if (availableType === SPECIFIC_AVAILABLE) {
    return 'Specific Availability';
  } else if (availableType === ALWAYS_AVAILABLE) {
    return '';
  } else {
    return 'Specific Availability';
  }
};
