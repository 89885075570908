import React, { useState, useCallback, useContext } from 'react';
import { Context as AuthorizationContext } from 'authorization/Context';
import { emailFormat } from 'styleguide/form/Telecom';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loading from 'styleguide/Loading';
import InlineAlert from 'styleguide/layout/InlineAlert/InlineAlert';
import TextField, { INPUT_PATTERN_PIN } from 'styleguide/form/Text';

export type Props = {
  onValidate;
  checkMaxLength;
  setVerificationCode;
  verificationCode;
  validationErrors;
  sendPINVerificationLoading;
  resendVerificationCode;
  validatePINVerificationLoading;
  validatePINVerificationData;
  verifyVerificationCode;
  sendPINVerificationCodeData;
  verifyDisabled;
  isMobile;
  handleOpenNumpad;
};

const VerifyPin: React.FC<Props> = ({
  onValidate,
  checkMaxLength,
  setVerificationCode,
  verificationCode,
  validationErrors,
  sendPINVerificationLoading,
  resendVerificationCode,
  validatePINVerificationLoading,
  validatePINVerificationData,
  verifyVerificationCode,
  sendPINVerificationCodeData,
  verifyDisabled,
  isMobile,
  handleOpenNumpad,
}) => {
  const { user } = useContext(AuthorizationContext);
  const [showVerificationCode, setShowVerificationCode] = useState<boolean>(
    false
  );

  const toggleVerificationCode = useCallback(
    () => setShowVerificationCode(!showVerificationCode),
    [setShowVerificationCode, showVerificationCode]
  );

  function getEmail(email) {
    const emailArray = email.split('@');
    const totalLength = emailArray[0].length;
    const hiddenPart =
      Array(totalLength - 3)
        .fill('*')
        .join('') + emailArray[0].slice(totalLength - 3, totalLength);
    return hiddenPart + '@' + emailArray.slice(1, emailArray.length).join('');
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          {' '}
          Please enter the verification code sent to{' '}
          {getEmail(emailFormat(user?.telecom))}{' '}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          endIcon={showVerificationCode ? 'visibility' : 'visibility_off'}
          label=""
          name="verificationCode"
          onChange={value =>
            checkMaxLength(value, setVerificationCode, 'VCode')
          }
          onValidate={onValidate}
          type={showVerificationCode ? 'text' : 'password'}
          value={verificationCode}
          onEndIconClick={toggleVerificationCode}
          errorMessage={validationErrors?.verificationCode}
          inputPattern={INPUT_PATTERN_PIN}
          onClick={isMobile ? () => handleOpenNumpad('vcode') : undefined}
        />
      </Grid>
      <Grid item xs={6}>
        {sendPINVerificationLoading && <Loading variant="spinner" />}
      </Grid>
      <Grid item xs={12}>
        {sendPINVerificationCodeData?.validatePINVerificationCode?.isError && (
          <InlineAlert severity="error">
            {
              sendPINVerificationCodeData?.validatePINVerificationCode
                ?.errorMessage
            }
          </InlineAlert>
        )}
        {validatePINVerificationData?.validatePINVerificationCode?.isError && (
          <InlineAlert severity="error">
            {
              validatePINVerificationData?.validatePINVerificationCode
                ?.errorMessage
            }
          </InlineAlert>
        )}
      </Grid>
      <Grid item xs={3}>
        <Link onClick={resendVerificationCode} style={{ cursor: 'pointer' }}>
          Resend code{' '}
        </Link>
      </Grid>
      <Grid item xs={9}>
        {validatePINVerificationLoading && <Loading variant="spinner" />}
      </Grid>

      <Grid item xs={6}>
        <Button
          disableElevation
          color="primary"
          variant={'contained'}
          disableRipple
          onClick={e => verifyVerificationCode(verificationCode)}
          disabled={verifyDisabled}
        >
          verify
        </Button>
      </Grid>
      <Grid item xs={6}></Grid>
    </>
  );
};

export default React.memo(VerifyPin);
