import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Icon from '@material-ui/core/Icon';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>
  createStyles({
    link: {
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    breadcrumb: {
      color: 'primary',
    },
    breadcrumbInverse: {
      color: 'white',
    },
  })
);

export type Breadcrumb = {
  id?: string;
  name: string;
  url?: string;
  onClick?: () => void;
};

export type Props = {
  breadcrumbs: Breadcrumb[];
  onBeforeNavigate?: () => void;
  onAfterNavigate?: () => void;
  onNavigate?: (breadcrumb: Breadcrumb) => void;
  inverse?: boolean;
  replace?: boolean;
};

const BackwardNavigation: React.FC<Props> = ({
  breadcrumbs,
  onBeforeNavigate,
  onAfterNavigate,
  onNavigate,
  inverse,
  replace,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" separator="">
        {breadcrumbs.map(breadcrumb => (
          <Link
            href={breadcrumb.url}
            key={breadcrumb.name}
            className={clsx({
              [classes.breadcrumb]: !inverse,
              [classes.breadcrumbInverse]: inverse,
            })}
            underline="none"
            onClick={event => {
              event?.preventDefault();
              event?.stopPropagation();

              onBeforeNavigate && onBeforeNavigate();
              if (onNavigate) {
                onNavigate(breadcrumb);
              } else if (breadcrumb?.url) {
                replace
                  ? history.replace(breadcrumb.url)
                  : history.push(breadcrumb.url);
              } else if (breadcrumb?.onClick) {
                breadcrumb?.onClick();
              }
              onAfterNavigate && onAfterNavigate();
            }}
          >
            <Box display="flex" ml={-1}>
              <Box display="flex">
                <Icon>navigate_before</Icon>
              </Box>
              <Box display="flex" className={classes.link}>
                {breadcrumb.name}
              </Box>
            </Box>
          </Link>
        ))}
      </Breadcrumbs>
    </>
  );
};

export default React.memo(BackwardNavigation);
