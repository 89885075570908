import React from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

export type Props = {
  text: string;
};

const useStyles = makeStyles({
  label: {
    fontSize: '16px',
  },
});

const InlineError: React.FC<Props> = ({ text }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" mr={1}>
        <Icon fontSize="large" color="error">
          error_outline
        </Icon>
      </Box>
      <Box>
        <Typography
          color="error"
          variant="body1"
          align="center"
          className={classes.label}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(InlineError);
