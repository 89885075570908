export const DISPLAY_DATETIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DISPLAY_DATETIME_PLACEHOLDER = 'MM/dd/yyyy HH:MM AM/PM';

export const UTC_OFFSET_TIMEZONE_DATA = {
  UTC11: 'SST',
  'UTC9.5': 'MART',
  UTC9: 'HDT',
  UTC7: 'PDT',
  UTC6: 'MDT',
  UTC5: 'CDT',
  UTC4: 'EDT',
  'UTC3.5': 'NST',
  UTC3: 'WGT',
  'UTC2.5': 'NDT',
  UTC2: 'WGST',
  UTC1: 'EGT',
  UTC0: 'EGST',
  'UTC-2': 'IST',
  'UTC-3': 'IDT',
  'UTC-3.5': 'IRST',
  'UTC-4': 'GST',
  'UTC-4.5': 'IRDT',
  'UTC-5': 'YEKT',
  'UTC-5:45': 'NPT',
  'UTC-5.5': 'IST',
  'UTC-6.5': 'MMT',
  'UTC-6': 'YEKST',
  'UTC-7': 'WIB',
  'UTC-8': 'WITA',
  'UTC-8:45': 'ACWST',
  'UTC-8.5': 'PYT',
  'UTC-9': 'PWT',
  'UTC-9.5': 'ACT',
  'UTC-10': 'AET',
  'UTC-10.5': 'LHST',
  'UTC-11': 'AET',
  'UTC-12': 'WFT',
  'UTC-13': 'WST',
  'UTC-14': 'TOST',
};
