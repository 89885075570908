import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { OrganizationListQuery } from 'organization/Schema';
import { Context as AuthorizationContext } from 'authorization/Context';

export function useLegalEntitiesQuery(state?: string) {
  const { organization: defaultOrganization } = useContext(
    AuthorizationContext
  );
  const [entities, setEntities] = useState<fhir.Organization[]>([]);

  const { loading, error } = useQuery(OrganizationListQuery, {
    skip: !defaultOrganization?.id,
    variables: { partOfId: defaultOrganization?.id, first: 1000, state },
    onCompleted: entityData => {
      setEntities(
        (entityData?.OrganizationList?.edges || [])
          .map(edge => edge?.node)
          .filter(item => !!item)
      );
    },
  });

  return {
    entities,
    error,
    loading,
  };
}
