import React from 'react';
import Button from '@material-ui/core/Button';
import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';

export type Size = 'small' | 'medium' | 'large';

export type CustomButtonProps = {
  color: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label: string;
  variant: 'outlined' | 'text' | 'contained';
  size?: Size;
  onClick?: () => void;
  transformText?: boolean;
};

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
  },
});

const CustomButton: React.FC<CustomButtonProps> = ({
  color,
  disabled,
  fullWidth,
  label,
  variant,
  size,
  onClick,
  transformText = true,
}) => {
  const classes = useStyles();
  const cancelButtonTheme = transformText
    ? createMuiTheme({
        palette: { primary: { main: color } },
      })
    : createMuiTheme({
        palette: { primary: { main: color } },
        typography: {
          button: {
            textTransform: 'none',
          },
        },
      });

  return (
    <ThemeProvider theme={cancelButtonTheme}>
      <Button
        disabled={disabled}
        disableElevation
        onClick={onClick}
        variant={variant}
        size={size}
        color="primary"
        fullWidth={fullWidth}
        className={transformText ? '' : classes.button}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

export default React.memo(CustomButton);
