import React, { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Loading from 'styleguide/Loading';
import FormLayoutFooter from './FormLayoutFooter';
import SectionHeader from '../Section/SectionHeader';
import Feedback from 'styleguide/Feedback';

export type Props = {
  asideButtonText?: string;
  asideButtonColor?: string;
  asideButtonVariant?: 'navigation' | 'outlined' | 'text' | 'contained';
  children?: React.ReactNode;
  error?: ApolloError;
  isValid?: boolean;
  loading?: boolean;
  onAsideAction?: () => void;
  onCancel?: () => void;
  onCompleted?: () => void;
  onSubmit?: () => void;
  canDisableSubmit?: boolean;
  canCancel?: boolean;
  readonly?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  title?: string;
  disclaimer?: string | undefined;
  enableAsideButton?: boolean;
  enableAsideButtonUndoCheckin?: boolean;
  footerRightPadding?: number;
  footerContent?: React.ReactElement;
};

const FormLayout: React.FC<Props> = ({
  asideButtonText,
  disclaimer = undefined,
  asideButtonColor,
  asideButtonVariant,
  children,
  error,
  isValid,
  loading,
  onAsideAction,
  onCancel,
  onCompleted,
  onSubmit,
  canDisableSubmit,
  canCancel = true,
  readonly,
  cancelButtonText,
  submitButtonText,
  title,
  enableAsideButton,
  enableAsideButtonUndoCheckin,
  footerRightPadding = 0,
  footerContent,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [prevLoading, setPrevLoading] = useState(false);

  useEffect(() => {
    if (prevLoading === true && loading === false) {
      setShowFeedback(true);
    }

    setPrevLoading(!!loading);
  }, [loading, prevLoading]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={1}
      maxHeight={1}
      position="relative"
    >
      {loading && <Loading variant="spinner" />}
      {showFeedback && (
        <Feedback
          onCompleted={() => {
            setShowFeedback(false);
            onCompleted && !error && onCompleted();
          }}
          variation={error ? 'error' : 'success'}
        />
      )}
      <Box
        flexGrow="1"
        display="flex"
        flexDirection="column"
        overflow="hidden auto"
        data-section={title || ''}
      >
        {title && <SectionHeader title={title} />}
        <Box flexGrow="1" overflow="hidden auto" display="flex">
          <Box width={1} height="auto">
            {children}
          </Box>
        </Box>
      </Box>
      {!readonly && (
        <FormLayoutFooter
          error={error}
          canSubmit={isValid && !loading}
          canDisableSubmit={canDisableSubmit}
          canCancel={canCancel && !loading}
          enableAsideButton={enableAsideButton}
          enableAsideButtonUndoCheckin={enableAsideButtonUndoCheckin}
          cancelButtonText={cancelButtonText}
          submitButtonText={submitButtonText}
          asideButtonText={asideButtonText}
          disclaimer={disclaimer}
          asideButtonColor={asideButtonColor}
          asideButtonVariant={asideButtonVariant}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onAsideAction={onAsideAction}
          footerRightPadding={footerRightPadding}
          footerContent={footerContent}
        />
      )}
    </Box>
  );
};

export default React.memo(FormLayout);
