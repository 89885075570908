import React, { useState, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import DatePicker from 'styleguide/form/Date';

export type Action = {
  icon: string;
  iconColor: 'error' | 'primary';
  onClick: () => void;
  disabled?: boolean;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateFieldContainer: { maxWidth: '160px' },
    disabled: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    switchFont: {
      minWidth: 130,
    },
  })
);

export type Props = {
  onToggle?: (abatementDateTime) => void;
  additionalData?: any;
};

const AdditionalFormField: React.FC<Props> = ({ onToggle, additionalData }) => {
  const [problemStatus, setProblemStatus] = useState<boolean>(true);
  const [abatementDateTime, setAbatementDateTime] = useState('');
  const classes = useStyles();
  const maxDate = new Date().toISOString();

  const resolvedDateChange = useCallback(
    (abatementDateTime: fhir.date) => {
      setAbatementDateTime(abatementDateTime);
      if (onToggle) {
        onToggle(abatementDateTime);
      }
    },
    [onToggle, setAbatementDateTime]
  );

  const handleStatusChange = useCallback(
    event => {
      event.stopPropagation();
      setProblemStatus(event?.target?.checked);
    },
    [setProblemStatus]
  );

  useEffect(() => {
    if (additionalData) {
      setAbatementDateTime(additionalData?.abatementDateTime);
      setProblemStatus(
        additionalData?.clinicalStatus?.coding?.[0]?.code === 'resolved'
          ? false
          : true
      );
    }
  }, [additionalData]);

  return (
    <>
      <Box
        display="flex"
        alignItems={'flex-end'}
        justifyContent="flex-end"
        width={1}
      >
        {additionalData && (
          <Box ml={1} display="flex" justifyContent="space-between">
            {!problemStatus && (
              <Box className={classes.dateFieldContainer}>
                <DatePicker
                  label={'Resolved date'}
                  name="abatementDateTime"
                  maxDate={maxDate}
                  data={abatementDateTime}
                  onChange={resolvedDateChange}
                  required={!problemStatus}
                  onValidate={() => {}}
                  disabled={false}
                />
              </Box>
            )}

            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={problemStatus}
                    onChange={event => {
                      handleStatusChange(event);
                    }}
                    name={'woundHealingTragectory'}
                    color="primary"
                    disabled={abatementDateTime !== null}
                  />
                }
                className={classes.switchFont}
                label={problemStatus ? 'Active' : 'Resolved'}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AdditionalFormField;
