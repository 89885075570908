import { hasUserRoles, UserRole } from 'user';
import {
  FORMULARY,
  PROCEDURES,
  ORDERS,
  ORDERSETS,
  TREATMENT,
  WOUND_TYPE,
  EDUCATIONAL_TRAINING,
  REVIEW_OF_SYSTEM,
  BARRIERS,
  MEDICAL_CONFIG,
  FORMS_CONFIG,
  ENCOUNTERS,
} from './config';
import { CLINICAL_CONFIG_PATH } from './constants';

export const ENTRY_PERMISSION = 'clinical-admin';
export const displayLink = (user, env, filterToggles) => {
  return hasUserRoles(user, [UserRole.clinicalAdmin]);
};

export const ENTRY_LINK = {
  path: CLINICAL_CONFIG_PATH,
  text: 'Clinical Config',
  icon: 'settings',
  permission: ENTRY_PERMISSION,
  displayLink: displayLink,
  children: [
    ORDERS,
    ORDERSETS,
    FORMULARY,
    PROCEDURES,
    TREATMENT,
    WOUND_TYPE,
    EDUCATIONAL_TRAINING,
    REVIEW_OF_SYSTEM,
    BARRIERS,
    MEDICAL_CONFIG,
    FORMS_CONFIG,
    ENCOUNTERS,
  ],
};
