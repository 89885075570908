import { getTelecomValue } from '../utils';
import { getSystemCode, groupBy } from '../utils';
import { textMaskFormat, INPUT_MASK_PHONE_NUMBER } from 'styleguide/form/Text';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { phoneNumber } from 'styleguide/form/Telecom/formatters';
import {
  INSTRUCTION_TYPE,
  MOBILE_CODE,
  ORGNAIZATION_SUB_TYPE,
  WORK_CODE,
  CONTACT_PERSON_STATUS,
  ORGNAIZATION_TYPE,
} from '../../constants';
import { fullAddressFormat } from 'fhir/general/Address';
import { addressCountyAndMarket } from 'fhir/general/Address/formatters';
import { HOME_HEALTH_CODE } from '../../constants';

export const getOrganizationDataAttributes = organization => {
  const careName = organization?.name;
  const careType = getSystemCode(organization?.type, ORGNAIZATION_SUB_TYPE)
    ?.coding?.[0]?.display;
  const isHomeHealth =
    getSystemCode(organization?.type, ORGNAIZATION_TYPE)?.coding?.[0]?.code ===
    HOME_HEALTH_CODE;
  const careStatus = organization?.active ? 'Active' : 'Inactive';
  return [
    {
      name: 'Facility Name',
      value: careName || PlaceholderKind.NotAvailable,
      columns: 2,
    },
    ...(!isHomeHealth
      ? [
          {
            name: 'Type',
            value: careType || PlaceholderKind.NotAvailable,
            columns: 2,
          },
        ]
      : []),
    {
      name: 'Status',
      value: careStatus || PlaceholderKind.NotAvailable,
      columns: 2,
    },
  ];
};

export const getAddressAttributes = (
  organization,
  showInstruction,
  showMarket
) => {
  const address = organization?.address?.[0];
  const instruction = address?.extension?.find(
    item => item?.url === INSTRUCTION_TYPE
  )?.valueString;
  return [
    {
      name: 'Address',
      value: fullAddressFormat(address) || PlaceholderKind.NotAvailable,
      columns: 2,
    },
    ...(showMarket
      ? [
          {
            name: 'Market',
            value: addressCountyAndMarket(address),
            columns: 2,
          },
        ]
      : []),
    ...(showInstruction
      ? [
          {
            name: 'Instructions',
            value: instruction || PlaceholderKind.NotAvailable,
            columns: 2,
          },
        ]
      : []),
  ];
};

export const getOrganizationTelecom = (
  organization,
  telecomData = undefined
) => {
  const telecom = telecomData || organization?.telecom;
  const telecomGrouped = groupBy(telecom, 'rank');
  return telecomGrouped?.map(item => {
    return [
      {
        name: 'Phone Number',
        value:
          phoneNumber(item?.telecom?.find(item => item?.system === 'phone')) ||
          PlaceholderKind.NotAvailable,
        columns: 2,
      },
      {
        name: 'Fax',
        value:
          textMaskFormat(
            getTelecomValue(item?.telecom, 'fax'),
            INPUT_MASK_PHONE_NUMBER
          ) || PlaceholderKind.NotAvailable,
      },
      {
        name: 'Email',
        value:
          getTelecomValue(item?.telecom, 'email') ||
          PlaceholderKind.NotAvailable,
      },
    ];
  });
};

export const getContactPersonAttributes = (
  contact,
  telecomData = undefined
) => {
  const telecom = telecomData || contact?.telecom;
  const phoneDetails = telecom?.filter(item => item.system === 'phone');
  const mobilePhone = phoneNumber(
    phoneDetails?.find(item => item?.use === MOBILE_CODE)
  );
  const workPhone = phoneNumber(
    phoneDetails?.find(item => item?.use === WORK_CODE)
  );

  return [
    {
      icon: 'phone',
      value: workPhone || PlaceholderKind.NotAvailable,
    },
    {
      icon: 'phone',
      value: mobilePhone || PlaceholderKind.NotAvailable,
    },
    {
      name: 'Fax',
      value:
        textMaskFormat(
          getTelecomValue(telecom, 'fax'),
          INPUT_MASK_PHONE_NUMBER
        ) || PlaceholderKind.NotAvailable,
    },
    {
      name: 'Email',
      value: getTelecomValue(telecom, 'email') || PlaceholderKind.NotAvailable,
    },
  ];
};

export const getContactPersonStatus = contact => {
  return contact?.extension?.find(item => item?.url === CONTACT_PERSON_STATUS)
    ?.valueBoolean;
};
