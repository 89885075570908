import React from 'react';
import { Palette } from '@woundtech/ui-colors';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ItemText from './ItemText';
import ItemIcon from './ItemIcon';
import Icon from '@material-ui/core/Icon';
import ResourceStatus from 'styleguide/display/ResourceStatus';

export type Attribute = {
  columns?: number;
  name?: string;
  icon?: string;
  value: string | string[];
  highligted?: boolean;
  noWrap?: boolean;
  svgIcon?: React.FC<any>;
  gridSpaces?:
    | boolean
    | 'auto'
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;
};

export type Props = {
  attributes: Attribute[];
  noWrap?: boolean;
  spacing?: 0 | 1;
  title?: string;
  subTitle?: string;
  subTitleIcon?: string;
  caption?: string;
  status?: string;
  statusBackgroundColor?: string;
  variant?: 'small' | 'large';
  showTwoColumnsOnMobile?: boolean;
  titleWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  statusIcon?: string;
};

const AttributeList: React.FC<Props> = ({
  attributes,
  noWrap = true,
  spacing = 1,
  title,
  caption = '',
  subTitle = '',
  subTitleIcon,
  status,
  statusBackgroundColor,
  variant = 'small',
  showTwoColumnsOnMobile = false,
  titleWrap = 'nowrap',
  statusIcon,
}) => {
  return (
    <Box width={1} data-attribute-list>
      <Grid container spacing={spacing} alignItems="center">
        {title && (
          <Grid item xs={12}>
            <Box display="flex" flexWrap={titleWrap}>
              <Box flexGrow={1}>
                <Box display="flex">
                  <Box>
                    <Typography display="inline" variant="subtitle1">
                      {title}
                    </Typography>
                    {caption !== '' && (
                      <Typography
                        display="inline"
                        variant="body2"
                        color="error"
                      >
                        {' '}
                        • {caption}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              {status && (
                <Box
                  pl={1}
                  ml="auto"
                  mb={titleWrap !== 'nowrap' ? '2px' : '0px'}
                >
                  <ResourceStatus
                    label={status}
                    color={statusBackgroundColor}
                  />
                </Box>
              )}
              {statusIcon && (
                <Box display="flex" color={statusBackgroundColor}>
                  <Icon color="inherit">{statusIcon}</Icon>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {subTitle !== '' && (
          <Grid item xs={12}>
            <Box
              display="flex"
              alignSelf="center"
              color={Palette.Gray.MediumDarker}
            >
              <Box pr={1} display="flex">
                <Icon fontSize="small" color="inherit">
                  {subTitleIcon}
                </Icon>
              </Box>
              <Typography variant="body2" noWrap={noWrap}>
                {subTitle}
              </Typography>
            </Box>
          </Grid>
        )}
        {attributes?.map(
          (
            {
              name,
              value,
              icon,
              highligted,
              columns = 1,
              noWrap: itemNoWrap = true,
              svgIcon,
            },
            index
          ) => (
            <React.Fragment key={name || index}>
              <Grid
                item
                xs={showTwoColumnsOnMobile ? 6 : 12}
                sm={columns === 2 ? 12 : 6}
                md={columns === 2 ? 12 : 6}
              >
                {name ? (
                  <ItemText
                    label={name}
                    value={value}
                    highligted={highligted}
                    noWrap={noWrap && itemNoWrap}
                    variant={variant}
                  />
                ) : (
                  <ItemIcon
                    icon={icon || ''}
                    svgIcon={svgIcon}
                    value={value.toString()}
                    noWrap={noWrap && itemNoWrap}
                  />
                )}
              </Grid>
            </React.Fragment>
          )
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(AttributeList);
