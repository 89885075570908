import React from 'react';
import Box from '@material-ui/core/Box';
import FileListItem from './FileListItem';

export type Props = {
  dispatch: any;
  items: any[];
  selectOptions?: any;
  onSelectChange?: any;
  selectOptionLabel?: string;
  onItemAdded?: any;
  onItemRemoved?: any;
};

const FileList: React.FC<Props> = ({
  dispatch,
  items,
  selectOptions = [],
  onSelectChange = null,
  selectOptionLabel,
  onItemAdded = null,
  onItemRemoved = null,
}) => {
  return (
    <>
      {items.map((item: any, index: number) => (
        <Box mt={2} key={item?.attachment?.id}>
          <FileListItem
            dispatch={dispatch}
            item={item}
            selectOptions={selectOptions}
            selectOptionName={item?.attachment?.id}
            selectOptionLabel={selectOptionLabel}
            onSelectChange={onSelectChange}
            onItemAdded={onItemAdded}
            onItemRemoved={onItemRemoved}
          />
        </Box>
      ))}
    </>
  );
};

export default React.memo(FileList);
