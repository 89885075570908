export const SERVICE_ADDRESS_URL = 'SERVICE_ADDRESS';
export const VERIFY_ADDRESS_URL = 'VERIFY_ADDRESS';
export const LOCATION_NAME = 'LOCATION_NAME';
export const LOCATION_ID = 'LOCATION_ID';
export const ADDRESS_MARKET_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/address/market';

export const FIRST_MAX_FETCH = 1000;
export const marketType = {
  add_market: 'additional-market',
  market: 'market',
};

export const ADDRESS_WITH_FACILITY_TYPE = [
  {
    coding: [
      {
        code: 'home',
        display: 'Home',
      },
    ],
    text: 'Home',
  },
  {
    coding: [
      {
        code: 'facility',
        display: 'Facility',
      },
    ],
    text: 'Facility',
  },
];

export const ADDRESS_WITH_OUT_FACILITY_TYPE = [
  {
    coding: [
      {
        code: 'home',
        display: 'Home',
      },
    ],
    text: 'Home',
  },
];
export const DEFAULT_ADDRESS_EXTENSION = [
  {
    url: SERVICE_ADDRESS_URL,
    valueBoolean: false,
  },
  {
    url: LOCATION_NAME,
    valueString: '',
  },
  {
    url: LOCATION_ID,
    valueString: 'LOCATION_0',
  },
];
