export const allActiveStatuses = ['planned', 'active', 'onhold'];
export const CARE_MONITOR_TYPE = 'cmon';
export const JOB_FUNC_VALUESET_URL =
  'http://woundtech.net/fhir/ValueSet/job-function';
export const VALUE_SET_ADMISSION_STATUS_URL =
  'http://hl7.org/fhir/ValueSet/episode-of-care-status';
export const CODE_SYSTEM_CANCELLATION_REASON =
  'http://woundtech.net/fhir/CodeSystem/admission-cancellation-reason';

export const ADMISSION_STATUS_ACTIVE = 'active';
export const ADMISSION_STATUS_CANCELLED = 'cancelled';
export const ADMISSION_STATUS_ERROR = 'entered-in-error';
export const ADMISSION_STATUS_FINISHED = 'finished';
export const ADMISSION_STATUS_PLANNED = 'planned';
export const ADMISSION_CANCELLATION_REASON_ERROR = 'createdInError';

export const VALUE_SET_PLACE_OF_SERVICE =
  'http://hl7.org/fhir/ValueSet/service-place';
export const VALUE_SET_DISCHARGE_REASONS =
  'http://woundtech.net/fhir/CodeSystem/discharge-reason';
export const VALUE_SET_ONHOLD_REASONS =
  'http://woundtech.net/fhir/CodeSystem/onhold-reason';
export const VALUE_SET_PLANNED_ONHOLD_REASONS =
  'http://woundtech.net/fhir/CodeSystem/planned-onhold-reason';
export const VALUE_SET_ADMISSION_DELIVERY_TYPE =
  'http://woundtech.net/fhir/ValueSet/admission-delivery-type';

export const ADMISSION_ONHOLD_REASON_URL = 'ADMISSION_ONHOLD_REASON_URL';
export const ADMISSION_PLANNED_ONHOLD_REASON_SYSTEM =
  'http://woundtech.net/fhir/admission-planned-onhold-reason';
export const ADMISSION_ACTIVE_ONHOLD_REASON_SYSTEM =
  'http://woundtech.net/fhir/admission-active-onhold-reason';

export enum AdmissionStatus {
  planned = 'planned',
  active = 'active',
  canceled = 'cancelled',
  dischargeOrdered = 'discharge-ordered',
  discharged = 'finished',
  onholdHospitalized = 'onhold-hospitalized',
  onholdPlanned = 'waitlist',
  onholdActive = 'onhold',
  plannedScheduled = 'planned-scheduled',
}

export enum AdmissionWorkflowType {
  INFO = 'info',
  DISCHARGE = 'discharge',
}

export enum AdmissionDeliveryType {
  inPerson = 'in-person',
  telemedicine = 'telehealth',
}

export const ADMISSION_DELIVERY_TYPE_IN_PERSON = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/delivery-type',
      code: 'in-person',
      display: 'In person',
    },
  ],
  text: 'In person',
};

export const ADMISSION_DELIVERY_TYPE_TELEHEALTH = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/delivery-type',
      code: 'telehealth',
      display: 'Telehealth',
    },
  ],
  text: 'Telemedicine',
};

export const medHistoryQuestionnaire = [
  'med-history-condition',
  'med-history-allergy',
  'med-history-medication',
  'med-history-surgical',
  'med-history-social',
  'med-history-family',
];

export const SESSION_PERPAGE = 6;

export const NO_RESULT = 'No Session data found for requested';

export const REMOVE_HOLD = 'Remove hold';
export const RESUME = 'Resume';
export const RESUME_ADMISSION = 'Resume Admission';

export enum AdmissionOnHoldReason {
  'not-eligible' = 'Eligibility',
  'awaiting-authorization-financial' = 'Authorization',
  'hospitalized-wound-related' = 'Hospitalized',
  'hospitalized-non-wound-related' = 'Hospitalized',
  'hospitalized-reason-unknown' = 'Hospitalized',
  'patient-unavailable' = 'Unavailable',
  'lack-of-clinician-capacity' = 'Capacity',
  'pending-disposition' = 'Pending',
  'other' = 'Other',
}

export const NO_HEALTH_HOME_FOUND = 'No home health assigned for this patient';

export const PLANNED_SCHEDULED_VALUE_STRING = 'planned scheduled';
export const PLANNED_SCHEDULED_KEY = 'plannedScheduled';
export const RENDERING_CLINICIAN = 'Rendering Clinician';
export const ASSIGNED_PROVIDER = 'Assigned Provider';
export const READMIT_REASON_OTHER_CODE = 'other';
export const READMIT_REASON_EXTENSION_URL = 'READMIT_REASON';
export const READMIT_REASON_OTHER_EXTENSION_URL = 'READMIT_REASON_OTHER_TEXT';
export const VALUE_SET_READMIT_REASON =
  'http://woundtech.net/fhir/ValueSet/admission-readmit-reason';
