import { User } from 'user';

export type Action =
  | {
      type: 'authorizationCompleted';
      user: User;
      organization: fhir.Organization;
    }
  | {
      type: 'authorizationFailed';
      error: string;
    };

export type State = {
  completed: boolean;
  error: string | null;
  organization: fhir.Organization | null;
  user: User | null;
};

export const defaultState: State = {
  completed: false,
  error: null,
  organization: null,
  user: null,
};

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'authorizationCompleted': {
      return {
        ...state,
        completed: true,
        error: null,
        user: action.user,
        organization: action.organization,
      };
    }
    case 'authorizationFailed': {
      return {
        ...state,
        completed: false,
        error: action.error,
        user: null,
        organization: null,
      };
    }
    default:
      return state;
  }
}
