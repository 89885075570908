import React from 'react';
import TextField, {
  INPUT_MASK_PHONE_NUMBER,
  VALIDATION_PATTERN_PHONE_NUMBER,
} from 'styleguide/form/Text';

import { Field } from '../types';

export interface Props extends Field {
  disabled?: boolean;
  onChange: (value: fhir.ContactPoint | undefined) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  value: fhir.ContactPoint | undefined;
}

const Fax: React.FC<Props> = ({
  value: data,
  disabled,
  onChange,
  onValidate,
  required,
  name = 'fax',
}) => {
  const handleChange = (value: string) => {
    onChange({
      ...data,
      value,
    });
  };

  const value = data?.value?.replace(/[^0-9]+/gi, '') || '';

  return (
    <TextField
      disabled={disabled}
      endIcon="print"
      label="Fax"
      mask={INPUT_MASK_PHONE_NUMBER}
      maxLength={INPUT_MASK_PHONE_NUMBER.length}
      name={name}
      onChange={handleChange}
      onValidate={onValidate}
      required={required}
      validationPattern={VALIDATION_PATTERN_PHONE_NUMBER}
      value={value}
      isEndIconDisabled
    />
  );
};

export default React.memo(Fax);
