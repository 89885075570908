import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export type Props = {
  count: number;
  total: number;
};

const DataListSummary: React.FC<Props> = ({ count, total }) => {
  return (
    <Box>
      <Divider />
      <Box mt={1}>
        <Typography align="center" noWrap variant="body2">
          Displayed {count} of {total} results
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(DataListSummary);
