import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddressField from 'fhir/general/Address';
import TextField from 'styleguide/form/Text';
import FieldSet from 'styleguide/layout/FieldSet';
import ValueSet from 'styleguide/form/ValueSet';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
  CARE_FACILITY_SUB_TYPE,
  CONTACT_PERSON_STATUS,
  CREATE_FACILITY_TEXT,
  ORGNAIZATION_SUB_TYPE,
} from 'master-list/constants';
import MultipleContactInfo from './MultipleContactInfo';
import MultipleContactPerson from './MultipleContactPerson';
import { CONTACT_PERSON_PRIMARY } from 'master-list/constants';
import { isContactPrimary } from '../utils';
import { ICareFacility } from '../../care-facility';

type Props = {
  data: ICareFacility;
  onChange: (data) => void;
  onValidate: (isValid: boolean) => void;
  formFields: any;
  entities?: fhir.Organization[];
};

const Form: React.FC<Props> = ({
  data,
  onChange,
  onValidate,
  formFields,
  entities,
}) => {
  const [validity, setValidity] = useState<any>({});
  const { organization } = useMemo(
    () => ({ organization: data?.organization }),
    [data]
  );
  const type = organization?.type?.find(
    type => type?.coding?.[0]?.system === ORGNAIZATION_SUB_TYPE
  );

  const handleAddressChange = useCallback(
    (address: fhir.Address | undefined) => {
      onChange({
        ...(data || {}),
        organization: {
          ...organization,
          address: address ? [address] : undefined,
        },
      });
    },
    [data, onChange, organization]
  );

  const handleNameChange = useCallback(
    (name: string | undefined) => {
      onChange({
        ...(data || {}),
        organization: {
          ...organization,
          name,
        },
      });
    },
    [data, onChange, organization]
  );

  const handleTypeChange = useCallback(
    (type: fhir.CodeableConcept) => {
      const filteredType =
        organization?.type?.filter(
          type => type?.coding?.[0]?.system !== ORGNAIZATION_SUB_TYPE
        ) || [];
      onChange({
        ...(data || {}),
        organization: {
          ...organization,
          type: [...filteredType, type],
        },
      });
    },
    [data, onChange, organization]
  );

  const handleStatusChange = useCallback(
    (value: boolean) => {
      onChange({
        ...(data || {}),
        organization: {
          ...organization,
          active: value,
        },
      });
    },
    [data, onChange, organization]
  );

  const handleContactInfoChange = useCallback(
    contacts => {
      onChange({
        ...(data || {}),
        organization: {
          ...organization,
          telecom: contacts,
        },
      });
    },
    [data, onChange, organization]
  );

  const handleContactPersonChange = (selectedIndex?: any | undefined) => (
    value: any
  ) => {
    if (selectedIndex === undefined) {
      let lastItem = value[value?.length - 1];
      if (lastItem && lastItem?.extension === undefined) {
        lastItem = {
          ...lastItem,
          extension: [
            { url: CONTACT_PERSON_STATUS, valueBoolean: true },
            {
              url: CONTACT_PERSON_PRIMARY,
              valueBoolean: value?.length === 1 ? true : false,
            },
          ],
        };
      }
      const updatedContacts = lastItem
        ? [...value?.slice(0, -1), lastItem]
        : value;
      onChange({
        ...(data || {}),
        practitioners: updatedContacts,
      });
    } else {
      let items = data?.practitioners || [];
      const isSelectedPrimary = isContactPrimary(value);
      if (isSelectedPrimary) {
        const updatedContact = items?.map(item => {
          const extension =
            item?.extension?.filter(
              item => item?.url !== CONTACT_PERSON_PRIMARY
            ) || [];
          return {
            ...item,
            extension: [
              ...extension,
              { url: CONTACT_PERSON_PRIMARY, valueBoolean: false },
            ],
          };
        });
        items = updatedContact.map((item, itemIndex) =>
          itemIndex === selectedIndex ? value : item
        );
      } else {
        items = items.map((item, itemIndex) =>
          itemIndex === selectedIndex ? value : item
        );
      }
      onChange({
        ...(data || {}),
        practitioners: items,
      });
    }
  };

  const handleValidityChange = useCallback(
    (key: string) => (isValid: boolean | undefined) => {
      if (validity[key] !== isValid) {
        setValidity({
          ...validity,
          [key]: isValid,
        });
      }
    },
    [validity, setValidity]
  );

  useEffect(() => {
    const isValid = Object.keys(validity).every((key: string) => validity[key]);
    onValidate(isValid);
  }, [validity, onValidate]);

  return (
    <>
      {formFields?.nameLabel && (
        <FieldSet
          label={formFields?.nameLabel}
          headerContent={
            <Box display="flex" justifyContent="flex-end" width={1}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={organization?.active}
                    onChange={event =>
                      handleStatusChange(event?.target?.checked)
                    }
                    color="primary"
                    value={organization?.active}
                  />
                }
                label="Active"
                labelPlacement="end"
              />
            </Box>
          }
          required
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="display"
                onChange={handleNameChange}
                onValidate={handleValidityChange('facilityName')}
                required
                label={'Name'}
                value={organization?.name}
              />
            </Grid>
          </Grid>
        </FieldSet>
      )}
      <Box mt={2} />
      {formFields?.typeLabel && (
        <FieldSet label={formFields?.typeLabel} required>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ValueSet
                name="type"
                value={type?.coding?.[0]?.code}
                onChange={handleTypeChange}
                onValidate={handleValidityChange('type')}
                getOptionWidth={(option, id, label, isMobile) =>
                  isMobile ? 12 : 6
                }
                variant="radio-button-group"
                required
                url={CARE_FACILITY_SUB_TYPE}
              />
            </Grid>
          </Grid>
        </FieldSet>
      )}
      <Box mt={2} />
      {formFields?.addressLabel && (
        <FieldSet label={formFields?.addressLabel}>
          <AddressField
            data={organization?.address?.[0]}
            onChange={handleAddressChange}
            onValidate={handleValidityChange('facilityAddress')}
            isStateRequired
            entities={
              formFields?.viewHeaderTitle === CREATE_FACILITY_TEXT
                ? entities
                : undefined
            }
            showInstruction={formFields?.showAddressInstruction}
            showCounty={true}
            showMarket={true}
            careFacility={
              formFields?.viewHeaderTitle === CREATE_FACILITY_TEXT
                ? true
                : false
            }
          />
        </FieldSet>
      )}
      <Box mt={2} />
      {formFields?.contactInfoLabel && (
        <MultipleContactInfo
          data={organization?.telecom}
          onChange={handleContactInfoChange}
          onValidate={handleValidityChange('contactInfo')}
          title={formFields?.contactInfoLabel}
        />
      )}
      <Box mt={2} />
      {formFields?.contactPersonLabel && (
        <MultipleContactPerson
          data={data?.practitioners}
          onChange={handleContactPersonChange}
          onValidate={handleValidityChange('contactPerson')}
          title={formFields?.contactPersonLabel}
        />
      )}
    </>
  );
};

export default Form;
