import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

const AccordionCard: React.FC<any> = ({ data, dataProps }) => {
  const history = useHistory();

  const description = useMemo(() => {
    return data?.event?.description;
  }, [data]);

  const patient = useMemo(() => {
    return data?.meta?.patientName;
  }, [data]);

  const navigateToSection = useCallback(() => {
    dataProps?.accordionClick(data);
  }, [data, dataProps]);

  const handlePatientClick = useCallback(
    e => {
      e.stopPropagation();
      const redirect = `patients/${data.meta.patientId}`;
      if (redirect !== '') {
        history.push(`/${redirect}`);
        dataProps.onClose();
      }
    },
    [history, data, dataProps]
  );

  return (
    <Box
      display={'flex'}
      flexGrow={1}
      onClick={navigateToSection}
      style={{ cursor: 'pointer' }}
    >
      <Box width={1} alignSelf="flex-start">
        <Box display="flex">
          <Typography variant="body1" color="textSecondary">
            Patient:
          </Typography>
          <Box ml={0.5} onClick={handlePatientClick}>
            <Typography variant="body1" color="primary">
              {patient}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width={1} alignSelf="flex-end">
        <Box display="flex">
          <Typography variant="body1" color="textSecondary">
            Event:
          </Typography>
          <Box ml={0.5}>
            <Typography variant="body1" color="primary">
              {description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(AccordionCard);
