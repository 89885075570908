import { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PrivateLayoutContext } from './Context';

export function useNavigation() {
  const { breadcrumbs, currentBreadcrumbId } = useContext(PrivateLayoutContext);
  const history = useHistory();

  const navigateTo = useCallback(
    (url: string, replace: boolean = false) => {
      const targetIndex = breadcrumbs.map(item => item.url).lastIndexOf(url);
      const currentIndex = breadcrumbs.findIndex(
        item => item.id === currentBreadcrumbId
      );

      if (targetIndex !== -1 && currentIndex !== -1) {
        const stepsBack = currentIndex - targetIndex;
        if (stepsBack > 0) {
          history.go(-stepsBack);
        } else {
          replace ? history.replace(url) : history.push(url);
        }
      } else {
        replace ? history.replace(url) : history.push(url);
      }
    },
    [history, breadcrumbs, currentBreadcrumbId]
  );

  return {
    navigateTo,
  };
}

export function useCurrentBreadcrum() {
  const { breadcrumbs } = useContext(PrivateLayoutContext);
  const currentBreadcrumName = useCallback(
    (url: string) => {
      const targetIndex = breadcrumbs.map(item => item.url).lastIndexOf(url);
      const currentBredcrum = breadcrumbs[targetIndex];
      return currentBredcrum?.name;
    },
    [breadcrumbs]
  );
  return {
    currentBreadcrumName,
  };
}
