import React, { useContext, useMemo } from 'react';
import ResourceStatus from 'styleguide/display/ResourceStatus';
import {
  getEnvironmentColor,
  getEnvironmentLabel,
  getEnvironmentTextColor,
} from './utils';
import { Context as AppConfigContext } from 'application/Configuration';

const EnvironmentChip: React.FC = () => {
  const { DeploymentEnvironment } = useContext(AppConfigContext);

  const color = useMemo(() => getEnvironmentColor(DeploymentEnvironment), [
    DeploymentEnvironment,
  ]);
  const label = useMemo(() => getEnvironmentLabel(DeploymentEnvironment), [
    DeploymentEnvironment,
  ]);
  const textColor = useMemo(
    () => getEnvironmentTextColor(DeploymentEnvironment),
    [DeploymentEnvironment]
  );
  return label ? (
    <ResourceStatus label={label || ''} color={color} textColor={textColor} />
  ) : null;
};

export default EnvironmentChip;
