import { isTelemedicineClinician } from 'user';
import { ENTRY_LINK as WAITING_ROOM_ENTRY_LINK } from './waiting-room';
import { ENTRY_LINK as MY_ENCOUNTERS_ENTRY_LINK } from './encounters';

export const TELEMEDICINE_EXTENSION_URL = 'TELEMEDICINE';
export const TELEMEDICINE_REQUESTED_AT_URL = 'REQUESTED_AT';
export const TELEMEDICINE_CHECKED_IN_AT_URL = 'CHECKED_IN_AT';
export const TELEMEDICINE_STATUS_URL = 'STATUS';
export const TELEMEDICINE_CHECKED_IN_BY_URL = 'CHECKED_IN_BY';

export const displayLink = (user, env) => {
  return isTelemedicineClinician(user);
};

export const ENTRY_PERMISSION = 'patient';

export const ENTRY_LINK = {
  path: '/telemedicine',
  text: 'Telehealth',
  icon: 'watch_later',
  permission: ENTRY_PERMISSION,
  displayLink: displayLink,
  children: [WAITING_ROOM_ENTRY_LINK, MY_ENCOUNTERS_ENTRY_LINK],
};
