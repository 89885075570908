import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export type Props = {
  code: string;
  message: string;
  inline?: boolean;
};

const HttpError: React.FC<Props> = ({ code, message, inline }) => {
  return (
    <Box
      p={1}
      height={inline ? 1 : '100vh'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      width={1}
    >
      <Typography
        variant="h1"
        component="h1"
        align="center"
        color="textSecondary"
      >
        {code}
      </Typography>

      <Typography
        variant="h2"
        component="h2"
        align="center"
        color="textSecondary"
      >
        {message}
      </Typography>
    </Box>
  );
};

export default HttpError;
