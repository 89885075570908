import React from 'react';
import { ApolloError } from '@apollo/client';
import Box from '@material-ui/core/Box';
import { PlaceholderKind } from '@woundtech/ui-constants';
import Loading from 'styleguide/Loading';
import Error from 'styleguide/Error';
import Empty from 'styleguide/Empty';
import { Props as EmptyProps } from 'styleguide/Empty/Empty';

export type Props = {
  children?: React.ReactNode;
  data?: any;
  error?: ApolloError;
  loading?: boolean;
  empty?: EmptyProps;
};

const defaultEmpty = {
  text: PlaceholderKind.NoResults,
  icon: 'find_in_page',
  iconAction: 'add',
};

const AsynchronousLayout: React.FC<Props> = ({
  children,
  data,
  error,
  loading,
  empty = defaultEmpty,
}) => {

  if(loading){
    return <Loading variant='component' />
  }

  if (error) {
    return <Error text={error?.message} />;
  }
  const isEmpty = !data || (Array.isArray(data) && data.length < 1);
  if (isEmpty) {
    return <Empty {...empty} />;
  }

  return (
    <Box position="relative" height={1} width={1}>
      {children}
    </Box>
  );
};

export default React.memo(AsynchronousLayout);
