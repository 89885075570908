export const ENTRY_PERMISSION = 'patient';

export const ENTRY_LINK = {
  path: '/patients',
  text: 'Patients',
  icon: 'assignment_ind',
  module: 'patient',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export function getPatientMenuUrl(
  patientId: string,
  isTablet: boolean
): string {
  return isTablet
    ? `/patients/${patientId}`
    : `/patients/${patientId}/patient-menu`;
}
