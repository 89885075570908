import { ENTRY_LINK as CBR_DASHBOARD_ENTRY_LINK } from 'cbr-dashboard';
import { hasUserRoles, isClinician } from 'user';

const displayLink = (user, __, featureToggles) => {
  return featureToggles?.EnableCBRReport === 'true'
    ? hasUserRoles(user, ['cbrbeta']) || isClinician(user)
    : hasUserRoles(user, ['cbrbeta']);
};

export const ENTRY_LINK = {
  path: '/report-dashboard',
  text: 'Reports',
  icon: 'analytics',
  permission: 'create',
  children: [CBR_DASHBOARD_ENTRY_LINK],
  displayLink: displayLink,
};
