import {
  ADDRESS_MARKET_EXTENSION_URL,
  SERVICE_ADDRESS_URL,
  VERIFY_ADDRESS_URL,
} from '../constants';

export function setAddressMarket(
  address: fhir.Address,
  market: fhir.Location | undefined
): fhir.Address {
  let extension = address?.extension?.filter(
    item => item.url !== ADDRESS_MARKET_EXTENSION_URL
  );

  if (market) {
    extension = [
      ...(extension || []),
      {
        url: ADDRESS_MARKET_EXTENSION_URL,
        valueReference: {
          display: market.name,
          reference: `${market.resourceType}/${market.id}`,
        },
      },
    ];
  }

  return {
    ...address,
    extension,
  };
}

export function getAddressMarketId(
  address: fhir.Address | undefined
): string | undefined {
  return address?.extension
    ?.find(item => item.url === ADDRESS_MARKET_EXTENSION_URL)
    ?.valueReference?.reference?.split('/')?.[1];
}

export function getAddressMarketName(
  address: fhir.Address | undefined
): string | undefined {
  return address?.extension
    ?.find(item => item.url === ADDRESS_MARKET_EXTENSION_URL)
    ?.valueReference?.display?.toUpperCase();
}

export function getIsServiceAddress(address: fhir.Address | undefined) {
  return address?.extension?.find(item => item.url === SERVICE_ADDRESS_URL)
    ?.valueBoolean;
}

export function getIsVerifiedAddress(address: fhir.Address | undefined) {
  return address?.extension?.find(item => item.url === VERIFY_ADDRESS_URL)
    ?.valueBoolean;
}
