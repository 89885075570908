import { MASTER_LIST_PATH } from '../constants';

export const ENTRY_PERMISSION = 'settings';

export const MARKET_CONFIG = {
  path: `${MASTER_LIST_PATH}/market-team-config`,
  text: 'Market Team',
  module: 'market-team-config',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
