import React from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { PrimaryNavigationProps } from './Navigation';
import NavigationItem from './NavigationItem';

const NavigationList: React.FC<PrimaryNavigationProps> = ({
  items,
  toggleOpen,
}) => {
  return (
    <Box>
      <List>
        {items.map(item => (
          <NavigationItem item={item} key={item.path} toggleOpen={toggleOpen} />
        ))}
      </List>
    </Box>
  );
};

export default React.memo(NavigationList);
