import { Patient } from '../types';
import { PATIENT_TIMEZONE_EXTENSION, SERVICE_ADDRESS_URL } from '../constants';
import { DICHARGE_HOME_HEALTH_CODE } from 'discharge';
import {
  getAddressMarketName,
  getIsServiceAddress,
} from 'fhir/general/Address/helperts';
import { differenceInYears } from 'date-fns';

export function getPatientSubStatus(
  patient: Patient | undefined
): string | undefined {
  if (!patient) {
    return;
  }

  const status = patient.extension?.find(
    item => item.url === 'PATIENT_SUB_STATUS'
  )?.valueString;

  if (status) {
    return status;
  }

  return patient?.admission?.status;
}

export function setPatientPreferredName(
  patient: Patient | undefined,
  value: string | undefined
): Patient | undefined {
  if (!patient) {
    return patient;
  }

  const name = patient?.name?.filter(name => name?.use !== 'nickname') || [];
  if ((!Array.isArray(name) || name.length < 1) && !value) {
    return patient;
  }

  if (value) {
    name.push({
      use: 'nickname',
      text: value,
    });
  }

  return {
    ...patient,
    name,
  };
}

export function getPatientPreferredName(
  patient: Patient | undefined
): string | undefined {
  return patient?.name?.find(name => name.use === 'nickname')?.text;
}

export function getPatientMarketName(patient) {
  if (!patient) {
    return;
  }

  const marketAddress = patient?.address?.find(address =>
    getIsServiceAddress(address)
  );

  return getAddressMarketName(marketAddress);
}

export function getPatientPayerName(patient) {
  if (!patient) {
    return;
  }

  return patient?.payer?.name;
}

export function getPatientCaregiverOrganization(patient) {
  if (!patient) {
    return;
  }
  return patient?.caregiver?.[0]?.organization;
}

export function getPatientCaregiver(patient) {
  if (!patient) {
    return;
  }
  return patient?.caregiver?.[0];
}

export function getPatientPCP(patient) {
  if (!patient) {
    return;
  }
  return patient?.pcp?.[0];
}

export function getPatientPCPPractitioner(patient) {
  if (!patient) {
    return;
  }
  return patient?.pcp?.[0]?.resource?.practitioner;
}

export function getPatientHomeHealth(patient) {
  if (!patient) {
    return;
  }
  return patient?.caregiver?.filter(item => {
    return item?.organization?.resource?.type?.find(
      type => type?.coding?.[0]?.code === DICHARGE_HOME_HEALTH_CODE
    );
  });
}

export function getRecentCoverage(coverages) {
  const coverageList = (coverages?.CoverageList || [])
    .slice()
    .sort((a, b) => +a.priority - +b.priority);
  return coverageList?.[0];
}

export function getPatientAddress(patient: Patient | undefined) {
  if (!patient) {
    return;
  }

  return patient.address?.find(address => {
    return address?.extension?.find(
      item => item.valueBoolean && item.url === SERVICE_ADDRESS_URL
    );
  });
}

export function getPatientTimeZone(patient: Patient | undefined) {
  return getPatientAddress(patient)?.extension?.find(
    item => item.url === PATIENT_TIMEZONE_EXTENSION
  )?.valueString;
}
export function getPatientAge(patient): number | undefined {
  return patient?.birthDate
    ? differenceInYears(new Date(), new Date(patient?.birthDate))
    : undefined;
}
