import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Grid, useMediaQuery } from '@material-ui/core';
import Form from 'styleguide/layout/Form';
import FieldSet from 'styleguide/layout/FieldSet';
import TextField, { INPUT_PATTERN_PASSWORD } from 'styleguide/form/Text';
import StrengthMeter from './StrengthMeter';
import {
  VALIDATION_ERROR_REQUIRED,
  VALIDATION_ERROR_VERIFY_PIN,
} from 'utility/constants';
import { AuthorizationQuery } from 'authorization/Schema';
import { Context as AuthenticationContext } from 'authentication/Context';
import { validatePIN } from '@woundtech/ui-utils';
import {
  ValidatePINOptions,
  ValidatePINResult,
} from '@woundtech/ui-utils/lib/validatePIN';

import { detectDevice } from 'utility/helpers';
import Numpad from 'styleguide/form/NumPad';
import VerifyPin from './VerifyPin';

import {
  UpsertUserPinMutation,
  validatePINVerificationCodeMutation,
  sendPINVerificationCodeMutation,
} from '../Schema';

import {
  PIN_MAX_LENGTH,
  PIN_MAX_REPEATED_CHARACTERS,
  PIN_MAX_SEQUENCED_CHARACTERS,
  PIN_MIN_LENGTH,
  PIN_VALIDATION_MSG_LENGTH,
  PIN_VALIDATION_MSG_NOT_MATCH,
  PIN_VALIDATION_MSG_NUMBER,
  PIN_VALIDATION_MSG_REPEATED_CHARACTERS,
  PIN_VALIDATION_MSG_SEQUENCED_CHARACTERS,
  PIN_INPUT_LABEL_NEW,
  PIN_INPUT_LABEL_CONFIRM,
  PIN_INPUT_VERIFICATION_CODE,
} from './constants';
import { PINStrengthLevel } from '@woundtech/ui-constants';

export type Props = {
  editPin?: boolean;
  handleCompleted?: () => void;
};

type ValidateResult = ValidatePINResult & {
  validations: { confirmedMatches: boolean };
};

const getPinStrength = (
  pin: string,
  validationStrength: PINStrengthLevel
): PINStrengthLevel => {
  if (pin?.length < 4) {
    return 0;
  } else if (pin?.length >= 4 && pin?.length < 6) {
    return 1;
  } else {
    return validationStrength;
  }
};

function validate(
  newPin: string,
  confirmedPin: string,
  previousPin?: string
): ValidateResult {
  const validationOptions: ValidatePINOptions = {
    rules: {
      minimumLength: PIN_MIN_LENGTH,
      maximumLength: PIN_MAX_LENGTH,
    },
    maxRepeatedCharacters: PIN_MAX_REPEATED_CHARACTERS,
    maxSequencedCharacters: PIN_MAX_SEQUENCED_CHARACTERS,
  };
  const validationResult = validatePIN(newPin, validationOptions, previousPin);
  const strength = getPinStrength(newPin, validationResult?.strength);
  const confirmedMatches = newPin === confirmedPin;
  return {
    ...validationResult,
    isValid: validationResult.isValid && confirmedMatches,
    validations: {
      ...validationResult.validations,
      confirmedMatches,
    },
    strength,
  };
}

function mapErrors(
  newPin: string,
  confirmedPin: string,
  { validations }: ValidateResult,
  verificationCode?: string,
  verifyDisabled?: boolean,
  isInternal?: boolean
) {
  const errors: {
    newPin?: string;
    confirmedPin?: string;
    verificationCode?: string;
  } = {};
  if (!validations.sequentialCharacters)
    errors.newPin = PIN_VALIDATION_MSG_SEQUENCED_CHARACTERS;
  if (!validations.repeatedCharacters) {
    errors.newPin = PIN_VALIDATION_MSG_REPEATED_CHARACTERS;
  }
  if (!validations.rules.length) errors.newPin = PIN_VALIDATION_MSG_LENGTH;
  if (!validations.numericOnly) errors.newPin = PIN_VALIDATION_MSG_NUMBER;
  if (!validations.confirmedMatches)
    errors.confirmedPin = PIN_VALIDATION_MSG_NOT_MATCH;
  if (!newPin) errors.newPin = VALIDATION_ERROR_REQUIRED;
  if (!confirmedPin) errors.confirmedPin = VALIDATION_ERROR_REQUIRED;
  if (isInternal) {
    if (!verificationCode) errors.verificationCode = VALIDATION_ERROR_REQUIRED;
    if (!verifyDisabled) errors.verificationCode = VALIDATION_ERROR_VERIFY_PIN;
  }
  return errors;
}

const Pin: React.FC<Props> = ({ editPin, handleCompleted }) => {
  const history = useHistory();

  const [newPin, setNewPin] = useState<string>('');
  const [confirmedPin, setConfirmedPin] = useState<string>('');
  const [showNew, setShowNew] = useState<boolean>(false);
  const [showConfirmNew, setShowConfirmNew] = useState<boolean>(false);

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifyDisabled, setVerifyDisabled] = useState<boolean>(false);

  const [numpadConfirm, setNumpadConfirm] = useState<string>('');

  const [maxLengthChar, setMaxLengthChar] = useState<number>(10);

  const [numpadConfig, setNumpadConfig] = useState({
    opened: false,
    initialValue: '',
    label: PIN_INPUT_LABEL_NEW,
  });

  const { storePin, isInternal } = useContext(AuthenticationContext);

  const [
    validatePINVerificationCode,
    {
      loading: validatePINVerificationLoading,
      data: validatePINVerificationData,
    },
  ] = useMutation(validatePINVerificationCodeMutation, {
    onCompleted: async data => {
      if (data?.validatePINVerificationCode?.isError) {
        setVerifyDisabled(false);
      } else if (data?.validatePINVerificationCode?.isValidCode) {
        setVerifyDisabled(true);
      } else setVerifyDisabled(false);
    },
  });
  const [
    sendPINVerificationCode,
    { loading: sendPINVerificationLoading, data: sendPINVerificationCodeData },
  ] = useMutation(sendPINVerificationCodeMutation);

  const [
    createUserPin,
    { loading: createPinLoading, error: createPinError },
  ] = useMutation(UpsertUserPinMutation, {
    onError: () => {},
    refetchQueries: [
      {
        query: AuthorizationQuery,
      },
    ],
    onCompleted: data => {
      storePin(data?.upsertUserPin?.userPin);
    },
  });

  useEffect(() => {
    sendPINVerificationCode();
  }, [sendPINVerificationCode]);

  const handleSubmit = useCallback(async () => {
    await createUserPin({
      variables: {
        userPin: newPin,
      },
    });
  }, [createUserPin, newPin]);

  const handleEditComplete = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleEditCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const onValidate = useCallback(() => {}, []);

  const resendVerificationCode = e => {
    sendPINVerificationCode();
    setVerifyDisabled(false);
  };

  const verifyVerificationCode = verificationCode => {
    validatePINVerificationCode({
      variables: { verificationCode },
    });
  };

  const toggleNew = useCallback(
    e => {
      e.stopPropagation();
      setShowNew(!showNew);
    },
    [setShowNew, showNew]
  );

  const toggleConfirmNew = useCallback(
    e => {
      e.stopPropagation();
      setShowConfirmNew(!showConfirmNew);
    },
    [setShowConfirmNew, showConfirmNew]
  );

  const checkMaxLength = (
    value: string,
    setPin: React.Dispatch<React.SetStateAction<string>>,
    VCode?: string
  ) => {
    const newValue = value.slice(0, VCode ? PIN_MIN_LENGTH : PIN_MAX_LENGTH);
    setPin(newValue);
  };

  const validationResult = validate(newPin, confirmedPin);
  const validationErrors = mapErrors(
    newPin,
    confirmedPin,
    validationResult,
    verificationCode,
    verifyDisabled,
    isInternal
  );

  const isFirefoxBrowser = useMemo(
    () => navigator?.userAgent?.indexOf('Firefox') !== -1,
    []
  );

  const getInputType = useCallback(
    showPassword =>
      isFirefoxBrowser ? (showPassword ? 'text' : 'password') : 'text',
    [isFirefoxBrowser]
  );

  const isMobile =
    useMediaQuery((theme: any) => theme.breakpoints.down('md')) ||
    detectDevice() === 'tablet';

  const handleOpenNumpad = (confirmStep: string) => {
    setNumpadConfirm(confirmStep);

    switch (confirmStep) {
      case 'npin': {
        setNumpadConfig({
          opened: true,
          initialValue: newPin,
          label: PIN_INPUT_LABEL_NEW,
        });
        setMaxLengthChar(10);
        break;
      }
      case 'cpin': {
        setNumpadConfig({
          opened: true,
          initialValue: confirmedPin,
          label: PIN_INPUT_LABEL_CONFIRM,
        });
        setMaxLengthChar(10);
        break;
      }
      case 'vcode': {
        setNumpadConfig({
          opened: true,
          initialValue: verificationCode,
          label: PIN_INPUT_VERIFICATION_CODE,
        });
        setMaxLengthChar(6);
        break;
      }
    }
  };
  const handleNumpadClose = () => {
    setNumpadConfig({
      opened: false,
      initialValue: '',
      label: PIN_INPUT_LABEL_NEW,
    });
  };

  const handleNumpadResponse = (data: string) => {
    switch (numpadConfirm) {
      case 'npin': {
        checkMaxLength(data, setNewPin);
        break;
      }
      case 'cpin': {
        checkMaxLength(data, setConfirmedPin);
        break;
      }
      case 'vcode': {
        checkMaxLength(data, setVerificationCode, 'VCode');
        break;
      }
    }
    setNumpadConfig({
      opened: false,
      initialValue: '',
      label: PIN_INPUT_LABEL_NEW,
    });
  };

  const verifyComponentProps = {
    onValidate,
    checkMaxLength,
    verificationCode,
    setVerificationCode,
    validationErrors,
    sendPINVerificationLoading,
    resendVerificationCode,
    validatePINVerificationLoading,
    validatePINVerificationData,
    verifyVerificationCode,
    sendPINVerificationCodeData,
    verifyDisabled,
    getInputType,
    isMobile,
    handleOpenNumpad,
  };

  const isValid = !Object.keys(validationErrors).length;

  return (
    <Form
      error={createPinError}
      isValid={isValid}
      loading={createPinLoading}
      onSubmit={handleSubmit}
      onCancel={editPin ? handleEditCancel : undefined}
      title={editPin ? 'Edit PIN' : 'Create PIN'}
      onCompleted={editPin ? handleEditComplete : handleCompleted}
    >
      <FieldSet>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <TextField
              endIcon={showNew ? 'visibility' : 'visibility_off'}
              label="New PIN"
              name="newPin"
              onChange={value => checkMaxLength(value, setNewPin)}
              onValidate={onValidate}
              type={getInputType(showNew)}
              maskInput={!showNew}
              value={newPin}
              onEndIconClick={toggleNew}
              errorMessage={validationErrors?.newPin}
              required
              inputPattern={INPUT_PATTERN_PASSWORD}
              onClick={isMobile ? () => handleOpenNumpad('npin') : undefined}
            />
          </Grid>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <TextField
              endIcon={showConfirmNew ? 'visibility' : 'visibility_off'}
              label="Confirm New PIN"
              name="confirmNewPin"
              onChange={value => checkMaxLength(value, setConfirmedPin)}
              onValidate={onValidate}
              type={getInputType(showConfirmNew)}
              maskInput={!showConfirmNew}
              value={confirmedPin}
              onEndIconClick={toggleConfirmNew}
              errorMessage={validationErrors?.confirmedPin}
              required
              inputPattern={INPUT_PATTERN_PASSWORD}
              onClick={isMobile ? () => handleOpenNumpad('cpin') : undefined}
            />
          </Grid>
          {newPin && (
            <Grid item xs={12}>
              <StrengthMeter
                strengthLevel={validationResult.strength}
                rules={validationResult.validations.rules}
              />
            </Grid>
          )}
          {isInternal && <VerifyPin {...verifyComponentProps} />}
        </Grid>
      </FieldSet>
      {isMobile && (
        <Numpad
          open={numpadConfig.opened}
          isMobile={isMobile}
          title={editPin ? 'Edit PIN' : 'Create PIN'}
          titleIcon=""
          userMessage={'Enter PIN'}
          inputTitle={numpadConfig.label}
          enterTitle="Confirm"
          hiddenValue={true}
          allowShowOnHidden={true}
          onEnter={(data: string) => {
            handleNumpadResponse(data);
          }}
          closeButton={true}
          onClose={() => handleNumpadClose()}
          max={maxLengthChar}
          min={6}
          textAlign="left"
          initValue={numpadConfig.initialValue}
          allowEmpty={false}
        />
      )}
    </Form>
  );
};

export default React.memo(Pin);
