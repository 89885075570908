import React from 'react';
import { Palette } from '@woundtech/ui-colors';
import NavigationButton from './NavigationButton';
import CustomButton from './CustomButton';

export type ButtonProps = {
  color?: string;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  variant: 'navigation' | 'outlined' | 'text' | 'contained';
  size?: 'small' | 'medium' | 'large';
  startIcon?: string;
  endIcon?: string;
  fullWidth?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  color,
  disabled = false,
  label,
  onClick,
  variant,
  size,
  startIcon = '',
  endIcon = '',
  fullWidth,
}) => {
  const props = {
    disabled,
    label,
    startIcon,
    endIcon,
    fullWidth,
    size,
    onClick,
  };
  switch (variant) {
    case 'navigation':
      return <NavigationButton {...props} />;
    case 'outlined':
      return (
        <CustomButton
          color={color || Palette.Blue.Main}
          variant="outlined"
          {...props}
        />
      );
    case 'text':
      return (
        <CustomButton
          color={color || Palette.Blue.Main}
          variant="text"
          {...props}
        />
      );
    case 'contained':
      return (
        <CustomButton
          color={color || Palette.Blue.Main}
          variant="contained"
          {...props}
        />
      );
    default:
      return null;
  }
};

export default React.memo(Button);
