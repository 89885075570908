import { Palette } from '@woundtech/ui-colors';

export enum AppointmentStatus {
  canceled = 'cancelled',
  checkedIn = 'checked-in',
  checkedOut = 'checked-out',
  confirmed = 'booked',
  error = 'error',
  finished = 'fulfilled',
  missed = 'noshow',
  pending = 'pending',
  signed = 'signed',
  amendRequested = 'amendRequested',
  nonBillable = 'nonBillable',
  isDoNotVisit = 'isDoNotVisit',
}

export const NOT_CANCELED_STATUSES = [
  AppointmentStatus.checkedIn,
  AppointmentStatus.confirmed,
  AppointmentStatus.pending,
];

export const STATUS_CONFIG_KEY_LOADING = 'loading';

export const STATUS_CONFIG = {
  [AppointmentStatus.canceled]: {
    color: Palette.Gray.Black80,
    label: 'Canceled',
    cardTextColor: Palette.Gray.Black80,
    cardColor: Palette.Gray.Moderate,
  },
  [AppointmentStatus.confirmed]: {
    color: Palette.Blue.Pressed,
    label: 'Confirmed',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Blue.Pressed,
  },
  [AppointmentStatus.error]: {
    label: 'Attention',
    color: Palette.Red.Main,
  },
  [AppointmentStatus.missed]: {
    color: Palette.Red.Main,
    label: 'Missed',
    cardTextColor: Palette.Red.Main,
    cardColor: Palette.Red.Light2,
  },
  [AppointmentStatus.pending]: {
    color: Palette.Blue.Main,
    label: 'Pending',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Blue.Main,
  },
  [AppointmentStatus.checkedIn]: {
    color: Palette.Teal.Main,
    label: 'Checked In',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Teal.Main,
  },
  [AppointmentStatus.checkedOut]: {
    color: Palette.Teal.Main,
    label: 'Checked Out',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Teal.Main,
  },
  [AppointmentStatus.signed]: {
    color: Palette.Teal.Dark,
    label: 'Signed',
    cardColor: Palette.Teal.Light2,
    cardTextColor: Palette.Teal.Dark,
  },
  [AppointmentStatus.nonBillable]: {
    color: Palette.Teal.Dark,
    label: 'Non-billable',
    cardColor: Palette.Teal.Light2,
    cardTextColor: Palette.Teal.Dark,
  },
  [AppointmentStatus.amendRequested]: {
    color: Palette.Purple.Main,
    label: 'Amend requested',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Purple.Main,
  },
  [AppointmentStatus.isDoNotVisit]: {
    color: Palette.Red.Main,
    label: 'Do not visit',
    cardColor: Palette.Red.Light2,
    cardTextColor: Palette.Red,
  },
  [STATUS_CONFIG_KEY_LOADING]: {
    color: Palette.Blue.Main,
    label: 'Pending',
    cardColor: Palette.Base.White,
    cardTextColor: Palette.Blue.Main,
  },
};

export const VALUE_SET_APPOINTMENT_SERVICE_TYPE =
  'http://woundtech.net/fhir/ValueSet/appointment-service-type';
export const VALUE_SET_APPOINTMENT_TYPE =
  'http://woundtech.net/fhir/ValueSet/appointment-type';
export const VALUE_SET_APPOINTMENT_MISSED_REASON =
  'http://woundtech.net/fhir/ValueSet/appointment-missed-reason';

export const EXTENSION_URL_TIME_BOUNDED = 'TIME_BOUNDED';
export const EXTENSION_URL_LINK_AUTH = 'LINK_AUTH';

export const SCHEDULED = 'SCHEDULED';

export const APPOINTMENT_TYPE_SOC_CONCEPT: fhir.CodeableConcept = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/appointment-type',
      code: 'soc-patient',
      display: 'Start of Care',
    },
  ],
};

export const APPOINTMENT_TYPE_FOLLOW_UP_CONCEPT: fhir.CodeableConcept = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/appointment-type',
      code: 'follow-up',
      display: 'Follow-up',
    },
  ],
};

export const APPOINTMENT_DEFAULT_SERVICE_TYPE_CONCEPT: fhir.CodeableConcept = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/appointment-service-type',
      code: 'woundcare',
      display: 'Woundcare',
    },
  ],
  text: 'Woundcare',
};

export const APPOINTMENT_DEFAULT_POS_CONCEPT: fhir.CodeableConcept = {
  coding: [
    {
      system: 'http://terminology.hl7.org/CodeSystem/ex-serviceplace',
      code: '12',
      display: "Patient's Residence / Home (12)",
    },
  ],
  text: "Patient's Residence / Home (12)",
};

export const APPOINTMENT_PARTICIPANT_TYPE_LOCATION: fhir.CodeableConcept = {
  coding: [
    {
      code: 'location',
      display: 'Location',
      system:
        'http://woundtech.net/fhir/CodeSystem/appointment-participant-type',
    },
  ],
};

export const APPOINTMENT_PARTICIPANT_TYPE_PATIENT: fhir.CodeableConcept = {
  coding: [
    {
      code: 'patient',
      display: 'Patient',
      system:
        'http://woundtech.net/fhir/CodeSystem/appointment-participant-type',
    },
  ],
};

export const APPOINTMENT_PARTICIPANT_TYPE_CLINICIAN: fhir.CodeableConcept = {
  coding: [
    {
      code: 'clinician',
      display: 'Clinician',
      system:
        'http://woundtech.net/fhir/CodeSystem/appointment-participant-type',
    },
  ],
};

export const AVAILABILITY_DATA = {
  startTime: '',
  endTime: '',
  isAlwaysAvailable: true,
};
export const EXCEPTION_DATA = {
  startTime: '',
  endTime: '',
  startDate: '',
  endDate: '',
  slotId: '',
};
export const WEEK_DAYS = [
  {
    id: '0',
    name: 'S',
  },
  {
    id: '1',
    name: 'M',
  },
  {
    id: '2',
    name: 'T',
  },
  {
    id: '3',
    name: 'W',
  },
  {
    id: '4',
    name: 'T',
  },
  {
    id: '5',
    name: 'F',
  },
  {
    id: '6',
    name: 'S',
  },
];

export const CANCELLATION_REASON_PATIENT_NOT_AVAILABLE_CODE =
  'patient-not-available';
export const CANCELLATION_REASON_PROVIDER_NOT_AVAILABLE_CODE =
  'provider-not-available';
export const CANCEL_APPOINTMENT_BY = 'CANCEL_APPOINTMENT_BY';
export const CANCEL_APPOINTMENT_DATE = 'CANCEL_APPOINTMENT_DATE';
export const MISSED_APPOINTMENT_BY = 'MISSED_APPOINTMENT_BY';
export const MISSED_APPOINTMENT_DATE = 'MISSED_APPOINTMENT_DATE';

export const APPOINTMENT_ENCOUNTER_DEFAULT_REASON: fhir.CodeableConcept = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/encounter-reason-cancelled',
      code: 'NA',
      display: 'The Patient is not available',
    },
  ],
  text: 'The Patient is not available',
};
export const PATIENT_APPOINTMENT_DEFAULT_STATUSES = [
  AppointmentStatus.checkedIn,
  AppointmentStatus.confirmed,
  AppointmentStatus.error,
  AppointmentStatus.finished,
  AppointmentStatus.missed,
  AppointmentStatus.pending,
];
export const ALWAYS_AVAILABLE = 'PATIENT_ALWAYS_AVAILABLE';
export const GENERAL_AVAILABLE = 'GENERAL_AVAILABLE';
export const SPECIFIC_AVAILABLE = 'SPECIFIC_AVAILABLE';
