import React from 'react';
import { Alert, Color } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';

type InlineAlertProps = {
  children?: React.ReactNode;
  severity?: Color;
  variant?: 'standard' | 'filled' | 'outlined';
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(1.5)}px`,
  },
}));

const InlineAlert: React.FC<InlineAlertProps> = ({
  children,
  severity,
  variant = 'outlined',
}) => {
  const classes = useStyles();
  return (
    <Alert className={classes.root} severity={severity} variant={variant}>
      {children}
    </Alert>
  );
};

export default React.memo(InlineAlert);
