import React, { useReducer, useMemo } from 'react';
import PrivateLayoutContext from './Context';
import { reducer, defaultState } from './reducer';

type Props = {
  children?: React.ReactNode;
};

const PrivateLayoutProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
    }),

    [state, dispatch]
  );

  return (
    <PrivateLayoutContext.Provider value={value}>
      {children}
    </PrivateLayoutContext.Provider>
  );
};

export default PrivateLayoutProvider;
