import { fade } from '@material-ui/core/styles/colorManipulator';
import { Palette } from '@woundtech/ui-colors';
import {
  LAST_SELECTED_OPTION_LABEL_CSS,
  OPTION_STATUS,
  THIN_BORDER_CSS,
} from '../constants';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const containerStylesDefault = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'inline-flex',
      boxSizing: 'border-box',
      minHeight: '48px',
      borderColor: Palette.Blue.Dark,
      borderRadius: 4,
      cursor: 'pointer',
      width: '100%',
      ' -webkit-tap-highlight-color': 'transparent',
    },
    thinBorder: THIN_BORDER_CSS,
    [OPTION_STATUS.selected]: {
      backgroundColor: Palette.Blue.Dark,
      color: theme.palette.common.white,
      '&.disabled': {
        cursor: 'default',
        color: theme.palette.common.white,
        backgroundColor: Palette.Gray.Black30,
        border: `1px solid ${Palette.Gray.Black30}`,
      },
    },
    [OPTION_STATUS.selectedAgain]: {
      backgroundColor: Palette.Blue.Dark,
      color: theme.palette.common.white,
    },
    [OPTION_STATUS.lastSelected]: {
      backgroundColor: fade(Palette.Gray.Light, 0.8),
      color: Palette.Blue.Dark,
      '&:hover': {
        backgroundColor: fade(Palette.Gray.Light, 0.6),
      },
      '&:active': {
        backgroundColor: Palette.Gray.Light,
      },
    },
    [OPTION_STATUS.unSelected]: {
      backgroundColor: theme.palette.common.white,
      color: Palette.Blue.Dark,
      '&:hover': {
        backgroundColor: fade(Palette.Blue.Dark, 0.04),
      },
      '&:active': {
        backgroundColor: fade(Palette.Blue.Dark, 0.12),
      },
      '&.disabled': {
        cursor: 'default',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${Palette.Gray.Black30}`,
      },
    },
    checkBoxContainer: {
      textAlign: 'center',
    },
    lastSelectedLabel: LAST_SELECTED_OPTION_LABEL_CSS,
  })
);

export const checkboxStylesDefault = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '12px',
      color: Palette.Blue.Dark,
      '&$checked': {
        color: theme.palette.common.white,
      },
    },
    [OPTION_STATUS.selectedAgain]: {
      '&$checked': {
        color: Palette.Gray.Light,
      },
    },
    checked: {},
    checkBoxRoot: {
      color: Palette.Blue.Dark,
      '&$checked': {
        color: Palette.Blue.Dark,
      },
    },
  })
);

export const containerStylesRed = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      display: 'inline-flex',
      boxSizing: 'border-box',
      minHeight: '48px',
      borderColor: Palette.Red.Dark,
      borderRadius: 4,
      cursor: 'pointer',
      width: '100%',
      ' -webkit-tap-highlight-color': 'transparent',
    },
    thinBorder: THIN_BORDER_CSS,
    [OPTION_STATUS.selected]: {
      backgroundColor: Palette.Red.Dark,
      color: theme.palette.common.white,
      '&.disabled': {
        cursor: 'default',
        color: theme.palette.common.white,
        backgroundColor: Palette.Gray.Black30,
        border: `1px solid ${Palette.Gray.Black30}`,
      },
    },
    [OPTION_STATUS.selectedAgain]: {
      backgroundColor: Palette.Red.Dark,
      color: theme.palette.common.white,
    },
    [OPTION_STATUS.lastSelected]: {
      backgroundColor: fade(Palette.Gray.Light, 0.8),
      color: Palette.Red.Dark,
      '&:hover': {
        backgroundColor: fade(Palette.Gray.Light, 0.6),
      },
      '&:active': {
        backgroundColor: Palette.Gray.Light,
      },
    },
    [OPTION_STATUS.unSelected]: {
      backgroundColor: theme.palette.common.white,
      color: Palette.Red.Dark,
      '&:hover': {
        backgroundColor: fade(Palette.Red.Dark, 0.04),
      },
      '&:active': {
        backgroundColor: fade(Palette.Red.Dark, 0.12),
      },
      '&.disabled': {
        cursor: 'default',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${Palette.Gray.Black30}`,
      },
    },
    checkBoxContainer: {
      textAlign: 'center',
    },
    lastSelectedLabel: LAST_SELECTED_OPTION_LABEL_CSS,
  })
);

export const checkboxStylesRed = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: '12px',
      color: Palette.Red.Dark,
      '&$checked': {
        color: theme.palette.common.white,
      },
    },
    [OPTION_STATUS.selectedAgain]: {
      '&$checked': {
        color: Palette.Gray.Light,
      },
    },
    checked: {},
    checkBoxRoot: {
      color: Palette.Red.Dark,
      '&$checked': {
        color: Palette.Red.Dark,
      },
    },
  })
);
