import React, { useCallback, useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { AUTOCOMPLETE_ATTRIBUTE } from 'utility/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Chip from '@material-ui/core/Chip';
import { isEqual, filter } from 'lodash';
import Button from '@material-ui/core/Button';
interface Props {
  search: string;
  onChange: (value: string) => void;
  server?: boolean;
}

function filterOut(text, cursor) {
  const beforeCursor = text.slice(0, cursor);
  const afterCursor = text.slice(cursor, text.length);

  const newText = beforeCursor + afterCursor;
  const newCursor = beforeCursor.length;

  return [newText, newCursor];
}

function useRunAfterUpdate() {
  const afterPaintRef = useRef<any>(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef.current) {
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = fn => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

const SearchBar: React.FC<Props> = ({ search, onChange, server = false }) => {
  const [query, setQuery] = useState('');
  const [searchArgs, setSearchArgs] = useState<string[]>([]);
  const runAfterUpdate = useRunAfterUpdate();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    const text = input.value;
    const cursor = input.selectionStart;

    const [newName, newCursor] = filterOut(text, cursor);

    setQuery(newName);
    onChange(newName);

    runAfterUpdate(() => {
      input.selectionStart = newCursor;
      input.selectionEnd = newCursor;
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!server) return;
    if (event.keyCode === 13 || event.charCode === 13) {
      onChange && onChange((query || '').trim());
      let temp = query.trim();
      if (temp === '') {
        return true;
      }
      const updatedSearchArgs = [temp];
      setSearchArgs(updatedSearchArgs);
      setQuery('');
    }
  };

  const handleChipDelete = useCallback(
    index => {
      const filtered = filter(searchArgs, (_, key) => !isEqual(key, index));
      setSearchArgs(filtered);
      setQuery('');
      onChange('');
    },
    [setSearchArgs, searchArgs, onChange]
  );

  const resetAll = useCallback(() => {
    setQuery('');
    setSearchArgs([]);
    onChange && onChange('');
  }, [setQuery, setSearchArgs, onChange]);

  return (
    <>
      <Box my={1}>
        <TextField
          autoComplete={AUTOCOMPLETE_ATTRIBUTE}
          fullWidth
          variant="outlined"
          size="small"
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          value={query}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box display="flex" p={1}>
                  <Icon color="action">search</Icon>
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx={4}
        my={1}
      >
        <Box flexGrow={1}>
          {searchArgs?.length > 0
            ? searchArgs.map((item, index) => (
                <>
                  <Chip
                    key={index}
                    label={item}
                    color="primary"
                    onDelete={() => handleChipDelete(index)}
                  />{' '}
                </>
              ))
            : null}
        </Box>
        {searchArgs?.length > 0 ? (
          <Box flexGrow={1} textAlign="right">
            <Button color="primary" onClick={resetAll}>
              Clear all
            </Button>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default SearchBar;
