import { REGIONAL_MEDICAL_DIRECTORS } from './constant';

export const PATH_LEAD_CLINICIAN_LIST = '/lead-clinician';
export const PATH_LEAD_CLINICIAN_VIEW = '/lead-clinician/:clinicianId';
export const PATH_LEAD_CLINICIAN_EDIT = '/lead-clinician/:clinicianId/edit';

export function getLeadClinicianListUrl(): string {
  return PATH_LEAD_CLINICIAN_LIST;
}

export function getLeadClinicianViewUrl(clinicianId: string): string {
  return PATH_LEAD_CLINICIAN_VIEW.replace(':clinicianId', clinicianId);
}

export function getLeadClinicianEditUrl(clinicianId: string): string {
  return PATH_LEAD_CLINICIAN_EDIT.replace(':clinicianId', clinicianId);
}

export const ENTRY_PERMISSION = 'settings';
export const ENTRY_LINK = {
  path: PATH_LEAD_CLINICIAN_LIST,
  text: REGIONAL_MEDICAL_DIRECTORS,
  module: 'lead-clinician-management',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export function getAssociatedIds(
  careTeam: any,
  roleCode: string[]
): string[] | undefined {
  return careTeam?.participant
    ?.filter(participant => {
      return participant?.role?.some((role: fhir.CodeableConcept) =>
        roleCode.includes(role?.coding?.[0]?.code || 'undefined')
      );
    })
    .map(participant => participant?.member?.reference?.split('/')[1])
    .filter(id => !!id);
}
