import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/DeleteOutlined';
import Clear from '@material-ui/icons/Clear';
import red from '@material-ui/core/colors/red';

export type Props = {
  dispatch: any;
  item: any;
  progress: any;
  onSelectChange?: any;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: red[500],
      padding: '2px',
    },
  })
);

const FileListItemDelete: React.FC<Props> = ({
  item,
  dispatch,
  progress,
  onSelectChange = null,
}) => {
  const classes = useStyles();
  const onDelete = () => {
    try {
      if (onSelectChange) {
        onSelectChange();
      }
      dispatch({ type: 'deleteFile', item });
    } catch {}
  };

  return (
    <>
      <IconButton
        aria-label="upload picture"
        component="span"
        classes={{ root: classes.root }}
        onClick={onDelete}
      >
        {progress === 100 && !item.error && <Delete fontSize="small" />}
        {item.error && <Clear fontSize="small" />}
        {progress > 0 && progress < 100 && <Clear fontSize="small" />}
      </IconButton>
    </>
  );
};

export default React.memo(FileListItemDelete);
