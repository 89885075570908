import { ApolloError, useQuery } from '@apollo/client';
import { Clinician } from 'clinician';
import { CareTeamPhysiciansQuery } from 'clinician/Schema';
import { useState } from 'react';

interface ClinicianQueryResult {
  loading: boolean;
  error: ApolloError | undefined;
  clinicians: Clinician[];
}

export const useClinicianQuery = ({ clinicianId }): ClinicianQueryResult => {
  const [clinicians, setClinicians] = useState<Clinician[]>([]);
  const { loading, error } = useQuery(CareTeamPhysiciansQuery, {
    skip: !clinicianId,
    fetchPolicy: 'no-cache',
    variables: {
      first: clinicianId.includes(',') ? 2 : 1,
      search: {
        id: clinicianId,
      },
    },
    onCompleted: data =>
      setClinicians(data?.CareTeamPhysicians?.edges?.map(edge => edge?.node)),
  });

  return {
    loading,
    error,
    clinicians,
  };
};
