import { conformToMask } from 'react-text-mask';

export { default } from './Field';

export const INPUT_PATTERN_ADDRESS_LINE = /^[a-z0-9.\s,]+$/gi;
export const INPUT_PATTERN_ALPHANUMERIC = /^[a-z0-9]+$/gi;
export const INPUT_PATTERN_ALPHANUMERIC_DOT = /^[a-z0-9.]+$/gi;
export const INPUT_PATTERN_ALPHANUMERIC_SPECIAL = /^[a-z0-9.*\-#]+$/gi;
export const INPUT_PATTERN_EMAIL = /^[+a-zA-Z0-9-_.@]+$/gi;
export const INPUT_PATTERN_NUMBER = /^[0-9]+$/gi;
export const INPUT_PATTERN_TWO_WORDS = /^[a-z]+(\s)?([a-z]+)?$/gi;
export const INPUT_PATTERN_WORDS = /^[a-z\s]+$/gi;
export const INPUT_PATTERN_ONSET_NUMBER = /^([1-9]|[0-9][0-2])+$/gi;
export const INPUT_PATTERN_ALPHANUMERIC_SPACE = /^[a-z0-9\s]+$/gi;
export const INPUT_PATTERN_URL = /^[a-z0-9._:/-]+$/gi;
export const INPUT_PATTERN_PASSWORD = /[^\s]+$/gi;
export const INPUT_PATTERN_PIN = /^[0-9]{1,6}$/gi;
export const INPUT_PIN = /^[0-9]{1,10}$/gi;

export const VALIDATION_PATTERN_ADDRESS_LINE = /^[a-z0-9.\s,]+$/gi;
export const VALIDATION_PATTERN_ALPHANUMERIC = /^[a-z0-9]+$/gi;
export const VALIDATION_PATTERN_EMAIL = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const VALIDATION_PATTERN_NPI = /^[0-9]{10}$/gi;
export const VALIDATION_PATTERN_PHONE_NUMBER = /^[\d]{10}$/gi;
export const VALIDATION_PATTERN_POSTAL_CODE = /^(\d{5}|\d{9})$/gi;
export const VALIDATION_PATTERN_SSN = /^\d\d\d\d$/gi;
export const VALIDATION_PATTERN_TWO_WORDS = /^[a-z]+(\s[a-z]+)?\s?$|^$/gi;
export const VALIDATION_PATTERN_URL = /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gi;
export const VALIDATION_PATTERN_WORDS = /^[a-z]+(\s[a-z]+){0,}\s?$|^$/gi;

export const MAX_LENGTH_LONG = 1000;
export const MAX_LENGTH_DEFAULT = 30;
export const MAX_LENGTH_NPI = 10;
export const MIN_LENGTH_DEFAULT = 1;
export const MAX_LENGTH_ADDRESS = 100;
export const TEXT_AREA_MIN_ROWS = 3;
export const TEXT_AREA_MAX_ROWS = Number.MAX_VALUE;
export const TEXT_AREA_MAX_LENGTH = 10000;
export const MAX_LENGTH_PATIENT_ALERT = 200;

export const INPUT_MASK_POSTAL_CODE = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const INPUT_MASK_SSN = [
  'X',
  'X',
  'X',
  '-',
  'X',
  'X',
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const INPUT_MASK_PHONE_NUMBER = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export function textMaskFormat(
  text: string | undefined,
  mask?: (string | RegExp)[]
): string {
  const display =
    mask && text
      ? conformToMask(text, mask, {
          guide: false,
        }).conformedValue.replace(/-$|\s$|\)$|\)\s$/, '')
      : text;

  return display || '';
}
