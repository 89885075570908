import { ApolloError } from '@apollo/client';
import { errorMessage } from 'encounter/EncounterLandingPage/UserVerificationDialog';
import { IMO_DIAGNOSIS_URL } from 'imo/constants';
import { DateTime } from 'luxon';
import uuid from 'react-uuid';

const _pipe = (a, b) => arg => b(a(arg));
export const pipe = (...ops) => ops.reduce(_pipe);

export const emtpyRow = linkId => {
  return {
    linkId,
    item: [],
    key: uuid(),
  };
};

export const hasAnswers = row => {
  return row.item.some(i => i.answer);
};

export const addKey = item => {
  return { ...item, key: uuid() };
};

export const removeKey = ({ linkId, item }) => {
  return { linkId, item };
};

export const getAnswerValue = data => {
  return {
    valueCoding: {
      display: data?.title,
      code: `${data?.code}:${data?.icd10CmCode}-${data?.imoLexicalCode}-${data?.sctConceptId}`,
      system: IMO_DIAGNOSIS_URL,
    },
  };
};

export const getAnswerValueString = data => {
  return {
    valueString: data,
  };
};

export const extractMessageFromApolloError = (
  error?: ApolloError | errorMessage
) => error?.message?.replace('GraphQL error: ', '');

export const findIdFromReference = (data: fhir.Reference | undefined) => {
  return data?.reference?.split('/')?.[1];
};

export const deepCopy = inObject => {
  let outObject, value, key;

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
};

export function areIsoDatesSame(isoDate1: string, isoDate2: string): boolean {
  return (
    DateTime.fromISO(isoDate1).toLocaleString() ===
    DateTime.fromISO(isoDate2).toLocaleString()
  );
}
