import { PlaceholderKind } from '@woundtech/ui-constants';
import { textMaskFormat } from 'styleguide/form/Text';

export const identifier = (
  identifiers: fhir.Identifier[] | undefined,
  type: string,
  mask?: (string | RegExp)[]
) => {
  const identifier = (identifiers || []).find(
    item => item?.type?.coding?.[0].code === type
  );

  return (
    textMaskFormat(identifier?.value, mask) || PlaceholderKind.NotAvailable
  );
};

export const identifierBySystem = (
  identifiers: fhir.Identifier[] | undefined,
  system: string
) => {
  const identifier = (identifiers || []).find(item => item?.system === system);

  return identifier?.value || PlaceholderKind.NotAvailable;
};
