import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ItemLabel from './ItemLabel';
import { attributeTextStyles } from './AttributeItemTextStyles';

const ItemText: React.FC<{
  label: string;
  value: string | string[];
  noWrap: boolean;
  highligted?: boolean;
  variant?: 'small' | 'large' | 'verysmall';
  textAlign?: 'left' | 'right';
}> = ({
  label,
  value,
  noWrap,
  highligted,
  variant = 'small',
  textAlign = 'left',
}) => {
  const classes = attributeTextStyles();
  return (
    <Box
      display="flex"
      width={1}
      justifyContent={textAlign === 'right' ? 'flex-end' : 'normal'}
    >
      <ItemLabel text={label} variant={variant} />
      {Array.isArray(value) ? (
        <Box width={1}>
          {value.map((text, index) => (
            <Typography
              noWrap={noWrap}
              color={highligted ? 'primary' : undefined}
              className={classes[variant]}
              key={index}
            >
              {text}
            </Typography>
          ))}
        </Box>
      ) : (
        <Typography
          noWrap={noWrap}
          className={classes[variant]}
          color={highligted ? 'primary' : undefined}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(ItemText);
