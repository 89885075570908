import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import DataListItem from './DataListItem';
import { Checkbox } from '@material-ui/core';
import Accordion from 'styleguide/layout/Accordion';
import { format } from 'date-fns';

export type Props = {
  component: React.FC<any>;
  data: { [key: string]: any }[];
  onSelect: (data: any) => void;
  getOptionDisabled: (item: any) => boolean;
  getOptionId: (item: any) => string;
  selectedId?: any;
  showItemCard?: boolean;
  dataProps?: any;
  showCheckbox?: boolean;
  onCardCheckBoxToggle?: (data: any) => void;
  showMultipleSelect?: boolean;
  showAccordion?: boolean;
  selectedAccordionPanel?: (id: string) => void;
};

const DataListContent: React.FC<Props> = ({
  component,
  data,
  onSelect,
  selectedId,
  getOptionDisabled,
  getOptionId,
  showItemCard,
  dataProps,
  showCheckbox,
  onCardCheckBoxToggle,
  showMultipleSelect,
  showAccordion,
  selectedAccordionPanel,
}) => {
  const [expanded, setExpanded] = useState<Array<string>>([]);
  const [accordionData, setAccordionData] = useState<any[]>([]);

  useEffect(() => {
    if (showAccordion) {
      setAccordionData(data);
    }
  }, [showAccordion, data, setAccordionData]);

  const setAccordionExpanded = useCallback(
    data => {
      if (!showAccordion) return;

      if (selectedAccordionPanel) {
        selectedAccordionPanel(data);
      }

      setExpanded(prev => {
        const temp = [...prev];
        const index = temp.findIndex(item => item === data._id);
        if (index === -1) {
          temp.push(data._id);
          setAccordionData(prev =>
            prev?.map(obj =>
              obj._id === data._id ? { ...obj, viewed: true } : obj
            )
          );
        } else {
          return temp.slice(0, index).concat(temp.slice(index + 1));
        }
        return temp;
      });
    },
    [selectedAccordionPanel, showAccordion, setAccordionData]
  );

  const loadData = useMemo(() => {
    return showAccordion ? accordionData : data;
  }, [showAccordion, accordionData, data]);

  const dataMap = useMemo(() => {
    const myMap = accordionData?.map<[string, any]>(data => [data._id, data]);
    return new Map(myMap);
  }, [accordionData]);

  return (
    <>
      {loadData?.map((item, i) => {
        const id = getOptionId(item);
        const active = id ? id === selectedId : false;
        const disabled =
          (getOptionDisabled ? getOptionDisabled(item) : false) || active;
        const createdAt = item?.createdAt
          ? new Date(parseInt(item?.createdAt))
          : null;
        const createdAtTime = createdAt
          ? format(createdAt, 'MM/dd/yyyy hh:mm a')
          : '';
        return (
          <Box key={i} mb={1} display={'flex'} justifyContent={'flex-start'}>
            {showCheckbox && (
              <Checkbox
                color="primary"
                onChange={onCardCheckBoxToggle}
                id={id}
                style={{ color: '#0069C0' }}
                checked={item.isChecked}
                disabled={!showMultipleSelect ? !item.pcpFax : false}
              />
            )}
            {!showAccordion && (
              <Box
                mb={1}
                width={1}
                onClick={() =>
                  !disabled && !showMultipleSelect && onSelect && onSelect(item)
                }
              >
                <DataListItem
                  active={active}
                  component={component}
                  data={item}
                  disabled={showMultipleSelect || disabled}
                  showItemCard={showItemCard}
                  dataProps={dataProps}
                />
              </Box>
            )}
            {showAccordion && (
              <Box width={1}>
                <Accordion
                  title={item.event.title}
                  time={createdAtTime}
                  expanded={expanded.includes(item._id)}
                  onChange={expand => {
                    setAccordionExpanded(item);
                  }}
                  variant={dataMap.get(item._id).viewed}
                >
                  <DataListItem
                    active={active}
                    component={component}
                    data={item}
                    disabled={showMultipleSelect || disabled}
                    showItemCard={showItemCard}
                    dataProps={dataProps}
                  />
                </Accordion>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default React.memo(DataListContent);
