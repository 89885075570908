import { makeStyles } from '@material-ui/core/styles';
import { Palette } from '@woundtech/ui-colors';

export const useNumpadStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    touchAction: 'manipulation',
    position: 'relative',
    width: '260px',
    margin: 'auto',
  },
  noModalBackground: {
    '& > div:first-child': {
      backgroundColor: 'transparent !important',
    },
  },
  numpad: {
    position: 'relative',
    backgroundColor: Palette.Base.White,
    borderRadius: '0 0 5px 5px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  gridItem: {
    width: '86px',
    height: '72px',
    borderBottom: `1px solid ${Palette.Gray.Light}`,
    borderRight: `1px solid ${Palette.Gray.Light}`,
    '&:last-child': {
      borderRight: 0,
    },
  },
  button: {
    width: '100%',
    maxHeight: '100%',
    padding: '26px 0',
    fontSize: '16px',
  },
  buttonDel: {
    fontSize: '32px',
    width: '100%',
    padding: '24px',
  },
  buttonDecimal: {
    fontSize: '32px',
    fontWeight: 300,
    width: '100%',
    textAlign: 'center',
    paddingBottom: '8px',
  },
  spanDecimal: {
    lineHeight: '48px',
  },
  buttonEnter: {
    float: 'right',
    width: '125px',
    margin: '3px',
    marginLeft: 0,
  },
  buttonEnterFullWith: {
    width: '100%',
    paddingTop: '8px',
    borderRadius: 0,
    '&:hover': {
      opacity: 0.8,
    },
  },
  enterIcon: {
    fontSize: '20px',
    marginRight: '6px',
  },
  input: {
    color: '#333',
    width: '240px',
    margin: '0 10px',
    paddingBottom: '4px',
    '& label.Mui-error': {
      color: '#333',
    },
  },
  title: {
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  titleIcon: {
    fontSize: '20px',
    marginRight: '5px',
    marginBottom: '-2px',
  },
  bottomLink: {
    color: Palette.Red.Main,
    borderColor: Palette.Red.Main,
    width: '125px',
    margin: '3px',
    '&:hover': {
      color: Palette.Red.Dark,
      borderColor: Palette.Red.Dark,
    },
  },
  buttonsBox: {
    height: '42px',
  },
  logo: {
    backgroundColor: Palette.Base.White,
    padding: '10px',
    height: '90px',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center',
  },
  image: {
    margin: 'auto',
  },
  chip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-5px',
  },
}));

export const useDesktopStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    position: 'relative',
    width: '450px',
    margin: 'auto',
  },
  noModalBackground: {
    '& > div:first-child': {
      backgroundColor: 'transparent !important',
    },
  },
  npdesktop: {
    position: 'relative',
    backgroundColor: Palette.Base.White,
    borderRadius: '0 0 5px 5px',
    padding: '10px',
  },
  input: {
    color: '#333',
    width: '100%',
  },
  inputAdornment: {
    marginRight: '10px',
  },
  fieldSet: {
    marginTop: theme.spacing(1),
    '& > div > div:last-child ': {
      marginTop: theme.spacing(1),
    },
  },
  title: {
    margin: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  titleIcon: {
    fontSize: '20px',
    marginRight: '5px',
    marginBottom: '-2px',
  },
  userMessage: {
    marginBottom: '5px',
  },
  bottomLink: {
    color: Palette.Base.White,
    textAlign: 'center',
    marginTop: '5px',
    '& span': {
      cursor: 'pointer',
    },
    '& span:hover': {
      decorator: 'underline',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  logo: {
    backgroundColor: Palette.Base.White,
    padding: '10px',
    height: '90px',
    borderRadius: '5px 5px 0 0',
    textAlign: 'center',
  },
  image: {
    margin: 'auto',
  },
  chip: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-5px',
  },
}));
