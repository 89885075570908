import { gql } from '@apollo/client';

export const getJobFunctionsList = gql`
  query {
    GetJobFunctions {
      _id
      title
      description
      status
      events {
        _id
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationList = gql`
  mutation setNotificationUpdates($id: String!) {
    setNotificationUpdates(id: $id) {
      _id
    }
  }
`;
export const UpdateEvents = gql`
  mutation updateJobFunction($input: JobFunctionInput!) {
    updateJobFunction(input: $input) {
      events {
        _id
      }
    }
  }
`;
export const GetJobFunction = gql`
  query GetJobFunction($id: String!) {
    GetJobFunction(id: $id) {
      _id
      title
      description
      status
      events {
        _id
      }
      createdAt
      updatedAt
    }
  }
`;
export const GetEvents = gql`
  query {
    GetEvents {
      _id
      title
      description
      createdAt
      updatedAt
      status
    }
  }
`;
export const getNotificationCount = gql`
  query GetNotificationsCount($practitionerId: String, $notify: [String]) {
    GetNotificationsCount(practitionerId: $practitionerId, notify: $notify)
  }
`;

export const getNotificationByTitle = gql`
  query GetJobFunctionByTitle($title: [String!]) {
    GetJobFunctionByTitle(title: $title) {
      _id
      title
      events {
        _id
      }
    }
  }
`;

export const getCCDRequestList = gql`
  query GetCCDRequestList($filters: NotificationFiltersInput) {
    GetCCDRequestList(filters: $filters) {
      requests {
        requestDetails {
          status
          dateRange
          userName
        }
        createdAt
      }
      totalRecords
    }
  }
`;

export const getNotificationList = gql`
  query GetNotificationList(
    $filters: NotificationFiltersInput
    $first: Int
    $after: String
  ) {
    GetNotificationList(filters: $filters, first: $first, after: $after) {
      edges {
        node {
          _id
          viewed
          event {
            title
            description
          }
          resource {
            admission
          }
          meta {
            patientId
            patientName
          }
          resourceDetails {
            id
            instantiatesCanonical
            resourceType
          }
          notify
          createdAt
        }
        cursor
      }
      totalCount
    }
  }
`;

export const onNotificationCreatedSubscription = gql`
  subscription onNotificationAdded($id: String, $notify: [String]) {
    onNotificationAdded(id: $id, notify: $notify) {
      resource
    }
  }
`;

export const getNotificationsCount = gql`
  query GetNotificationsCount($notify: [String]) {
    GetNotificationsCount(notify: $notify)
  }
`;
