import React, { useMemo } from 'react';
import { Palette } from '@woundtech/ui-colors';
import ResourceCard from 'styleguide/display/ResourceCard';
import { PlaceholderKind } from '@woundtech/ui-constants';
import Box from '@material-ui/core/Box';
import { fullAddressFormat } from 'fhir/general/Address';
import { fullNameFormat } from 'fhir/general/HumanName';
import { ORGNAIZATION_SUB_TYPE } from '../../constants';
import { getSystemCode } from '../utils';
import { phoneNumber } from 'styleguide/form/Telecom/formatters';

type Props = {
  data: any;
  onClick: () => void;
  customAttributes?: any;
  title?: string;
  cardStatus?: string;
  type?: boolean;
};

const Component: React.FC<Props> = ({
  data,
  onClick,
  customAttributes = [],
  title,
  cardStatus,
  type,
}) => {
  const attributes = useMemo(() => {
    const telecom = phoneNumber(
      data?.telecom?.find(item => item?.system === 'phone')
    );
    const orgType = getSystemCode(data?.type, ORGNAIZATION_SUB_TYPE)
      ?.coding?.[0]?.display;
    if (type) {
      return [
        {
          icon: 'phone',
          value: telecom,
          columns: 2,
        },
        {
          icon: 'location_on',
          value: fullAddressFormat(data?.address?.[0]),
          columns: 2,
        },
      ];
    }
    if (orgType) {
      return [
        {
          name: 'Type',
          value: orgType || PlaceholderKind.NotAvailable,
          columns: 2,
        },
        {
          icon: 'location_on',
          value: fullAddressFormat(data?.address?.[0]),
          columns: 2,
        },
      ];
    }
  }, [data, type]);

  const { displayName, status, statusColor } = useMemo(() => {
    const displayName =
      typeof data?.name !== 'string' ? fullNameFormat(data?.name) : data?.name;
    const status = customAttributes?.length ? cardStatus : data?.active;
    const statusText = status ? 'Active' : 'Inactive';
    const statusColor =
      statusText === 'Active' ? Palette.Teal.Main : Palette.Orange.Main;
    return { status: statusText, statusColor, displayName };
  }, [data, customAttributes, cardStatus]);

  return (
    <Box onClick={onClick}>
      <ResourceCard
        title={title || displayName}
        attributes={customAttributes?.length ? customAttributes : attributes}
        status={status}
        statusBackgroundColor={statusColor}
        hideAvatar
      />
    </Box>
  );
};

export default React.memo(Component);
