import React, { useState, useEffect } from 'react';
import Loading from 'styleguide/Loading';

export interface IWebVersion {
  status?: string;
  info?: {
    web?: {
      status?: string;
      version?: string;
    };
  };
  error?: { T };
  details?: {
    web?: {
      status?: string;
      version?: string;
    };
  };
}

const defaultValue: IWebVersion = {};

export const Context = React.createContext(defaultValue);

const WebVersion: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [webVersion, setWebVersion] = useState<IWebVersion>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(window?._env_?.WEB_VERSION_URI || '/api/status')
      .then(async response => {
        const data = await response.json();
        setWebVersion(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading, setWebVersion]);

  if (loading) {
    return <Loading />;
  }

  return <Context.Provider value={webVersion}>{children}</Context.Provider>;
};

export default WebVersion;
