import { setReloadReason } from 'application/wt-debugger';
import React from 'react';
import Error from 'styleguide/Error';

type State = {
  error?: any;
};

class ErrorBoundary extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;

    if (error) {
      const isLazyLoadingError =
        error?.toString().indexOf('ChunkLoadError') > -1;

      if (isLazyLoadingError) {
        setReloadReason(window.location.href, error);
        window.location.reload();

        return null;
      }

      return <Error text={error.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
