import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiDrawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { NAVIGATION_DRAWER_WIDTH } from '../../utility/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: NAVIGATION_DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: NAVIGATION_DRAWER_WIDTH,
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '0%',
    },
  })
);

export type Props = {
  anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  open?: boolean;
};

const PermanentDrawer: React.FC<Props> = ({ anchor, children, open }) => {
  const classes = useStyles();

  return (
    <MuiDrawer
      anchor={anchor}
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Box display="flex" flexDirection="column" height={1} width={1}>
        <Box>
          <Toolbar />
        </Box>
        <Box flexGrow={1} display="flex">
          {children}
        </Box>
      </Box>
    </MuiDrawer>
  );
};

export default PermanentDrawer;
