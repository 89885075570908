import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import {
  VALIDATION_ERROR_REQUIRED,
  AUTOCOMPLETE_ATTRIBUTE,
} from 'utility/constants';
import DefaultFormControl from 'styleguide/DefaultFormControl';

import { Field } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: grey[800],
    },
    stat: {
      color: red[600],
    },
    asap: {
      color: orange[700],
    },
    menuItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    select: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
    },
    urgent: {
      color: yellow[600],
    },
    routine: {
      color: grey[600],
      padding: 0,
      margin: 0,
    },
  })
);

export interface Props extends Field {
  onChange: (value: any | undefined, name: string) => void;
  options: any[];
  value: string | undefined;
  getOptionId: (option: any) => string;
  getOptionLabel: (option: any) => string;
}

const validate = (value: string | undefined, required?: boolean) => {
  if (required && !value) {
    return VALIDATION_ERROR_REQUIRED;
  }
};

const DropDown: React.FC<Props> = ({
  disabled,
  label,
  name,
  onChange,
  onValidate,
  options,
  required,
  value,
  getOptionLabel,
  getOptionId,
}) => {
  const classes = useStyles();

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<{ value: any }>) => {
    const concept =
      options.find((concept: any) => {
        return getOptionId(concept) === value;
      }) || undefined;
    onChange(concept, name);
  };

  const helperText = validate(value, required) || '';
  const isValid = !helperText;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  return (
    <DefaultFormControl
      disabled={disabled}
      helperText={helperText}
      label={label}
      required={required}
    >
      <Select
        data-field-name={name}
        autoComplete={AUTOCOMPLETE_ATTRIBUTE}
        value={options?.length > 0 ? value || '' : ''}
        onChange={handleChange}
        classes={{
          select: name === 'priority' ? classes.select : '',
        }}
        MenuProps={{ variant: 'menu' }}
        variant="filled"
      >
        <MenuItem value="" key="0">
          <em>Select...</em>
        </MenuItem>
        {options?.map(option => (
          <MenuItem
            key={getOptionLabel(option)}
            value={getOptionId(option)}
            classes={{
              root: name === 'priority' ? classes.menuItem : '',
            }}
          >
            {getOptionLabel(option)}
            {name === 'priority' && (
              <Icon
                classes={{
                  root: classes[option?.coding?.[0]?.code || ''],
                }}
                fontSize="small"
              >
                flag
              </Icon>
            )}
          </MenuItem>
        ))}
      </Select>
    </DefaultFormControl>
  );
};

export default React.memo(DropDown);
