import {
  CARE_FACILITY_CODE,
  ASSISTED_LIVING_FACILITY_CODE,
  SKILLED_NURSING_FACILITY_CODE,
} from '../master-list/constants';
export const fullNameLabel = 'Full Name';
export const preferredNameLabel = 'Preferred Name';
export const genderLabel = 'Gender';
export const dobLabel = 'Date of Birth';
export const maritalStatusLabel = 'Marital Status';
export const EPNLabel = 'External Patient Number';
export const SSNLabel = 'SSN - Last 4 Digits';
export const phoneNumberLabel = 'Phone Number';
export const primaryAddressLabel = 'Primary Address';
export const secondaryAddressLabel = 'Secondary Address';
export const emergencyContactLabel = 'Emergency Contact';
export const relationshipLabel = 'Relationship';
export const caregiversLabel = 'Caregivers';
export const emptyCaregivers = 'No Caregiver info available';
export const emptyEmergencyContact =
  'No emergency contact specified for the patient';
export const addressesLabel = 'Addresses';
export const emailLabel = 'Email';
export const faxLabel = 'Fax';
export const fax = 'fax';
export const raceLabel = 'Race';
export const ethnicityLabel = 'Ethnicity';
export const RACE_TYPE = 'RACE';
export const ETHNICITY_TYPE = 'ETHNICITY';
export const DEMOGRAPHICS_VIEW_TITLE = 'Demographics';
export const FACESHEET_TITLE = 'Face Sheet';
export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const PATIENT_PAYER_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/patient/payer';

export const SERVICE_ADDRESS_STATE_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/patient/service-address/state';

export const UNABLE_TO_OBTAIN_EMAIL_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/patient/unable-to-obtain-email';

export const RCOPIA_IDENTIFIER =
  'http://woundtech.net/identifiers/eprescription/rcopiaid';

export const VALUE_SET_ADMISSION_STATUS_URL =
  'http://woundtech.net/fhir/ValueSet/admission-status';

export const ssnIdentifierType = 'SB';
export const ssnTemplate = {
  use: 'official',
  system: 'http://hl7.org/fhir/sid/us-ssn',
  type: {
    coding: [
      {
        code: ssnIdentifierType,
        system: 'http://hl7.org/fhir/sid/us-ssn',
        display: SSNLabel,
      },
    ],
  },
  value: '',
};

export const epnIdentifierType = 'PN';
export const epnTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: epnIdentifierType,
        system: 'http://www.woundtech.net',
        display: EPNLabel,
      },
    ],
  },
  value: '',
};
export const VALUE_SET_RELATIONSHIP =
  'http://woundtech.net/fhir/ValueSet/relationship-type';

export const PATIENT_ROOT_ROUTES = [
  {
    path: '/patients/:patientId/communication-log',
    primaryText: 'Communication & Notes',
    icon: 'forum',
    disabled: false,
    count: 0,
    module: 'communication-log',
    component: 'CommunicationLogPage',
    order: 1,
  },
  {
    path: '/patients/:patientId/alerts',
    primaryText: 'Alerts',
    icon: 'notification_important_icon',
    disabled: false,
    count: 0,
    module: 'flag',
    component: 'AlertsPage',
    order: 2,
  },
  {
    path: '/patients/:patientId/demographics',
    primaryText: 'Demographics',
    icon: 'people',
    disabled: false,
    count: 0,
    module: 'patient',
    component: 'DemographicsPage',
    order: 3,
  },
  {
    path: '/patients/:patientId/coverages',
    primaryText: 'Insurance, Eligibility & Authorizations',
    icon: 'security',
    disabled: false,
    count: 0,
    module: 'patient',
    component: 'DemographicsPage',
    order: 4,
  },
  {
    path: '/patients/:patientId/referrals',
    primaryText: 'Referrals',
    icon: 'people',
    disabled: false,
    count: 0,
    module: 'patient',
    component: 'DemographicsPage',
    order: 5,
  },
  {
    path: '/patients/:patientId/admissions',
    primaryText: 'Admissions',
    icon: 'description',
    disabled: false,
    count: 0,
    module: 'admission',
    component: 'Dashboard',
    order: 6,
  },
  {
    path: '/patients/:patientId/encounter',
    primaryText: 'Encounters',
    icon: 'people',
    disabled: false,
    count: 0,
    module: 'encounter',
    component: 'EncounterPage',
    order: 7,
  },
  {
    path: '/patients/:patientId/problemslist/problems',
    primaryText: 'Problems List',
    icon: 'cached',
    disabled: false,
    count: 0,
    module: 'problem',
    component: 'ProblemsListPage',
    order: 8,
  },
  {
    path: '/patients/:patientId/documents',
    primaryText: 'Documents',
    icon: 'description',
    disabled: false,
    count: 0,
    module: 'document-management',
    component: 'Dashboard',
    order: 9,
  },
  {
    path: '/patients/:patientId/orders',
    primaryText: 'Orders',
    icon: 'assignmentturnedin',
    disabled: false,
    count: 0,
    module: 'orders',
    component: 'Dashboard',
    order: 10,
  },
  {
    path: '/patients/:patientId/appointments',
    primaryText: 'Appointments',
    icon: 'access_time',
    disabled: false,
    count: 0,
    module: 'appointment',
    component: 'List',
    order: 11,
  },
  {
    path: '/patients/:patientId/tasks',
    primaryText: 'Tasks',
    icon: 'assignment_turned_in',
    disabled: false,
    count: 0,
    module: 'task',
    component: 'PatientTaskDashboard',
    order: 12,
  },
];

export const defaultStatuses = [
  {
    system: 'http://woundtech.net/fhir/CodeSystem/admission-status',
    code: 'planned',
    display: 'Planned',
  },
  {
    system: 'http://woundtech.net/fhir/CodeSystem/admission-status',
    code: 'active,discharge-ordered',
    display: 'Active (includes Discharge Pending)',
  },
  {
    system: 'http://woundtech.net/fhir/CodeSystem/admission-status',
    code: 'onhold',
    display: 'Active - on hold',
  },
  {
    system: 'http://woundtech.net/fhir/CodeSystem/admission-status',
    code: 'waitlist',
    display: 'Planned - on hold',
  },
];

export const PATIENT_TIMEZONE_EXTENSION =
  'http://woundtech.net/fhir/extensions/patient/timezone';

export const SERVICE_ADDRESS_URL = 'SERVICE_ADDRESS';
export const INSURERS = 'insurers';
export const IPAS = 'ipas';
export const MSOS = 'msos';
export const ON_HOLD_PLANNED_REMOVED = 'onHoldPlannedRemoved';
export const ON_HOLD_ACTIVE_REMOVED = 'onHoldActiveRemoved';
export const ON_HOLD_NOT_REMOVED = 'onHoldnotRemoved';

export const CARE_FACILITY_SERVICE_EXTENSION =
  'http://woundtech.net/fhir/extensions/care-facility-address';

export const CARE_FACILITY_INSTRUCTION_EXTENSION =
  'http://woundtech.net/extensions/fhir/care-team-facility/instruction';

export const EXTENSION_URL_PATIENT_ALWAYS_AVAILABLE =
  'PATIENT_ALWAYS_AVAILABLE';
export const EXTENSION_URL_PATIENT_AVAILABLE_STARTTIME =
  'PATIENT_AVAILABLE_STARTTIME';
export const EXTENSION_URL_PATIENT_AVAILABLE_ENDTIME =
  'PATIENT_AVAILABLE_ENDTIME';
export const EXTENSION_URL_PATIENT_EXCEPTION_STARTTIME =
  'PATIENT_EXCEPTION_STARTTIME';
export const EXTENSION_URL_PATIENT_EXCEPTION_ENDTIME =
  'PATIENT_EXCEPTION_ENDTIME';

export const validType = [
  CARE_FACILITY_CODE,
  ASSISTED_LIVING_FACILITY_CODE,
  SKILLED_NURSING_FACILITY_CODE,
];

export const APPOINTMENT_AVAILABILITY_TIME = 'Appointment Available Time';
export const SELECT_AVAILABILITY_TIME = 'Select time to set availability';
export const SELECT_AVAILABILITY_DAY = 'Select day to set availability';
export const ALWAYS_AVAILABLE_CODE = 'always-available';
export const GENERAL_CODE = 'general';
export const SPECIFIC_CODE = 'specific';
export const availabilityTypes = [
  {
    coding: [
      {
        code: ALWAYS_AVAILABLE_CODE,
        display: 'Always available',
      },
    ],
    text: 'PATIENT_ALWAYS_AVAILABLE',
  },
  {
    coding: [
      {
        code: GENERAL_CODE,
        display: 'General',
      },
    ],
    text: 'GENERAL_AVAILABLE',
  },
  {
    coding: [
      {
        code: SPECIFIC_CODE,
        display: 'Specific',
      },
    ],
    text: 'SPECIFIC_AVAILABLE',
  },
];
export const ADMISSION_CAREPLAN_PATHWAY_EXTENSION_URL =
  'CAREPLAN-TREATMENT-PATHWAY';
export const ADMISSION_CAREPLAN_ACUITY_EXTENSION_URL =
  'CAREPLAN-PATIENT-ACUITY-LEVEL';
