import React, { useMemo } from 'react';
import ContactPerson from './ContactPerson';
import { checkIfEmpty } from '../utils';
import Collection from 'fhir/general/Collection';

export type Props = {
  data: any;
  onChange: (data: any) => void;
  onValidate: (isValid: boolean) => void;
  title: string;
};

const MultipleContactPerson: React.FC<Props> = ({
  data,
  onChange,
  onValidate,
  title,
}) => {
  const isEmpty = contactInfo => {
    if (checkIfEmpty(contactInfo).length === 0) return false;
    else return checkIfEmpty(contactInfo).some(entry => entry === true);
  };
  const contactPersonData = useMemo(() => data?.filter(item => !item?.active), [
    data,
  ]);
  return (
    <Collection
      data={contactPersonData}
      isItemEmpty={isEmpty}
      onChange={onChange}
      onValidate={onValidate}
      page={ContactPerson}
      showRemoveForFirstEntry={true}
      title={title}
      customOnChange
      softDeleteItem
    />
  );
};

export default React.memo(MultipleContactPerson);
