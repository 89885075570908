import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  VALIDATION_ERROR_REQUIRED,
  AUTOCOMPLETE_ATTRIBUTE,
} from 'utility/constants';
import { Field } from '../types';

export interface Props extends Field {
  getOptionDisabled?: (option: any) => boolean;
  getOptionId: (option: any) => string;
  getOptionLabel: (option: any) => string;
  hasError?: boolean;
  onChange: (value: any | undefined, name: string) => void;
  options: any[];
  value: string | undefined;
}

const validate = (value: string | undefined, required?: boolean) => {
  if (required && !value) {
    return VALIDATION_ERROR_REQUIRED;
  }
};

const Typeahead: React.FC<Props> = ({
  disabled,
  getOptionDisabled,
  getOptionId = concept => concept?.coding?.[0]?.code,
  getOptionLabel = concept =>
    concept?.coding?.[0]?.display || concept?.coding?.[0]?.code || '',
  hasError = false,
  label,
  name,
  onChange,
  onValidate,
  options,
  required,
  value,
}) => {
  const [showErrors, setShowErrors] = useState(false);

  const handleChange = (event: object, value: any) => {
    onChange(value, name);
  };

  useEffect(() => {
    if (hasError) {
      setShowErrors(true);
    }
  }, [hasError]);

  const error = validate(value, required) || '';
  const isValid = !error;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const selectedConcept =
    options.find((concept: fhir.CodeableConcept) => {
      return getOptionId(concept) === value;
    }) || null;

  return (
    <FormControl
      fullWidth
      size="small"
      variant="filled"
      required={required}
      error={showErrors && !!error}
    >
      <Autocomplete
        autoComplete={false}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        onChange={handleChange}
        value={selectedConcept}
        disabled={disabled}
        renderInput={params => {
          if (
            params &&
            params.inputProps &&
            params.inputProps['autoComplete']
          ) {
            params.inputProps['autoComplete'] = AUTOCOMPLETE_ATTRIBUTE;
          }

          return (
            <TextField
              {...params}
              data-field-name={name}
              autoComplete={AUTOCOMPLETE_ATTRIBUTE}
              label={label}
              size="small"
              required={required}
              fullWidth
              variant="filled"
              onFocus={() => setShowErrors(false)}
              onBlur={() => setShowErrors(true)}
              error={showErrors && !!error}
            />
          );
        }}
      />
      {showErrors && !!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(Typeahead);
