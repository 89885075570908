import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import Typeahead from 'styleguide/form/SingleChoice/Typeahead';
import { MarketListQuery } from '../Schema';
import { debounce } from 'utility/helpers';

export type Props = {
  name?: string;
  label?: string;
  passOption?: (options: any[]) => void;
  county?: string;
  postalCode?: string;
  required?: boolean;
  state?: string;
  value?: string;
  onChange: (value: fhir.Location | undefined) => void;
};

const SingleMarketChoice: React.FC<Props> = ({
  name,
  label,
  passOption,
  county,
  postalCode,
  required,
  state,
  value,
  onChange,
}) => {
  const [getData, { data, error, loading }] = useLazyQuery(MarketListQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const options = data?.MarketList?.edges?.map(edge => edge.node);
      if (passOption) {
        passOption(options);
      }
      if (!value && options && options.length === 1 && options[0]) {
        onChange(options[0]);
      }
    },
  });

  const options = useMemo(
    () => data?.MarketList?.edges?.map(edge => edge.node) || [],
    [data]
  );

  useEffect(() => {
    const variables = {
      first: 200,
      states: state ? [state] : undefined,
      postalCodes: postalCode ? [postalCode.substr(0, 5)] : undefined,
      counties: county ? [county] : undefined,
    };

    const dbounceInstance = debounce(() => {
      getData({
        variables,
      });
    }, 10);
    dbounceInstance.function();
  }, [county, getData, postalCode, state]);
  return (
    <Typeahead
      name={name ?? 'market'}
      label={label ?? 'Market'}
      value={value}
      onChange={onChange}
      onValidate={() => {}}
      options={options}
      getOptionLabel={market =>
        state
          ? market?.name?.toUpperCase()
          : `${market?.name?.toUpperCase()} (${market?.address?.state})`
      }
      getOptionId={market => market?.id}
      required={required}
      hasError={!!error}
      disabled={loading}
    />
  );
};

export default React.memo(SingleMarketChoice);
