import { CareTeam } from 'team';
export const getSubExtensions = (data: CareTeam | undefined, url: string) => {
  return data?.extension?.find(item => item.url === url)?.extension;
};

export const getOtherExtensions = (
  data: CareTeam | fhir.Practitioner | undefined,
  url: string
) => {
  return data?.extension?.filter(item => item.url !== url);
};
