import { BULK_OPERATIONS } from './constants';
import { ENTRY_LINK as MISSED_APPOINTMENT } from './missed-appoitments';
import { ENTRY_LINK as MADE_ENCOUNTER } from './made-encounters';
import { ENTRY_LINK as ELIGIBILITY } from 'bulk-eligibility';

export const ENTRY_PERMISSION = 'settings';

export const ENTRY_LINK = {
  path: `/${BULK_OPERATIONS}`,
  text: 'Bulk Operations',
  icon: 'group',
  permission: ENTRY_PERMISSION,
  children: [ELIGIBILITY, MISSED_APPOINTMENT, MADE_ENCOUNTER],
};
