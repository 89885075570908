import { ENTRY_LINK as TASKING_DASHBOARD_ENTRY_LINK } from 'tasking-dashboard';

export const ENTRY_PERMISSION = 'settings';

export const ENTRY_LINK = {
  path: '/admin-dashboard',
  text: 'Dashboard',
  icon: 'assessment',
  permission: ENTRY_PERMISSION,
  children: [TASKING_DASHBOARD_ENTRY_LINK],
};
