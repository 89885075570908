import React from 'react';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';

export type Props = {
  text?: string;
};

const useStyles = makeStyles({
  label: {
    fontSize: '14px',
  },
});

const Error: React.FC<Props> = ({ text = 'Something went wrong' }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height={1}
      width={1}
      data-testid="error"
    >
      <Box p={1}>
        <Icon fontSize="large" color="error">
          error_outline
        </Icon>
      </Box>
      <Typography
        color="error"
        variant="body1"
        align="center"
        className={classes.label}
      >
        {text?.replace('GraphQL error: ', '')}
      </Typography>
    </Box>
  );
};

export default React.memo(Error);
