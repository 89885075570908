import { gql } from '@apollo/client';
import { AddressFragment } from 'fhir/general/Address/fragments';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';

export const LocationFragment = gql`
  fragment Location on Location {
    id
    resourceType
    name
    address {
      ...Address
    }
    partOf {
      reference
      resource {
        id
        status
        name
        type {
          ...CodeableConcept
        }
        address {
          ...Address
        }
      }
    }
  }
  ${AddressFragment}
  ${CodeableConceptFragment}
`;

export const MarketListQuery = gql`
  query MarketList(
    $states: [String]
    $postalCodes: [String]
    $counties: [String]
    $term: String
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    MarketList(
      states: $states
      postalCodes: $postalCodes
      counties: $counties
      term: $term
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          ...Location
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${LocationFragment}
`;

export const CountyListQuery = gql`
  query CountyList(
    $states: [String]
    $postalCodes: [String]
    $markets: [String]
    $term: String
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    CountyList(
      states: $states
      postalCodes: $postalCodes
      markets: $markets
      term: $term
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          ...Location
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${LocationFragment}
`;
