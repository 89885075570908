export const PIN_MIN_LENGTH = 6;
export const PIN_MAX_LENGTH = 10;
export const PIN_MAX_REPEATED_CHARACTERS = 3;
export const PIN_MAX_SEQUENCED_CHARACTERS = 3;
export const PIN_VALIDATION_MSG_NOT_MATCH = 'PIN does not match.';
export const PIN_VALIDATION_MSG_NUMBER =
  'PIN validation failed: Only numbers are allowed.';
export const PIN_VALIDATION_MSG_LENGTH = `PIN validation failed: PIN should have min ${PIN_MIN_LENGTH} digits and max ${PIN_MAX_LENGTH} digits.`;
export const PIN_VALIDATION_MSG_REPEATED_CHARACTERS = `PIN validation failed: Each PIN digit can only appear ${PIN_MAX_REPEATED_CHARACTERS} times.`;
export const PIN_VALIDATION_MSG_SEQUENCED_CHARACTERS = `PIN validation failed: PIN should not have sequences of more than ${PIN_MAX_SEQUENCED_CHARACTERS} digits long.`;
export const PIN_CHANGE_LABEL = 'Enter your password to change PIN';
export const PIN_INPUT_LABEL_NEW = 'New Pin';
export const PIN_INPUT_LABEL_CONFIRM = 'Confirm Pin';
export const PIN_INPUT_VERIFICATION_CODE = 'Verification Code';
