import React from 'react';
import { Box, Icon } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';

export type Props = {
  color?: string;
  icon?: string;
  label: string;
  size?: 'small' | 'medium';
  textColor?: string;
  iconColor?: string;
};

const ResourceStatus: React.FC<Props> = ({
  color,
  icon,
  label,
  size = 'medium',
  textColor = Palette.Base.White,
  iconColor = Palette.Gray.MediumDarker,
}) => {
  return (
    <Box
      borderRadius={32}
      bgcolor={color}
      textAlign="center"
      color={textColor}
      px={2}
      py={size === 'small' ? 0 : 0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      whiteSpace="nowrap"
    >
      {icon && (
        <Box pr={1} display="flex" color={iconColor ? iconColor : undefined}>
          <Icon fontSize="small">{icon}</Icon>
        </Box>
      )}
      <Box>{label}</Box>
    </Box>
  );
};

export default React.memo(ResourceStatus);
