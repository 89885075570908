import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Palette } from '@woundtech/ui-colors';
import {
  LAST_SELECTED_OPTION_LABEL_CSS,
  OPTION_STATUS,
  THIN_BORDER_CSS,
} from '../constants';

export interface Props {
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    value: string | undefined
  ) => void;
  value: string | undefined;
  variant?: OPTION_STATUS;
  className?: string;
  noWrap?: boolean;
  disabled?: boolean;
  type?: 'button';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    [OPTION_STATUS.selected]: {
      backgroundColor: Palette.Blue.Dark,
      color: theme.palette.common.white,
      cursor: 'pointer',
    },
    [OPTION_STATUS.unSelected]: {
      backgroundColor: theme.palette.common.white,
      color: Palette.Blue.Dark,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: fade(Palette.Blue.Dark, 0.04),
      },
      '&:active': {
        marginLeft: '1px 1px 0',
        backgroundColor: fade(Palette.Blue.Dark, 0.12),
      },
    },
    [OPTION_STATUS.lastSelected]: {
      backgroundColor: fade(Palette.Gray.Light, 0.8),
      color: Palette.Blue.Dark,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: fade(Palette.Gray.Light, 0.6),
      },
      '&:active': {
        marginLeft: '1px 1px 0',
        backgroundColor: Palette.Gray.Light,
      },
    },
    [OPTION_STATUS.selectedAgain]: {
      backgroundColor: Palette.Blue.Dark,
      cursor: 'pointer',
      color: Palette.Gray.Light,
    },
    [OPTION_STATUS.disabled]: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
    whiteSpace: {
      whiteSpace: 'nowrap',
    },
    root: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-flex',
      boxSizing: 'border-box',
      minHeight: 48,
      padding: '6px 38px',
      borderColor: Palette.Blue.Dark,
      borderRadius: 28,
      width: '100%',
      ' -webkit-tap-highlight-color': 'transparent',
    },
    typeRoot: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'inline-flex',
      boxSizing: 'border-box',
      minHeight: 48,
      padding: '6px 38px',
      borderColor: Palette.Blue.Dark,
      borderRadius: 4,
      width: '100%',
      ' -webkit-tap-highlight-color': 'transparent',
    },
    thinBorder: THIN_BORDER_CSS,
    lastSelectedLabel: LAST_SELECTED_OPTION_LABEL_CSS,
    label: {
      fontSize: 18,
    },
  })
);

const RadioTouchButton: React.FC<Props> = ({
  onClick,
  value,
  variant = OPTION_STATUS.unSelected,
  className,
  noWrap = true,
  disabled = false,
  type,
}) => {
  const classes = useStyles();
  const handleClick = event => {
    if (onClick && !disabled) {
      onClick(event, value);
    }
  };
  const whiteSpaceWrap = noWrap ? classes.whiteSpace : '';
  const isLastSelected = variant === OPTION_STATUS.lastSelected;
  return (
    <>
      {type ? (
        <Box
          ml={1}
          onClick={handleClick}
          className={clsx(
            classes.typeRoot,
            whiteSpaceWrap,
            classes[variant],
            classes.thinBorder,
            className
          )}
          data-variant={variant}
        >
          <Typography
            className={clsx(
              classes.label,
              isLastSelected ? classes.lastSelectedLabel : ''
            )}
          >
            {value}
          </Typography>
        </Box>
      ) : (
        <Box
          onClick={handleClick}
          className={clsx(
            classes.root,
            whiteSpaceWrap,
            classes[variant],
            classes.thinBorder,
            className
          )}
          data-variant={variant}
        >
          <Typography
            className={clsx(
              classes.label,
              isLastSelected ? classes.lastSelectedLabel : ''
            )}
          >
            {value}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(RadioTouchButton);
