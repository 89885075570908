import React from 'react';
import { Box, Fab, Icon, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Palette } from '@woundtech/ui-colors';

export type NavigationButtonProps = {
  label: string;
  startIcon?: string;
  endIcon?: string;
  disabled?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: 48,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      backgroundColor: Palette.Teal.Dark,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: Palette.Teal.Main,
      },
      '&:active': {
        backgroundColor: Palette.Teal.Light,
      },
    },
    text: {
      textTransform: 'none',
    },
  })
);

const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  disabled,
  startIcon,
  endIcon,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Fab
      onClick={onClick}
      disabled={disabled}
      variant="extended"
      className={classes.root}
    >
      {startIcon && (
        <Box
          display="flex"
          fontSize={18}
          pr={1}
          color={Palette.Base.White}
          alignItems="center"
          className={classes.text}
        >
          <Box display="flex">
            <Icon>{startIcon}</Icon>
          </Box>
          <Box>
            <Typography noWrap>{label}</Typography>
          </Box>
        </Box>
      )}
      {endIcon && (
        <Box
          display="flex"
          fontSize={18}
          pl={1}
          color={Palette.Base.White}
          alignItems="center"
          className={classes.text}
        >
          <Box>
            <Typography noWrap>{label}</Typography>
          </Box>
          <Box display="flex">
            <Icon>{endIcon}</Icon>
          </Box>
        </Box>
      )}
    </Fab>
  );
};

export default React.memo(NavigationButton);
