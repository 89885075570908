import React, { useRef, useState, useCallback } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Icon, Typography } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';
import Alert from 'styleguide/layout/Alert';

export type Props = {
  contentType: string;
  onSelect: (files: (File | null)[]) => void;
  disabled?: boolean;
  limit?: number;
};

const useStyles = makeStyles(() =>
  createStyles({
    defaultDropZone: {
      cursor: 'pointer',
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
      '&:hover': {
        borderBottom: `1px solid ${Palette.Base.Black}`,
      },
    },
    activeDropZone: {
      cursor: 'pointer',
      borderBottom: `1px solid ${Palette.Blue.Main}`,
      '&:hover': {
        borderBottom: `1px solid ${Palette.Blue.Main}`,
      },
    },
  })
);

const FileInput: React.FC<Props> = ({
  contentType,
  onSelect,
  disabled,
  limit,
}) => {
  const [popupText, setPopupText] = useState<
    { title: string; text: string } | undefined
  >(undefined);
  const classes = useStyles();
  const [inputKey, setInputKey] = useState(Date.now());
  const [isActive, setIsActive] = useState(false);

  const inputOpenFileRef = useRef<HTMLInputElement>(null);

  const handleSelection = (files: (File | null)[]) => {
    if (limit && files?.length < limit) {
      setPopupText({
        title: `Maximum ${limit} files allowed`,
        text: `Sorry, you cannot upload more than ${limit} files`,
      });
    } else {
      onSelect(files);
      setInputKey(Date.now());
    }
  };

  const onSelectFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.target.files;
    if (files) {
      handleSelection(Object.values(files));
    }
  };

  const onDropFile = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    const items = event.dataTransfer.items as DataTransferItemList;

    const files = Object.values(items)
      .filter((item: DataTransferItem) => item?.kind === 'file')
      .map((item: DataTransferItem) => item.getAsFile())
      .filter((file: File | null) => !!file);

    if (files) {
      handleSelection(files);
    }

    setIsActive(false);
  };

  const onButtonClick = (event: any) => {
    event.stopPropagation();
    inputOpenFileRef.current && inputOpenFileRef.current.click();
  };

  const handleDragOver = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();
      !isActive && setIsActive(true);
    },
    [isActive, setIsActive]
  );

  const handleDragLeave = useCallback(() => {
    isActive && setIsActive(false);
  }, [isActive, setIsActive]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box
        onDragOver={handleDragOver}
        onDrop={onDropFile}
        onDragLeave={handleDragLeave}
        className={isActive ? classes.activeDropZone : classes.defaultDropZone}
        p={1}
        width={1}
        position="relative"
        onClick={onButtonClick}
        bgcolor="white"
        borderRadius="4px 4px 0 0"
      >
        <input
          accept={contentType}
          ref={inputOpenFileRef}
          type="file"
          style={{ display: 'none' }}
          onChange={onSelectFile}
          multiple={!!limit ? limit > 1 : true}
          key={inputKey}
          disabled={disabled}
        />
        <Box textAlign="center">
          <Icon color="action">cloud_upload</Icon>
        </Box>
        <Box textAlign="center">
          <Typography>Drag and Drop files here</Typography>
        </Box>
        <Box textAlign="center" width={1} mt={1}>
          <Button
            onClick={onButtonClick}
            color="primary"
            variant="contained"
            disableElevation={true}
            disabled={disabled}
          >
            Browse
          </Button>
        </Box>
      </Box>
      <Alert
        open={!!popupText}
        icon="report_problem_outline_outline"
        title={popupText?.title || ''}
        submitButtonText="CLOSE"
        hideCancelButton
        onCancel={() => setPopupText(undefined)}
        onSubmit={() => setPopupText(undefined)}
      >
        <Typography color="textSecondary">{popupText?.text}</Typography>
      </Alert>
    </Box>
  );
};

export default React.memo(FileInput);
