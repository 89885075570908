import { formatISO, endOfMonth, addDays, startOfDay } from 'date-fns';

export { default } from './Date';

export const getCurrentDateTime = () => new Date().toISOString();
export const getStartOfDay = () => startOfDay(new Date()).toISOString();

export const getCurrentDate = () =>
  formatISO(new Date(), {
    representation: 'date',
  });

export const getNextMonthStartDate = () =>
  formatISO(addDays(endOfMonth(new Date()), 1));
