export const ENTRY_PERMISSION = 'settings';
export const PATH_PAYER_LIST = '/payers';
export const PATH_PAYER_NEW = `/payers/new`;
export const PATH_PAYER_VIEW = `/payers/:payerId`;
export const PATH_PAYER_EDIT = `/payers/:payerId/edit`;
export const PAYER_VALUESET = 'http://woundtech.net/fhir/ValueSet/payor-type';

export function getPayerNewUrl(): string {
  return PATH_PAYER_NEW;
}

export function getPayerViewUrl(payerId: string): string {
  return PATH_PAYER_VIEW.replace(':payerId', payerId);
}
export interface OrganizationReference extends fhir.Reference {
  resource?: fhir.Organization;
}
export interface Payer extends fhir.Organization {
  partOf?: OrganizationReference;
}
export const ENTRY_LINK = {
  path: '/payers',
  text: 'Payers',
  module: 'payer',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
export const ORGANIZATION_TYPE_SYSTEM =
  'http://woundtech.net/fhir/CodeSystem/organization-type';

export const IDENTIFIER_PAYER_ID_SYSTEM =
  'http://woundtech.net/identifiers/insurer-external';

export const IDENTIFIER_PAYER_CONTRACTED =
  'http://woundtech.net/identifiers/contracted';

export const IDENTIFIER_AVAILITY_PAYER_ID_SYSTEM =
  'https://apps.availity.com/public-web/payerlist-ui/payerlist-ui';

export const PAYER_ID_CODE = {
  payerId: IDENTIFIER_PAYER_ID_SYSTEM,
  availityPayerId: IDENTIFIER_AVAILITY_PAYER_ID_SYSTEM,
};

export const PAYER_LOOKUP_TYPES = [
  {
    coding: [
      {
        code: 'ins',
        display: 'Insurer',
        system: 'http://woundtech.net/fhir/CodeSystem/organization-type',
      },
    ],
    text: 'Insurer',
  },
  {
    coding: [
      {
        code: 'mso',
        display: 'MSO',
        system: 'http://woundtech.net/fhir/CodeSystem/organization-type',
      },
    ],
    text: 'MSO',
  },
  {
    coding: [
      {
        code: 'ipa',
        display: 'IPA',
        system: 'http://woundtech.net/fhir/CodeSystem/organization-type',
      },
    ],
    text: 'IPA',
  },
];
export const emptyMSOMessage = 'No MSO associated with this IPA.';
export const IPA_PAYER_TYPE = 'ipa';
export const PAYER_ORG_TYPES = 'ins,mso,ipa';
export const PAYER_ORG_TYPES_DISPLAY = {
  ins: 'Insurer',
  mso: 'MSO',
  ipa: 'IPA',
};
export const PAYER_STATUSES = {
  true: 'Active',
  false: 'Inactive',
};

export function getPayerId(data: Payer | undefined) {
  return data?.identifier?.find(
    identifier => identifier?.system === IDENTIFIER_PAYER_ID_SYSTEM
  )?.value;
}

export function getAvailityPayerId(data: Payer | undefined) {
  return data?.identifier?.find(
    identifier => identifier?.system === IDENTIFIER_AVAILITY_PAYER_ID_SYSTEM
  )?.value;
}

export function getPayerContracted(data: Payer | undefined) {
  return data?.identifier?.find(
    identifier => identifier?.system === IDENTIFIER_PAYER_CONTRACTED
  )?.value;
}
