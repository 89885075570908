import { APPLICATION_NAME } from '@woundtech/ui-constants';
import { setSessionStorageRecord } from 'application';
import { Breadcrumb } from 'styleguide/navigation/Backward/Component';
import { WoundTypeSuggestionType } from 'clinicalDecisionSupport/constants';
import { NOTIFICATION_CONTEXT_SESSION_KEY } from '.';
type Notification = {
  title: string;
  content: string;
  alertSeverity?: 'success' | 'info' | 'warning' | 'error';
  submitLabel?: string;
  onSubmit?: () => void;
};

type SuggestionsType = {
  woundTypeSuggestions?: WoundTypeSuggestionType[];
};

export type Action =
  | {
      type: 'addBreadcrumb';
      value: Breadcrumb;
    }
  | {
      type: 'replaceBreadcrumb';
      value: Breadcrumb;
    }
  | {
      type: 'resetBreadcrumbs';
    }
  | {
      type: 'setCurrentBreadcrumbId';
      id: string;
    }
  | { type: 'setInfoText'; value: string }
  | { type: 'setNotification'; value?: Notification }
  | { type: 'setPasswordExpiryNotification'; value?: Notification }
  | { type: 'loadLeftPane'; value: boolean }
  | { type: 'loadBarrierLastUpdated'; value: boolean }
  | { type: 'showSuggestion'; value: boolean; clickEnventHandler?: () => void }
  | { type: 'updateSuggestions'; value: SuggestionsType }
  | { type: 'setSuggestedWoundType'; value: string }
  | {
      type:
        | 'setSearchQuery'
        | 'setFilters'
        | 'setSavedFilters'
        | 'setFilterToggle'
        | 'setCachedFilterOwner'
        | 'setSavedFiltersApplied'
        | 'setUserPreferences';
      value?: any;
    }
  | {
      type: 'applyFilters';
      value?: {
        values: any;
        dateFilterValues: any;
      };
    };
export interface ITaskFilters {
  status?: fhir.CodeableConcept[];
  type?: fhir.CodeableConcept[];
  priority?: fhir.CodeableConcept[];
  owner?: any[];
  filterUnassigned?: boolean;
  dueBy?: any;
  authoredOn?: any;
  state?: any;
  markets?: any;
  pcc?: {
    reference: string;
    resource: fhir.Practitioner;
  };
}
export type State = {
  title: string;
  breadcrumbs: Breadcrumb[];
  info: string | undefined;
  loadLeftPane: boolean;
  loadBarrierLastUpdated: boolean;
  currentBreadcrumbId?: string;
  notification?: Notification;
  passwordExpiryNotification?: Notification;
  showSuggestion?: boolean;
  onClickSuggestion?: () => void;
  suggestions?: SuggestionsType;
  suggestedWoundType?: string | undefined;
  searchQuery?: string;
  filters?: ITaskFilters | undefined;
  savedFilters?: any;
  filterToggle?: boolean;
  owner?: any;
  dateFilterTab?: boolean;
  dueByDateFilterOption?: any;
  createdDateFilterOption?: any;
  dateFilterPeriod?: any;
  savedFiltersApplied?: boolean;
  userPreferences?: any;
};

export const defaultState: State = {
  title: APPLICATION_NAME,
  breadcrumbs: restoreBreadcrumbs(),
  info: undefined,
  loadLeftPane: false,
  loadBarrierLastUpdated: false,
  currentBreadcrumbId: restoreCurrentBreadcrumbId(),
  showSuggestion: false,
  onClickSuggestion: () => {},
};

function backup(breadcrumbs: Breadcrumb[], currentBreadcrumbId?: string) {
  if (window?.sessionStorage) {
    window.sessionStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));

    if (currentBreadcrumbId) {
      window.sessionStorage.setItem('currentBreadcrumbId', currentBreadcrumbId);
    }
  }
}

function restoreBreadcrumbs() {
  if (!window?.sessionStorage) {
    return [];
  }

  const data = window.sessionStorage.getItem('breadcrumbs');
  if (!data) {
    return [];
  }

  try {
    return JSON.parse(data);
  } catch {
    return [];
  }
}

function restoreCurrentBreadcrumbId() {
  if (!window?.sessionStorage) {
    return;
  }

  const data = window.sessionStorage.getItem('currentBreadcrumbId');
  if (!data) {
    return;
  }

  return data;
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addBreadcrumb': {
      const currentBreadcrumbId = action.value.id;

      // if already exists skip
      if (state.breadcrumbs.find(item => item.id === currentBreadcrumbId)) {
        return state;
      }

      let breadcrumbs = state.breadcrumbs;

      const activeIndex = state.breadcrumbs.findIndex(
        breadcrumb => breadcrumb.id === state.currentBreadcrumbId
      );

      if (activeIndex !== -1 && activeIndex !== breadcrumbs.length - 1) {
        breadcrumbs = state.breadcrumbs.filter(
          (_, index) => index <= activeIndex
        );
      }

      breadcrumbs = [...breadcrumbs, action.value];

      backup(breadcrumbs, currentBreadcrumbId);

      return {
        ...state,
        breadcrumbs,
        currentBreadcrumbId,
      };
    }
    case 'setCurrentBreadcrumbId': {
      const currentBreadcrumbId = action.id;

      // if already exists skip
      if (state.currentBreadcrumbId === currentBreadcrumbId) {
        return state;
      }

      backup(state.breadcrumbs, currentBreadcrumbId);

      return {
        ...state,
        currentBreadcrumbId,
      };
    }
    case 'replaceBreadcrumb': {
      const currentBreadcrumbId = action.value.id;

      // if already exists skip
      if (state.breadcrumbs.find(item => item.id === currentBreadcrumbId)) {
        return state;
      }

      const breadcrumbs = state.breadcrumbs.map(breadcrumb => {
        if (breadcrumb.id === state.currentBreadcrumbId) {
          return {
            ...breadcrumb,
            id: currentBreadcrumbId,
          };
        }

        return breadcrumb;
      });

      backup(breadcrumbs, currentBreadcrumbId);

      return {
        ...state,
        currentBreadcrumbId,
        breadcrumbs,
      };
    }
    case 'resetBreadcrumbs': {
      const currentBreadcrumbId = undefined;
      const breadcrumbs = [];

      backup(breadcrumbs, currentBreadcrumbId);

      return {
        ...state,
        currentBreadcrumbId,
        breadcrumbs,
      };
    }
    case 'setInfoText': {
      return {
        ...state,
        info: action.value,
      };
    }
    case 'loadLeftPane': {
      return {
        ...state,
        loadLeftPane: action.value,
      };
    }
    case 'loadBarrierLastUpdated': {
      return {
        ...state,
        loadBarrierLastUpdated: action.value,
      };
    }
    case 'setNotification': {
      return {
        ...state,
        notification: action.value,
      };
    }
    case 'setPasswordExpiryNotification': {
      return {
        ...state,
        passwordExpiryNotification: action.value,
      };
    }
    case 'showSuggestion': {
      return {
        ...state,
        showSuggestion: action.value,
        onClickSuggestion: action.clickEnventHandler,
      };
    }
    case 'updateSuggestions': {
      return {
        ...state,
        suggestions: action.value,
      };
    }
    case 'setSuggestedWoundType': {
      return {
        ...state,
        suggestedWoundType: action.value,
      };
    }
    case 'setSearchQuery': {
      const newState = { ...state, searchQuery: action.value };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'setFilters': {
      const newState = {
        ...state,
        filters: { ...(state?.filters || []), ...action.value },
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'setUserPreferences': {
      const newState = { ...state, userPreferences: { ...action.value } };
      return newState;
    }
    case 'setSavedFilters': {
      const newState = {
        ...state,
        savedFilters: { ...action.value },
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'setSavedFiltersApplied': {
      const newState = {
        ...state,
        savedFiltersApplied: action.value,
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'applyFilters': {
      const newState = {
        ...state,
        filters: { ...(state?.filters || []), ...action.value?.values },
        ...action.value?.dateFilterValues,
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'setCachedFilterOwner': {
      const newState = {
        ...state,
        owner: action.value,
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }
    case 'setFilterToggle': {
      const newState = {
        ...state,
        filterToggle: action.value,
      };
      setSessionStorageRecord(NOTIFICATION_CONTEXT_SESSION_KEY, newState);
      return newState;
    }

    default:
      return state;
  }
}
