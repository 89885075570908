import { format, utcToZonedTime } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { DISPLAY_DATETIME_FORMAT, UTC_OFFSET_TIMEZONE_DATA } from './constants';

export const shortDatetime = (
  date: fhir.date | undefined,
  timeZone?: string
) => {
  if (date) {
    try {
      if (timeZone) {
        return format(utcToZonedTime(date, timeZone), DISPLAY_DATETIME_FORMAT);
      }

      return format(parseISO(date), DISPLAY_DATETIME_FORMAT);
    } catch {
      return PlaceholderKind.NotAvailable;
    }
  }

  return PlaceholderKind.NotAvailable;
};

export const shortTimezoneDateTime = (date: string | undefined) => {
  if (date) {
    try {
      return format(new Date(date), DISPLAY_DATETIME_FORMAT);
    } catch {
      return PlaceholderKind.NotAvailable;
    }
  }
  return PlaceholderKind.NotAvailable;
};

export const shortDatetimeWithTimezone = (date: fhir.date | undefined) => {
  if (date) {
    let fmtdDate: string;
    let utcOffset: number | string;
    try {
      fmtdDate = format(parseISO(date), DISPLAY_DATETIME_FORMAT);
    } catch {
      return PlaceholderKind.NotAvailable;
    }
    try {
      utcOffset = new Date(date).getTimezoneOffset() / 60;
    } catch {
      utcOffset = new Date().getTimezoneOffset() / 60;
    }
    utcOffset = `UTC${utcOffset}`;
    return `${fmtdDate} ${UTC_OFFSET_TIMEZONE_DATA[utcOffset] || utcOffset}`;
  }

  return PlaceholderKind.NotAvailable;
};
