import React, { useState, useEffect, useMemo } from 'react';
import Icon from '@material-ui/core/Icon';
import { Box, Divider, Button, Typography } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import FieldSet from 'styleguide/layout/FieldSet';
import { PatchOperationKind } from '@woundtech/ui-constants';

const cancelButtonTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
  overrides: {
    MuiButton: {
      root: {
        whiteSpace: 'nowrap',
        minWidth: 'auto',
      },
      outlined: {
        background: '#FFF',
      },
    },
  },
});

export type Props = {
  context?: any;
  data: any;
  disableRemove?: boolean;
  isItemEmpty: (data: any) => boolean;
  maxItems?: number;
  message?: string;
  minItems?: number;
  onChange: (data?: any) => any;
  onValidate: (isValid: boolean) => void;
  page: React.FC<any>;
  showRemoveForFirstEntry?: boolean;
  title: string;
  required?: boolean;
  patch?: boolean;
  customOnChange?: boolean;
  softDeleteItem?: boolean;
};

const Collection: React.FC<Props> = ({
  context,
  data,
  disableRemove,
  isItemEmpty,
  maxItems,
  message,
  minItems = 0,
  onChange,
  onValidate,
  page: Component,
  showRemoveForFirstEntry,
  title,
  required,
  patch,
  customOnChange,
  softDeleteItem,
}) => {
  const [validity, setValidity] = useState<any>({});
  let items = useMemo(() => data || (minItems === 0 ? [] : [{}]), [
    data,
    minItems,
  ]);

  const addItems = () => {
    const defaultObject = !context?.defaultValue ? {} : context.defaultValue;
    const values = [...items, defaultObject];
    if (customOnChange) {
      onChange()(values);
    } else {
      onChange(values);
    }
  };

  const removeItems = patch
    ? (index: number) => {
        const values = items
          .map((currentItem, currentIndex) => {
            if (currentIndex === index) {
              if (currentItem?.operation === PatchOperationKind.Add) {
                return undefined;
              }

              return {
                ...currentItem,
                operation: PatchOperationKind.Remove,
              };
            }

            return currentItem;
          })
          .filter(item => !!item);

        const validityValues = { ...validity };
        delete validityValues[index];
        setValidity({ ...validityValues });
        if (customOnChange) {
          onChange()(values);
        } else {
          onChange(values);
        }
      }
    : (index: number) => {
        const values = [...items];
        values.splice(index, 1);
        const validityValues = { ...validity };
        delete validityValues[index];
        setValidity({ ...validityValues });
        if (customOnChange) {
          if (softDeleteItem) {
            const updatedItems = values?.map((item, currentIndex) =>
              currentIndex === index ? { ...item, active: false } : item
            );
            onChange()(updatedItems);
          } else {
            onChange()(values);
          }
        } else {
          onChange(values);
        }
      };

  const handleItemsChange = selectedIndex => (value: any) => {
    let items = data || [];

    const selectedObject = items[selectedIndex];
    if (!selectedObject) {
      items = [...items, value];
    } else {
      items = items.map((item, itemIndex) =>
        itemIndex === selectedIndex ? value : item
      );
    }
    onChange(items);
  };

  const handleValidityChange = (key: string) => (
    isValid: boolean | undefined
  ) => {
    if (validity[key] !== isValid) {
      setValidity({
        ...validity,
        [key]: isValid,
      });
    }
  };
  const isValid = Object.keys(validity).every((key: string) =>
    items[key] ? validity[key] : true
  );
  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);

  const isDisabled =
    useMemo(() => {
      return isItemEmpty(items);
    }, [items, isItemEmpty]) || !isValid;

  const action = {
    text: 'Add',
    icon: 'add',
    onClick: addItems,
    disabled: (maxItems && items.length === maxItems) || isDisabled,
  };

  const headerContent = message ? (
    <Typography variant="body1">
      No authorization attached for this patient
    </Typography>
  ) : (
    undefined
  );

  return (
    <FieldSet
      label={title}
      required={required}
      action={action}
      headerContent={headerContent}
    >
      {items
        .filter(item => item?.operation !== PatchOperationKind.Remove)
        .map((item, index) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Box mt={2} />}
              {index > 0 && <Divider light />}
              <Box mt={2} />
              <FieldSet>
                <Box>
                  <Component
                    data={item}
                    onChange={
                      customOnChange
                        ? onChange(index)
                        : handleItemsChange(index)
                    }
                    onValidate={handleValidityChange(index)}
                    {...context}
                    index={index}
                    isStateRequired
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="right"
                  mt={2}
                >
                  {!showRemoveForFirstEntry ? (
                    index !== 0 && (
                      <ThemeProvider theme={cancelButtonTheme}>
                        <Button
                          disableElevation
                          color="primary"
                          variant="outlined"
                          size="small"
                          startIcon={<Icon>{'delete'}</Icon>}
                          onClick={() => removeItems(index)}
                        >
                          REMOVE
                        </Button>
                      </ThemeProvider>
                    )
                  ) : (
                    <ThemeProvider theme={cancelButtonTheme}>
                      <Button
                        disableElevation
                        color="primary"
                        variant="outlined"
                        size="small"
                        startIcon={<Icon>{'delete'}</Icon>}
                        onClick={() => removeItems(index)}
                        disabled={disableRemove}
                      >
                        REMOVE
                      </Button>
                    </ThemeProvider>
                  )}
                </Box>
              </FieldSet>
            </React.Fragment>
          );
        })}
    </FieldSet>
  );
};

export default React.memo(Collection);
