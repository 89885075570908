import { OrganizationReference } from 'organization';

export const VALUE_SET_ROLE = 'http://woundtech.net/fhir/ValueSet/role';
export const VALUE_SET_JOB_FUNCTION =
  'http://woundtech.net/fhir/ValueSet/job-function';

export const CODE_SYSTEM_JOB_FUNCTION =
  'http://woundtech.net/fhir/CodeSystem/job-function';

export enum UserJobFunction {
  billing = 'billing',
  caseManager = 'cmon',
  clinician = 'clin',
  crossFunctional = 'other',
  executive = 'executive',
  leadClinician = 'leadclin',
  advanceClinician = 'advclin',
  pcc = 'pons',
  pccSupport = 'poff',
  processAssociate = 'pass',
  supervisingPhysician = 'sphy',
  telemedicineClinician = 'telemedicine-clinician',
  onsiteProvider = 'onsite-provider',
  licensedClinician = 'lic-clin',
  assignedProvider = 'assigned-provider',
}

export enum UserRole {
  administrator = 'admisnistrator',
  external = 'external',
  staff = 'staff',
  financialAdmin = 'finadmin',
  securityAdmin = 'security-admin',
  clinicalAdmin = 'clinical-admin',
  reviewerReadOnly = 'reviewer-readonly',
}

export const JOB_FUNCTION_ONSHORE_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.pcc,
    },
  ],
};

export const JOB_FUNCTION_OFFSHORE_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.pccSupport,
    },
  ],
};

export const JOB_FUNCTION_CLINICIAN_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.clinician,
      display: 'Clinician',
    },
  ],
};
export const JOB_FUNCTION_ADVANCE_CLINICIAN_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.advanceClinician,
      display: 'Lead Preceptor',
    },
  ],
};

export const JOB_FUNCTION_LEAD_CLINICIAN_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.leadClinician,
    },
  ],
};

export const JOB_FUNCTION_TH_CLINICIAN_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.telemedicineClinician,
    },
  ],
};

export const JOB_FUNCTION_ONSITE_PROVIDER_CONCEPT = {
  coding: [
    {
      system: 'http://woundtech.net/fhir/CodeSystem/job-function',
      code: UserJobFunction.onsiteProvider,
    },
  ],
};

export interface User extends fhir.Practitioner {
  jobFunctions?: fhir.CodeableConcept[];
  legalEntities?: OrganizationReference[];
  payers?: OrganizationReference[];
  roles?: fhir.CodeableConcept[];
  permissions?: fhir.CodeableConcept[];
  status?: string;
  statusUpdatedDate?: string;
  lastLoggedIn?: string;
  userPin?: string;
  userPinModifiedOn?: string;
  passwordChangedOn?: string;
  passwordExpiresConfigValue?: number;
  passwordChangedSinceInDays?: number;
}

export const PATH_USER_LIST = '/users';
export const PATH_USER_NEW = `/users/new`;
export const PATH_USER_VIEW = `/users/:userId`;
export const PATH_USER_EDIT = `/users/:userId/edit`;

export function getUserListUrl(): string {
  return PATH_USER_LIST;
}

export function getUserNewUrl(): string {
  return PATH_USER_NEW;
}

export function getUserViewUrl(userId: string): string {
  return PATH_USER_VIEW.replace(':userId', userId);
}

export function getUserEditUrl(userId: string): string {
  return PATH_USER_EDIT.replace(':userId', userId);
}

export function getUserStates(user) {
  return user?.legalEntities?.map(
    entity => entity?.resource?.address?.[0]?.state
  );
}

export const ENTRY_PERMISSION = 'security';
export const ENTRY_LINK = {
  path: '/users',
  text: 'Users',
  module: 'user',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export function getUserById(user, userId) {
  return user?.find(value => value?.id === userId);
}

export function hasUserPermission(user, permissionId) {
  return !!user?.permissions?.find(
    permission => permission?.coding?.[0]?.code === permissionId
  );
}

export function hasUserJobFunctions(user, targetJobFunctionCodes: string[]) {
  return user?.jobFunctions?.some(jobFunction => {
    const jobFunctionCode = jobFunction?.coding?.[0].code;
    return targetJobFunctionCodes.some(
      targetJobFunctionCode => targetJobFunctionCode === jobFunctionCode
    );
  });
}

export function hasUserRoles(user, targetRoleCodes: string[]) {
  return user?.roles?.some(role => {
    const roleCode = role?.coding?.[0]?.code;
    return targetRoleCodes.some(targetRoleCode => targetRoleCode === roleCode);
  });
}

export function isPcc(user?: User | null) {
  return hasUserJobFunctions(user, [
    UserJobFunction.pcc,
    UserJobFunction.pccSupport,
  ]);
}

export function isClinician(user?: User | null) {
  return hasUserJobFunctions(user, [
    UserJobFunction.clinician,
    UserJobFunction.advanceClinician,
    UserJobFunction.licensedClinician,
  ]);
}

export function isClinOrAdvClinician(user?: User | null) {
  return hasUserJobFunctions(user, [
    UserJobFunction.clinician,
    UserJobFunction.advanceClinician,
  ]);
}

export function isClinOrLicClinician(user?: User | null) {
  return hasUserJobFunctions(user, [
    UserJobFunction.clinician,
    UserJobFunction.licensedClinician,
  ]);
}

export function isAdvClinician(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.advanceClinician]);
}

export function isLeadClinician(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.leadClinician]);
}

export function isSupervisingPhysician(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.supervisingPhysician]);
}

export function isOnsiteProvider(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.onsiteProvider]);
}

export function isTelemedicineClinician(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.telemedicineClinician]);
}

export function isCaseManager(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.caseManager]);
}

export function isExternalUser(user?: User | null) {
  return hasUserRoles(user, [UserRole.external]);
}

export function isReviewerReadOnlyUser(user?: User | null) {
  return hasUserRoles(user, [UserRole.reviewerReadOnly]);
}

export function isExternalUserOrReviewerReadOnlyUser(user?: User | null) {
  return hasUserRoles(user, [UserRole.external, UserRole.reviewerReadOnly]);
}

export function isLicensedClinician(user?: User | null) {
  return hasUserJobFunctions(user, [UserJobFunction.licensedClinician]);
}
