import { User } from '../user/index';

export interface CareTeamParticipant {
  member: {
    reference?: string;
    resource: User;
  };
}

export interface CareTeam extends fhir.CareTeam {
  participant: CareTeamParticipant[];
}

export const ENTRY_PERMISSION = 'settings';
export const ENTRY_LINK = {
  path: '/teams',
  text: 'Teams',
  module: 'team',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};
