import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import { ModalBar } from './ModalBar';
import VerticalSpacing from 'styleguide/spacing/Vertical';
import HorizontalSpacing from 'styleguide/spacing/Horizontal';

export type ActionProps = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

export type Props = {
  children?: React.ReactNode;
  noSpacing?: boolean;
  onClose: () => void;
  onBackdropClick?: () => void;
  open?: boolean;
  title?: string;
  lgWidth?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContent: {
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '50%',
      },
    },
    drawerLargeContent: {
      height: '100%',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '84%',
      },
    },
  })
);

const ModalDrawer: React.FC<Props> = ({
  children,
  open,
  onClose,
  onBackdropClick,
  title,
  noSpacing = false,
  lgWidth,
}) => {
  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        classes={{
          paper: lgWidth ? classes.drawerLargeContent : classes.drawerContent,
        }}
        ModalProps={{
          onBackdropClick: onBackdropClick ? onBackdropClick : onClose,
        }}
      >
        <Box
          data-drawer
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <ModalBar onCancel={onClose} title={title} />
          <Box
            data-drawer-content
            flexGrow={1}
            overflow="hidden auto"
            display="flex"
          >
            <Box height="auto" width={1}>
              {noSpacing ? (
                <Box height={1} overflow="auto">
                  {children}
                </Box>
              ) : (
                <VerticalSpacing>
                  <HorizontalSpacing>
                    <Box height={1} overflow="auto">
                      {children}
                    </Box>
                  </HorizontalSpacing>
                </VerticalSpacing>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default React.memo(ModalDrawer);
