import React from 'react';
import CheckBoxTouchButton from './CheckBoxTouchButton';
import Grid from '@material-ui/core/Grid';
import { OPTION_STATUS } from '../constants';

export type Props = {
  value: any;
  lastSelectedValues?: any[];
  options: any[];
  onChange: (isChecked: boolean, option: any) => void;
  getOptionId: (option: any) => string | undefined;
  getOptionLabel: (option: any) => string | undefined;
  isDisabled?: (option: any) => boolean;
  isSelected?: (option: any) => boolean;
  fullWidth?: boolean;
  getTag?: (option: any) => any | undefined;
  customStyle?: boolean;
};

const CheckBoxTouchGroup: React.FC<Props> = ({
  value,
  lastSelectedValues = [],
  options = [],
  onChange,
  getOptionId,
  getOptionLabel,
  fullWidth = false,
  isDisabled = () => false,
  getTag,
  isSelected = option =>
    value?.some(
      selectedValue =>
        selectedValue?.valueCoding?.code === option?.code ||
        selectedValue?.code === option?.code
    ),
  customStyle,
}) => {
  const getOptionVariant = (option: fhir.CodeableConcept) => {
    const previouslySelected = lastSelectedValues?.some(
      value => getOptionId(option) === getOptionId(value)
    );
    if (isSelected(option) && previouslySelected) {
      return OPTION_STATUS.selectedAgain;
    } else if (isSelected(option)) {
      return OPTION_STATUS.selected;
    } else if (previouslySelected) {
      return OPTION_STATUS.lastSelected;
    } else {
      return OPTION_STATUS.unSelected;
    }
  };

  const getColumnXS = (label): 4 | 8 | 12 => {
    return 12;
  };

  const getColumnSM = (label): 3 | 6 | 8 | 12 => {
    if (fullWidth) return 12;
    if (label.length > 30) {
      return 12;
    } else if (label.length > 20) {
      return 8;
    } else if (label.length > 8) {
      return 6;
    } else {
      return 3;
    }
  };

  const getColumnMD = (label): 4 | 8 | 12 => {
    if (fullWidth) return 12;
    if (label.length > 40) {
      return 12;
    } else if (label.length > 15) {
      return 8;
    } else {
      return 4;
    }
  };
  const getColumnXL = (label): 3 | 6 | 9 | 12 => {
    if (fullWidth) return 12;
    if (label.length > 40) {
      return 9;
    } else if (label.length > 15) {
      return 6;
    } else {
      return 3;
    }
  };

  return (
    <Grid container spacing={2}>
      {options.map((option, i) => {
        const optionLabel = getOptionLabel(option) || '';
        const optionId = getOptionId(option) || '';
        return (
          <Grid
            item
            key={optionId + i}
            xs={getColumnXS(optionLabel)}
            sm={getColumnSM(optionLabel)}
            md={getColumnMD(optionLabel)}
            xl={getColumnXL(optionLabel)}
          >
            <CheckBoxTouchButton
              label={optionLabel || option?.display}
              onClick={isChecked => onChange(isChecked, option)}
              variant={getOptionVariant(option)}
              disabled={isDisabled(option)}
              tag={getTag && getTag(option)}
              customStyle={customStyle}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default React.memo(CheckBoxTouchGroup);
