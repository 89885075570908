import { PlaceholderKind } from '@woundtech/ui-constants';

export const multipleValuesFormat = (
  concepts: fhir.CodeableConcept[] | undefined
) => {
  if (!Array.isArray(concepts) || concepts.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  return (
    concepts
      ?.map(
        (concept: fhir.CodeableConcept) =>
          concept.coding?.[0]?.display || concept.coding?.[0]?.code
      )
      .join(', ') || PlaceholderKind.NotAvailable
  );
};
