import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Field } from '../types';
import RadioTouchGroup from './RadioTouchGroup';

import { VALIDATION_ERROR_REQUIRED } from 'utility/constants';
import Label from 'styleguide/form/Label';
import InlineError from 'styleguide/core/InlineError';
import Disabled from 'styleguide/Disabled';
import SearchBar from 'styleguide/form/SearchBar';

export interface Props extends Field {
  onChange: (value: any | undefined, name: string) => void;
  options: any[];
  value: string | undefined;
  lastSelectedValue?: any;
  getOptionId?: (option: any) => string | undefined;
  getTag?: (option: any) => any;
  getOptionLabel?: (option: any) => string | undefined;
  getOptionWidth?: (
    option: any,
    id: string,
    label: string,
    isMobile?: boolean
  ) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  description?: string | undefined;
  hasError?: boolean;
  inline?: boolean;
  labelSize?: 'large' | 'medium' | 'small';
  noWrap?: boolean;
  enableSearchBar?: boolean;
  getOptionDisabled?: (option: fhir.CodeableConcept) => boolean;
  search?: string;
  setSearch?: (value) => void;
  filteredOptions?: string[];
  type?: 'button';
}

const validate = (value: string | undefined, required?: boolean) => {
  if (required && !value) {
    return VALIDATION_ERROR_REQUIRED;
  }
};

const SingleChoiceGroup: React.FC<Props> = ({
  label,
  name,
  onChange,
  onValidate,
  options,
  required,
  value,
  lastSelectedValue,
  description,
  hasError,
  inline,
  labelSize,
  getOptionId = (option: fhir.CodeableConcept) => option?.coding?.[0]?.code,
  getOptionLabel = (option: fhir.CodeableConcept) =>
    option?.coding?.[0]?.display,
  getOptionWidth,
  noWrap,
  getTag,
  disabled,
  enableSearchBar = false,
  getOptionDisabled,
  search = '',
  setSearch = () => {},
  filteredOptions = [],
  type,
}) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, code: string) => {
    if (code === value) {
      return required ? undefined : onChange(undefined, name);
    }
    const concept =
      options.find((option: any) => {
        return getOptionId(option) === code;
      }) || undefined;
    onChange(concept, name);
  };

  const helperText = validate(value, required) || '';
  const isValid = !helperText;

  useEffect(() => {
    onValidate(isValid);
  }, [isValid, onValidate]);
  return (
    <Box width={1}>
      <Disabled disabled={disabled}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Box display="flex" alignItems="center" flexGrow={1}>
            {label ? (
              <Label size={labelSize} required={required}>
                {label}
              </Label>
            ) : null}
          </Box>
          {hasError && (
            <Box>
              <InlineError text={VALIDATION_ERROR_REQUIRED} />
            </Box>
          )}
        </Box>
        {description && (
          <Typography color="textSecondary">{description}</Typography>
        )}
        {enableSearchBar && <SearchBar search={search} onChange={setSearch} />}
        <Box id={name} mt={label ? 2 : 0}>
          <RadioTouchGroup
            getOptionDisabled={getOptionDisabled}
            onChange={handleChange}
            value={value}
            options={enableSearchBar ? filteredOptions.sort() : options}
            getOptionId={getOptionId}
            getOptionLabel={getOptionLabel}
            getOptionWidth={getOptionWidth}
            lastSelectedValue={lastSelectedValue}
            inline={inline}
            noWrap={noWrap}
            getTag={getTag}
            type={type}
          />
        </Box>
      </Disabled>
    </Box>
  );
};

export default SingleChoiceGroup;
