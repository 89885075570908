import React from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      height: '100%',
      left: '50%',
      marginLeft: '-50vw',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      width: '100vw',
      background: `linear-gradient(45deg,${theme.palette.primary.main} 0%,${theme.palette.secondary.main} 100%)`,
      opacity: '0.8',
      zIndex: 1,
    },
    children: {
      height: '100%',
      left: '0',
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 2,
      overflow: 'hidden',
    },
  })
);

const PublicLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.overlay} />
      <Box className={classes.children}>{children}</Box>
      <Hidden mdDown>
        <video autoPlay loop muted playsInline preload="auto" width="100%">
          <source
            src="https://woundtech.net/wt2019/wp-content/uploads/2019/01/Woundtech-Cover.mp4"
            type="video/mp4"
          />
        </video>
      </Hidden>
    </>
  );
};

export default PublicLayout;
