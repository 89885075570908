import React, { useCallback, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import createStyles from '@material-ui/styles/createStyles';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/styles/makeStyles';

import { PrivateLayoutContext } from './Context';
import { PrimaryNavigationItemProps } from './Navigation';
import NavigationList from './NavigationList';

export type NavigationItemProps = {
  item: PrimaryNavigationItemProps;
  toggleOpen?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: '36px',
    },
  })
);

const NavigationItem: React.FC<NavigationItemProps> = ({
  item,
  toggleOpen,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const { dispatch } = useContext(PrivateLayoutContext);

  const selection = useRouteMatch({
    path: item.path,
    exact: false,
  });

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const url = item.path;
  const isSelected = selection?.isExact;
  const handleNavigation = useCallback(() => {
    if (toggleOpen) toggleOpen();

    if (isSelected) {
      return false;
    }

    history.push(url);

    dispatch({
      type: 'resetBreadcrumbs',
    });
  }, [isSelected, history, url, dispatch, toggleOpen]);

  if (!!item.children) {
    return (
      <>
        <ListItem button disabled={item.disabled} onClick={handleClick}>
          <ListItemIcon className={classes.icon}>
            <Icon color="inherit">{item.icon}</Icon>
          </ListItemIcon>
          <ListItemText disableTypography primary={item.text} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box pl={1}>
            <NavigationList items={item.children} toggleOpen={toggleOpen} />
          </Box>
        </Collapse>
      </>
    );
  }

  return (
    <ListItem
      button
      disabled={item.disabled}
      onClick={handleNavigation}
      selected={!!selection}
    >
      <ListItemIcon className={classes.icon}>
        <Icon color="inherit">{item.icon}</Icon>
      </ListItemIcon>
      <ListItemText disableTypography primary={item.text} />
    </ListItem>
  );
};

export default React.memo(NavigationItem);
