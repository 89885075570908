export const CLINICAL_CONFIG_PATH = '/clinical-config';
export const PRODUCTS = 'products';
export const PROCEDURES = 'procedures';
export const TREATMENT_STEPS = 'treatment-steps';
export const WOUND_TYPE = 'wound-type';
export const ORDERS = 'orders';
export const FORM_CONFIG = 'forms-config';
export const ENCOUNTER_BASELINE_TITLE = 'Encounter Baseline';
export const ENCOUNTER_DURATION = 'encounter-duration';
export const ENCOUNTER_STANDARD_ID = 'standard-duration';
export const ENCOUNTER_ACCUITY_LEVEL_ID = 'accuity-level-multiplier';
export const ENCOUNTER_STANDARD = 'Standard duration';
export const ENCOUNTER_ACCUITY_LEVEL = 'Accuity level multiplier';
export const SNOMED_CODE =
  'http://woundtech.net/fhir/CodeSystem/woundtech-snomedct';
export const TOPIC_LABEL = 'Name';
export const DISPLAY_ROLE = 'admin';
export const AVAILABLE_PRODUCT = 'Available Product';
export const SELECTED_PRODUCT = 'Selected Product';
export const SELECTED_FORMULARY = 'Select a formulary to preview';
export const SELECTED_PROCEDURES = 'Select a procedure to preview';
export const SELECTED_TREATMENT = 'Select a treatment to preview';
export const DRAG_DROP_TREATMENT =
  'You can drag and drop the treatment step as per the order';
export const WoundTypeIds = [
  'pressure-injury',
  'diabetic-ulcer',
  'venous-ulcer',
  'arterial-ulcer',
  'vasculitic-ulcer',
  'trauma-wound',
  'surgical-wound',
  'malignant-wound',
  'atypical-wound',
  'acute-skin-injury',
  'generic-treatment',
];
export const TOPIC_TEST_LABEL = 'Test';
export const TOPIC_NO_TESTS_LABEL = 'There are no associated tests';
export const ORDERS_CARD_TEXT = 'Select a card to preview';
export const ORDERS_NON_CONFIGURABLE_IDS = [
  'biopsy',
  'medication',
  'others',
  'sharp-debridement',
];
export const CPT_LOOKUP_SYSTEM =
  'http://woundtech.net/fhir/CodeSystem/cpt-hcpcs';
export const ORDERS_CPT_TYPE = 'procedureCode';
export const ORDERS_EXTENSION =
  'http://woundtech.net/fhir/CodeSystem/woundtech-clinical-orders';
export const EDUCATIONAL_TRAINING = 'educational-training';
export const EDUCATION_DELIVERY = 'educational-delivery';
export const EDUCATION_DELIVERY_PROVIDED_ON =
  'educational-delivery-training-provided-on';
export const EDUCATION_EXTENSION =
  'http://woundtech.net/fhir/CodeSystem/woundtech-snomedct-educational-delivery';
export const EDUCATION_SELECT_TEXT =
  'Select an educational delivery to preview';
export const EDUCATION_TOPIC = 'Training Topic';
export const REVIEW_OF_SYSTEM = 'review-of-systems';
export const BARRIERS = 'barriers';
export const MEDICAL_CONDITION = 'medical-condition';
export const ADDITIONAL_CONDITION = 'additional-conditions';
export const ONE_OR_MORE = 'one-or-more';
export const MUST_UNLESS_DOCUMENTED = 'must-unless-documented';
export const SOC_PATIENT = 'soc-patient';
export const FOLLOW_UP = 'follow-up';
export const ALL_OR_NONE = 'all-or-none';
export const ENCOUNTER_ALL_FORMS =
  'http://woundtech.net/fhir/ValueSet/encounter-all-forms';

export const VISIBLE_URL = 'visible';
export const REQUIRED_URL = 'required';
export const MUST = 'must';
export const NEVER = 'never';
export const NO = 'no';
export const ANY = 'any';
export const COULD = 'could';

export const REQUIRED_OPTIONS = [
  {
    coding: [
      {
        code: MUST,
        display: 'Yes',
      },
    ],
    text: 'Yes',
  },
  {
    coding: [
      {
        code: NO,
        display: 'No',
      },
    ],
    text: 'No',
  },
];

export const VISIBLE_OPTIONS = [
  {
    coding: [
      {
        code: ONE_OR_MORE,
        display: 'Always',
      },
    ],
    text: 'Always',
  },
  {
    coding: [
      {
        code: NEVER,
        display: 'Never',
      },
    ],
    text: 'Never',
  },
];

export const PERIODIC_VISIBLE_OPTION = [
  {
    coding: [
      {
        code: 'any',
        display: 'Periodic',
      },
    ],
    text: 'Periodic',
  },
];

export const PERIODIC_REQUIRED_OPTION = [
  {
    coding: [
      {
        code: 'could',
        display: 'Periodic',
      },
    ],
    text: 'Periodic',
  },
];

export const FORM_LOGIC_NON_CONFIGURABLE_IDS = ['wounds'];
