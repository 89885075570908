import React, { useCallback, useState, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PrimaryList from 'common/PrimaryList';
import { formatSearch } from 'patient/utils';
import { DocumentNode } from 'graphql';

type Props = {
  card: React.ReactNode;
  query: DocumentNode;
  title: string | undefined;
  baseUrl?: string;
  type: string;
  listType: string;
  paramId?: string;
  textCreate?: string;
  active?: string;
  dataList?: any;
  onSelect?: (careFacility: any) => void;
  payloadvariables?: any;
};

const Container: React.FC<Props> = ({
  card,
  query,
  title,
  baseUrl,
  type,
  listType,
  paramId,
  textCreate,
  active = 'true,false',
  onSelect,
  dataList,
  payloadvariables,
}) => {
  const history = useHistory();

  const isCreatePath = useRouteMatch({
    path: `${baseUrl}/new`,
    exact: true,
  });
  const [searchEncounterQuery, setSearchEncounterQuery] = useState<string>('');

  const handleSearch = useCallback((value: string) => {
    setSearchEncounterQuery(value);
  }, []);

  const handleCreate = useCallback(() => {
    const url = `${baseUrl}/new`;
    history.push(url);
  }, [history, baseUrl]);

  const handleSelection = useCallback(
    (selection: any) => {
      const url = `${baseUrl}/${selection?.id}`;
      if (paramId) {
        history.replace({
          pathname: url,
          state: { info: selection },
        });
      } else {
        history.push({
          pathname: url,
          state: { info: selection },
        });
      }
    },
    [history, paramId, baseUrl]
  );

  const dataListProps = useMemo(() => {
    return (
      dataList || {
        component: card,
        onCreate: handleCreate,
        onSelect: onSelect ? onSelect : handleSelection,
        onSearch: handleSearch,
        searchQuery: searchEncounterQuery,
        selectedId: paramId,
        textCreate: textCreate,
        canCreate: !isCreatePath,
      }
    );
  }, [
    dataList,
    card,
    handleCreate,
    onSelect,
    handleSelection,
    handleSearch,
    searchEncounterQuery,
    paramId,
    textCreate,
    isCreatePath,
  ]);

  const variables = useMemo(
    () =>
      payloadvariables || {
        ...(searchEncounterQuery?.length && {
          term: formatSearch(searchEncounterQuery),
        }),
        first: 25,
        sort: '-_lastUpdated',
        type: type,
        active: active,
      },
    [active, searchEncounterQuery, type, payloadvariables]
  );
  return (
    <PrimaryList
      type={listType}
      query={query}
      variables={variables}
      dataListProps={dataListProps}
      title={title}
      fullRow
    />
  );
};
export default Container;
