import { isSupervisingPhysician } from 'user';

export const displayLink = user => {
  return isSupervisingPhysician(user);
};

export const ENTRY_LINK = {
  path: '/encounters-review',
  text: 'Encounters Review',
  module: 'encounters-review',
  component: 'Component',
  icon: 'grading',
  displayLink: displayLink,
  permission: 'patient',
};
