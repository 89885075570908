export function setSessionStorageRecord(key: string, value: any) {
  if (window?.sessionStorage) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
}

export function getSessionStorageRecord(key, defaultValue?: any) {
  if (!window?.sessionStorage) {
    return defaultValue;
  }

  const data = window.sessionStorage.getItem(key);
  if (!data) {
    return defaultValue;
  }

  try {
    return JSON.parse(data);
  } catch {
    return defaultValue;
  }
}
