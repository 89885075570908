import React, { useState, useCallback } from 'react';
import {
  Badge,
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Hidden,
  Menu,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Label from 'styleguide/form/Label';
import DataListSearch from './DataListSearch';
import {
  isAnyAllowedAdmissionStatus,
  isAnyPlannedEncounter,
} from 'admission/helpers';
import { EncounterFilterCard } from '../../encounter/EncounterFilterCard';

export type ToggleSwitchProps = {
  label?: string;
  status?: boolean;
  onToggleClick?: () => void;
};

export type Props = {
  data?: { [key: string]: any }[];
  canCreate?: boolean;
  canSearch?: boolean;
  hideAddIcon?: boolean;
  onCreate?: () => void;
  onSearch?: (query: string) => void;
  onToggleTune?: () => void;
  onSortingChange?: (item: string) => void;
  sortingOptions?: any;
  searchQuery?: string;
  textCreate?: string;
  title?: string;
  filterCount?: string;
  size?: 'large' | 'medium' | 'small';
  secondaryAction?: () => void;
  secondaryText?: string;
  loading?: boolean;
  teritiaryAction?: () => void;
  createButtonLoading?: boolean;
  fullRow?: boolean;
  listControls?: React.ReactElement;
  toggleSwitch?: ToggleSwitchProps;
  type?: string;
  onFilterEncounter?: (value: any) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerIconButton: {
      padding: 4,
    },
    refreshButton: {
      padding: 1,
    },
    actionButton: {
      height: '100%',
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

const DataListHeader: React.FC<Props> = ({
  data,
  canCreate,
  canSearch,
  hideAddIcon,
  onCreate,
  onSearch,
  onToggleTune,
  onSortingChange = () => {},
  sortingOptions,
  searchQuery,
  textCreate,
  title,
  filterCount,
  size,
  secondaryAction,
  secondaryText,
  loading = false,
  teritiaryAction,
  createButtonLoading,
  fullRow,
  listControls,
  toggleSwitch,
  type,
  onFilterEncounter,
}) => {
  const classes = useStyles();
  const [orderMenu, setOrderMenu] = useState<null | HTMLElement>(null);
  const handleToggleSort = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setOrderMenu(event.currentTarget);
    },
    [setOrderMenu]
  );

  const handleClose = () => {
    setOrderMenu(null);
  };

  const handleSortBySelection = event => {
    handleClose();
    onSortingChange(event);
  };

  if (data && type === 'AdmissionList') {
    canCreate = canCreate && isAnyAllowedAdmissionStatus(data) ? false : true;
  }

  if (data && type === 'EncounterList') {
    canCreate = !canCreate && !isAnyPlannedEncounter(data);
  }

  return !!onSearch ||
    !!onCreate ||
    !!onFilterEncounter ||
    !!onToggleTune ||
    !!title ? (
    <Box>
      {fullRow && (
        <Box display="flex" alignItems="center" p={1}>
          {title && (
            <>
              <Label size={size}>{title}</Label>
            </>
          )}
          {!!listControls && listControls}
        </Box>
      )}

      <Box display="flex" alignItems="center">
        <Box flexGrow="1">
          {title && !fullRow && (
            <>
              <Label size={size}>{title}</Label>
            </>
          )}
          {onSearch && (
            <DataListSearch
              onSearch={onSearch}
              searchQuery={searchQuery}
              disabled={!canSearch && loading}
            />
          )}
        </Box>
        {teritiaryAction && (
          <Box textAlign="center" pl={0.5}>
            <IconButton
              aria-label="refresh"
              className={classes.refreshButton}
              color="primary"
              size="medium"
              onClick={teritiaryAction}
            >
              <Icon>refresh</Icon>
            </IconButton>
          </Box>
        )}
        {onToggleTune && (
          <Box textAlign="center" pl={teritiaryAction ? 0 : 1.5} pr={0.5}>
            <IconButton
              aria-label="open drawer"
              onClick={onToggleTune}
              color="primary"
              className={classes.drawerIconButton}
              size="medium"
              disabled={loading}
            >
              <Badge color="primary" badgeContent={filterCount}>
                <Icon>tune</Icon>
              </Badge>
            </IconButton>
          </Box>
        )}
        {toggleSwitch && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={toggleSwitch?.status}
                onChange={toggleSwitch?.onToggleClick}
                color="primary"
                value={toggleSwitch?.status}
                disabled={false}
              />
            }
            label={toggleSwitch?.label || ''}
            labelPlacement="start"
          />
        )}
        {sortingOptions && (
          <Box textAlign="center" pl={1.5} pr={0.5}>
            <IconButton
              aria-label="open panel"
              onClick={handleToggleSort}
              color="primary"
              className={classes.drawerIconButton}
              size="medium"
              disabled={loading}
            >
              <Badge color="primary">
                <Icon>sort</Icon>
              </Badge>
            </IconButton>
            <Menu
              id="sorting-menu"
              anchorEl={orderMenu}
              keepMounted
              open={Boolean(orderMenu)}
              onClose={handleClose}
            >
              {sortingOptions.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  onClick={handleSortBySelection}
                  disabled={loading}
                >
                  {item?.text}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
        {secondaryAction && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height={1}
            pl={1}
          >
            <Button
              disableElevation
              fullWidth
              color="primary"
              variant="outlined"
              onClick={secondaryAction}
              disabled={loading}
            >
              <Hidden xsDown>{secondaryText || 'Add'}</Hidden>
              <Hidden smUp>{'Add'}</Hidden>
            </Button>
          </Box>
        )}
        {onFilterEncounter && (
          <EncounterFilterCard
            setEnableEncounterStatusFilter={onFilterEncounter}
          />
        )}
        {onCreate && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height={1}
            pl={1}
          >
            <Box mr={1} className={classes.wrapper}>
              <Button
                disabled={!canCreate || loading}
                disableElevation
                fullWidth
                color="primary"
                variant="outlined"
                startIcon={!hideAddIcon ? <Icon>add</Icon> : null}
                onClick={onCreate}
              >
                <Hidden xsDown>{textCreate || 'Add'}</Hidden>
                <Hidden smUp>{'Add'}</Hidden>
              </Button>
              {createButtonLoading && (
                <CircularProgress
                  size={24}
                  thickness={4}
                  className={classes.buttonProgress}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box mb={1} mt={1}>
        <Divider />
      </Box>
    </Box>
  ) : null;
};

export default React.memo(DataListHeader);
