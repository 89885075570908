import { Palette } from '@woundtech/ui-colors';

export const getEnvironmentColor = (env: string | undefined): string => {
  switch (env) {
    case 'dev':
      return Palette.Red.Main;
    case 'qa':
      return Palette.Orange.Main;
    case 'staging':
      return Palette.Purple.Main;
    case 'staging2':
      return Palette.Green.Main;
    case 'loadtest':
      return Palette.Base.White;
    default:
      return Palette.Base.White;
  }
};

export const getEnvironmentLabel = (env: string | undefined): string => {
  switch (env) {
    case 'dev':
      return 'Dev';
    case 'qa':
      return 'QA';
    case 'staging':
      return 'Staging';
    case 'staging2':
      return 'UAT';
    case 'loadtest':
      return 'Load Test';
    default:
      return '';
  }
};

export const getEnvironmentTextColor = (env: string | undefined): string =>
  env === 'loadtest' ? Palette.Base.Black : Palette.Base.White;
