import React, { useState, useEffect, useContext } from 'react';
import Amplify from 'aws-amplify';
import Error from 'styleguide/Error';
import Loading from 'styleguide/Loading';
import PublicLayout from 'styleguide/layout/Public';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';
import { clearReloadReason, getReloadReason } from 'application/wt-debugger';

export interface IConfiguration {
  AWSRegion?: string;
  AWSUploadsBucket?: string;
  DeploymentEnvironment?: string;
  IdentityPoolId?: string;
  UserPoolId?: string;
  UserPoolWebClientId?: string;
  IdleScreenLockDelay?: number;
  IdleLogoutDelay?: number;
  FlexLicenseKey?: string;
  GoogleAnalyticsMeasureId?: string;
  OauthURL?: string;
  OauthClientID?: string;
  OauthRedirectURL?: string;
  GooleMapsApiKey?: string;
  LaunchDarklyClientSideId?: string;
  ClinicianCbrReportId?: string;
  ClinicianCbrTenantId?: string;
}

const defaultValue: IConfiguration = {};

export const Context = React.createContext(defaultValue);

const Configuration: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [configuration, setConfiguration] = useState<
    IConfiguration | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const { EnableWoundtechADIntegration } = useContext(FeatureToggleContext);

  useEffect(() => {
    fetch(window?._env_?.CONFIGURATION_URI || '/api/config')
      .then(async response => {
        const data = await response.json();
        setConfiguration(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [setLoading, setConfiguration]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <PublicLayout>
        <Error
          text={`Could not load the application configuration. ${error?.message}.`}
        />
      </PublicLayout>
    );
  }

  if (!configuration) {
    return null;
  }

  let configurations = () => {
    if (EnableWoundtechADIntegration) {
      return {
        Auth: {
          region: configuration?.AWSRegion,
          userPoolId: configuration?.UserPoolId,
          userPoolWebClientId: configuration?.OauthClientID,
          authenticationFlowType: 'USER_PASSWORD_AUTH',
          identityPoolId: configuration?.IdentityPoolId,
          storage: window?.sessionStorage,
          oauth: {
            domain: window?._env_?.OAUTH_DOMAIN,
            scope: ['email', 'openid'],
            label: 'Woundtech Login',
            redirectSignIn: window?._env_?.REDIRECT_SIGN_IN,
            redirectSignOut: window?._env_?.REDIRECT_SIGN_OUT,
            responseType: 'code',
          },
        },
        Storage: {
          AWSS3: {
            bucket: configuration?.AWSUploadsBucket,
            region: configuration?.AWSRegion,
          },
        },
      };
    } else {
      return {
        Auth: {
          region: configuration?.AWSRegion,
          userPoolId: configuration?.UserPoolId,
          userPoolWebClientId: configuration?.UserPoolWebClientId,
          authenticationFlowType: 'USER_PASSWORD_AUTH',
          identityPoolId: configuration?.IdentityPoolId,
          storage: window?.sessionStorage,
        },
        Storage: {
          AWSS3: {
            bucket: configuration?.AWSUploadsBucket,
            region: configuration?.AWSRegion,
          },
        },
      };
    }
  };

  const reloadReason = getReloadReason();
  if (reloadReason) {
    // eslint-disable-next-line
    clearReloadReason();
  }

  Amplify.configure(configurations());
  return <Context.Provider value={configuration}>{children}</Context.Provider>;
};

export default Configuration;
