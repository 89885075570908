import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import Typeahead from 'styleguide/form/SingleChoice/Typeahead';
import { CountyListQuery } from '../Schema';

export type Props = {
  postalCode?: string;
  required?: boolean;
  state?: string;
  value?: string;
  onChange: (value: fhir.Location | undefined) => void;
};

const SingleCountyChoice: React.FC<Props> = ({
  postalCode,
  required,
  state,
  value,
  onChange,
}) => {
  const [getData, { data, error, loading }] = useLazyQuery(CountyListQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const options = data?.CountyList?.edges?.map(edge => edge.node) || [];
      if (!value && options && options.length === 1 && options[0]) {
        onChange(options[0]);
      }
    },
  });

  const options = useMemo(
    () => data?.CountyList?.edges?.map(edge => edge.node) || [],
    [data]
  );

  useEffect(() => {
    if (postalCode) {
      const variables = {
        first: 200,
        states: state ? [state] : undefined,
        postalCodes: [postalCode.substr(0, 5)],
      };
      getData({
        variables,
      });
    }
  }, [getData, postalCode, state]);

  return (
    <Typeahead
      name="county"
      label="County"
      value={value}
      onChange={onChange}
      onValidate={() => {}}
      options={options}
      getOptionLabel={option => option?.name}
      getOptionId={option => option?.name}
      required={required}
      hasError={!!error}
      disabled={loading}
    />
  );
};

export default React.memo(SingleCountyChoice);
