import React from 'react';
import Error from '../Error';

export type Props = {
  text: string;
};

const DataListError: React.FC<Props> = ({ text }) => {
  return <Error text={text} />;
};

export default React.memo(DataListError);
