import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';

export type DefaultFormControlProps = {
  children: React.ReactNode;
  disabled?: boolean;
  helperText: string;
  label?: string;
  required?: boolean;
  validate?: boolean;
};

const DefaultFormControl: React.FC<DefaultFormControlProps> = ({
  children,
  label = '',
  helperText,
  required,
  disabled,
  validate,
}) => {
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const hasError = !!(showErrors && !!helperText);

  useEffect(() => {
    if (validate) {
      setShowErrors(true);
    }
  }, [validate]);

  return (
    <FormControl
      error={hasError}
      fullWidth
      onBlur={() => setShowErrors(false)}
      onFocus={() => setShowErrors(false)}
      size="small"
      variant="filled"
      required={required}
      disabled={disabled}
    >
      <InputLabel required={required}>{label}</InputLabel>
      {children}
      {hasError && <FormHelperText error={true}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default React.memo(DefaultFormControl);
