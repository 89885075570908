import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import SingleChoiceField from 'styleguide/form/SingleChoice';
import MultipleChoiceField from 'styleguide/form/MultipleChoice';
import { Field } from 'styleguide/form/types';
import { ValueSetFieldQuery } from './Schema';
import { sortCodeableConceptDisplay } from 'utility/helpers';

export interface Props extends Field {
  value: any;
  onChange: (data: any) => void;
  variant?: 'drop-down' | 'button-group' | 'typeahead' | 'radio-button-group';
  url: string;
  placeholder?: string;
  multiple?: boolean;
  getOptionId?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  getOptionDisabled?: (option: fhir.CodeableConcept) => boolean;
  getOptionWidth?: (
    option: any,
    id: string,
    label: string,
    isMobile?: boolean
  ) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sortOptions?: (options: fhir.CodeableConcept[]) => fhir.CodeableConcept[];
  sortDisplay?: 'ascending' | 'descending';
  onTopOptions?: string[];
  onBottomOptions?: string[];
  hasError?: boolean;
  filterOptions?: (options: fhir.CodeableConcept[]) => fhir.CodeableConcept[];
}

const ValueSetField: React.FC<Props> = ({
  value,
  label,
  onChange,
  onValidate,
  required,
  name,
  variant = 'drop-down',
  url,
  placeholder,
  disabled,
  multiple,
  requiredText,
  getOptionId,
  getOptionLabel,
  getOptionDisabled,
  getOptionWidth,
  sortOptions,
  sortDisplay,
  onTopOptions,
  onBottomOptions,
  hasError = false,
  filterOptions,
}) => {
  const { data: response } = useQuery(ValueSetFieldQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      url,
    },
  });

  const sortOptionsDisplay = useCallback(
    result => {
      let optionArr = result;
      let onTopOptionsArr: fhir.CodeableConcept[] = [];
      let onBottomOptionsArr: fhir.CodeableConcept[] = [];
      if (onTopOptions?.length) {
        optionArr = result.filter(
          item => !(onTopOptions.indexOf(item?.coding?.[0]?.code) > -1)
        );
        onTopOptions.forEach(code => {
          const codeableConcept = result.find(
            option => code === option?.coding?.[0]?.code
          );
          onTopOptionsArr.push(codeableConcept);
        });
      }
      if (onBottomOptions?.length) {
        optionArr = result.filter(
          item => !(onBottomOptions.indexOf(item?.coding?.[0]?.code) > -1)
        );
        onBottomOptions.forEach(code => {
          const codeableConcept = result.find(
            option => code === option?.coding?.[0]?.code
          );
          onBottomOptionsArr.push(codeableConcept);
        });
      }
      let sortedOptions: fhir.CodeableConcept[] = sortCodeableConceptDisplay(
        optionArr,
        sortDisplay === 'ascending'
      );
      return [...onTopOptionsArr, ...sortedOptions, ...onBottomOptionsArr];
    },
    [onBottomOptions, onTopOptions, sortDisplay]
  );

  const options = useMemo(() => {
    const responseCodes = response?.expandValueSet?.expansion?.contains;
    const updatedResponse = filterOptions
      ? filterOptions(responseCodes)
      : responseCodes;

    const result =
      updatedResponse?.map((item: any) => ({
        coding: [{ ...item }],
        text: item?.display || item?.code || '',
      })) || [];

    return sortOptions
      ? sortOptions(result)
      : sortDisplay
      ? sortOptionsDisplay(result)
      : result;
  }, [response, filterOptions, sortOptions, sortDisplay, sortOptionsDisplay]);

  return multiple ? (
    <MultipleChoiceField
      value={value}
      label={label}
      name={name}
      onChange={onChange}
      onValidate={onValidate}
      options={options}
      required={required}
      disabled={disabled}
      requiredText={requiredText}
      getOptionId={getOptionId}
      placeholder={placeholder}
    />
  ) : (
    <SingleChoiceField
      value={value}
      label={label}
      name={name}
      onChange={onChange}
      onValidate={onValidate}
      options={options}
      required={required}
      variant={variant}
      disabled={disabled}
      getOptionId={getOptionId}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      getOptionWidth={getOptionWidth}
      hasError={hasError}
    />
  );
};

export default React.memo(ValueSetField);
