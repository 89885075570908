import React, {
  useState,
  useCallback,
  useReducer,
  useMemo,
  useEffect,
} from 'react';
import Auth from '@aws-amplify/auth';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import Context from './Context';
import { reducer, defaultState } from './reducer';
import { cryptoKey } from './constants';

import CryptoJS from 'crypto-js';
import { setSessionStorageRecord, getSessionStorageRecord } from 'application';

type Props = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  children?: React.ReactNode;
};

const Provider: React.FC<Props> = ({ apolloClient, children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const [lockedScreen, setLockedScreen] = useState(false);

  const screenLock = useCallback(() => {
    setLockedScreen(true);
    setSessionStorageRecord('locked-app', 'true');
  }, []);

  const screenUnlock = useCallback(() => {
    setLockedScreen(false);
    setSessionStorageRecord('locked-app', 'false');
  }, []);

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: 'init' });
        const token = getSessionStorageRecord('access-token');
        const isInternal = getSessionStorageRecord('is-internal');

        // user refresh locked screen
        const lockedState = getSessionStorageRecord('locked-app');
        if (!!token && lockedState && lockedState === 'true') {
          screenLock();
        } else {
          screenUnlock();
        }

        dispatch({
          type: 'initCompleted',
          isAuthenticated: !!token,
          isInternal: isInternal === 'true',
        });
      } catch (error) {
        dispatch({ type: 'initFailed', error: error?.message });
      }
    })();
  }, [screenLock, screenUnlock]);

  const signIn = useCallback(async () => {
    try {
      dispatch({ type: 'signIn' });

      const user = await Auth.currentUserInfo();

      dispatch({ type: 'signInCompleted', user });
    } catch (error) {
      dispatch({ type: 'signInFailed', error: error?.message });
    }
  }, []);

  const setSequenceDestinationForPrivate = useCallback(data => {
    dispatch({
      type: 'setSequenceDestinationForPrivate',
      sequencedDestinationsPrivate: data,
    });
  }, []);

  const ssoSignIn = useCallback(() => {
    setSessionStorageRecord('is-internal', 'true');
    dispatch({ type: 'ssoSignInCompleted' });
  }, []);

  const ssoSignInFailed = useCallback(error => {
    dispatch({ type: 'ssoSignInFailed', error });
  }, []);

  const signOut = useCallback(
    async (isExpired?: boolean) => {
      try {
        dispatch({ type: 'signOut' });
        apolloClient.clearStore();
        await Auth.signOut();
        screenUnlock();
        sessionStorage && sessionStorage.clear();
        dispatch({ type: 'signOutCompleted', isExpired });
      } catch (error) {
        dispatch({ type: 'signOutFailed', error: error?.message });
      }
    },
    [apolloClient, screenUnlock]
  );

  const storePin = useCallback((pin: string) => {
    let cpin = CryptoJS.AES.encrypt(pin, cryptoKey).toString();
    setSessionStorageRecord('pin-key', cpin);
  }, []);

  const verifyPin = useCallback((pin: string) => {
    const savedKey = getSessionStorageRecord('pin-key');
    if (savedKey) {
      var bytes = CryptoJS.AES.decrypt(savedKey, cryptoKey);
      var decriptedSavedKey = bytes.toString(CryptoJS.enc.Utf8);
      return decriptedSavedKey === pin;
    }
    return false;
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      signIn,
      ssoSignIn,
      ssoSignInFailed,
      signOut,
      lockedScreen,
      screenLock,
      screenUnlock,
      storePin,
      verifyPin,
      setSequenceDestinationForPrivate,
    }),
    [
      state,
      ssoSignIn,
      ssoSignInFailed,
      signIn,
      signOut,
      lockedScreen,
      screenLock,
      screenUnlock,
      storePin,
      verifyPin,
      setSequenceDestinationForPrivate,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
