import { PlaceholderKind } from '@woundtech/ui-constants';
import { textMaskFormat, INPUT_MASK_POSTAL_CODE } from 'styleguide/form/Text';
import { SERVICE_ADDRESS_URL } from 'fhir/general/constants';
import { getAddressMarketName } from './helperts';

export const cityAndStateFormat = (data: fhir.Address | undefined) => {
  return data
    ? [data?.city, data?.state].filter(value => !!value).join(', ')
    : PlaceholderKind.NotAvailable;
};

export const stateAndPostalCodeFormat = (data: fhir.Address | undefined) => {
  return data
    ? [data?.state, textMaskFormat(data?.postalCode, INPUT_MASK_POSTAL_CODE)]
        .filter(value => !!value)
        .join(' ')
    : PlaceholderKind.NotAvailable;
};

export const address = (data: fhir.Address | undefined, seprateor = '') => {
  const lines = data?.line?.filter(x => x !== '') || [];
  const addressToShow =
    seprateor === '--'
      ? [
          lines?.join(', '),
          '--',
          data?.city,
          data?.state,
          textMaskFormat(data?.postalCode, INPUT_MASK_POSTAL_CODE),
        ]
      : [
          lines?.join(', '),
          data?.city,
          data?.state,
          textMaskFormat(data?.postalCode, INPUT_MASK_POSTAL_CODE),
        ];

  return data
    ? addressToShow.filter(value => !!value).join(', ')
    : PlaceholderKind.NotAvailable;
};

export const addresses = (data: fhir.Address[] | undefined) => {
  return (
    (data || []).map(x => address(x)).join() || PlaceholderKind.NotAvailable
  );
};

export const serviceAddress = (data: fhir.Address[] | undefined) => {
  const serviceAddresses =
    data?.filter(item => {
      return item?.extension?.find(
        extension =>
          extension.url === SERVICE_ADDRESS_URL &&
          extension.valueBoolean === true
      );
    }) || [];

  if (serviceAddresses?.length < 1) {
    return PlaceholderKind.NotAvailable;
  }

  return addresses(serviceAddresses);
};

export const addressCountyAndMarket = (data: fhir.Address | undefined) => {
  if (!data) {
    return PlaceholderKind.NotAvailable;
  }

  const market = getAddressMarketName(data);
  const value = [data?.district, market].filter(value => !!value).join(', ');

  return value || PlaceholderKind.NotAvailable;
};

export const formatRoutingAddress = (address: string | undefined) => {
  return (
    address
      ?.split(',')
      ?.slice(0, -1)
      ?.filter(e => e)
      ?.join(', ') || ''
  );
};
