import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import DateField from 'styleguide/form/Date';
import { startOfDay, endOfDay, parseISO, isValid } from 'date-fns';
import { Field } from '../types';
import { MADE_ENCOUNTERS_FILTERNAME } from 'bulk-operations/constants';

export interface Props extends Field {
  endLabel?: string;
  endMaxDate?: string;
  endMinDate?: string;
  endName?: string;
  endRequired?: boolean;
  startMinDate?: string;
  onChange: (data: fhir.Period) => void;
  startLabel?: string;
  startMaxDate?: string;
  startName?: string;
  startRequired?: boolean;
  value: fhir.Period | undefined;
  type?: string;
}

const Period: React.FC<Props> = ({
  value: data,
  endLabel = 'End',
  endName = 'end',
  onChange,
  onValidate,
  required,
  startRequired,
  endRequired,
  startLabel = 'Start',
  startName = 'start',
  startMinDate,
  startMaxDate,
  endMinDate,
  type,
}) => {
  const [isStartValid, setIsStartValid] = useState<boolean>(false);
  const [isEndValid, setIsEndValid] = useState<boolean>(false);
  const [maxEndDate, setMaxEndDate] = useState<string | undefined>();
  const handleStartChange = useCallback(
    (value: fhir.dateTime) => {
      const date = parseISO(value);
      if (isValid(date)) {
        let endMaxDate;
        if (type === MADE_ENCOUNTERS_FILTERNAME) {
          endMaxDate = new Date(
            new Date(value).setDate(new Date(value).getDate() + 14)
          ).toISOString();
          setMaxEndDate(endMaxDate);
        }
        onChange({
          ...(data || {}),
          start: isValid(date) ? startOfDay(date).toISOString() : value,
          end: type === MADE_ENCOUNTERS_FILTERNAME ? endMaxDate : data?.end,
        });
      }
    },
    [data, onChange, setMaxEndDate, type]
  );

  useEffect(() => {
    if (type === MADE_ENCOUNTERS_FILTERNAME && data?.start) {
      const endMaxDate = new Date(
        new Date(data?.start).setDate(new Date(data?.start).getDate() + 14)
      ).toISOString();
      setMaxEndDate(endMaxDate);
    }
  }, [data, type]);

  const handleEndChange = useCallback(
    (value: fhir.dateTime) => {
      const date = parseISO(value);
      if (isValid(date)) {
        onChange({
          ...(data || {}),
          end: isValid(date) ? endOfDay(date).toISOString() : value,
        });
      }
    },
    [data, onChange]
  );

  useEffect(() => {
    onValidate(isStartValid && isEndValid);
  }, [isStartValid, isEndValid, onValidate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <DateField
          required={startRequired || required}
          label={startLabel}
          name={startName}
          data={data?.start}
          onChange={handleStartChange}
          onValidate={setIsStartValid}
          minDate={startMinDate}
          maxDate={startMaxDate || data?.end}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateField
          required={endRequired || required}
          label={endLabel}
          name={endName}
          data={data?.end}
          onChange={handleEndChange}
          onValidate={setIsEndValid}
          minDate={endMinDate || data?.start || startMinDate}
          maxDate={maxEndDate}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(Period);
