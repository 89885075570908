import React, { useMemo, useCallback } from 'react';
import { getExtensionValueBoolean } from '../utils';

import { Box, Radio } from '@material-ui/core';
import { fullNameFormat } from 'fhir/general/HumanName';
import CardLayout from 'styleguide/layout/Card';
import ContactCard from './Card';
import {
  getContactPersonAttributes,
  getContactPersonStatus,
} from '../Builder/builder';
import Typography from '@material-ui/core/Typography';
import { CONTACT_PERSON_STATUS } from 'master-list/constants';

type Props = {
  data: any;
  onClick?: () => void;
  title?: string;
  handelCardSelection: (conatct: any) => void;
  selected?: string | undefined;
};

const ContactPersonCard: React.FC<Props> = ({
  data,
  onClick,
  title,
  handelCardSelection,
  selected,
}) => {
  const contacts = useMemo(() => data?.participant || [], [data]);

  const getAttributes = useCallback(contact => {
    const telecom = contact?.member?.resource?.telecom;
    return getContactPersonAttributes(contact, telecom);
  }, []);
  const activeContacts = useMemo(
    () =>
      contacts
        ?.filter(contact =>
          getExtensionValueBoolean(
            contact?.member?.resource,
            CONTACT_PERSON_STATUS
          )
        )
        .sort((a, b) =>
          a?.member?.resource?.name?.[0]?.text?.toUpperCase() >
          b?.member?.resource?.name?.[0]?.text?.toUpperCase()
            ? 1
            : -1
        ),
    [contacts]
  );

  return (
    <Box>
      {activeContacts?.map((contact, index) => (
        <Box display="flex" key={index} mb={1}>
          <Radio
            checked={
              selected !== 'none' && selected === contact?.member?.resource?.id
            }
            onChange={() => handelCardSelection(contact?.member?.resource)}
            value={contact?.member?.resource?.telecom?.[0]?.value || index}
            color="primary"
            name="radio-button"
          />
          <Box width={1}>
            <CardLayout
              onClick={() => handelCardSelection(contact?.member?.resource)}
            >
              <ContactCard
                data={contact?.member?.resource}
                customAttributes={getAttributes(contact)}
                onClick={() => handelCardSelection(contact?.member?.resource)}
                title={title || fullNameFormat(contact?.member?.resource?.name)}
                cardStatus={getContactPersonStatus(contact?.member?.resource)}
              />
            </CardLayout>
          </Box>
        </Box>
      ))}

      <Box display="flex" mb={1}>
        <Radio
          checked={selected === 'none'}
          onChange={() => handelCardSelection('none')}
          value={'None'}
          color="primary"
          name="radio-button"
        />
        <Box width={1}>
          <CardLayout onClick={() => handelCardSelection('none')}>
            <Typography>None</Typography>
          </CardLayout>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(ContactPersonCard);
