import { BULK_OPERATIONS, MADE_ENCOUNTERS } from '../constants';
import { isPcc } from 'user';
export const ENTRY_PERMISSION = 'settings';

export const displayLink = (user, env) => {
  return isPcc(user);
};

export const ENTRY_LINK = {
  path: `/${BULK_OPERATIONS}${MADE_ENCOUNTERS}`,
  text: 'Made Encounters',
  module: 'made-encounters',
  component: 'Dashboard',
  displayLink: displayLink,
  permission: ENTRY_PERMISSION,
};
