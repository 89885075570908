import { MASTER_LIST_PATH, CARE_FACILITY_CODE } from '../constants';

export const ENTRY_PERMISSION = 'settings';

export const CARE_FACILITY = {
  path: `${MASTER_LIST_PATH}/care-facility`,
  text: 'Care Facilities',
  module: CARE_FACILITY_CODE,
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
};

export type ICareFacility = {
  organization: fhir.Organization;
  practitioners?: fhir.Practitioner[];
};
