export const KEY_RELOAD_REASON = 'KEY_RELOAD_REASON';

export const setReloadReason = (path: string, error: any) => {
  if (window?.sessionStorage) {
    window.sessionStorage.setItem(
      KEY_RELOAD_REASON,
      `Reloading path: ${path}, Reason: ${JSON.stringify(error)}`
    );
  }
};

export const getReloadReason = (): string => {
  if (window?.sessionStorage) {
    return window.sessionStorage.getItem(KEY_RELOAD_REASON) || '';
  }

  return '';
};

export const clearReloadReason = () => {
  if (window?.sessionStorage) {
    window.sessionStorage.removeItem(KEY_RELOAD_REASON);
  }
};
