import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { FormHelperText } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { DISPLAY_DATE_FORMAT } from './constants';
import { transformInput, transformOutput, validate } from './utils';

export type Props = {
  data?: fhir.date;
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  label?: string;
  maxDate?: string;
  minDate?: string;
  name: string;
  onChange: (data: fhir.date, name: string) => void;
  onValidate: (isValid: boolean) => void;
  required?: boolean;
  timeZone?: string;
  representation?: 'date' | 'complete';
};

const Date: React.FC<Props> = ({
  data,
  disabled,
  disableFuture,
  disablePast,
  label,
  maxDate,
  minDate = '1900-01-01',
  name,
  onChange,
  onValidate,
  required,
  timeZone,
  representation = 'date',
}) => {
  const [showErrors, setShowErrors] = useState<any>({});

  const handleChange = (date: any, value?: string | null) => {
    onChange(
      transformOutput(date, value || '', timeZone, representation),
      name
    );
  };

  useEffect(() => {
    const errors = validate(data, required, minDate, maxDate);
    onValidate(Object.keys(errors).length < 1);
  }, [data, required, onValidate, minDate, maxDate]);

  const value = transformInput(data, timeZone);
  const errors = validate(data, required, minDate, maxDate);

  const getError = (name: string) => {
    return showErrors?.[name] && !!errors?.[name] ? errors?.[name] || '' : '';
  };

  const handleBlur = (name: string) => () => {
    setShowErrors({
      ...showErrors,
      [name]: false,
    });
  };

  const handleFocus = (name: string) => () => {
    setShowErrors({
      ...showErrors,
      [name]: false,
    });
  };

  const dateError = getError('date');

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          autoOk
          required={required}
          format={DISPLAY_DATE_FORMAT}
          fullWidth
          inputVariant={'filled'}
          label={label}
          name={name}
          disablePast={disablePast}
          disableFuture={disableFuture}
          onChange={handleChange}
          size="small"
          InputProps={{
            autoComplete: 'off',
            error: !!dateError,
            onBlur: handleBlur('date'),
            onFocus: handleFocus('date'),
          }}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage=""
          maxDateMessage=""
          invalidDateMessage=""
          value={value ? value : null}
          placeholder={DISPLAY_DATE_FORMAT}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
      {dateError && (
        <FormHelperText error={!!dateError}>{dateError}</FormHelperText>
      )}
    </>
  );
};

export default React.memo(Date);
