import { hasUserJobFunctions, UserJobFunction } from 'user';
export const ENTRY_PERMISSION = 'patient';

export const displayLink = (user, env) => {
  return hasUserJobFunctions(user, [
    UserJobFunction.caseManager,
    UserJobFunction.leadClinician,
  ]);
};

export const ENTRY_LINK = {
  path: '/manage-session',
  text: 'Clinical Rounds',
  icon: 'description',
  permission: ENTRY_PERMISSION,
  displayLink: displayLink,
  module: 'manage-session',
  component: 'Dashboard',
};
