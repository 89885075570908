import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Field } from '../types';
import CheckBoxTouchGroup from './CheckBoxTouchGroup';

import Label from 'styleguide/form/Label';
import InlineError from 'styleguide/core/InlineError';
import { VALIDATION_ERROR_REQUIRED } from 'utility/constants';
import SearchBar from 'styleguide/form/SearchBar';

export interface Props extends Field {
  onChange: (isChecked: boolean, option: any) => void;
  options: any[];
  value: any[];
  lastSelectedValues?: any[];
  getOptionId?: (option: any) => string | undefined;
  getOptionLabel?: (option: any) => string | undefined;
  isSelected?: (option: any) => boolean;
  fullWidth?: boolean;
  description?: string;
  hasError?: boolean;
  labelSize?: 'large' | 'medium' | 'small';
  getTag?: (option: any) => any;
  enableSearchBar?: boolean;
  customCheckbox?: boolean;
  search?: string;
  setSearch?: (value) => void;
  filteredOptions?: string[];
}

const MultiChoiceGroup: React.FC<Props> = ({
  label,
  onChange,
  options,
  required,
  value,
  lastSelectedValues,
  getOptionId = (option: fhir.CodeableConcept) => option?.coding?.[0]?.code,
  getOptionLabel = (option: fhir.CodeableConcept) =>
    option?.coding?.[0]?.display,
  isSelected,
  fullWidth,
  description,
  labelSize,
  hasError = false,
  getTag,
  enableSearchBar = false,
  customCheckbox = false,
  search = '',
  setSearch = () => {},
  filteredOptions = [],
}) => {
  const handleChange = (isChecked: boolean, option: any) => {
    onChange(isChecked, option);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={1}>
          {label ? (
            <Label size={labelSize} required={required}>
              {label}
            </Label>
          ) : null}
        </Box>
        {hasError && (
          <Box>
            <InlineError text={VALIDATION_ERROR_REQUIRED} />
          </Box>
        )}
      </Box>
      {enableSearchBar && <SearchBar search={search} onChange={setSearch} />}
      {description && (
        <Typography color="textSecondary">{description}</Typography>
      )}
      <Box mt={label ? 2 : 0}>
        <CheckBoxTouchGroup
          onChange={handleChange}
          value={value}
          lastSelectedValues={lastSelectedValues}
          options={enableSearchBar ? filteredOptions?.sort() : options}
          getOptionId={getOptionId}
          getOptionLabel={getOptionLabel}
          isSelected={isSelected}
          fullWidth={fullWidth}
          getTag={getTag}
          customStyle={customCheckbox}
        />
      </Box>
    </Box>
  );
};

export default MultiChoiceGroup;
