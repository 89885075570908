import React from 'react';
import Box from '@material-ui/core/Box';
import { Props as SectionActionProps } from './SectionAction';
import SectionHeader from './SectionHeader';

export type Props = {
  action?: SectionActionProps;
  children: React.ReactNode;
  title: string;
  secondaryAction?: SectionActionProps;
  ternaryAction?: SectionActionProps;
  quaternary?: SectionActionProps;
  headerContent?: React.ReactElement;
  scroll?: boolean;
  noWrap?: boolean;
};

const Section: React.FC<Props> = ({
  action,
  children,
  title,
  secondaryAction,
  ternaryAction,
  headerContent,
  quaternary,
  scroll = true,
  noWrap,
}) => {
  return (
    <Box display="flex" flexDirection="column" height={1} data-section={title}>
      <Box>
        <SectionHeader
          action={action}
          title={title}
          secondaryAction={secondaryAction}
          ternaryAction={ternaryAction}
          headerContent={headerContent}
          quaternary={quaternary}
          noWrap={noWrap}
        />
      </Box>
      <Box
        flexGrow={1}
        overflow={scroll ? 'hidden auto' : 'hidden'}
        display="flex"
      >
        <Box width={1} height="auto">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Section);
