import { gql } from '@apollo/client';
import { OrganizationFragment } from 'organization/Schema';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';
import { HumanNameFragment } from 'fhir/general/HumanName';
import { IdentifierFragment } from 'fhir/general/Identifier';
import { ContactPointFragment } from 'styleguide/form/Telecom';
const AddressFragment = gql`
  fragment Address on Address {
    extension {
      url
      valueReference {
        display
        reference
      }
      valueString
    }
    city
    country
    district
    line
    postalCode
    state
    text
    type
    use
  }
`;
export const HealthCareTeamFragment = gql`
  fragment CareTeam on CareTeam {
    id
    resourceType
    status
    category {
      coding {
        system
        code
        display
      }
      text
    }
    participant {
      member {
        reference
        resource {
          ... on Practitioner {
            id
            resourceType
            extension {
              url
              valueBoolean
            }
            identifier {
              use
              system
              value
            }
            active
            name {
              use
              text
              family
              given
            }
            telecom {
              extension {
                url
                valueString
              }
              system
              value
              use
              rank
            }
            qualification {
              code {
                coding {
                  system
                  code
                  display
                }
                text
              }
            }
          }
          ... on PractitionerRole {
            id
            active
            resourceType
            practitioner {
              reference
            }
            specialty {
              coding {
                system
              }
            }
          }
        }
      }
    }
    note {
      text
    }
  }
`;

export const CreateCareFacilityMutation = gql`
  mutation createHealthCareFacility(
    $organization: OrganizationInput!
    $practitioners: [PractitionerUpdateInput]
  ) {
    createHealthCareFacility(
      organization: $organization
      practitioners: $practitioners
    ) {
      organization {
        ...Organization
      }
      careTeam {
        ...CareTeam
      }
    }
  }
  ${OrganizationFragment}
  ${HealthCareTeamFragment}
`;

export const UpdateCareFacilityMutation = gql`
  mutation updateHealthCareFacility(
    $organization: OrganizationInput!
    $practitioners: [PractitionerUpdateInput]
  ) {
    updateHealthCareFacility(
      organization: $organization
      practitioners: $practitioners
    ) {
      organization {
        ...Organization
      }
      careTeam {
        ...CareTeam
      }
    }
  }
  ${OrganizationFragment}
  ${HealthCareTeamFragment}
`;

export const CareFacilityQuery = gql`
  query getHealthCareFacilityById($organizationId: String!) {
    getHealthCareFacilityById(organizationId: $organizationId) {
      organization {
        ...Organization
      }
      careTeam {
        ...CareTeam
      }
    }
  }
  ${OrganizationFragment}
  ${HealthCareTeamFragment}
`;

export const HealthCareFacilityListQuery = gql`
  query getHealthCareFacilityList(
    $type: String
    $partOfId: String
    $careId: String
    $state: String
    $name: String
    $term: String
    $first: Int
    $after: String
    $last: Int
    $before: String
    $active: String
    $sort: String
  ) {
    getHealthCareFacilityList(
      first: $first
      after: $after
      type: $type
      partOfId: $partOfId
      state: $state
      term: $term
      last: $last
      before: $before
      sort: $sort
      name: $name
      active: $active
      careId: $careId
    ) {
      edges {
        cursor
        node {
          id
          resourceType
          name
          active
          identifier {
            ...Identifier
          }
          telecom {
            ...ContactPoint
          }
          address {
            ...Address
          }
          contact {
            name {
              ...HumanName
            }
          }
          type {
            ...CodeableConcept
          }
          partOf {
            reference
            resource {
              ...Organization
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${AddressFragment}
  ${CodeableConceptFragment}
  ${HumanNameFragment}
  ${IdentifierFragment}
  ${ContactPointFragment}
  ${OrganizationFragment}
`;

export const getHealthCareFacilityByPatientIdQuery = gql`
  query getHealthCareFacilityByPatientId(
    $patientId: String!
    $facilityTypes: [String]!
  ) {
    getHealthCareFacilityByPatientId(
      patientId: $patientId
      facilityTypes: $facilityTypes
    ) {
      organization {
        ...Organization
      }
      careTeam {
        ...CareTeam
      }
    }
  }
  ${OrganizationFragment}
  ${HealthCareTeamFragment}
`;
