import React from 'react';
import PermanentDrawer from './PermanentDrawer';
import TemporaryDrawer from './TemporaryDrawer';

export type Props = {
  anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
  variant: 'permanent' | 'temporary';
  open?: boolean;
  toggleOpen?: () => void;
};

const Drawer: React.FC<Props> = ({
  anchor,
  variant,
  children,
  open,
  toggleOpen,
}) => {
  const props = { anchor, children, open, toggleOpen };
  switch (variant) {
    case 'permanent':
      return <PermanentDrawer {...props} />;
    case 'temporary':
      return <TemporaryDrawer {...props} />;
    default:
      return null;
  }
};

export default Drawer;
