import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import SectionAction, { Props as SectionActionProps } from './SectionAction';
import SectionTitle from './SectionTitle';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export type Props = {
  action?: SectionActionProps;
  title: string;
  secondaryAction?: SectionActionProps;
  ternaryAction?: SectionActionProps;
  headerContent?: React.ReactElement;
  quaternary?: SectionActionProps;
  noWrap?: boolean;
};

const cancelButtonTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
});

const SectionHeader: React.FC<Props> = ({
  action,
  title,
  secondaryAction,
  ternaryAction,
  headerContent,
  quaternary,
  noWrap,
}) => {
  const hasActions =
    action || secondaryAction || ternaryAction || quaternary || headerContent;

  return (
    <Box mb={1}>
      <Box
        display="flex"
        alignItems="center"
        width={1}
        mb={1}
        flexWrap={noWrap ? 'nowrap' : 'wrap'}
        justifyContent="flex-end"
      >
        <Box flexGrow={1} overflow="hidden">
          <SectionTitle text={title} noWrap={noWrap} />
        </Box>
        {hasActions && (
          <Box display="flex" justifyContent="flex-end">
            {headerContent}
            {quaternary && (
              <Box textAlign="right" ml={1}>
                <SectionAction {...quaternary} color="primary" />
              </Box>
            )}
            {secondaryAction && (
              <ThemeProvider theme={cancelButtonTheme}>
                <Box textAlign="right" ml={1}>
                  <SectionAction {...secondaryAction} color="primary" />
                </Box>
              </ThemeProvider>
            )}
            {action && (
              <Box textAlign="right" ml={1}>
                <SectionAction {...action} />
              </Box>
            )}
            {ternaryAction && (
              <Box textAlign="right" ml={1}>
                <SectionAction {...ternaryAction} color="primary" />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Divider />
    </Box>
  );
};

export default React.memo(SectionHeader);
