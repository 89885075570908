import React from 'react';
import { Box, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataListEnd from './DataListEnd';

export type Props = {
  loading?: boolean;
  showEndText: boolean;
  onLoadMore?: () => void;
  displayedCount: number;
  totalCount: number;
  continiousScrollToggle?: boolean;
};

const DataListLoadMore: React.FC<Props> = ({
  loading,
  showEndText,
  onLoadMore,
  displayedCount,
  totalCount,
  continiousScrollToggle,
}) => {
  const hasNextPage = displayedCount < totalCount;

  return (
    <>
      {onLoadMore &&
        !loading &&
        (hasNextPage ? (
          continiousScrollToggle ? (
            ''
          ) : (
            <Button
              disableElevation
              color="primary"
              variant="outlined"
              fullWidth
              onClick={onLoadMore}
            >
              Load more
            </Button>
          )
        ) : showEndText ? (
          <DataListEnd />
        ) : null)}
      {onLoadMore && loading && (
        <Box p={1} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default React.memo(DataListLoadMore);
