import React from 'react';
import Alert from 'styleguide/layout/Alert/Alert';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { Palette } from '@woundtech/ui-colors';
import {
  WEB_VERSION_ALERT_TITLE,
  OLDER_WEB_VERSION_WARNING,
  CURRENT_VERSION,
  NEW_VERSION,
  SUBMIT_BUTTON_TEXT,
} from './constants';

export type Props = {
  currentWebVersion: string | undefined;
  newWebVersion: string | undefined;
  onSubmit: () => void;
};

const WebVersionAlert: React.FC<Props> = ({
  onSubmit,
  currentWebVersion,
  newWebVersion,
}) => {
  return (
    <Alert
      open={true}
      icon="warning"
      title={WEB_VERSION_ALERT_TITLE}
      hideSubmitButton
      hideCancelButton
    >
      {OLDER_WEB_VERSION_WARNING}
      <Box pt={2}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexGrow="1"
        >
          <Box display="flex">
            <Box
              borderRadius={4}
              bgcolor={Palette.Gray.Moderate}
              textAlign="center"
              px={0.5}
            >
              <Typography variant="caption">{`${CURRENT_VERSION} ${currentWebVersion}`}</Typography>
            </Box>
            <Box
              ml={1}
              borderRadius={4}
              bgcolor={Palette.Teal.Light}
              textAlign="center"
              px={0.5}
            >
              <Typography variant="caption">{`${NEW_VERSION} ${newWebVersion}`}</Typography>
            </Box>
          </Box>
          <Box>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              {SUBMIT_BUTTON_TEXT}
            </Button>
          </Box>
        </Box>
      </Box>
    </Alert>
  );
};

export default WebVersionAlert;
