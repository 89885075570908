import React, { useState, useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import AsynchronousLayout from 'styleguide/layout/Asynchronous';
import Empty from 'styleguide/Empty';
import FormLayout from 'styleguide/layout/Form';
import CareFacilityLookupFilter from './Filter';
import CareFacilityCard from '../Card';
import HomeHealthCard from 'master-list/home-health/Card';
import ContactPersonCard from '../Card/ContactPerson';
import FieldSet from 'styleguide/layout/FieldSet';
import {
  CareFacilityQuery,
  UpdateCareFacilityMutation,
  HealthCareFacilityListQuery,
} from 'master-list/common/Schema';
import { Box } from '@material-ui/core';
import CardLayout from 'styleguide/layout/Card';
import ModalDrawer from 'styleguide/ModalDrawer';
import { useMutation } from '@apollo/client';
import {
  CONTACT_PERSON_STATUS,
  CONTACT_PERSON_PRIMARY,
  CREATE_HOME_HEALTH_TEXT,
} from 'master-list/constants';

import CareFacilityContactCard from '../Card/HealthCareContactCard';
import { updatePayloadAndSetPrimary } from '../utils';
import ContactPerson from '../Form/ContactPerson';
import Typography from '@material-ui/core/Typography';
import HealthCareList from 'master-list/common/List';
import AddHealthCareFacility from 'patient/Form/AddHealthCareFacility';
import AddHomeHealthFacility from 'admission/Form/AddHomeHealthFacility';

type Props = {
  data?: any;
  entities: fhir.Organization[] | undefined;
  showModalDrawer: boolean;
  onContactSelect: (facility: any) => void;
  onLookupClose: () => void;
  selectedAddress?: any;
  type?: string;
  headingText?: string;
  emptyText?: string;
  showFacilitybutton?: boolean;
  isAdmission?: boolean;
};

const CareFacilityList: React.FC<Props> = ({
  entities,
  onContactSelect,
  showModalDrawer,
  onLookupClose,
  selectedAddress,
  type = 'care-facility,assisted-living-facility,skilled-nursing-facility',
  headingText = 'Care Facility',
  emptyText,
  showFacilitybutton,
  isAdmission,
}) => {
  const defaultState = {
    name: '',
    type: '',
    state: '',
  };
  const [filters, setVariables] = useState<{ [key: string]: string | number }>(
    defaultState
  );
  const [careFacility, setCareFacility] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [addNewFacility, setAddNewFacility] = useState<boolean>(false);
  const [addNewhomehealth, setAddNewhomehealth] = useState<boolean>(false);

  const [showFacilityList, setShowFacilityList] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    extension: [
      { url: CONTACT_PERSON_STATUS, valueBoolean: true },
      {
        url: CONTACT_PERSON_PRIMARY,
        valueBoolean: false,
      },
    ],
    resourceType: 'Practitioner',
  });
  const [healthCareFacility, setHealthCareFacility] = useState<any>();
  const [submit, { loading, error }] = useMutation(UpdateCareFacilityMutation);
  const [
    getHealthCareFacilityInfo,
    { loading: healthCareFacilityLoading, error: healthCareFacilityError },
  ] = useLazyQuery(CareFacilityQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setHealthCareFacility(data?.getHealthCareFacilityById);
    },
  });

  const title = useMemo(
    () => `${headingText} ${careFacility?.facility ? 'Contact' : 'Lookup'}`,
    [careFacility, headingText]
  );

  const searchProvider = (payload: { [key: string]: string }) => {
    setVariables({
      ...payload,
      first: 25,
      sort: '-_lastUpdated',
      type:
        payload?.type === null || payload?.type === '' ? type : payload?.type,
      active: 'true',
    });
    setCareFacility(undefined);
    setShowFacilityList(true);
    setHealthCareFacility({});
  };
  const clearSearchedResult = useCallback(() => {
    setShowFacilityList(false);
    setIsOpen(false);
    if (title === `${headingText} Lookup`) {
      onLookupClose();
    }
    setCareFacility(undefined);
  }, [onLookupClose, title, headingText]);

  const hanldeCareFacilitySelection = (careFacility: any) => {
    getHealthCareFacilityInfo({
      variables: {
        organizationId: careFacility?.id,
      },
    });
    setCareFacility({
      facility: careFacility,
    });
  };

  const handelCardSelection = useCallback(
    (contact: any) => {
      setCareFacility({
        ...careFacility,
        contact: contact === 'none' ? { id: 'none' } : contact,
        facilityInfo: healthCareFacility,
      });
    },
    [healthCareFacility, careFacility]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const practitioners = [
        ...updatePayloadAndSetPrimary(
          healthCareFacility?.careTeam?.participant,
          formData
        ),
        formData,
      ];
      await submit({
        variables: {
          practitioners: practitioners,
          organization: healthCareFacility?.organization,
        },
      });
      getHealthCareFacilityInfo({
        variables: {
          organizationId: careFacility?.facility?.id,
        },
      });
      setFormData({
        extension: [
          { url: CONTACT_PERSON_STATUS, valueBoolean: true },
          {
            url: CONTACT_PERSON_PRIMARY,
            valueBoolean: false,
          },
        ],
        resourceType: 'Practitioner',
      });
      setIsOpen(false);
    } catch {}
  }, [
    formData,
    submit,
    careFacility,
    healthCareFacility,
    getHealthCareFacilityInfo,
  ]);

  const handleSave = useCallback(
    (careFacility: any) => {
      onContactSelect(careFacility);
      onLookupClose();
    },
    [onContactSelect, onLookupClose]
  );

  const isNewAddress = useMemo(() => showModalDrawer && isOpen, [
    isOpen,
    showModalDrawer,
  ]);

  if (!showModalDrawer && !selectedAddress) {
    return <Typography variant="body1">{emptyText}</Typography>;
  }
  return (
    <>
      {showModalDrawer ? (
        <>
          <ModalDrawer
            open={showModalDrawer}
            onClose={() => onLookupClose()}
            title={title}
          >
            <FormLayout
              isValid={isNewAddress ? isValid : careFacility?.contact?.id}
              onCancel={() =>
                isNewAddress ? setIsOpen(false) : clearSearchedResult()
              }
              onSubmit={() =>
                isNewAddress ? handleSubmit() : handleSave(careFacility)
              }
              canDisableSubmit={!careFacility}
              submitButtonText={isNewAddress ? 'Add' : 'Save'}
            >
              <Box display="flex" flexDirection="column" height={1} width={1}>
                {!careFacility && (
                  <Box>
                    <CareFacilityLookupFilter
                      applyFilters={searchProvider}
                      clearSearchedResult={clearSearchedResult}
                      entities={entities}
                      type={type}
                    />
                  </Box>
                )}
                <Box pt={1} flexGrow="1" display="flex">
                  <Box width={1} height="auto">
                    {!careFacility ? (
                      showFacilityList ? (
                        <HealthCareList
                          onSelect={hanldeCareFacilitySelection}
                          title={undefined}
                          type={type}
                          active={'true'}
                          card={CareFacilityCard}
                          listType="getHealthCareFacilityList"
                          query={HealthCareFacilityListQuery}
                          dataList={{
                            component: showFacilitybutton
                              ? CareFacilityCard
                              : HomeHealthCard,
                            onSelect: hanldeCareFacilitySelection,
                            textCreate: showFacilitybutton
                              ? 'facility'
                              : CREATE_HOME_HEALTH_TEXT,
                            onCreate: () =>
                              showFacilitybutton
                                ? setAddNewFacility(true)
                                : setAddNewhomehealth(true),
                          }}
                          payloadvariables={filters}
                        />
                      ) : (
                        <Empty
                          icon="search"
                          text="Apply criteria to search"
                        ></Empty>
                      )
                    ) : (
                      <>
                        <FieldSet>
                          <Box display="flex">
                            <Box width={1}>
                              <CardLayout onClick={() => {}}>
                                {showFacilitybutton ? (
                                  <CareFacilityCard
                                    data={careFacility?.facility}
                                    onClick={() => {}}
                                  />
                                ) : (
                                  <HomeHealthCard
                                    data={careFacility?.facility}
                                    onClick={() => {}}
                                  />
                                )}
                              </CardLayout>
                            </Box>
                          </Box>
                        </FieldSet>
                        <Box mt={2} />
                        <AsynchronousLayout
                          data={healthCareFacility}
                          loading={loading || healthCareFacilityLoading}
                          error={error || healthCareFacilityError}
                        >
                          <FieldSet
                            label={
                              isNewAddress
                                ? 'New Contact Person'
                                : 'Contact Person'
                            }
                            action={{
                              icon: 'add',
                              text: 'Add',
                              disabled: isNewAddress,
                              onClick: () => setIsOpen(true),
                            }}
                          >
                            {isNewAddress ? (
                              <ContactPerson
                                data={formData}
                                onChange={setFormData}
                                onValidate={setIsValid}
                              />
                            ) : (
                              <ContactPersonCard
                                data={healthCareFacility?.careTeam}
                                handelCardSelection={handelCardSelection}
                                selected={
                                  careFacility?.contact?.id || undefined
                                }
                              />
                            )}
                          </FieldSet>
                        </AsynchronousLayout>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </FormLayout>
          </ModalDrawer>
        </>
      ) : (
        selectedAddress &&
        !showModalDrawer && (
          <CareFacilityContactCard
            data={selectedAddress}
            emptyText={emptyText}
            type={isAdmission}
          />
        )
      )}
      {showFacilitybutton && !isAdmission ? (
        <ModalDrawer
          open={addNewFacility}
          onClose={() => setAddNewFacility(false)}
          title={'New Care Facility'}
        >
          <AddHealthCareFacility
            isOpen={addNewFacility}
            onClose={() => setAddNewFacility(false)}
          />
        </ModalDrawer>
      ) : (
        <ModalDrawer
          open={addNewhomehealth}
          onClose={() => setAddNewhomehealth(false)}
          title={'New Home Health'}
        >
          <AddHomeHealthFacility
            isOpen={addNewhomehealth}
            onClose={() => setAddNewhomehealth(false)}
          />
        </ModalDrawer>
      )}
    </>
  );
};

export default CareFacilityList;
