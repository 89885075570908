import React, { Suspense, lazy } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Theme from 'styleguide/Theme';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import axios from 'axios';
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
  from,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import 'typeface-roboto';
import * as serviceWorker from './serviceWorker';
import { Provider as AuthenticationProvider } from 'authentication/Context';
import { Provider as RoutingScheduleProvider } from 'routing/Schedule';
import { Provider as AuthorizationProvider } from 'authorization/Context';
import { Provider as UserPreferenceProvider } from 'user-preference/Context';
import AuthorizationRoute from 'authorization/Route';
import Loading from 'styleguide/Loading';
import PublicLayout from 'styleguide/layout/Public';
import {
  PRIVATE_ROOT_ROUTES,
  PRIVATE_PATHNAME,
  NO_INTERNET_CONNECTION_ERROR_MESSAGE,
} from 'utility/constants';
import { PRIVATE_FLATENED_ROOT_ROUTES } from 'utility/constants';
import { PrivateLayoutProvider } from './styleguide/layout/Private/Context';
import { PrivateLayout } from './styleguide/layout/Private';
import Configuration from 'application/Configuration';
import FeatureToggle from 'application/FeatureToggle';
import WebVersion from 'application/WebVersion';
import Analytics from 'application/Analytics';
import ErrorBoundary from 'application/ErrorBoundary';
import TelemedicineWaitingRoomNotification from 'telemedicine/waiting-room/Notification';
import PasswordExpiryNotification from 'telemedicine/waiting-room/Notification/PasswordExpiryNotification';
import { onError } from '@apollo/client/link/error';
import UserPinAlert from 'user/UserPinAlert/UserPinAlert';
import { getSessionStorageRecord, setSessionStorageRecord } from 'application';
import OldVersionAlert from 'user/OldVersionAlert/OldVersionAlert';
import componentLoader from 'utility/componentLoader';

const HttpError = lazy(() =>
  componentLoader(() => import('styleguide/core/HttpError'))
);
const SignIn = lazy(() =>
  componentLoader(() => import('authentication/SignIn'))
);
const SSOAuthentication = lazy(() =>
  componentLoader(() => import('authentication/SignIn/SSOAuthentication'))
);
declare global {
  interface Window {
    _env_: {
      GRAPHQL_URI: string;
      CONFIGURATION_URI: string;
      BUILD_NUMBER: string;
      SUBSCRIPTION_SERVICE_URI: string;
      FEATURE_TOGGLE_URI: string;
      WEB_VERSION_URI: string;
      OAUTH_DOMAIN: string;
      OAUTH_URL: string;
      OAUTH_CLIENT_ID: string;
      OAUTH_REDIRECT_URL: string;
      REDIRECT_SIGN_IN: string;
      REDIRECT_SIGN_OUT: string;
      IDLE_LOCK_SCREEN_DELAY: string;
      SSO_URI: string;
      JWT_TOKEN_URI: string;
      REFRESH_TOKEN_SSO_URI: string;
    };
  }
}

const getGraphqlLink = () => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    new WebSocketLink({
      uri:
        window?._env_?.SUBSCRIPTION_SERVICE_URI ||
        `${window.location.origin.replace(
          'http',
          'ws'
        )}/subscriptions/ws/graphql`,
      options: { reconnect: true },
    }),
    new HttpLink({
      uri: window?._env_?.GRAPHQL_URI || '/graphql',
    })
  );
};

let link = getGraphqlLink();

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          QuestionnaireResponseListForClinical: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Admission: {
        fields: {
          careManager: {
            merge: true,
          },
          team: () => {
            return false;
          },
        },
      },
    },
  }),
  link: from([
    new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          (key, value) => {
            if (key === '__typename') {
              return undefined;
            }

            if (key === 'meta') {
              return undefined;
            }

            if (value === null) {
              return undefined;
            }

            if (typeof value === 'string') {
              return value.trim();
            }

            return value;
          }
        );
      }

      return forward(operation);
    }),
    setContext(async (_, { headers }) => {
      const isInternal = getSessionStorageRecord('is-internal');
      let token = getSessionStorageRecord('access-token'),
        idToken = getSessionStorageRecord('id-token');
      if (isInternal === 'true') {
        // This user auth session is maintained by AWS Cognito
        // hence we will use the Cognito SDK for token refresh.
        const refreshToken = getSessionStorageRecord('refresh-token');
        const expiry = Number(getSessionStorageRecord('expiry')) || 0;
        if (refreshToken && expiry) {
          const currentTime = Math.floor(new Date().getTime() / 1000);
          const REMAINING_SECONDS_TO_EXPIRE = 1000; // Just for caution, what if the token is going to expire in next 100 seconds.
          if (expiry - currentTime < REMAINING_SECONDS_TO_EXPIRE) {
            try {
              const response = await axios.get(
                `${window?._env_?.REFRESH_TOKEN_SSO_URI || '/refreshToken'}`,
                {
                  headers: {
                    refreshToken: getSessionStorageRecord('refresh-token'),
                  },
                }
              );
              token = response.data['access_token'];
              idToken = response.data['id_token'];
              setSessionStorageRecord('access-token', token);
              setSessionStorageRecord('id-token', idToken);
              setSessionStorageRecord('expiry', response.data['expiry']);
            } catch (err) {
              throw err;
            }
          }
        }
      } else {
        const session = await Auth.currentSession();
        token = session.getAccessToken().getJwtToken();
        idToken = session.getIdToken().getJwtToken();
        setSessionStorageRecord('access-token', token);
        setSessionStorageRecord('id-token', idToken);
      }
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
          'woundtech-id-token': idToken ? idToken : '',
        },
      };
    }),
    onError(({ networkError, operation, forward }) => {
      const error: any = Object.assign({}, networkError);
      if (networkError && !navigator.onLine)
        networkError.message = NO_INTERNET_CONNECTION_ERROR_MESSAGE;
      else if (networkError && error.bodyText) {
        try {
          JSON.parse(error.bodyText);
        } catch (e) {
          networkError.message = error.bodyText.replace(/(<([^>]+)>)/gi, '');
        }
      }
      forward(operation);
    }),
    link,
  ]),
});

const authorizationPaths = [
  ...PRIVATE_FLATENED_ROOT_ROUTES.map(item => item?.path),
  PRIVATE_PATHNAME,
];
const authenticationPaths = ['/', '/sign-in', ...authorizationPaths];

const Application = () => (
  <Theme>
    <CssBaseline />
    <Configuration>
      <FeatureToggle>
        <WebVersion>
          <Router>
            <Analytics />
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <Route path={authenticationPaths}>
                    <AuthenticationProvider apolloClient={apolloClient}>
                      <ApolloProvider client={apolloClient}>
                        <Switch>
                          <Route exact path="/">
                            <Redirect to="/sign-in" />
                          </Route>
                          <Route path="/sign-in">
                            <PublicLayout>
                              <SignIn />
                            </PublicLayout>
                          </Route>
                          <Route path="/authenticate">
                            <SSOAuthentication />
                          </Route>
                          <Route path={authorizationPaths}>
                            <AuthorizationProvider>
                              <UserPreferenceProvider>
                                <RoutingScheduleProvider>
                                  <PrivateLayoutProvider>
                                    <PrivateLayout
                                      navigationItems={PRIVATE_ROOT_ROUTES}
                                    >
                                      <OldVersionAlert />
                                      <TelemedicineWaitingRoomNotification />
                                      <PasswordExpiryNotification />
                                      <UserPinAlert />
                                      <Suspense fallback={<Loading />}>
                                        <Switch>
                                          {PRIVATE_FLATENED_ROOT_ROUTES.map(
                                            item => {
                                              return (
                                                <AuthorizationRoute
                                                  key={item.path}
                                                  path={item.path}
                                                  permission={item.permission}
                                                  component={lazy(() =>
                                                    componentLoader(() => {
                                                      switch (item.module) {
                                                        case 'task':
                                                          return import(
                                                            './task/Dashboard'
                                                          );
                                                        case 'clinician-scheduling':
                                                          return import(
                                                            './clinician-scheduling/Dashboard'
                                                          );
                                                        case 'scheduling':
                                                          return import(
                                                            './scheduling/Dashboard'
                                                          );
                                                        case 'patient':
                                                          return import(
                                                            './patient/Dashboard'
                                                          );
                                                        case 'account':
                                                          return import(
                                                            './account/Dashboard'
                                                          );
                                                        case 'tasking-dashboard':
                                                          return import(
                                                            './tasking-dashboard/Dashboard'
                                                          );
                                                        case 'user':
                                                          return import(
                                                            './user/Dashboard'
                                                          );
                                                        case 'team':
                                                          return import(
                                                            './team/Dashboard'
                                                          );
                                                        case 'pcc-management':
                                                          return import(
                                                            './pcc-management/Dashboard'
                                                          );
                                                        case 'clinician-config':
                                                          return import(
                                                            './clinician-config/Dashboard'
                                                          );
                                                        case 'lead-clinician-management':
                                                          return import(
                                                            './lead-clinician-management/Dashboard'
                                                          );
                                                        case 'th-clinician-management':
                                                          return import(
                                                            './config/th-clinician-management/Dashboard'
                                                          );
                                                        case 'payer':
                                                          return import(
                                                            './payer/Dashboard'
                                                          );
                                                        case 'my-encounters':
                                                          return import(
                                                            './telemedicine/encounters/Dashboard'
                                                          );
                                                        case 'waiting-room':
                                                          return import(
                                                            './telemedicine/waiting-room/Dashboard'
                                                          );
                                                        case 'encounters-review':
                                                          return import(
                                                            './encounters-review/Component'
                                                          );
                                                        case 'Orders':
                                                          return import(
                                                            './clinical-config/config/Orders/Dashboard'
                                                          );
                                                        case 'order-sets':
                                                          return import(
                                                            './clinical-config/config/OrderSets/Dashboard'
                                                          );
                                                        case 'Educational Delivery':
                                                          return import(
                                                            './clinical-config/config/EducationalDelivery/Dashboard'
                                                          );
                                                        case 'Formulary':
                                                          return import(
                                                            './clinical-config/config/Formulary/Dashboard'
                                                          );
                                                        case 'Procedures':
                                                          return import(
                                                            './clinical-config/config/Procedures/Dashboard'
                                                          );
                                                        case 'treatment-steps':
                                                          return import(
                                                            './clinical-config/config/TreatmentStep/Dashboard'
                                                          );
                                                        case 'wound-type':
                                                          return import(
                                                            './clinical-config/config/WoundType/Dashboard'
                                                          );
                                                        case 'barriers':
                                                          return import(
                                                            './clinical-config/config/Barriers/Dashboard'
                                                          );
                                                        case 'medical-condition':
                                                          return import(
                                                            './clinical-config/config/MedicalCondition/Dashboard'
                                                          );
                                                        case 'Review-of-Systems':
                                                          return import(
                                                            './clinical-config/config/ReviewOfSystem/Dashboard'
                                                          );
                                                        case 'Forms-Logic':
                                                          return import(
                                                            './clinical-config/config/FormsLogic/Dashboard'
                                                          );
                                                        case 'encounter-duration':
                                                          return import(
                                                            './clinical-config/config/EncounterDuration/Dashboard'
                                                          );
                                                        case 'administrative-config-code-system':
                                                          return import(
                                                            './master-list/code-system/Dashboard'
                                                          );
                                                        case 'clinician-encounters':
                                                          return import(
                                                            './clinician-encounters/Dashboard'
                                                          );
                                                        case 'bulk-eligibility':
                                                          return import(
                                                            './bulk-eligibility/Dashboard'
                                                          );
                                                        case 'missed-appointments':
                                                          return import(
                                                            './bulk-operations/missed-appoitments/Dashboard'
                                                          );
                                                        case 'manage-session':
                                                          return import(
                                                            './rounding-document/manage-session/Dashboard'
                                                          );
                                                        case 'made-encounters':
                                                          return import(
                                                            './bulk-operations/made-encounters/Dashboard'
                                                          );
                                                        case 'market-team-config':
                                                          return import(
                                                            './master-list/market-team-config/Dashboard'
                                                          );
                                                        case 'care-facility':
                                                          return import(
                                                            './master-list/care-facility/Dashboard'
                                                          );
                                                        case 'home-health':
                                                          return import(
                                                            './master-list/home-health/Dashboard'
                                                          );
                                                        case 'notification-config':
                                                          return import(
                                                            './notification-config/Dashboard'
                                                          );
                                                        case 'pcp-config':
                                                          return import(
                                                            './master-list/pcp-config/Dashboard'
                                                          );
                                                        case 'payroll-info':
                                                          return import(
                                                            './payroll-info/Dashboard'
                                                          );
                                                        case 'cbr-dashboard':
                                                          return import(
                                                            './cbr-dashboard/Dashboard'
                                                          );
                                                        default:
                                                          return Promise.reject(
                                                            'Unhandled root route'
                                                          );
                                                      }
                                                    })
                                                  )}
                                                />
                                              );
                                            }
                                          )}
                                        </Switch>
                                      </Suspense>
                                    </PrivateLayout>
                                  </PrivateLayoutProvider>
                                </RoutingScheduleProvider>
                              </UserPreferenceProvider>
                            </AuthorizationProvider>
                          </Route>
                        </Switch>
                      </ApolloProvider>
                    </AuthenticationProvider>
                  </Route>
                  <Route path="*">
                    <PublicLayout>
                      <HttpError code="404" message="Not Found" />
                    </PublicLayout>
                  </Route>
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Router>
        </WebVersion>
      </FeatureToggle>
    </Configuration>
  </Theme>
);

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
