export function getEmail(telecom: fhir.ContactPoint[] | undefined) {
  return telecom?.find(item => item.system === 'email')?.value;
}

export function getPhoneNumberExtension(
  telecom: fhir.ContactPoint | undefined
) {
  return telecom?.extension?.find(item => item.url === 'PHONE_NUMBER_EXTENSION')
    ?.valueString;
}

export function getOtherLabelExtension(telecom: fhir.ContactPoint | undefined) {
  return telecom?.extension?.find(item => item.url === 'OTHER_LABEL_EXTENSION')
    ?.valueString;
}
