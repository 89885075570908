import { CLINICAL_CONFIG_PATH, FORM_CONFIG } from 'clinical-config/constants';

export const FORMULARY = {
  path: `${CLINICAL_CONFIG_PATH}/products`,
  text: 'Formulary',
  module: 'Formulary',
  component: 'Dashboard',
};

export const PROCEDURES = {
  path: `${CLINICAL_CONFIG_PATH}/procedures`,
  text: 'Procedures',
  module: 'Procedures',
  component: 'Dashboard',
};

export const TREATMENT = {
  path: `${CLINICAL_CONFIG_PATH}/treatment-steps`,
  text: 'Treatment steps',
  module: 'treatment-steps',
  component: 'Dashboard',
};

export const WOUND_TYPE = {
  path: `${CLINICAL_CONFIG_PATH}/wound-type`,
  text: 'Wound Type',
  module: 'wound-type',
  component: 'Dashboard',
};

export const ORDERS = {
  path: `${CLINICAL_CONFIG_PATH}/orders`,
  text: 'Orders',
  module: 'Orders',
  component: 'Dashboard',
};

export const ORDERSETS = {
  path: `${CLINICAL_CONFIG_PATH}/order-sets`,
  text: 'Order sets',
  module: 'order-sets',
  component: 'Dashboard',
};

export const BARRIERS = {
  path: `${CLINICAL_CONFIG_PATH}/barriers`,
  text: 'Barriers',
  module: 'barriers',
  component: 'Dashboard',
};

export const MEDICAL_CONFIG = {
  path: `${CLINICAL_CONFIG_PATH}/medical-condition`,
  text: 'Medical Condition',
  module: 'medical-condition',
  component: 'Dashboard',
};

export const EDUCATIONAL_TRAINING = {
  path: `${CLINICAL_CONFIG_PATH}/educational-training`,
  text: 'Educational delivery',
  module: 'Educational Delivery',
  component: 'Dashboard',
};

export const REVIEW_OF_SYSTEM = {
  path: `${CLINICAL_CONFIG_PATH}/review-of-systems`,
  text: 'Review of Systems',
  module: 'Review-of-Systems',
  component: 'Dashboard',
};

export const FORMS_CONFIG = {
  path: `${CLINICAL_CONFIG_PATH}/${FORM_CONFIG}`,
  text: 'Form Config',
  module: 'Forms-Logic',
  component: 'Dashboard',
};

export const ENCOUNTERS = {
  path: `${CLINICAL_CONFIG_PATH}/encounter-duration`,
  text: 'Encounter Duration',
  module: 'encounter-duration',
  component: 'Dashboard',
};
