import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from 'styleguide/Avatar';
import AttributeList from 'styleguide/display/AttributeList';

export type Props = {
  title?: string;
  imageUrl?: string;
  imageIcon?: string;
  imageText?: string;
  imageTextColor?: string;
  imageColor?: string;
  attributes: any[];
  status?: string;
  subTitle?: string;
  subTitleIcon?: string;
  statusBackgroundColor?: string;
  hideAvatar?: boolean;
  pictureLoad?: () => void;
  titleWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  statusIcon?: string;
};

const ResourceCardComponent: React.FC<Props> = ({
  attributes,
  imageUrl,
  imageIcon,
  imageText,
  imageTextColor,
  imageColor,
  title,
  status,
  subTitle,
  subTitleIcon,
  statusBackgroundColor,
  hideAvatar,
  pictureLoad,
  titleWrap = 'nowrap',
  statusIcon,
}) => (
  <>
    <Box display="flex" alignItems="flex-end">
      {!hideAvatar && (
        <Box width="72px">
          <Avatar
            color={imageColor}
            icon={imageIcon}
            name={title || ''}
            src={imageUrl}
            text={imageText}
            textColor={imageTextColor}
            pictureLoad={pictureLoad}
          />
        </Box>
      )}
      <Box pl={1} flexGrow={1} overflow="hidden">
        <AttributeList
          title={title}
          attributes={attributes}
          subTitle={subTitle}
          subTitleIcon={subTitleIcon}
          spacing={0}
          status={status}
          statusBackgroundColor={statusBackgroundColor}
          titleWrap={titleWrap}
          statusIcon={statusIcon}
        />
      </Box>
    </Box>
  </>
);

export default React.memo(ResourceCardComponent);
