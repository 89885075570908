import React from 'react';
import Loading from '../Loading';

export type Props = {};

const DataListLoading: React.FC<Props> = () => {
  return <Loading variant="component" />;
};

export default React.memo(DataListLoading);
