import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context as AppConfigContext } from 'application/Configuration';

import ReactGA from 'react-ga';

const Analytics: React.FC = () => {
  const location = useLocation();
  const { GoogleAnalyticsMeasureId } = useContext(AppConfigContext);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (GoogleAnalyticsMeasureId) {
      ReactGA.initialize(GoogleAnalyticsMeasureId);
      ReactGA.pageview(currentPath);
    }
  }, [location, GoogleAnalyticsMeasureId]);

  return null;
};

export default Analytics;
