import React from 'react';
import { PlaceholderKind } from '@woundtech/ui-constants';
import Empty from '../Empty';

export type Props = {
  onCreate?: () => void;
  textCreate?: string;
};

const DataListEmpty: React.FC<Props> = ({ onCreate, textCreate }) => {
  return (
    <Empty
      icon="find_in_page"
      text={PlaceholderKind.NoResults}
      onAction={onCreate}
      textAction={textCreate}
    />
  );
};

export default React.memo(DataListEmpty);
