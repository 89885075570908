import React, { useEffect, useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Replay from '@material-ui/icons/ReplayOutlined';
import { Storage } from 'aws-amplify';
import LinearProgress from '@material-ui/core/LinearProgress';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Grid from '@material-ui/core/Grid';
import FileListItemDelete from './FileListItemDelete';
import { getProgress } from './utils';
import IconButton from '@material-ui/core/IconButton';
import SingleChoiceField from 'styleguide/form/SingleChoice/Field';

export type Props = {
  item: any;
  dispatch: any;
  selectOptions?: any;
  selectOptionName: string;
  selectOptionLabel?: string;
  onSelectChange?: any;
  onItemAdded?: any;
  onItemRemoved?: any;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px',
    },
    progressDisplayStyle: {
      color: grey[600],
    },
    input: {
      display: 'none',
    },
  })
);

const FileListItem: React.FC<Props> = ({
  item,
  dispatch,
  selectOptions = [],
  onSelectChange = null,
  selectOptionName,
  selectOptionLabel,
  onItemAdded = null,
  onItemRemoved = null,
}) => {
  const progress = item?.progress || 0;

  const classes = useStyles();
  const color = progress === 100 ? green[400] : blue[400];
  const [fileSize, setFileSize] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [resultOptionValue, setResultOptionValue] = useState<string>(
    item?.attachment?.code?.coding?.[0]?.code || ''
  );
  const BorderLinearProgress = withStyles({
    root: {
      backgroundColor: grey[300],
    },
    bar: {
      backgroundColor: item.error ? red[400] : color,
    },
  })(LinearProgress);

  const loadFile = useCallback(
    async (item: any) => {
      dispatch({ type: 'fileUploadingStart', item });
      try {
        await Storage.put(item?.attachment?.id, item.file, {
          contentType: item?.attachment?.contentType,
          progressCallback(progress: any) {
            setFileSize(parseFloat(progress.total));
            setUploadedSize(parseFloat(progress.loaded));
            const value = Math.round(
              (parseFloat(progress.loaded) / parseFloat(progress.total)) * 100
            );

            if (progress.loaded < progress.total) {
              dispatch({
                type: 'fileUploadingProgress',
                item,
                progress: value,
              });
            }
          },
        });
        onItemAdded?.(item?.attachment);
        dispatch({ type: 'fileUploadingCompleted', item });
      } catch (error) {
        dispatch({ type: 'fileUploadingFailed', item, error: error?.message });
      }
    },
    [dispatch, onItemAdded]
  );

  useEffect(() => {
    if (item.upload) {
      loadFile(item);
    }
  }, [item, loadFile]);

  const retryUpload = () => loadFile(item);

  const updateOptionChange = data => {
    setResultOptionValue(data?.code);
    onSelectChange({
      id: item?.attachment?.id,
      code: data?.code,
    });
  };

  const deleteOptionChange = () => {
    onItemRemoved(item?.attachment?.id);
  };

  useEffect(() => {
    if (selectOptions?.length === 1) {
      const defualtCode = selectOptions?.[0]?.code;
      setResultOptionValue(defualtCode);
      onSelectChange({
        id: item?.attachment?.id,
        code: defualtCode,
      });
    }
  }, [selectOptions, item, onSelectChange]);

  return (
    <Box display="flex" justifyContent="space-between">
      <Grid container spacing={selectOptions.length > 0 ? 2 : 0}>
        <Grid item xs={selectOptions.length > 0 ? 7 : 12}>
          <Grid container>
            <Grid item xs={8}>
              <Typography>{item?.attachment?.title || ''}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box textAlign="right">
                {item.error && (
                  <IconButton
                    aria-label="upload picture"
                    component="span"
                    classes={{ root: classes.root }}
                    color="primary"
                    onClick={retryUpload}
                  >
                    <Replay fontSize="small" />
                  </IconButton>
                )}
                <FileListItemDelete
                  item={item}
                  dispatch={dispatch}
                  progress={progress}
                  onSelectChange={deleteOptionChange}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                {progress >= 0 && (
                  <BorderLinearProgress
                    variant="determinate"
                    value={progress}
                  />
                )}
                {progress >= 0 && progress < 100 && (
                  <Typography className={classes.progressDisplayStyle}>
                    {`${getProgress(fileSize, uploadedSize)} (${progress}%)`}
                  </Typography>
                )}
                {item.error && (
                  <Typography color="error">{`${item.error}. You can either retry or cancel it`}</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {selectOptions.length > 0 && (
          <Grid item xs={5}>
            <SingleChoiceField
              name={selectOptionName}
              label={selectOptionLabel}
              value={resultOptionValue}
              onChange={updateOptionChange}
              onValidate={() => {}}
              options={selectOptions}
              variant="drop-down"
              getOptionId={(option: any) => option?.code}
              getOptionLabel={(option: any) => option?.display}
              required={true}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default React.memo(FileListItem);
