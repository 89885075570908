import React, { useContext, useCallback } from 'react';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Context as AuthorizationContext } from 'authorization/Context';
import { Context as AuthenticationContext } from 'authentication/Context';
import ListItemLink from 'styleguide/ListItemLink';
import { makeStyles, createStyles } from '@material-ui/core';
import { fullNameFormat } from 'fhir/general/HumanName';
import { ENTRY_LINK as ACCOUNT_ENTRY_LINK } from 'account';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';

export type Props = {};

const useStyles = makeStyles(theme => {
  return createStyles({
    active: {
      backgroundColor: 'rgba(0, 0, 0, 0.14);',
      pointerEvents: 'none',
    },
  });
});
const Navigation: React.FC<Props> = () => {
  const { signOut, screenLock } = useContext(AuthenticationContext);
  const { user } = useContext(AuthorizationContext);
  const featureToggles = useContext(FeatureToggleContext);
  const classes = useStyles();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleScreenLock = useCallback(() => {
    screenLock();
  }, [screenLock]);

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Icon color="inherit">account_circle</Icon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText disableTypography primary={fullNameFormat(user?.name)} />
      </ListItem>
      <Divider />
      <ListItem
        button
        component={ListItemLink}
        to={ACCOUNT_ENTRY_LINK.path}
        activeClassName={classes.active}
      >
        <ListItemIcon>
          <Icon color="inherit">{ACCOUNT_ENTRY_LINK.icon}</Icon>
        </ListItemIcon>
        <ListItemText disableTypography primary={ACCOUNT_ENTRY_LINK.text} />
      </ListItem>
      {featureToggles?.EnableUserPin === 'true' ? (
        <ListItem button onClick={handleScreenLock}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText disableTypography primary="Lock EMR" />
        </ListItem>
      ) : null}
      <ListItem button onClick={handleSignOut}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText disableTypography primary="Sign Out" />
      </ListItem>
    </List>
  );
};

export default React.memo(Navigation);
