import React from 'react';
import { defaultState } from './reducer';

const Context = React.createContext({
  ...defaultState,
  isAuthorized: (action: string): boolean => {
    return false;
  },
});

export default Context;
