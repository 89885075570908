import React, { useState, useCallback, useContext, useEffect } from 'react';
import Alert from 'styleguide/layout/Alert/Alert';
import { Context as AuthorizationContext } from 'authorization/Context';
import {
  CREATE_PIN_TEXT,
  CREATE_PIN_TITLE,
  SUBMIT_BUTTON_TEXT,
} from './constants';
import Pin from 'account/Pin';
import { getSessionStorageRecord } from 'application';
import { Context as FeatureToggleContext } from 'application/FeatureToggle';

export type Props = {};

const UserPinAlert: React.FC<Props> = () => {
  const { user } = useContext(AuthorizationContext);
  const featureToggles = useContext(FeatureToggleContext);
  const [openPinAlert, setopenPinAlert] = useState<boolean>(false);
  const [showCreatePinForm, setShowCreatePinForm] = useState<boolean>(false);

  useEffect(() => {
    if (!!user) {
      const savedPin = getSessionStorageRecord('pin-key');
      const openPinAlert = !user?.userPin && !savedPin;
      setopenPinAlert(openPinAlert);
    }
  }, [user]);

  const handleCreatePin = useCallback(() => {
    setShowCreatePinForm(true);
  }, []);

  return featureToggles?.EnableUserPin === 'true' ? (
    <Alert
      open={openPinAlert}
      icon="warning"
      title={showCreatePinForm ? '' : CREATE_PIN_TITLE}
      submitButtonText={SUBMIT_BUTTON_TEXT}
      hideSubmitButton={showCreatePinForm}
      hideCancelButton
      onSubmit={handleCreatePin}
    >
      {showCreatePinForm ? (
        <Pin handleCompleted={() => setopenPinAlert(false)} />
      ) : (
        CREATE_PIN_TEXT
      )}
    </Alert>
  ) : null;
};

export default UserPinAlert;
