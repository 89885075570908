import { gql } from '@apollo/client';

export const ValueSetFieldQuery = gql`
  query expandValueSet($url: String!) {
    expandValueSet(url: $url) {
      resourceType
      status
      expansion {
        total
        offset
        parameter {
          name
          valueInteger
        }
        contains {
          system
          code
          display
        }
      }
    }
  }
`;

export const ValueSetFragment = gql`
  fragment ValueSet on ValueSet {
    resourceType
    status
    expansion {
      total
      offset
      parameter {
        name
        valueInteger
      }
      contains {
        system
        code
        display
      }
    }
  }
`;
