export const PREFIX_OPTIONS = [
  {
    coding: [
      {
        code: 'Dr',
        display: 'Dr',
      },
    ],
    text: 'Dr',
  },
  {
    coding: [
      {
        code: 'Mr',
        display: 'Mr',
      },
    ],
    text: 'Mr',
  },
  {
    coding: [
      {
        code: 'Miss',
        display: 'Miss',
      },
    ],
    text: 'Miss',
  },
  {
    coding: [
      {
        code: 'Ms',
        display: 'Ms',
      },
    ],
    text: 'Ms',
  },
  {
    coding: [
      {
        code: 'Mrs',
        display: 'Mrs',
      },
    ],
    text: 'Mrs',
  },
  {
    coding: [
      {
        code: 'NP',
        display: 'NP',
      },
    ],
    text: 'NP',
  },
  {
    coding: [
      {
        code: 'PA',
        display: 'PA',
      },
    ],
    text: 'PA',
  },
];

export const VALIDATION_PATTERN_SPECIAL_CHARACTERS = /^[a-zA-Z0-9s]+(?:[-:%/\\()\u2122.+][a-zA-Z0-9s]+)*/;
