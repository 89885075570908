import { Palette } from '@woundtech/ui-colors';
import { ucFirst } from '@woundtech/ui-utils';
import {
  AdmissionStatus,
  ADMISSION_ONHOLD_REASON_URL,
  allActiveStatuses,
} from './constants';
import { differenceInDays } from 'date-fns';
import { PlaceholderKind } from '@woundtech/ui-constants';
import { ENCOUNTER_STATUS_PLANNED } from 'encounter/constants';

export function isAdmissionEditable(admission: fhir.EpisodeOfCare) {
  const editableStatuses = [AdmissionStatus.active, AdmissionStatus.planned];

  return editableStatuses.some(status => status === admission?.status);
}

export function getAdmissionStatusLabel(
  status: string | undefined,
  description?: string
) {
  switch (status) {
    case AdmissionStatus.onholdPlanned: {
      return description ? `On hold (${description})` : 'Planned - on hold';
    }
    case AdmissionStatus.plannedScheduled: {
      return 'Planned (Scheduled)';
    }
    case AdmissionStatus.onholdActive: {
      return description ? `On hold (${description})` : 'Active - on hold';
    }
    case AdmissionStatus.dischargeOrdered: {
      return 'Discharge pending';
    }
    case AdmissionStatus.discharged: {
      return 'Discharged';
    }
    default: {
      return ucFirst(status || '');
    }
  }
}

export function getAdmissionStatusColor(status: string | undefined) {
  switch (status) {
    case AdmissionStatus.active: {
      return Palette.Teal.Main;
    }
    case AdmissionStatus.dischargeOrdered: {
      return Palette.Orange.Main;
    }
    case AdmissionStatus.discharged: {
      return Palette.Gray.Black80;
    }
    case AdmissionStatus.planned: {
      return Palette.Blue.Main;
    }
    case AdmissionStatus.plannedScheduled: {
      return Palette.Blue.Main;
    }
    case AdmissionStatus.onholdPlanned: {
      return Palette.Blue.Main;
    }
    case AdmissionStatus.onholdHospitalized: {
      return Palette.Orange.Main;
    }
    case AdmissionStatus.onholdActive: {
      return Palette.Orange.Main;
    }
    default: {
      return Palette.Orange.Main;
    }
  }
}

export function getOnHoldDate(admission: fhir.EpisodeOfCare | undefined) {
  const admissionStatusHistory = (admission?.statusHistory || [])
    .slice()
    .sort(
      (a, b) =>
        new Date(b.period?.start || '').valueOf() -
        new Date(a.period?.start || '').valueOf()
    );
  const latestAdmissionStatus = admissionStatusHistory?.[0];
  return latestAdmissionStatus?.period?.start;
}

export function getOnHoldDays(admission: fhir.EpisodeOfCare | undefined) {
  const latestOnHoldDate = getOnHoldDate(admission);
  const days = differenceInDays(new Date(), new Date(latestOnHoldDate || ''));
  return days === 0 ? 1 : days;
}

export function getOnholdReasons(reasons: fhir.CodeableConcept[]): string {
  const modifyReasons = reasons?.map(reason => {
    const code = reason?.coding?.[0]?.code;
    const display = reason?.coding?.[0]?.display;
    if (code === 'other') {
      return `${display} (${reason?.text})`;
    }
    return display;
  });
  return reasons ? modifyReasons.join(', ') : PlaceholderKind.NotAvailable;
}

export function getOnHoldHospitalOrganization(
  admission: fhir.EpisodeOfCare | undefined
): fhir.Organization | undefined {
  const latestAdmissionOnhold =
    admission?.statusHistory?.[admission?.statusHistory?.length - 1];
  const onHoldHospitalReference = latestAdmissionOnhold?.modifierExtension?.find(
    extension => extension.url === 'ONHOLD_HOSPITAL_ORGANIZATION_URL'
  )?.valueReference?.reference;

  const organizationDetails: any = admission?.contained?.find(
    item => item.id === onHoldHospitalReference?.substring(1)
  );

  return organizationDetails;
}

export const isAdmissionOnHold = (admission: fhir.EpisodeOfCare) =>
  admission?.status === AdmissionStatus.onholdPlanned ||
  admission?.status === AdmissionStatus.onholdActive;

export const isAdmissionForPlannedActive = (admission: fhir.EpisodeOfCare) =>
  admission?.status === AdmissionStatus.onholdActive;

export const getAdmissionOnHoldReasons = (extension: fhir.Extension[]) =>
  extension
    ?.filter(item => item?.url === ADMISSION_ONHOLD_REASON_URL)
    ?.map(item => {
      const reasonCoding = item?.valueCodeableConcept?.coding?.[0];
      const code = reasonCoding?.code;
      const display = reasonCoding?.display;
      if (code === 'other') {
        return `${display} - ${item?.valueCodeableConcept?.text}`;
      }
      return display;
    });

export const extractAuditFieldParameters = appointmentStatusHistory => {
  if (!appointmentStatusHistory) {
    return [];
  }
  const auditFilterFields = appointmentStatusHistory?.AdmissionStatusHistory?.map(
    status => {
      if (status?.status === 'planned') {
        return {
          name: status?.status,
          key: 'status',
          value: status?.status,
        };
      } else {
        return {
          name: status?.status,
          key: 'status',
          value: status?.status,
          date: status?.period?.start,
        };
      }
    }
  );
  return auditFilterFields;
};

export const getAdmissionStatusHistory = (
  admissionStatusHistoryData,
  admissionHistoryAuditData
) => {
  return admissionStatusHistoryData?.AdmissionStatusHistory.map(
    (admissionStatus, index) => {
      if (!!admissionHistoryAuditData?.AuditResource?.auditFields?.length) {
        const {
          updatedBy,
        } = admissionHistoryAuditData?.AuditResource?.auditFields[index];
        return { ...admissionStatus, updatedBy };
      }
      return admissionStatus;
    }
  );
};

export function isAnyAllowedAdmissionStatus(admissions): boolean {
  const allowedStatuses = allActiveStatuses,
    existingStatuses: string[] = admissions?.map(item => item?.status);

  const isAnyAllowedStatus = allowedStatuses.filter((status: string) => {
    return existingStatuses.indexOf(status) !== -1;
  });

  return isAnyAllowedStatus.length > 0;
}

export function isAnyPlannedEncounter(encounters): boolean {
  const existingStatuses: string[] = encounters?.map(item => item?.status);

  return existingStatuses.indexOf(ENCOUNTER_STATUS_PLANNED) !== -1;
}
