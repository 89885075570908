export const ENTRY_PERMISSION = 'financial';

const displayLink = (_, __, featureToggles) => {
  return featureToggles?.EnableADPIntegration === 'true';
};

export const ENTRY_LINK = {
  path: '/payroll-info',
  text: 'Payroll Info',
  icon: 'account_balance_wallet',
  module: 'payroll-info',
  component: 'Dashboard',
  permission: ENTRY_PERMISSION,
  displayLink: displayLink,
};
