export const groupNumberLabel = 'Group Number';
export const groupNameLabel = 'Group Name';
export const insurerLabel = 'Insurer';
export const insurerNameLabel = 'Insurer Name';
export const policyNumberLabel = 'Member ID';
export const priorityLabel = 'Type';
export const statusLabel = 'Status';
export const contractTypeLabel = 'Contract Type';
export const pcpNameLabel = 'PCP Name';
export const pcpFirstNameLabel = 'PCP First Name';
export const pcpLastNameLabel = 'PCP Last Name';
export const sameAsPatientLabel = 'Same as Patient';
export const pcpNPILabel = 'PCP NPI';
export const planNameLabel = 'Plan Name';
export const copayLabel = 'Copay';
export const deductibleLabel = 'Deductible';
export const coinsuranceLabel = 'Coinsurance';
export const labCompanyLabel = 'Lab Company';
export const checkedDateLabel = 'Checked On';
export const checkedTimeLabel = 'Checked At';
export const startDateLabel = 'Effective Date';
export const endDateLabel = 'Termination Date';
export const isEligibleLabel = 'Status';
export const ineligibleReasonLabel = 'Reason';
export const dobLabel = 'Date Of Birth';
export const subscriberLabel = 'Subscriber';
export const patientResponsibilityLabel = 'Patient Responsibility';
export const referenceNumberLabel = 'Reference Number';
export const authorizationNumberLabel = 'Authorization Number';
export const payerLabel = 'Payer';
export const MAX_LENGTH_PLAN_GROUP_NAME = 60;
export const AUTH_PAGE = 'Auth';
export const ELIGIBITY_PAGE = 'Eligibility';
export const PAYER_ELIGIBILITY_TYPE = 'payer';
export const INSURER_ELIGIBILITY_TYPE = 'insurer';
export const INSURER_ELIGIBILITY = 'ins';
export const INELIGIBLE_TASK_MESSAGE =
  'New task created for PCC (with results of the last eligibility check)';
export const referenceNumberIdentifierType = 'RN';
export const ineligibilityErrorSystem =
  'http://woundtech.net/fhir/eligibility/error';
export const referenceNumberTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: referenceNumberIdentifierType,
        system: 'http://www.woundtech.net',
        display: referenceNumberLabel,
      },
    ],
  },
  value: '',
};

export const authorizationNumberIdentifierType = 'AN';
export const authorizationNumberTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: authorizationNumberIdentifierType,
        system: 'http://www.woundtech.net',
        display: authorizationNumberLabel,
      },
    ],
  },
  value: '',
};

export const pcpNpiIdentifierType = 'PCPNPI';
export const pcpNpiTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: pcpNpiIdentifierType,
        system: 'http://www.woundtech.net',
        display: pcpNPILabel,
      },
    ],
  },
  value: '',
};

export const pcpFirstNameIdentifierType = 'PCPFIRSTNAME';
export const pcpFirstNameTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: pcpFirstNameIdentifierType,
        system: 'http://www.woundtech.net',
        display: pcpFirstNameLabel,
      },
    ],
  },
  value: '',
};
export const pcpLastNameIdentifierType = 'PCPLASTNAME';
export const pcpLastNameTemplate = {
  use: 'official',
  system: 'http://www.woundtech.net',
  type: {
    coding: [
      {
        code: pcpLastNameIdentifierType,
        system: 'http://www.woundtech.net',
        display: pcpLastNameLabel,
      },
    ],
  },
  value: '',
};

export const NPILabel = 'NPI';
export const taxIDlabel = 'Tax ID';
export const contactNumberLabel = 'Contact Number';
export const stateLabel = 'State';
export const insurerIDLabel = 'Insurer ID';
export const addressLabel = 'Address';
export const codeTypeTax = 'TAX';
export const displayEntityMessage = 'No Entities Found';

export const PURPOSE_ELIGIBILITY = 'benefits';
export const PURPOSE_AUTHORIZATION = 'auth-requirements';
export const CPT_LOOKUP_SYSTEM =
  'http://woundtech.net/fhir/CodeSystem/cpt-hcpcs';
export const ICD10_LOOKUP_SYSTEM = 'http://hl7.org/fhir/sid/icd-10-cm';

export const COVERAGE_PRIORITY_PRIMARY = '1';
export const COVERAGE_PRIORITY_SECONDARY = '2';
export const priorityOptions = [
  {
    coding: [
      {
        code: COVERAGE_PRIORITY_PRIMARY,
        display: 'Primary',
      },
    ],
    text: 'Primary',
  },
  {
    coding: [
      {
        code: COVERAGE_PRIORITY_SECONDARY,
        display: 'Secondary',
      },
    ],
    text: 'Secondary',
  },
];

export const statusOptions = [
  {
    coding: [
      {
        code: 'active',
        display: 'Active',
      },
    ],
    text: 'Active',
  },
  {
    coding: [
      {
        code: 'draft',
        display: 'Inactive',
      },
    ],
    text: 'Inactive',
  },
];

export const AUTHORIZATION_STATUS_APPROVED = {
  coding: [
    {
      code: 'approved',
      display: 'Approved',
    },
  ],
  text: 'Approved',
};

export const AUTHORIZATION_STATUS_PENDING = {
  coding: [
    {
      code: 'pending',
      display: 'Pending',
    },
  ],
  text: 'Pending',
};

export const AUTHORIZATION_STATUS_UNAPPROVED = {
  coding: [
    {
      code: 'unapproved',
      display: 'Not Approved',
    },
  ],
  text: 'Not Approved',
};

export const AUTHORIZATION_STATUS_OPTIONS = [
  AUTHORIZATION_STATUS_APPROVED,
  AUTHORIZATION_STATUS_PENDING,
  AUTHORIZATION_STATUS_UNAPPROVED,
];

export const AUTHORIZATION_THRESHOLD_VALUE = 5;
export const planTypeLabel = 'Plan Type';
export const VALUE_SET_PAYER_PLAN_TYPE_URL =
  'http://woundtech.net/fhir/ValueSet/payer-plan-type';
export const PLAN_TYPE = 'plan-type';

export const COPAY_URL =
  'http://woundtech.net/fhir/extensions/coverageeligibilityresponse/copay';
export const DEDUCTIBLE_URL =
  'http://woundtech.net/fhir/extensions/coverageeligibilityresponse/deductible';
export const COINSURANCE_URL =
  'http://woundtech.net/fhir/extensions/coverageeligibilityresponse/coinsurance';
export const LAB_COMPANY_URL =
  'http://woundtech.net/fhir/extensions/coverageeligibilityresponse/lab-company';
