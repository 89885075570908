import { gql } from '@apollo/client';
import { ContactPointFragment } from 'styleguide/form/Telecom';
import { HumanNameFragment } from 'fhir/general/HumanName';
import { IdentifierFragment } from 'fhir/general/Identifier';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';

export const UserFragment = gql`
  fragment User on User {
    id
    status
    resourceType
    identifier {
      ...Identifier
    }
    extension {
      url
      valueDateTime
      valueBoolean
    }
    name {
      ...HumanName
    }
    telecom {
      ...ContactPoint
    }
    roles {
      ...CodeableConcept
    }
    permissions {
      ...CodeableConcept
    }
    jobFunctions {
      ...CodeableConcept
    }
    legalEntities {
      reference
      resource {
        id
        resourceType
        name
        address {
          state
        }
      }
    }
    payers {
      reference
      resource {
        id
        resourceType
        name
        identifier {
          ...Identifier
        }
        address {
          state
        }
      }
    }
  }
  ${CodeableConceptFragment}
  ${ContactPointFragment}
  ${HumanNameFragment}
  ${IdentifierFragment}
`;

export const UserQuery = gql`
  query User($id: String!) {
    User(id: $id) {
      ...User
      statusUpdatedDate
      lastLoggedIn
      userPin
      userPinModifiedOn
    }
  }
  ${UserFragment}
`;


export const UserListAdmissionInfo = gql`
query UserListAdmissionInfo($id: String!) {
  UserListAdmissionInfo(id: $id) {
    ...User
    statusUpdatedDate
    lastLoggedIn
    userPin
    userPinModifiedOn
  }
}
${UserFragment}
`;

export const UserListQuery = gql`
  query UserList(
    $first: Int
    $after: String
    $term: String
    $firstName: String
    $lastName: String
    $organizationId: String
    $jobFunction: String
  ) {
    UserList(
      first: $first
      after: $after
      term: $term
      firstName: $firstName
      lastName: $lastName
      organizationId: $organizationId
      jobFunction: $jobFunction
    ) {
      edges {
        cursor
        node {
          ...User
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${UserFragment}
`;

export const CreateUserMutation = gql`
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      id
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(user: $user) {
      id
    }
  }
`;

export const ReinviteUserMutation = gql`
  mutation reinviteUser($user: UserInput!) {
    reinviteUser(user: $user) {
      id
    }
  }
`;

export const ResetUserPasswordMutation = gql`
  mutation resetUserPassword($user: UserInput!) {
    resetUserPassword(user: $user) {
      id
    }
  }
`;

export const DeactivateUserMutation = gql`
  mutation deactivateUser($user: UserInput!) {
    deactivateUser(user: $user) {
      id
    }
  }
`;

export const ResetUserPinMutation = gql`
  mutation resetUserPin($email: String!) {
    resetUserPin(email: $email) {
      id
    }
  }
`;

export const ReactivateUserMutation = gql`
  mutation reactivateUser($user: UserInput!) {
    reactivateUser(user: $user) {
      id
    }
  }
`;
