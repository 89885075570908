export const CONTACT_ONSITE_PROVIDER = 'Contact the onsite provider';
export const TELEMEDICINE_ENCOUNTER_TITLE = 'Telehealth encounter';
export const TELEMEDICINE_CHECKIN_TITLE = 'Telehealth Check-in';
export const ONSITE_PROVIDER_CALL_TITLE =
  'Please call the onsite provider in Synzi';
export const TABS = [
  { text: 'Previous Encounter', id: '0' },
  { text: 'Current Encounter', id: '1' },
  { text: 'Dashboard', id: '2' },
];
export const ESIGN_ERROR_MESSAGE =
  'E-sign could not be done. Please try again later';

export const ESIGN_RACE_CONDITION_ERR =
  'Another user has submitted an updated version of the form that you were trying to submit. Please refresh and submit your changes again.';
export const ESIGN_SIMULTANEOUS_MOD_ERR =
  'GraphQL error: The encounter is already in progress. Please select another encounter to check-in.';
export const UNDO_CHECKIN_TEXT = 'Undo check-in';
