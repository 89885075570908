import React from 'react';
import { ApolloError } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CustomButton from 'styleguide/core/Button';
import Alert from '@material-ui/lab/Alert';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Typography from '@material-ui/core/Typography';
import { extractMessageFromApolloError } from 'utility/utils';
import { UNDO_CHECKIN_TEXT } from 'telemedicine/constants';
const cancelButtonRedTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
});

export type Props = {
  asideButtonText?: string;
  asideButtonColor?: string;
  asideButtonVariant?: 'navigation' | 'outlined' | 'text' | 'contained';
  error?: ApolloError;
  canSubmit?: boolean;
  canDisableSubmit?: boolean;
  enableAsideButton?: boolean;
  enableAsideButtonUndoCheckin?: boolean;
  canCancel?: boolean;
  cancelButtonText?: string;
  onAsideAction?: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitButtonText?: string;
  disclaimer?: string | undefined;
  footerRightPadding?: number;
  footerContent?: React.ReactElement;
};

const FormLayoutFooter: React.FC<Props> = ({
  asideButtonText,
  asideButtonColor,
  asideButtonVariant = 'contained',
  error,
  canSubmit = false,
  canDisableSubmit = false,
  enableAsideButton = false,
  enableAsideButtonUndoCheckin = false,
  onAsideAction,
  onCancel,
  cancelButtonText = 'Cancel',
  onSubmit,
  submitButtonText = 'Save',
  canCancel,
  disclaimer = undefined,
  footerRightPadding = 0,
  footerContent,
}) => {
  if (!error && !asideButtonText && !onCancel && !onSubmit && !footerContent) {
    return null;
  }

  return (
    <Box pr={footerRightPadding} pt={2}>
      {error && (
        <Box pb={2}>
          <Alert severity="error">{extractMessageFromApolloError(error)}</Alert>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexGrow="1"
      >
        {disclaimer && (
          <Box textAlign="left" width={1}>
            <Typography variant="caption"> {disclaimer} </Typography>
          </Box>
        )}
        {asideButtonText && (
          <Box mr="auto">
            <CustomButton
              color={asideButtonColor}
              variant={asideButtonVariant}
              label={asideButtonText}
              disabled={
                asideButtonText === UNDO_CHECKIN_TEXT
                  ? !enableAsideButtonUndoCheckin
                  : enableAsideButton
                  ? false
                  : !canSubmit
              }
              onClick={onAsideAction}
            />
          </Box>
        )}
        {onCancel && (
          <Box>
            <ThemeProvider theme={cancelButtonRedTheme}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                disabled={!canCancel}
                onClick={onCancel}
              >
                {cancelButtonText}
              </Button>
            </ThemeProvider>
          </Box>
        )}
        {onSubmit && (
          <Box ml={1}>
            <Button
              disableElevation
              color="primary"
              variant="contained"
              disabled={!canSubmit || canDisableSubmit}
              onClick={onSubmit}
            >
              {submitButtonText}
            </Button>
          </Box>
        )}
        {footerContent && <Box ml={1}>{footerContent}</Box>}
      </Box>
    </Box>
  );
};

export default React.memo(FormLayoutFooter);
