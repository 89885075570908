import React from 'react';
import { Box, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  })
);

type Props = {
  disabled?: boolean;
  children: React.ReactNode;
};

const Disabled: React.FC<Props> = ({ disabled, children }) => {
  const classes = useStyle();

  return <Box className={disabled ? classes.disabled : ''}>{children}</Box>;
};

export default Disabled;
