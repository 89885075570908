import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import LookupFilterLayout from 'styleguide/layout/LookupFilter';
import TextField from 'styleguide/form/Text';
import SingleChoiceField from 'styleguide/form/SingleChoice';
import { CARE_FACILITY_TYPE } from 'master-list/constants';
import { CARE_FACILITY_CODE } from '../../constants';

type Props = {
  applyFilters: (payload: { [key: string]: string }) => void;
  entities: fhir.Organization[] | undefined;
  label?: string;
  clearSearchedResult: () => void;
  type?: string;
};
function getEntityLabel(option: fhir.Organization) {
  return option?.address?.[0].state || '';
}
const HealthCareFacilityLookupFilter: React.FC<Props> = ({
  applyFilters,
  entities,
  label,
  clearSearchedResult,
  type = CARE_FACILITY_CODE,
}) => {
  const defaultState = useMemo(() => {
    return {
      name: '',
      type: '',
      state: '',
    };
  }, []);
  const [filters, setFilters] = useState(defaultState);
  const disableSearchButton = useMemo(() => {
    if (filters?.name || filters?.type || filters?.state) {
      return false;
    } else {
      return true;
    }
  }, [filters]);

  const getFacilityLabel = useCallback((option: fhir.Coding) => {
    return option?.display || '';
  }, []);

  const getFacilityId = useCallback((option: fhir.Coding) => {
    return option?.code || '';
  }, []);

  const hanldeNameChange = value => {
    setFilters({
      ...filters,
      name: value || '',
    });
  };

  const updateStateChange = (option: fhir.Organization | undefined) => {
    setFilters({
      ...filters,
      state: option?.address?.[0].state || '',
    });
  };

  const updateTypeChange = (option: fhir.Coding | undefined) => {
    setFilters({
      ...filters,
      type: option?.code || '',
    });
  };

  const resetFilters = useCallback(() => {
    setFilters(defaultState);
    clearSearchedResult();
  }, [clearSearchedResult, defaultState]);
  const isCareFacility = useMemo(() => type === CARE_FACILITY_CODE, [type]);
  return (
    <LookupFilterLayout
      disableSearchButton={disableSearchButton}
      label={label}
      onApply={() => applyFilters({ ...filters })}
      onReset={resetFilters}
    >
      <Grid container spacing={2}>
        <Grid item xs={isCareFacility ? 12 : 6} sm={isCareFacility ? 12 : 6}>
          <TextField
            label="Name"
            name="name"
            onChange={hanldeNameChange}
            onValidate={() => {}}
            value={filters?.name}
          />
        </Grid>

        {isCareFacility && (
          <Grid item xs={6} sm={6}>
            <SingleChoiceField
              name="type"
              label="Type"
              value={filters.type}
              onChange={updateTypeChange}
              onValidate={() => {}}
              options={CARE_FACILITY_TYPE?.map(item => item?.coding?.[0])}
              variant="drop-down"
              getOptionLabel={getFacilityLabel}
              getOptionId={getFacilityId}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={6}>
          <SingleChoiceField
            name="facilityState"
            label="State"
            value={filters.state}
            onChange={updateStateChange}
            onValidate={() => {}}
            options={entities || []}
            variant="drop-down"
            getOptionLabel={getEntityLabel}
            getOptionId={getEntityLabel}
          />
        </Grid>
      </Grid>
    </LookupFilterLayout>
  );
};

export default HealthCareFacilityLookupFilter;
