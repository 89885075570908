import { ENTRY_LINK as PATIENT_ENTRY_LINK } from 'patient';
import { ENTRY_LINK as TASK_ENTRY_LINK } from 'task';
import { ENTRY_LINK as ACCOUNT_ENTRY_LINK } from 'account';
import { ENTRY_LINK as SCHEDULING_LINK } from 'scheduling';
import { ENTRY_LINK as ADMIN_DASHBOARD_ENTRY_LINK } from 'admin-dashboard';
import { ENTRY_LINK as REPORT_DASHBOARD_ENTRY_LINK } from 'report-dashboard';
import { ENTRY_LINK as CLINICIAN_SCHEDULING_LINK } from 'clinician-scheduling';
import { ENTRY_LINK as CONFIG_ENTRY_LINK } from '../../src/config';
import { ENTRY_LINK as TELEMEDICINE_ENTRY_LINK } from 'telemedicine';
import { ENTRY_LINK as ENCOUNTERS_REVIEW_ENTRY_LINK } from 'encounters-review';
import { ENTRY_LINK as CLINICIAN_ENCOUNTERS_ENTRY_LINK } from 'clinician-encounters';
// import { ENTRY_LINK as ADMINISTRATIVE_CONFIG_ENTRY_LINK } from 'master;
import { ENTRY_LINK as CLINICAL_CONFIG_ENTRY_LINK } from 'clinical-config';
import { ENTRY_LINK as BULK_OPERATIONS } from 'bulk-operations';
import { ENTRY_LINK as ROUNDING_DOCUMENT_ENTRY_LINK } from 'rounding-document';
import { ENTRY_LINK as MASTER_LIST } from 'master-list';
import { ENTRY_LINK as PAYROLL_INFO_ENTRY_LINK } from 'payroll-info';

export const PRIVATE_ROOT_ROUTES: any[] = [
  TASK_ENTRY_LINK,
  CLINICIAN_SCHEDULING_LINK,
  SCHEDULING_LINK,
  CLINICIAN_ENCOUNTERS_ENTRY_LINK,
  PATIENT_ENTRY_LINK,
  TELEMEDICINE_ENTRY_LINK,
  ROUNDING_DOCUMENT_ENTRY_LINK,
  ADMIN_DASHBOARD_ENTRY_LINK,
  BULK_OPERATIONS,
  CONFIG_ENTRY_LINK,
  CLINICAL_CONFIG_ENTRY_LINK,
  MASTER_LIST,
  ENCOUNTERS_REVIEW_ENTRY_LINK,
  ACCOUNT_ENTRY_LINK,
  PAYROLL_INFO_ENTRY_LINK,
  REPORT_DASHBOARD_ENTRY_LINK,
];

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_RECORD_INDEX = 0;
export const PRIVATE_PATHNAME = '/private';
export const PUBLIC_PATHNAME = '/';
export const AUTOCOMPLETE_ATTRIBUTE = 'none';
export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_QUALITY = 40;
export const MAX_IMAGE_SIZE = 1048000;
export const NO_APPOINTMENTS_AVAILABLE =
  'No appointments available on this day.';
export const NOT_AVAILABLE = 'Not Available';

export const flatenRoutes = (routes: any, flatenedRoutes: any[]): any[] => {
  for (let route of routes) {
    if (!!route.children) flatenRoutes(route.children, flatenedRoutes);
    else flatenedRoutes.push(route);
  }
  return flatenedRoutes;
};

export const PRIVATE_FLATENED_ROOT_ROUTES: any[] = flatenRoutes(
  PRIVATE_ROOT_ROUTES,
  []
);

// VALIDATION MESSAGES
export const VALIDATION_ERROR_REQUIRED = 'This field is required';
export const VALIDATION_ERROR_VERIFY_PIN = 'Please verify your PIN';
export const VALIDATION_ERROR_INCORRECT = 'This field has incorrect value';

// VALUE SETS
export const VALUE_SET_GENDER_URL =
  'http://hl7.org/fhir/ValueSet/administrative-gender';
export const VALUE_SET_LAB_COMPANY_URL =
  'http://woundtech.net/fhir/ValueSet/lab-company';
export const VALUE_SET_MARITAL_STATUS_URL =
  'http://hl7.org/fhir/ValueSet/marital-status';
export const VALUE_SET_STATES_URL =
  'http://hl7.org/fhir/us/core/ValueSet/us-core-usps-state';
export const VALUE_SET_PROVIDER_SPECIALTY =
  'http://hl7.org/fhir/us/core/ValueSet/us-core-provider-specialty';
export const VALUE_SET_PRIORITY_URL =
  'http://hl7.org/fhir/ValueSet/request-priority';

export const NAVIGATION_DRAWER_WIDTH = '240px';
export const VALUE_SET_RACE_URL =
  'http://hl7.org/fhir/us/core/ValueSet/omb-race-category';
export const VALUE_SET_ETHNICITY_URL =
  'http://hl7.org/fhir/us/core/ValueSet/omb-ethnicity-category';

export const NO_INTERNET_CONNECTION_ERROR_MESSAGE = 'No internet connection';

export enum StateNameByAbbreviation {
  AZ = 'Arizona',
  CA = 'California',
  FL = 'Florida',
  HI = 'Hawaii',
  NJ = 'New Jersey',
  NV = 'Nevada',
  OH = 'Ohio',
  PR = 'Puerto Rico',
  TX = 'Texas',
  UT = 'Utah',
}

export enum ResourceType {
  Slot = 'Slot',
  Patient = 'Patient',
}
